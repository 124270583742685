import { Market } from '../../../interface/market';
import ProductDetailsComponent from '../product-details';
import './index.scss';

type Props = {
  skus: string[];
  market: Market;
  className?: string;
};

export default function ProductsComponent(props: Props) {
  const { skus, market, className = '' } = props;

  return (
    <section className={`products-container ${className}`}>
      {skus.map((sku, i) => (
        <ProductDetailsComponent key={sku} id={`product-details-${i}`} marketCode={market.code} sku={sku} />
      ))}
    </section>
  );
}
