import { useCallback, useEffect } from 'react';
import { RequestStatusEnum, RequestType } from '../../../resources/approval-request/approval-request-types';
import useModels from '../../../resources/model-output/model-output-hook';
import PaginatedTableComponent, { Column } from '../../../shared/paginated-table';
import { columns as manualColumns } from '../../manual-model';
import useScenarios from '../../../resources/scenarios/scenarios-hook';
import ExternalDataRenderer from '../../../components/hocs/external-data-renderer';
import ReadonlyStepsTable from '../../scenario-list/scenario-details-screen/readonly-scenario-steps/readonly-steps-table';
import useMerchRules from '../../../resources/merch-rules/merch-rules-hook';
import EditableRulesTable from '../../merchandising-rules/merchandising-rules-configuration/editable-rules-table';
import {
  InitialTableListRules,
  MerchRuleConfig,
  MerchRuleVersionType,
} from '../../../resources/merch-rules/merch-rules-types';
import { Typography } from '@amway/react-components';
import MerchandisingRulesConfigurationMetadata from '../../merchandising-rules/merchandising-rules-configuration/merchandising-rules-configuration-metadata';

interface Props {
  itemType: RequestType;
  itemId: string[];
  itemStatus?: RequestStatusEnum;
  selectedItemId: number;
  approvalRequestId: number;
  merchRuleType?: string;
}

export default function AccordionContent({
  itemType,
  itemId,
  selectedItemId,
  approvalRequestId,
  merchRuleType,
  itemStatus,
}: Props) {
  const { getModelOutputVersionById, models } = useModels();
  const { fetchApprovalScenarioVersion, fetchScenarioDraftVersion, draftScenarioVersion } = useScenarios();
  const {
    fetchRules,
    rules: merchRules,
    fetchRulesByVersionIds,
    fetchLiveRulesByItemIds,
    liveRules,
    fetchAffectedByMerchRules,
    affectedByMerchRules,
  } = useMerchRules();

  useEffect(() => {
    let abortModels: VoidFunction | undefined;
    let abortScenario: VoidFunction | undefined;
    let abortAffectedByMerchRules: VoidFunction | undefined;
    let abortLiveRules: VoidFunction | undefined;
    let abortRules: VoidFunction | undefined;
    if (approvalRequestId === selectedItemId) {
      switch (itemType) {
        case RequestType.ManualModel:
          abortModels = getModelOutputVersionById(Number(itemId[0]));
          break;
        case RequestType.StepViewer:
          abortScenario = fetchApprovalScenarioVersion(Number(itemId[0]), Number(approvalRequestId));
          break;
        case RequestType.MerchandisingRules:
          abortAffectedByMerchRules = fetchAffectedByMerchRules({ versionIds: itemId });
          abortLiveRules = fetchLiveRulesByItemIds(itemId, merchRuleType as MerchRuleVersionType, 'versionId');
          abortRules = fetchRulesByVersionIds(itemId);
          break;
        default:
          break;
      }
    }
    return () => {
      abortModels?.();
      abortScenario?.();
      abortAffectedByMerchRules?.();
      abortLiveRules?.();
      abortRules?.();
    };
  }, [
    fetchApprovalScenarioVersion,
    fetchScenarioDraftVersion,
    getModelOutputVersionById,
    itemId,
    itemType,
    selectedItemId,
    approvalRequestId,
    fetchRules,
    fetchRulesByVersionIds,
    fetchLiveRulesByItemIds,
    merchRuleType,
    fetchAffectedByMerchRules,
  ]);

  // ------------------- MERCHANDISING RULES METHODS -----------------------
  const columns = useCallback(
    (type: string) =>
      [
        {
          id: 'name',
          label: type === 'container' ? 'Container' : 'SKU',
        } as any,
        {
          id: 'rules',
          label: 'Rules',
        },
      ] as Column[],
    [],
  );

  const rows = useCallback(
    (data: InitialTableListRules[]) => {
      const rulesSet: MerchRuleConfig[] = [];

      (liveRules.data ?? [])?.forEach(rule => {
        if (rule.rules) rulesSet.push(...rule.rules);
      });

      const formattedRows = data.map(row => ({
        ...row,
        rules: row.rules?.map(rule => {
          const liveVersionRules = (liveRules.data ?? []).find(live => live.name === row.name);
          return (liveVersionRules?.rules ?? []).findIndex(liveRule => liveRule.rule === rule.rule) === -1 &&
            itemStatus === RequestStatusEnum.Pending
            ? rule.rule + ' 🆕'
            : rule.rule;
        }),
      }));

      // verify if any liveRule was deleted
      (liveRules.data ?? [])?.forEach(liveRule => {
        liveRule.rules?.forEach(rules => {
          if (
            formattedRows.findIndex(rule => rule.rules?.some(rule => rule === rules.rule)) === -1 &&
            itemStatus === RequestStatusEnum.Pending
          ) {
            if (formattedRows.findIndex(rule => rule.name === liveRule.name) !== -1) {
              formattedRows[formattedRows.findIndex(rule => rule.name === liveRule.name)].rules?.push(
                rules.rule + ' ❌',
              );
              return;
            } else {
              formattedRows.push({
                ...formattedRows[0],
                name: liveRule.name ?? 'Not found',
                rules: [rules.rule + ' ❌'],
              });
            }
          }
        });
      });

      // rulesSet.forEach(liveRule => {
      //   if (
      //     formattedRows.findIndex(rule => rule.rules?.some(rule => rule === liveRule.rule)) === -1 &&
      //     itemStatus === RequestStatusEnum.Pending
      //   ) {
      //     formattedRows.push({
      //       ...formattedRows[0],
      //       name: liveRule. ?? 'Not found',
      //       rules: [liveRule.rule + ' ❌'],
      //     });
      //   }
      // });

      return formattedRows;
    },
    [itemStatus, liveRules.data],
  );

  const formatDataRules = useCallback((data: InitialTableListRules[]) => {
    const merchRulesRows: MerchRuleConfig[] = [];

    data.forEach(rule => {
      if (rule.rules) merchRulesRows.push(...rule.rules);
    });
    return merchRulesRows;
  }, []);

  const editedMerchRules = useCallback(
    (rules: InitialTableListRules[]) => {
      const merchRulesRows: MerchRuleConfig[] = [];
      const liveRulesSet: MerchRuleConfig[] = [];

      (liveRules.data ?? [])?.forEach(rule => {
        if (rule.rules) liveRulesSet.push(...rule.rules);
      });

      merchRulesRows.push(...formatDataRules(rules));

      // verify if any liveRule was deleted
      liveRulesSet.forEach(liveRule => {
        if (
          merchRulesRows.findIndex(rule => rule.rule === liveRule.rule) === -1 &&
          itemStatus === RequestStatusEnum.Pending
        ) {
          merchRulesRows.push({
            ...liveRule,
            rule: liveRule.rule + ' ❌',
          });
        }
      });

      return merchRulesRows.map(rule => {
        return {
          ...rule,
          rule: Array.isArray(rule.rule) ? rule.rule : [rule.rule],
        };
      });
    },
    [formatDataRules, itemStatus, liveRules.data],
  );

  // -----------------------------------------------------------------

  switch (itemType) {
    case RequestType.ManualModel:
      return (
        <ExternalDataRenderer
          externalData={models}
          makeDataElement={data => (
            <PaginatedTableComponent
              columns={manualColumns}
              rows={data}
              rowIdPropName="id"
              sx={{ maxWidth: 'calc(100vw - 307px - 2*24px - 32px - 38px - 19px)' }}
            />
          )}
        />
      );
    case RequestType.StepViewer:
      return (
        <ExternalDataRenderer
          externalData={draftScenarioVersion}
          makeDataElement={data => <ReadonlyStepsTable scenarioSteps={data.steps} />}
        />
      );
    case RequestType.MerchandisingRules:
      return itemId.length === 1 ? (
        <ExternalDataRenderer
          externalData={merchRules}
          makeDataElement={data => (
            <>
              <ExternalDataRenderer
                externalData={affectedByMerchRules}
                makeDataElement={data => <MerchandisingRulesConfigurationMetadata affectedByMerchRules={data} />}
              />
              <EditableRulesTable editingMerchRules={editedMerchRules(data)} notEditable />
            </>
          )}
        />
      ) : (
        <ExternalDataRenderer
          externalData={merchRules}
          makeDataElement={data =>
            data.length > 0 ? (
              <>
                <ExternalDataRenderer
                  externalData={affectedByMerchRules}
                  makeDataElement={data => <MerchandisingRulesConfigurationMetadata affectedByMerchRules={data} />}
                />
                <ExternalDataRenderer
                  externalData={liveRules}
                  makeDataElement={() => (
                    <PaginatedTableComponent columns={columns(data[0].type)} rows={rows(data)} rowIdPropName="id" />
                  )}
                />
              </>
            ) : (
              <Typography variant="heading">No results found.</Typography>
            )
          }
        />
      );
    default:
      return <div />;
  }
}
