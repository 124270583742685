import React from 'react';
import { Form } from 'react-bootstrap';
import './index.scss';

export interface Props {
  title?: string;
  name?: string;
  value?: string;
  placeholder: string;
  required?: boolean;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  className?: string;
  errorMessage?: string | false;
}

const Input: React.FC<Props> = props => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { title, name, value, onChange, placeholder, required, className, errorMessage } = props;

  return (
    <Form.Group className={`our-input-block ${className}`}>
      {title && (
        <Form.Label>
          {title} {required && <b style={{ color: 'var(--warning-error)' }}>*</b>}
        </Form.Label>
      )}
      <Form.Control
        {...(name ? { name } : {})}
        className={`our-input ${errorMessage ? 'has-error' : ''}`}
        placeholder={placeholder}
        required={required}
        value={value}
        onChange={onChange}
      />
      {errorMessage && <span className="feedback-invalid">{errorMessage}</span>}
    </Form.Group>
  );
};

export default Input;
