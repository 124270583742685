import { mdiCursorDefaultClickOutline, mdiPencil } from '@mdi/js';
import Icon from '@mdi/react';
import { Checkbox, SxProps, TableHead, Theme } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Form, Row as BsRow } from 'react-bootstrap';
import CollapsibleTableRow from './collapsible-table-row';
import './index.scss';
import TableCellOverlayComponent from './table-cell-overlay';
import TablePaginationActions from './table-pagination-actions';
import { BsDashSquareFill } from 'react-icons/bs';
import { Button, Typography } from '@amway/react-components';
import Tooltip from '../tooltip';

export interface Row<T = any> {
  [key: string]: any;
  __meta?: {
    className?: string;
    onClick?: (row: Row) => void;
    payload?: T;
    // If this is true, the row is not included on counting
    countable?: boolean;
  };
}

export interface Column {
  id: string;
  label: string;
  minWidth?: number;
  maxWidth?: number;
  align?: 'right';
  href?: string;
  format?: (value: number) => string;
  sx?: (value: number | string) => SxProps<Theme>;
  // this is intended to return the navigation url
  onNavClick?: (row: Row) => string;
}

type Props = {
  columns: Column[];
  rows: Row[];
  rowIdPropName: string;
  selectedRowsPerPage?: number;
  searchable?: boolean;
  collapsible?: boolean;
  collapsibleRowsPropName?: string;
  collapsibleBeforeChevronColumns?: string[][];
  sx?: SxProps<Theme>;
  onClick?: (column: Column, row: Row) => void;
  className?: string;
  withoutPagination?: boolean;
  selectableRows?: boolean;
  setSelectedRows?: (rows: string[]) => void;
  handleEditSelected?: (rows: string[]) => void;
};

export default function PaginatedTableComponent({
  columns = [],
  rows = [],
  rowIdPropName,
  withoutPagination = false,
  selectedRowsPerPage = 10,
  searchable = false,
  collapsible = false,
  collapsibleRowsPropName,
  collapsibleBeforeChevronColumns = [],
  sx,
  onClick,
  className = '',
  selectableRows = false,
  setSelectedRows,
  handleEditSelected,
}: Props) {
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(selectedRowsPerPage);
  const [filteredRows, setFilteredRows] = useState<any[]>(rows);
  const [selected, setSelected] = useState<string[]>([]);

  useEffect(() => {
    setSelectedRows?.(selected);
  }, [selected, setSelectedRows]);

  useEffect(() => {
    if (withoutPagination) {
      setRowsPerPage(Number.MAX_SAFE_INTEGER);
    } else {
      setRowsPerPage(selectedRowsPerPage);
    }
  }, [selectedRowsPerPage, withoutPagination]);

  useEffect(() => {
    setFilteredRows(rows);
  }, [rows]);

  const displayCollapsibleRow = useMemo(
    () => collapsible && collapsibleRowsPropName,
    [collapsible, collapsibleRowsPropName],
  );

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = useMemo(
    () => (page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredRows.length) : 0),
    [page, rowsPerPage, filteredRows],
  );

  const handleChangePage = useCallback((event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  }, []);

  const handleChangeRowsPerPage = useCallback((event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }, []);

  const formatCell = useCallback((column: Column, row: any) => {
    const value = row[column.id];
    return column.format && typeof value === 'number' ? column.format(value) : value;
  }, []);

  const filter = useCallback(
    (searchText: string = '') => {
      if (searchText) {
        setPage(0);
        setFilteredRows(
          rows.filter(row => Object.values(row).some((value: any) => value?.toString().includes(searchText))),
        );
      } else {
        setFilteredRows(rows);
      }
    },
    [rows],
  );

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.map(n => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const handleSelectItem = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  return (
    <Paper className={`paginated-table ${className}`} sx={{ boxShadow: 'none' }}>
      <TableContainer sx={sx}>
        {searchable && (
          <Form>
            <BsRow>
              <Col>
                <Form.Group>
                  <Form.Control
                    style={{ maxWidth: '350px', marginLeft: 'auto', marginBottom: '20px' }}
                    placeholder="Search..."
                    onChange={e => filter(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </BsRow>
          </Form>
        )}
        {selectableRows && selected && selected?.length > 0 && (
          <BsRow className="mb-2">
            <Col
              style={{
                padding: '15px 5px',
                display: 'flex',
                background: 'var(--primary)',
                alignItems: 'center',
                gap: '10px',
              }}>
              <BsDashSquareFill
                onClick={() => setSelected([])}
                color="white"
                size="20px"
                className="ms-4"
                style={{ cursor: 'pointer' }}
              />{' '}
              <Typography color="bg-white">
                {selected?.length} of {rows.length} items selected
              </Typography>
              <Button
                onClick={() => {
                  handleEditSelected?.(selected);
                }}
                className="me-4"
                style={{ marginLeft: 'auto' }}
                variant="link"
                fontColor="bg-white">
                Edit selected items
              </Button>
            </Col>
          </BsRow>
        )}
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {displayCollapsibleRow && <TableCell />}
              {selectableRows && (
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={rows.length > 0 && selected.length === rows.length}
                    onChange={handleSelectAllClick}
                    inputProps={{
                      'aria-label': 'select all rows',
                    }}
                  />
                </TableCell>
              )}
              {columns.map(column => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  sx={{
                    fontFamily: 'Lato, sans-serif',
                  }}>
                  <strong>{column.label}</strong>
                </TableCell>
              ))}
              {rows.some(row => row?.onEditClick) && <TableCell key="9879879" />}
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? filteredRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : filteredRows
            ).map(row => {
              const isItemSelected = isSelected(row.id);
              return displayCollapsibleRow ? (
                <CollapsibleTableRow
                  key={row[rowIdPropName]}
                  columns={columns}
                  row={row}
                  formatCell={formatCell}
                  collapsibleRowIdPropName={rowIdPropName}
                  collapsibleRowsPropName={collapsibleRowsPropName!}
                  collapsibleBeforeChevronColumns={collapsibleBeforeChevronColumns}
                  collapsibleRows={row[collapsibleRowsPropName!]}
                  onClick={onClick}
                />
              ) : (
                <TableRow
                  selected={selectableRows && isItemSelected}
                  onClick={
                    selectableRows && !row?.onEditClick?.isActionDisabled
                      ? event => handleSelectItem(event, row.id)
                      : undefined
                  }
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={row[rowIdPropName]}>
                  {selectableRows && (
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          'aria-label': 'select all desserts',
                        }}
                      />
                    </TableCell>
                  )}
                  {columns.map(column => (
                    <TableCellOverlayComponent
                      key={column.id}
                      column={column}
                      row={row}
                      formatCell={formatCell}
                      onClick={onClick}
                    />
                  ))}
                  {row?.onEditClick && (
                    <TableCell
                      padding="checkbox"
                      onClick={row?.onEditClick?.isActionDisabled ? () => {} : () => row.onEditClick?.onClick?.(row)}>
                      <Tooltip
                        title={
                          <>
                            {row?.onEditClick?.isActionDisabled
                              ? 'You have a pending request'
                              : row?.onEditClick?.isDraft
                              ? 'Continue Editing'
                              : 'Edit Item'}
                            <Icon
                              className="ms-1"
                              path={mdiCursorDefaultClickOutline}
                              size="16px"
                              color="var(--bg-white)"
                            />
                          </>
                        }>
                        <div className={`pencil-icon-edit-row ${row?.onEditClick?.isActionDisabled ? 'disabled' : ''}`}>
                          <Icon
                            path={mdiPencil}
                            size="25px"
                            color={`${
                              row?.onEditClick?.isActionDisabled ? 'var(--warning-disable)' : 'var(--primary)'
                            }`}
                          />
                          <div className={`${row?.onEditClick?.isDraft ? 'has-changes' : ''}`} />
                        </div>
                      </Tooltip>
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {!withoutPagination && (
        <TablePagination
          component="div"
          rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
          colSpan={displayCollapsibleRow ? columns.length + 1 : columns.length}
          count={filteredRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{
            inputProps: {
              'aria-label': 'rows per page',
            },
            native: true,
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
          sx={{ overflow: 'hidden' }}
        />
      )}
    </Paper>
  );
}
