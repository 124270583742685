import { FacetKind } from '../../interface/filter-types';

export enum FilterNames {
  Type = 'Type',
  AnalysisStatus = 'Analysis Status',
  // Market = 'Market',
}

export const initialFilterValues = [
  {
    name: FilterNames.Type,
    kind: FacetKind.Intersection,
    values: [
      { name: 'Step Viewer', value: 'step-viewer' },
      { name: 'Manual Model', value: 'manual-model' },
      { name: 'Merchandising Rules', value: 'merchandising-rules' },
      { name: 'Container Header', value: 'container-header' },
    ],
  },
  {
    name: FilterNames.AnalysisStatus,
    kind: FacetKind.Intersection,
    values: [
      { name: 'Pending', value: 'PENDING_APPROVAL' },
      { name: 'Approved', value: 'APPROVED' },
      { name: 'Rejected', value: 'REJECTED' },
      { name: 'Canceled', value: 'CANCELED' },
    ],
  },
  // {
  //   name: FilterNames.Market,
  //   kind: FacetKind.Intersection,
  //   values: [
  //     { name: 'United States', value: 'us' },
  //     { name: 'Brasil', value: 'br' },
  //     { name: 'Argentina', value: 'ar' },
  //   ],
  // },
];

export const initialOrderByValues = [
  { name: 'Most Recent', value: 'most' },
  { name: 'Least Recent', value: 'least' },
];
