import { mdiAlertCircle, mdiCheckCircle } from '@mdi/js';
import Icon from '@mdi/react';
import { MouseEvent, MouseEventHandler, useCallback, useMemo } from 'react';
import { Badge, CloseButton } from 'react-bootstrap';
import LoadingComponent from '../loading-component';
import './index.scss';

const VerifierIcon: React.FC<{ verified?: Verified }> = ({ verified }) => {
  if (verified === undefined) {
    return <LoadingComponent className="me-1 spinner" />;
  } else if (verified.success) {
    return <Icon path={mdiCheckCircle} color="var(--warning-success)" size={0.6} className="me-1" />;
  }

  return <Icon path={mdiAlertCircle} color="var(--warning-error)" size={0.6} className="me-1" />;
};

const resolveVerifierClassName = (verified?: Verified) => {
  if (verified === undefined) return 'pending';
  else if (verified.success) return 'verified';
  return 'not-verified';
};

export type BadgeColorTheme = 'gray' | 'red' | 'orange' | 'green' | 'verifier';

export interface Verified {
  success: boolean;
}

type Props = {
  id: string;
  label: string;
  theme: BadgeColorTheme;
  verified?: Verified;
  onClose: () => void;
};

export default function ClosableBadgeComponent(props: Props) {
  const { id, label, verified, theme, onClose } = props;
  const hasVerifierTheme = useMemo(() => theme === 'verifier', [theme]);

  const handleClose: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      onClose();
    },
    [onClose],
  );
  return (
    <Badge
      id={id}
      title={label}
      className={`closable-badge ${theme} ${hasVerifierTheme ? resolveVerifierClassName(verified) : undefined}`}>
      {hasVerifierTheme && <VerifierIcon verified={verified} />}
      {label}
      <CloseButton onClick={handleClose} />
    </Badge>
  );
}
