import { useState } from 'react';
import { ScenarioDetails } from '../../../../resources/scenarios/scenarios-models';
import {
  ScenarioParentStep,
  ScenarioStep,
  ScenarioSteps,
} from '../../../../resources/scenarios/scenarios-versions-models';
import { Row } from '../../../../shared/paginated-table';
import ParentStepEditionConfirmationCell from './components/parent-step-edition-confirmation-cell';
import ParentStepMenuPopupCell from './components/parent-step-menu-popup-cell';
import ParentStepNameCell from './components/parent-step-name-cell';
import ParentStepNameEditorCell from './components/parent-step-name-editor-cell';
import StepActionButtonsCell from './components/step-action-buttons-cell';
import StepMoverCell, { Props as StepMoverCellProps } from './components/step-mover-cell';
import {
  StepAdderOrientation,
  isStepAdderRow,
  makeParentStepLabel,
  spreadStepRowsWithStepAdderRows,
} from './scenario-steps-helpers';

export interface BuilderHandlers {
  handleOrderChange: StepMoverCellProps['onMove'];
  handleDeleteStep: (step: ScenarioParentStep | ScenarioStep) => void;
  handleEditParentStep: (step: ScenarioParentStep, newName?: string) => void;
  handleAddChildStep: (parentStep: ScenarioParentStep) => void;
  handleAddStep: (refStep: ScenarioParentStep | ScenarioStep, orientation: StepAdderOrientation) => void;
}

export const useStepsRowsDataManager = (
  parentSteps: ScenarioSteps,
  handlers: BuilderHandlers,
  scenarioDetails: ScenarioDetails,
): Row[] => {
  const { handleOrderChange, handleDeleteStep, handleEditParentStep, handleAddChildStep, handleAddStep } = handlers;
  const [editingParentStep, setEditingParentStep] = useState<ScenarioParentStep>();

  const onEditParentStepClick = (parentStep: ScenarioParentStep): boolean => {
    setEditingParentStep({ ...parentStep });
    return true;
  };

  const onEditingParentStepChange = (newName: string) => {
    setEditingParentStep(data => ({ ...data, name: newName } as any));
  };

  const onFinishEditionHandler = () => {
    handleEditParentStep(editingParentStep!, editingParentStep!.name);
    setEditingParentStep(undefined);
  };

  const onCancelEditionHandler = () => {
    setEditingParentStep(undefined);
  };

  const onDeleteParentStepClick = (parentStep: ScenarioParentStep) => {
    handleDeleteStep(parentStep);
    return true;
  };

  const onAddChildStepClick = (parentStep: ScenarioParentStep): boolean => {
    handleAddChildStep(parentStep);
    return true;
  };

  const handleStepAdderClick = (refStep: ScenarioParentStep | ScenarioStep, orientation: StepAdderOrientation) => {
    handleAddStep(refStep, orientation);
  };

  let counter = 1;

  return spreadStepRowsWithStepAdderRows(parentSteps, handleStepAdderClick).map((parentStep: any) =>
    isStepAdderRow(parentStep)
      ? parentStep
      : {
          ...parentStep,
          name:
            editingParentStep && parentStep.id === editingParentStep.id ? (
              <ParentStepNameEditorCell
                value={editingParentStep.name ?? makeParentStepLabel(parentStep)}
                onChange={newName => onEditingParentStepChange(newName)}
                onFinishEdition={onFinishEditionHandler}
              />
            ) : (
              <ParentStepNameCell step={parentStep} />
            ),
          settings:
            editingParentStep && parentStep.id === editingParentStep.id ? (
              <ParentStepEditionConfirmationCell onApply={onFinishEditionHandler} onCancel={onCancelEditionHandler} />
            ) : (
              <ParentStepMenuPopupCell
                onEdit={() => onEditParentStepClick(parentStep)}
                onDelete={() => onDeleteParentStepClick(parentStep)}
                onAddChildStep={() => onAddChildStepClick(parentStep)}
              />
            ),
          order: <StepMoverCell step={parentStep} stepList={parentSteps} onMove={handleOrderChange} />,
          executionSeq: counter++,
          subSteps: parentStep.subSteps.map((subStep: any) =>
            isStepAdderRow(subStep)
              ? subStep
              : {
                  ...subStep,
                  order: !subStep.standard && (
                    <StepMoverCell step={subStep} stepList={parentStep.subSteps} onMove={handleOrderChange} />
                  ),
                  executionSeq: counter++,
                  settings: (
                    <StepActionButtonsCell
                      step={subStep}
                      scenarioDetails={scenarioDetails}
                      onDelete={() => handleDeleteStep(subStep)}
                    />
                  ),
                },
          ),
        },
  );
};
