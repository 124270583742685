import { Button } from '@amway/react-components';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Card, Container, Row } from 'react-bootstrap';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import ExternalDataRenderer from '../../../components/hocs/external-data-renderer';
import { Market } from '../../../interface/market';
import { RecommendationParamsRequest } from '../../../interface/recommender';
import useBreadcrumbs from '../../../resources/breadcrumbs/breadcrumbs-hook';
import useRecommendation from '../../../resources/recommendation/recommendation-hook';
import useScenarios from '../../../resources/scenarios/scenarios-hook';
import DropdownBtn, { Item } from '../../../shared/dropdown-btn';
import LogoLoadingComponent from '../../../shared/logo-loading-component';
import Title from '../../../shared/title';
import Typography from '../../../shared/typography';
import Breadcrumbs from '../../../shared/ui/breadcrumbs';
import ApproveRequestModal from '../../request-list/modals/approve-request';
import RejectRequestModal from '../../request-list/modals/reject-request';
import ProductsComponent from '../../scenario-tester/products';
import RecommendedProductsComponent from '../../scenario-tester/recommended-products';
import ScenarioTesterParamsForm, {
  ScenarioTesterFilterFormFields,
} from '../../scenario-tester/scenario-tester-filters-form';
import './index.scss';
import SendToApprovalModal from './send-to-approval-modal';

interface Props {}

const ScenarioVersionTesterScreen: React.FC<Props> = props => {
  const navigate = useNavigate();
  const {
    scenarioDetails: scenarioDetailsData,
    draftScenarioVersion,
    fetchScenarioDetails,
    fetchScenarioVersionByVersionId,
    fetchScenarioDraftVersion,
    fetchApprovalScenarioVersion,
    sendScenarioVersionToApproval,
  } = useScenarios();
  const { recommendation, fetchRecommendationByScenarioVersionId, resetRecommendations } = useRecommendation();
  const { id, scenarioVersionId } = useParams();
  const [searchParams] = useSearchParams();
  const approvalRequest = useMemo(
    () => (searchParams.get('approval') ? Number(searchParams.get('approval')) : undefined),
    [searchParams],
  );
  const { breadcrumbs } = useBreadcrumbs();
  const [recommendationParams, setRecommendationParams] = useState<RecommendationParamsRequest | null>(null);
  const [isSendToApprovalModalOpen, setIsSendToApprovalModalOpen] = useState(false);
  const [isApproveRequestModalOpen, setIsApproveRequestModalOpen] = useState(false);
  const [isRejectRequestModalOpen, setIsRejectRequestModalOpen] = useState(false);
  const [channelId, pageId] = useMemo(() => {
    if (!scenarioDetailsData.data) return [undefined, undefined, undefined];
    const market = scenarioDetailsData.data.markets[0];
    const channel = market.channels[0];
    const page = channel.pages[0];

    return [channel.id.toString(), page.id.toString()];
  }, [scenarioDetailsData.data]);
  const [markets, setMarkets] = useState<Item[]>([]);
  const scenarioMarketCodes = useMemo(() => {
    if (!scenarioDetailsData.data) return [];

    setMarkets(scenarioDetailsData.data.markets.map(market => ({ ...market, label: market.name })));
    return scenarioDetailsData.data.markets.map(mkt => mkt.code.toString());
  }, [scenarioDetailsData.data]);
  const [market, setMarket] = useState<Market>();
  const [isSendApprovalRequestLoading, setIsSendApprovalRequestLoading] = useState(false);
  const [inputSkus, setInputSkus] = useState<string[]>([]);

  useEffect(() => {
    fetchScenarioDetails(parseInt(id as string));
    if (scenarioVersionId) {
      fetchScenarioVersionByVersionId(parseInt(id as string), Number(scenarioVersionId));
    } else if (approvalRequest) {
      fetchApprovalScenarioVersion(parseInt(id as string), approvalRequest);
    } else {
      fetchScenarioDraftVersion(parseInt(id as string));
    }
  }, [
    approvalRequest,
    fetchApprovalScenarioVersion,
    fetchScenarioDetails,
    fetchScenarioDraftVersion,
    fetchScenarioVersionByVersionId,
    id,
    scenarioVersionId,
  ]);

  useEffect(() => {
    return () => {
      resetRecommendations();
    };
  }, [resetRecommendations]);

  const handleBackToConfigurations = () => {
    navigate(`/scenario-list/${id}/edit`);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleScenarioParamsFormChanges = useCallback(
    _.debounce((request: ScenarioTesterFilterFormFields) => {
      setRecommendationParams(request);
      setInputSkus(request.skus);
    }, 100),
    [],
  );

  const handleFetchRecommendations = () => {
    fetchRecommendationByScenarioVersionId(market!.code, parseInt(id as string), {
      ...recommendationParams!,
      scenarioVersionId: parseInt(scenarioVersionId || '') || draftScenarioVersion.data?.id,
      // these ids are beeing passed just to fill the request and not throw an error
      channel: channelId!,
      pageName: pageId!,
    });
  };

  const handleSendToApproval = (title: string, description: string) => {
    setIsSendApprovalRequestLoading(true);
    sendScenarioVersionToApproval(
      parseInt(id as string),
      scenarioVersionId ? parseInt(scenarioVersionId) : draftScenarioVersion.data!.id,
      title,
      description,
    ).then(() => {
      setIsSendApprovalRequestLoading(false);
      navigate(`/scenario-list/${id}`);
    });
  };

  const handleCancel = () => {
    setIsSendToApprovalModalOpen(false);
  };

  const handleOpenSendToApprovalModal = () => {
    setIsSendToApprovalModalOpen(true);
  };

  const selectMarket = useCallback((market: Market) => {
    if (market) {
      setMarket(market);
    }
  }, []);

  const productsStyle = useMemo(
    () => ({
      maxWidth: inputSkus.length * (240 + 117) + 'px',
    }),
    [inputSkus],
  );

  return (
    <Container className="scenario-version-tester-container">
      <Card className="scenario-tester-params">
        <Card.Body>
          <Breadcrumbs breadcrumbs={breadcrumbs} />
          <ExternalDataRenderer
            externalData={scenarioDetailsData}
            makeDataElement={scenarioDetails => (
              <>
                <Title>Test Scenario Configuration</Title>
                <Typography weight="bold">
                  Test your changes on <Link to={`/scenario-list/${scenarioDetails.id}`}>{scenarioDetails.name}</Link>{' '}
                  Scenario before sending the request.
                </Typography>
                <DropdownBtn
                  id="dropdown-test-scenario-market"
                  className="dropdown-test-scenario-market"
                  required
                  label="Market"
                  placeholder="Select Market"
                  items={markets}
                  onClick={selectMarket as unknown as (item?: Item | null) => void}
                />
                <ScenarioTesterParamsForm
                  className="mt-3"
                  marketId={market?.id}
                  onFormChange={handleScenarioParamsFormChanges}
                />
                {market && inputSkus.length > 0 && (
                  <>
                    <Row className="title">
                      <Card.Title>Input</Card.Title>
                    </Row>
                    <Card.Text className="input-description">These are the products you selected as input.</Card.Text>
                    <Row className="products" style={productsStyle}>
                      <ProductsComponent market={market} skus={inputSkus} />
                    </Row>
                  </>
                )}
                <footer className="params-actions-container">
                  <Button
                    disabled={!market ? true : !draftScenarioVersion.data && !scenarioVersionId ? true : false}
                    onClick={handleFetchRecommendations}>
                    TEST RECOMMENDATIONS
                  </Button>
                </footer>
              </>
            )}
          />
        </Card.Body>
      </Card>
      <ExternalDataRenderer
        externalData={recommendation}
        makeLoadingElement={() => (
          <Card>
            <Card.Body>
              <LogoLoadingComponent />
            </Card.Body>
          </Card>
        )}
        makeDataElement={recommendationData => (
          <Card>
            <Card.Body className="recommendation-results-container">
              <span className="execution-metrics">
                <Typography sizeVariant="sm">Matched Scenario: {scenarioDetailsData.data?.name}</Typography>
                <Typography sizeVariant="sm">Execution time: {recommendationData.executionTime}ms</Typography>
              </span>
              <Title>Recommendation Results</Title>
              <Typography weight="bold">Previewing container based on scenario and custom parameters.</Typography>
              <RecommendedProductsComponent
                className="products-container"
                marketCode={market!.code}
                recommendations={recommendationData.recommendations}
              />
              <footer className="actions-container">
                <Button variant="link" fontColor="primary" onClick={handleBackToConfigurations}>
                  BACK TO CONFIGURATIONS
                </Button>
                {approvalRequest ? (
                  <div style={{ display: 'flex', justifyContent: 'center', gap: '48px' }}>
                    <Button variant="link" fontColor="warning-error" onClick={() => setIsRejectRequestModalOpen(true)}>
                      REJECT CHANGES
                    </Button>
                    <Button backgroundColor="warning-success" onClick={() => setIsApproveRequestModalOpen(true)}>
                      APPROVE CHANGES
                    </Button>
                  </div>
                ) : (
                  <Button onClick={handleOpenSendToApprovalModal}>SEND REQUEST</Button>
                )}
              </footer>
            </Card.Body>
          </Card>
        )}
      />
      <SendToApprovalModal
        isLoading={isSendApprovalRequestLoading}
        isOpen={isSendToApprovalModalOpen}
        scenarioTitle={scenarioDetailsData.data?.name ?? ''}
        onSend={handleSendToApproval}
        onCancel={handleCancel}
      />
      <ApproveRequestModal
        scenarioMarketCodes={scenarioMarketCodes}
        isOpen={isApproveRequestModalOpen}
        onCancel={() => setIsApproveRequestModalOpen(false)}
        id={String(approvalRequest) ?? '0'}
      />
      <RejectRequestModal
        isOpen={isRejectRequestModalOpen}
        onCancel={() => setIsRejectRequestModalOpen(false)}
        id={String(approvalRequest) ?? '0'}
      />
    </Container>
  );
};

export default ScenarioVersionTesterScreen;
