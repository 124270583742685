import { useCallback, useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import './index.css';

type Props = {
  lastSubmitDate: Date;
  pollingEnabled: boolean;
  pollingTimeExpired: boolean;
  startDate?: string;
  endDate?: string;
};

export default function DashboardPollingInfoComponent({
  lastSubmitDate,
  pollingEnabled,
  pollingTimeExpired,
  startDate,
  endDate,
}: Props) {
  const toLocaleDateString = useCallback(
    (dateISOString?: string) => (dateISOString ? new Date(dateISOString).toLocaleDateString() : null),
    [],
  );
  const lastUpdate = useMemo<string>(() => lastSubmitDate.toLocaleTimeString(), [lastSubmitDate]);
  const startLocaleDate = useMemo<string>(() => toLocaleDateString(startDate) ?? '', [startDate, toLocaleDateString]);
  const endLocaleDate = useMemo<string>(() => toLocaleDateString(endDate) ?? '', [endDate, toLocaleDateString]);

  return (
    <Row className="polling-info">
      <Col>
        {pollingEnabled ? (
          pollingTimeExpired ? (
            <p>Last update at {lastUpdate}</p>
          ) : (
            <p className="real-time">Viewing real time data</p>
          )
        ) : (
          <p>
            Viewing data from {startLocaleDate} to {endLocaleDate}
          </p>
        )}
      </Col>
    </Row>
  );
}
