import OktaAuth, { AuthState, toRelativeUrl } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { apiWrapper } from '../../config/api-wrapper';
import { oktaAuth } from '../../config/okta-auth';
import { resolveLocationFromState } from '../../utils/location.utils';
import AuthContext, { SingInOptions } from './auth-context';
import { User } from './user-model';

const AuthProvider: React.FC = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [user, setUser] = useState<User | undefined>();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

  const signIn = useCallback(async (options: SingInOptions): Promise<void> => {
    void oktaAuth.signInWithRedirect(options);
  }, []);

  const signOut = useCallback((): void => {
    void oktaAuth.signOut();
  }, []);

  const onOktaAuthChanges = useCallback(async (authState: AuthState) => {
    if (authState.isAuthenticated) {
      apiWrapper.setAuthToken(authState.accessToken!.accessToken);
      setIsAuthenticated(true);
      try {
        const user = await oktaAuth.token.getUserInfo();
        const { name, email } = user;
        setUser({ name: name as string, email: email as string });
      } catch {
        setIsAuthenticated(false);
      }
    } else {
      setIsAuthenticated(false);
      setUser(undefined);
      apiWrapper.clearAuthToken();
    }
  }, []);

  useEffect(() => {
    void oktaAuth.authStateManager.updateAuthState();

    oktaAuth.authStateManager.subscribe(onOktaAuthChanges);

    oktaAuth.tokenManager.on('expired', (key, expiredToken) => {
      console.log('Token with key', key, ' has expired:', expiredToken);
      void signIn({ originalUri: resolveLocationFromState(location) });
    });

    return () => {
      console.log('authClient authStateManager unsubscribed');
      oktaAuth.authStateManager.unsubscribe();
    };
  }, [location, onOktaAuthChanges, signIn]);

  const handleRestoreOriginalUri = useCallback(
    (_oktaAuth: OktaAuth, originalUri: string) => {
      navigate(toRelativeUrl(originalUri || '/', window.location.origin), {
        replace: true,
      });
    },
    [navigate],
  );

  return (
    <AuthContext.Provider value={{ user, isAuthenticated, signIn, signOut }}>
      <Security oktaAuth={oktaAuth} restoreOriginalUri={handleRestoreOriginalUri}>
        {children}
      </Security>
    </AuthContext.Provider>
  );
};

export default AuthProvider;
