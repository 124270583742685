import { InitialTableListRules, MerchRuleVersionType } from './merch-rules-types';

export const initialTableListRulesMapper = (apiData: any, type: MerchRuleVersionType): InitialTableListRules[] => {
  return apiData.map((data: any) => ({
    id: type?.toLowerCase() === 'sku' ? data.productId : data.containerId,
    name: type?.toLowerCase() === 'sku' ? data.sku : data.name,
    userHasDraft: data.userHasDraft,
    userHasPending: data.userHasPendingApproval,
    type: type?.toLowerCase(),
    rules:
      data.merchRuleConfigs && data.merchRuleConfigs.length > 0
        ? data.merchRuleConfigs.map((rule: any) => ({
            id: rule.id,
            attribute: rule.attribute,
            priority: rule.priority,
            value: rule.value,
            operation: rule.operation,
            exceptions: rule.exceptions,
            lastUpdate: data.lastUpdatedDt,
            status: rule.status,
            rule: `if ${rule.attribute} is ${rule.value} then ${rule.operation} ${
              rule.exceptions ? `except ${rule.exceptions.join(', ')}` : ''
            }`,
          }))
        : data.rules && data.rules.length > 0
        ? data.rules.map((rule: any) => ({
            id: rule.id,
            attribute: rule.attribute,
            priority: rule.priority,
            value: rule.value,
            operation: rule.operation,
            exceptions: rule.exceptions,
            lastUpdate: data.lastUpdatedDt,
            status: rule.status,
            rule: `if ${rule.attribute} is ${rule.value} then ${rule.operation} ${
              rule.exceptions ? `except ${rule.exceptions.join(', ')}` : ''
            }`,
          }))
        : null,
    lastUpdate: data.lastUpdatedDt,
  }));
};

export const merchRuleConfigMapper = (apiData: any): any => {
  return apiData.map((data: any) => ({
    id: data.id,
    merchRuleName: data?.merchRuleName,
    attribute: data.attribute,
    priority: data.priority,
    value: data.value,
    operation: data.operation,
    exceptions: data.exceptions,
    lastUpdate: data.lastUpdatedDt,
    status: data.status,
    rule: `if ${data.attribute} is ${data.value} then ${data.operation} ${
      data.exceptions ? `except ${data.exceptions.join(', ')}` : ''
    }`,
  }));
};
