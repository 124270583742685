import { Container, Image, Navbar } from 'react-bootstrap';
import environment, { Environment } from '../../config/env';
import useAuth from '../../resources/auth/auth-hook';
import UserDetailsComponent from '../user-details';
import UserSignedOutComponent from '../user-signed-out';
import './index.scss';
import QaNavBarContainer from './qa-navbar-container';

const shouldShowQaContainer = [Environment.DEV, Environment.QA].includes(environment.ENV);

export default function NavBarComponent() {
  const auth = useAuth();

  return (
    <Navbar bg="primary" sticky="top" id="navbar">
      <Container fluid>
        {shouldShowQaContainer && <QaNavBarContainer />}
        <Navbar.Brand href="/">
          <Image src="/assets/amway-logo.svg" width="98" height="35" />
          Product Recommender
        </Navbar.Brand>
        <div className="user-container">
          <Image className="notification-bell me-5" src="/assets/notification-bell-icon.svg" width="18" height="21" />
          {auth?.user ? <UserDetailsComponent user={auth.user} /> : <UserSignedOutComponent />}
        </div>
      </Container>
    </Navbar>
  );
}
