import React, { useMemo } from 'react';
import PaginatedTableComponent from '../../../../../shared/paginated-table';
import { ScenarioSteps } from '../../../../../resources/scenarios/scenarios-versions-models';
import ParentStepNameCell from '../../scenario-steps/components/parent-step-name-cell';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';
import './index.scss';
import { ScenarioVersionStatus } from '../../../../../resources/scenarios/scenarios-versions-models';
import { ModelOutput } from '../../../../../resources/model-output/model-output-models';

interface Props {
  scenarioSteps?: ScenarioSteps;
  models?: ModelOutput[];
  marketCode?: string;
  className?: string;
  status?: string;
  scenarioVersionId?: number;
}

const columns = [
  {
    id: 'executionSeq',
    label: 'Step',
    format: (value: number) => value.toLocaleString(),
  },
  {
    id: 'name',
    label: 'Name',
  },
];

const modelColumns = [
  {
    id: 'keyType',
    label: 'Key Type',
    format: (value: number) => `${value.toLocaleString()} key types`,
    sx: (value: number | string) => (typeof value === 'number' ? { color: '#4994EE' } : {}),
  },
  {
    id: 'key',
    label: 'Key',
    format: (value: number) => `${value.toLocaleString()} keys`,
    sx: (value: number | string) => (typeof value === 'number' ? { color: '#4DD895' } : {}),
  },
  {
    id: 'productListStr',
    label: 'Product List',
    // format: (value: string) => `${value.toLocaleString()} products`,
    sx: (value: number | string) => (typeof value === 'number' ? { color: '#F4CD67' } : {}),
  },
];

export const makeTableSteps = (scenarioSteps: ScenarioSteps) => {
  let counter = 1;

  return scenarioSteps.map(parentStep => ({
    ...parentStep,
    name: <ParentStepNameCell step={parentStep} />,
    executionSeq: counter++,
    subSteps: parentStep.subSteps.map(subStep => ({
      executionSeq: counter++,
      ...subStep,
    })),
  }));
};

function ReadonlyStepsTable(props: Props) {
  const { scenarioSteps, models, marketCode } = props;
  const rawTableSteps = scenarioSteps ? makeTableSteps(scenarioSteps) : models;
  const tableSteps = useMemo(() => rawTableSteps, [rawTableSteps]);
  const path = marketCode ? marketCode + '/edit/test/' + props.scenarioVersionId : 'test/' + props.scenarioVersionId;

  return (
    <>
      <PaginatedTableComponent
        columns={scenarioSteps ? columns : modelColumns}
        rows={tableSteps!}
        rowIdPropName="id"
        collapsible={true}
        collapsibleRowsPropName="subSteps"
        withoutPagination
        className={props.className}
      />
      {props?.className && props?.status !== ScenarioVersionStatus.LIVE && (
        <Link className="link-readonly-steps-table" color="primary" component={RouterLink} to={path}>
          test and request restore
        </Link>
      )}
    </>
  );
}

export default ReadonlyStepsTable;
