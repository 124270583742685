import { mdiAlert } from '@mdi/js';
import { Icon } from '@mdi/react';
import './index.scss';

interface Props {
  title: string;
  description: React.ReactNode;
  className?: string;
  hidden?: boolean;
}

function InlineNotification(props: Props) {
  const { title, description, hidden, className = '' } = props;

  return (
    <section className={`save-confirmation-container ${hidden ? 'hidden' : ''} ${className}`}>
      <article className="save-confirmation-content">
        <h4>
          <Icon path={mdiAlert} size={0.7} color="var(--warning-orange)" className="me-1" />
          {title}
        </h4>
        {description}
      </article>
    </section>
  );
}

export default InlineNotification;
