import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import PaginatedTableComponent from '../../../../shared/paginated-table';
import './index.scss';
import { MerchRuleConfig } from '../../../../resources/merch-rules/merch-rules-types';
import { useRulesEditorDataManagement } from './use-rules-editor-data-management';
import { Direction } from './editor-mover-cell';
import { moveIfCan } from '../../../../utils/array.utils';
import { editableColumns, readonlyColumns } from './helpers';
import { Button } from '@amway/react-components';

interface Props {
  handleGoToTests?: (latestData: MerchRuleConfig[]) => void;
  isSaving?: boolean;
  className?: string;
  editingMerchRules: MerchRuleConfig[];
  setEditingMerchRules?: React.Dispatch<React.SetStateAction<MerchRuleConfig[]>>;
  setDeletedMerchRules?: React.Dispatch<React.SetStateAction<String[]>>;
  handleResetChanges?: () => void;
  notEditable?: boolean;
}

function EditableRulesTable(props: Props) {
  const navigate = useNavigate();
  const {
    editingMerchRules,
    setEditingMerchRules,
    isSaving,
    className = '',
    handleGoToTests,
    setDeletedMerchRules,
    handleResetChanges,
    notEditable,
  } = props;

  const handleRuleOrderChange = useCallback(
    (ruleId: number, dir: Direction) => {
      const ruleIdx = editingMerchRules?.findIndex(rule => rule.priority === ruleId);
      const convertedDir = dir === Direction.UP ? -1 : 1;

      setEditingMerchRules?.(rules => {
        const updatedRows = moveIfCan(rules.slice(), ruleIdx!, convertedDir);
        return updatedRows.map((rule, idx) => ({ ...rule, priority: idx + 1 }));
      });
    },
    [editingMerchRules, setEditingMerchRules],
  );

  const handleDeleteRule = useCallback(
    (rule: MerchRuleConfig) => {
      // remove rule from list
      let updatedRules = editingMerchRules?.filter(_rule => _rule.priority !== rule.priority);
      // update priority
      updatedRules = updatedRules?.map((rule, idx) => ({ ...rule, priority: idx + 1 }));
      setEditingMerchRules?.(updatedRules);
      if (rule.id) {
        setDeletedMerchRules?.(prev => [...prev, rule.id!.toString()]);
      }
    },
    [editingMerchRules, setDeletedMerchRules, setEditingMerchRules],
  );

  const handleTryToGoBack = () => {
    navigate(`/admin-configurations/merchandising-rules`);
  };

  const handleTryToGoToTests = () => {
    handleGoToTests?.(editingMerchRules!);
  };

  const merchRulesRows = useRulesEditorDataManagement(editingMerchRules!, {
    handleOrderChange: handleRuleOrderChange,
    handleDeleteRule: handleDeleteRule,
  });

  return (
    <>
      <section className={`scenario-steps-container ${className}`}>
        <PaginatedTableComponent
          columns={notEditable ? readonlyColumns : editableColumns}
          rows={notEditable ? editingMerchRules! : merchRulesRows}
          rowIdPropName="rule"
          withoutPagination
          className="steps-table-container"
          selectableRows={false}
        />
        {notEditable === undefined && (
          <footer className="actions-footer mt-3">
            <Button onClick={handleTryToGoBack} variant="link" fontColor="primary">
              BACK TO MERCHANDISING RULES
            </Button>
            <div style={{ display: 'flex', gap: '48px' }}>
              <Button onClick={handleResetChanges} variant="link" fontColor="warning-error">
                RESET CHANGES
              </Button>
              <Button
                disabled={handleGoToTests === undefined || editingMerchRules?.length === 0 || isSaving}
                onClick={handleTryToGoToTests}
                loading={isSaving}>
                TEST CHANGES
              </Button>
            </div>
          </footer>
        )}
      </section>
    </>
  );
}

export default EditableRulesTable;
