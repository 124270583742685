import { Row } from '../../../../shared/paginated-table';
import { MerchRuleConfig } from '../../../../resources/merch-rules/merch-rules-types';
import EditorMoverCell, { Props as RulesEditorMoverCell } from './editor-mover-cell';
import IconButton from '../../../../shared/icon-btn';
import { mdiDeleteOutline } from '@mdi/js';

export interface BuilderHandlers {
  handleOrderChange: RulesEditorMoverCell['onMove'];
  handleDeleteRule: (rule: MerchRuleConfig) => void;
}

export const useRulesEditorDataManagement = (merchRules: MerchRuleConfig[], handlers: BuilderHandlers): Row[] => {
  const { handleDeleteRule, handleOrderChange } = handlers;

  const onDeleteParentStepClick = (rule: MerchRuleConfig) => {
    handleDeleteRule(rule);
    return true;
  };

  let counter = 1;

  type ExtendedMerchRule = MerchRuleConfig & { settings?: JSX.Element; order?: JSX.Element };

  return merchRules.map((rule: ExtendedMerchRule) => ({
    ...rule,
    settings: rule.settings ?? (
      <IconButton
        onClick={() => onDeleteParentStepClick(rule)}
        mdiIconPath={mdiDeleteOutline}
        size="20px"
        color="var(--primary)"
        style={{ marginLeft: 'auto' }}
      />
    ),
    order: rule.order ?? <EditorMoverCell rule={rule} rulesList={merchRules} onMove={handleOrderChange} />,
    executionSeq: counter++,
  }));
};
