import { TableCell } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Column } from '..';
import Chips from '../../chips';

export interface Props {
  column: Column;
  row: any;
  formatCell: (column: Column, row: any) => string;
  onClick?: (column: Column, row: any) => void;
}

export default function TableCellOverlayComponent({ column, row, formatCell, onClick }: Props) {
  const cellContent: string = useMemo(() => formatCell(column, row), [column, row, formatCell]);

  const displayOverlay = useMemo(
    () => column.maxWidth && cellContent && cellContent.length - 4 > column.maxWidth,
    [column, cellContent],
  );

  const displayChips = useMemo(() => {
    if (Array.isArray(cellContent)) {
      return cellContent;
    }
    return undefined;
  }, [cellContent]);

  const sx = useMemo(() => {
    const value = row[column.id];
    return column.sx ? column.sx(value) : undefined;
  }, [column, row]);

  const href: string = useMemo(() => {
    const cellValue = row[column.id];

    return column.href
      ? row[column.href]
      : typeof cellValue === 'string' && cellValue.startsWith('http')
      ? cellValue
      : undefined;
  }, [column, row]);

  const handleClick = useCallback(() => {
    if (onClick) {
      onClick(column, row);
    }
  }, [onClick, column, row]);

  return (
    <TableCell
      align={column.align}
      onClick={handleClick}
      sx={{
        fontFamily: 'Lato, sans-serif',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        maxWidth: column.maxWidth ? column.maxWidth + 'em' : undefined,
        cursor: onClick ? 'pointer' : 'auto',
        ...sx,
      }}>
      {displayOverlay ? (
        <OverlayTrigger
          placement="auto-start"
          overlay={
            <Tooltip>
              <>{cellContent}</>
            </Tooltip>
          }>
          {href ? (
            <a target="_blank" href={href} rel="noreferrer">
              {cellContent}
            </a>
          ) : column.onNavClick ? (
            <Link to={column.onNavClick(row)}>{cellContent}</Link>
          ) : (
            <span>{cellContent}</span>
          )}
        </OverlayTrigger>
      ) : href ? (
        <a target="_blank" href={href} rel="noreferrer">
          {cellContent}
        </a>
      ) : column.onNavClick ? (
        <Link to={column.onNavClick(row)}>{cellContent}</Link>
      ) : displayChips ? (
        <Chips chips={displayChips.map(chip => ({ title: chip, value: chip }))} />
      ) : (
        <>{cellContent}</>
      )}
    </TableCell>
  );
}
