import { Typography } from '@amway/react-components';
import Icon from '@mdi/react';
import { Card, Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import './index.scss';

export interface ConfigurationCardProps {
  title: string;
  description: string;
  mdiIconPath: string;
  to: string;
  active?: boolean;
}

export default function ConfigurationCard({
  title,
  description,
  mdiIconPath,
  to,
  active = true,
}: ConfigurationCardProps) {
  const navigate = useNavigate();

  const handleCardClick = () => {
    navigate(to);
  };

  return (
    <Card
      className={`configuration-card-container ${!active ? 'disabled' : ''}`}
      onClick={active ? handleCardClick : undefined}>
      <Card.Body className="configuration-card-body card-body--with-margin">
        <Col className="configuration-card-body card-body--aligned">
          <Icon path={mdiIconPath} size="24px" color="var(--primary)" />
          <Typography variant="subheading" className="title-test-test">
            {title}
          </Typography>
        </Col>
        <Row>
          <Col>
            <Typography color="text-gray">{description}</Typography>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}
