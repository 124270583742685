import { array, ease, Root, Tooltip } from '@amcharts/amcharts5';
import { AxisRenderer } from '@amcharts/amcharts5/.internal/charts/xy/axes/AxisRenderer';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import {
  AxisRendererX,
  AxisRendererY,
  CategoryAxis,
  ColumnSeries,
  ValueAxis,
  XYChart,
  XYCursor,
} from '@amcharts/amcharts5/xy';
import { CSSProperties, useCallback, useEffect, useState } from 'react';
import { DashboardRecommendationMetrics } from '../../../interface/dashboard';
import './index.css';

type Props = {
  id: string;
  style?: CSSProperties;
  data: DashboardRecommendationMetrics[];
};

export default function DashboardSortedBarChartComponent({ id, style, data }: Props) {
  const [root, setRoot] = useState<Root>();
  const [chart, setChart] = useState<XYChart>();
  const [yAxis, setYAxis] = useState<CategoryAxis<AxisRenderer>>();
  const [series, setSeries] = useState<ColumnSeries>();

  // Get series item by category
  const getSeriesItem = useCallback((series: ColumnSeries, category: string) => {
    for (var i = 0; i < series.dataItems.length; i++) {
      let dataItem = series.dataItems[i];
      if (dataItem.get('categoryY') === category) {
        return dataItem;
      }
    }
  }, []);

  // Axis sorting
  const sortCategoryAxis = useCallback(
    (series: ColumnSeries, yAxis: CategoryAxis<AxisRenderer>) => {
      // Sort by value
      series.dataItems.sort((a, b) => {
        const aValue = a.get('valueX') ?? 0;
        const bValue = b.get('valueX') ?? 0;
        return aValue - bValue; // descending
        //return a.get("valueY") - b.get("valueX"); // ascending
      });

      // Go through each axis item
      array.each(yAxis.dataItems, dataItem => {
        // get corresponding series item
        let seriesDataItem = getSeriesItem(series, dataItem.get('category')!);

        if (seriesDataItem) {
          // get index of series data item
          const index = series.dataItems.indexOf(seriesDataItem);
          // calculate delta position
          const deltaPosition = (index - dataItem.get('index', 0)) / series.dataItems.length;
          // set index to be the same as series data item index
          dataItem.set('index', index);
          // set deltaPosition instanlty
          dataItem.set('deltaPosition', -deltaPosition);
          // animate delta position to 0
          dataItem.animate({
            key: 'deltaPosition',
            to: 0,
            duration: 1000,
            easing: ease.out(ease.cubic),
          });
        }
      });

      // Sort axis items by index.
      // This changes the order instantly, but as deltaPosition is set,
      // they keep in the same places and then animate to true positions.
      yAxis.dataItems.sort((x, y) => {
        const xIndex = x.get('index') ?? 0;
        const yIndex = y.get('index') ?? 0;
        return xIndex - yIndex;
      });
    },
    [getSeriesItem],
  );

  useEffect(() => {
    if (!root) {
      setRoot(Root.new(id));
    }
  }, [id, root]);

  useEffect(() => {
    if (root && !chart) {
      // Set themes
      root.setThemes([am5themes_Animated.new(root)]);

      // Create chart
      const chart = root.container.children.push(
        XYChart.new(root, {
          panX: false,
          panY: false,
          wheelX: 'none',
          wheelY: 'none',
        }),
      );

      // We don't want zoom-out button to appear while animating, so we hide it
      chart.zoomOutButton.set('forceHidden', true);

      setChart(chart);
    }
  }, [root, chart]);

  useEffect(() => {
    if (root && chart) {
      // Create axes
      const yRenderer = AxisRendererY.new(root, {
        minGridDistance: 30,
      });

      const yAxis = chart.yAxes.push(
        CategoryAxis.new(root, {
          maxDeviation: 0,
          categoryField: 'name',
          renderer: yRenderer,
          tooltip: Tooltip.new(root, { themeTags: ['axis'] }),
        }),
      );

      const xAxis = chart.xAxes.push(
        ValueAxis.new(root, {
          maxDeviation: 0,
          min: 0,
          extraMax: 0.1,
          renderer: AxisRendererX.new(root, {}),
        }),
      );

      const series = chart.series.push(
        ColumnSeries.new(root, {
          name: 'Series 1',
          xAxis,
          yAxis,
          valueXField: 'total',
          categoryYField: 'name',
          tooltip: Tooltip.new(root, {
            pointerOrientation: 'left',
            labelText: 'Requests: {valueX}',
          }),
        }),
      );

      // Rounded corners for columns
      series.columns.template.setAll({
        cornerRadiusTR: 5,
        cornerRadiusBR: 5,
      });

      // Make each column to be of a different color
      series.columns.template.adapters.add('fill', (fill, target) =>
        chart.get('colors')?.getIndex(series.columns.indexOf(target as any)),
      );

      series.columns.template.adapters.add('stroke', (stroke, target) =>
        chart.get('colors')?.getIndex(series.columns.indexOf(target as any)),
      );

      chart.set(
        'cursor',
        XYCursor.new(root, {
          behavior: 'none',
          xAxis,
          yAxis,
        }),
      );

      setYAxis(yAxis);
      setSeries(series);
    }
  }, [root, chart]);

  useEffect(() => {
    if (chart && series && yAxis && series && data.length) {
      yAxis.data.setAll(data);
      series.data.setAll(data);
      sortCategoryAxis(series, yAxis);

      // Make stuff animate on load
      series.appear(1000);
      chart.appear(1000, 100);
    }
  }, [chart, yAxis, series, sortCategoryAxis, data]);

  return <div id={id} className="dashboard-sorted-bar-chart" style={style} />;
}
