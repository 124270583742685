import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

export type Value = string | string[];

interface SingleQueryParam {
  key: string;
  value: Value;
}

export type QueryParams = Record<string, Value>;

type InitialQueryParams = Record<string, string>;

interface QueryParamsOptions {
  state?: any;
}

type UrlParam = [key: string, value: string];

type SetQueryParams = (params: QueryParams) => void;

type UseQueryParams = (
  initialQueryParams: InitialQueryParams,
  queryParamsOptions?: QueryParamsOptions,
) => [QueryParams, SetQueryParams];

const isValueEmpty = (value: Value): boolean => {
  return Array.isArray(value) ? value.length === 0 : value === '';
};

const keyValueTupleToSingleQueryParam = ([key, value]: [key: string, value: Value]): SingleQueryParam => {
  return { key, value };
};

const urlParamValueToValue = (urlParamValue: string): Value => {
  let paramValue: Value = urlParamValue.split(',');

  if (paramValue.length === 1) {
    paramValue = paramValue[0];
  }

  return paramValue;
};

const singleQueryParamToUrlParam = (param: SingleQueryParam): UrlParam => {
  const { key, value: valueOrValues } = param;
  return [key, Array.isArray(valueOrValues) ? valueOrValues.join(',') : valueOrValues];
};

const urlParamsToSearchParams = (urlParams: UrlParam[]): string => {
  return new URLSearchParams(urlParams).toString();
};

const searchParamsToQueryParams = (searchParams: URLSearchParams): QueryParams => {
  return Array.from(searchParams.entries()).reduce<QueryParams>((accumulator, entry) => {
    const [key, value] = entry;
    accumulator[key] = urlParamValueToValue(value);
    return accumulator;
  }, {});
};

const useQueryParams: UseQueryParams = (initialQueryParams: InitialQueryParams, options?: QueryParamsOptions) => {
  const [searchParams, setSearchParams] = useSearchParams(initialQueryParams);
  const [params, setParams] = useState<QueryParams>(searchParamsToQueryParams(searchParams));

  const handleParamsChanges = useCallback((): void => {
    const newQueryParams: QueryParams = Array.from(searchParams.entries()).reduce<QueryParams>((accumulator, entry) => {
      const [key, value] = entry;
      accumulator[key] = urlParamValueToValue(value);
      return accumulator;
    }, {});

    setParams(newQueryParams);
  }, [searchParams]);

  useEffect(() => {
    handleParamsChanges();
  }, [handleParamsChanges, searchParams]);

  const setQueryParams = useCallback<SetQueryParams>(
    queryParams => {
      const urlParams = Object.entries(queryParams)
        .map(entry => keyValueTupleToSingleQueryParam(entry))
        .filter(({ value }) => !isValueEmpty(value))
        .map(givenParam => singleQueryParamToUrlParam(givenParam));

      setSearchParams(urlParamsToSearchParams(urlParams), options);
    },
    [setSearchParams, options],
  );

  return [params, setQueryParams];
};

export default useQueryParams;
