// This enum is used by the Accordion component
export enum RequestStatusEnum {
  Pending = 'Pending',
  Rejected = 'Rejected',
  Approved = 'Approved',
  Canceled = 'Canceled',
}
// This enum is used by our API
export enum ApprovalStatus {
  PendingApproval = 'PendingApproval',
  Approved = 'Approved',
  Rejected = 'Rejected',
  Canceled = 'Canceled',
}

export enum RequestType {
  StepViewer = 'step-viewer',
  ManualModel = 'manual-model',
  ContainerHeader = 'container-header',
  MerchandisingRules = 'merchandising-rules',
}

export interface ApprovalRequest {
  approvalStatus?: ApprovalStatus;
  market?: string;
}

export interface ScenarioTransitionRequest {
  id: number;
  scenarioId: number;
  status: string;
  decisionReason: string;
}

export interface ScenarioCancelRequest {
  id: number;
  scenarioId: number;
}

export interface ModelTransitionRequest {
  modelId: number;
  status: string;
  decisionReason: string;
}

export interface MerchandisingRulesTransitionRequest {
  approvalId: string;
  status: string;
  decisionReason: string;
}

export interface ApprovalItem {
  id: number;
  createdDt: Date;
  updatedDt: Date;
  createdBy: string;
  updatedBy: string;
  status: ApprovalStatus;
  title: string;
  requestReason: string;
  decisionReason: string;
  referenceId: string[];
  itemType?: RequestType;
  headerLabel: string;
  versionId?: string;
  marketId?: string;
}
