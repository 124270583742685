import { mdiChevronDoubleRight } from '@mdi/js';
import Icon from '@mdi/react';
import { Drawer, DrawerProps } from '@mui/material';
import './index.scss';

interface Props {
  isOpen: boolean;
  close: VoidFunction;
  HeaderActions?: JSX.Element;
  className?: string;
}

const DrawerLayout: React.FC<Props> = props => {
  const { children, HeaderActions, isOpen, close, className = '' } = props;

  const onTryClose: DrawerProps['onClose'] = (e, reason) => {
    close();
  };

  return (
    <Drawer open={isOpen} anchor="right" onClose={onTryClose}>
      <section className="drawer-container">
        <header className="drawer-header">
          <span className="drawer-close" onClick={close}>
            <Icon size={'26px'} path={mdiChevronDoubleRight} color="var(--primary)" />
            Close
          </span>
          {HeaderActions && HeaderActions}
        </header>
        <article className={`drawer-content-container ${className}`}>{children}</article>
      </section>
    </Drawer>
  );
};

export default DrawerLayout;
