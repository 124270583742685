import { Typography } from '@amway/react-components';
import { Card, Col, Container, Row } from 'react-bootstrap';
import ConfigurationCard from './configuration-card';
import { links } from './helpers';
import './index.scss';

export default function AdminConfigurations() {
  return (
    <Container className="admin-configurations">
      <Card>
        <Card.Body>
          <Row>
            <Col>
              <Typography variant="heading">Admin Configurations</Typography>
              <Typography weight="bold" color="text-gray" className="mt-3">
                Click on one option to access the configurations.{' '}
              </Typography>
            </Col>
          </Row>
          <div style={{ display: 'flex', justifyContent: 'space-between', margin: '32px 32px', gap: '32px' }}>
            {links.map(link => (
              <ConfigurationCard key={link.to} {...link} />
            ))}
          </div>
        </Card.Body>
      </Card>
    </Container>
  );
}
