import { useCallback, useState } from 'react';
import { ToastContainer } from 'react-bootstrap';
import { ToastType } from '../../@types/toasts';
import Toast from '../../shared/toast';
import { randomId } from '../../utils/id.utils';
import ToastsContext, { PushOptions } from './toasts-context';

const ToastsProvider: React.FC<{}> = ({ children }) => {
  const [toasts, setToasts] = useState<
    Array<{ id: string; text: string; type?: ToastType; delay?: number; customTitle?: string }>
  >([]);

  const push = useCallback((text: string, type?: ToastType, options?: PushOptions): void => {
    const { customTitle, delay } = options ?? {};
    setToasts(prev => [...prev, { id: randomId(), text, type, delay, customTitle }]);
  }, []);

  (window as any).push = push;

  const remove = useCallback((id: string): void => {
    setToasts(prev => prev.filter(toast => toast.id !== id));
  }, []);

  return (
    <ToastsContext.Provider value={{ push }}>
      <ToastContainer style={{ zIndex: 99999 }} className="p-5 mt-5" position="top-end">
        {toasts.map(toast => (
          <Toast
            key={toast.id}
            text={toast.text}
            type={toast.type}
            customTitle={toast.customTitle}
            delay={toast.delay}
            onClose={() => remove(toast.id)}
          />
        ))}
      </ToastContainer>
      {children}
    </ToastsContext.Provider>
  );
};

export default ToastsProvider;
