import { Column } from '../../../../shared/paginated-table';

export const editableColumns: Column[] = [
  {
    id: 'order',
    label: '',
  },
  {
    id: 'priority',
    label: 'Priority',
  },
  {
    id: 'rule',
    label: 'Rule',
  },
  {
    id: 'settings',
    label: '',
  },
];

export const readonlyColumns: Column[] = [
  {
    id: 'priority',
    label: 'Priority',
  },
  {
    id: 'rule',
    label: 'Rule',
  },
];
