import React from 'react';
import {
  NewStep,
  ScenarioParentStep,
  ScenarioStep,
} from '../../../../../../resources/scenarios/scenarios-versions-models';
import Typography from '../../../../../../shared/typography';
import { isScenarioChildStep, isStepAdderRow } from '../../scenario-steps-helpers';
import './index.scss';

interface Props {
  step: ScenarioParentStep | ScenarioStep | NewStep;
  className?: string;
}

const StepFigure: React.FC<{ step: ScenarioStep | NewStep }> = ({ step }) => {
  return <span className={`step-figure ${step.modelName ? 'step-rect' : 'step-circle'}`} />;
};

export const ChildStepViewer: React.FC<{ step: ScenarioStep | NewStep }> = ({ step }) => {
  return (
    <article className="step-item">
      <header>
        <StepFigure step={step} />
        <h6>{step.name}</h6>
      </header>
      {step.modelName && (
        <section>
          <Typography>Model name: </Typography> <Typography colorVariant="primary">{step.modelName}</Typography>
        </section>
      )}
    </article>
  );
};

const StepViewer: React.FC<Props> = props => {
  const { step, className = '' } = props;

  if (isScenarioChildStep(step)) {
    return (
      <section className={`parent-step-viewer-container ${className}`}>
        <section className="steps-list fancy-scrollbar">
          <ChildStepViewer step={step as ScenarioStep} />
        </section>
      </section>
    );
  } else {
    return (
      <section className={`parent-step-viewer-container ${className}`}>
        <h3 title={(step as ScenarioParentStep).name}>{(step as ScenarioParentStep).name}</h3>
        <section className="steps-list fancy-scrollbar">
          {(step as ScenarioParentStep).subSteps
            .filter(item => !isStepAdderRow(item))
            .map(subStep => (
              <ChildStepViewer key={subStep.id} step={subStep} />
            ))}
        </section>
      </section>
    );
  }
};

export default StepViewer;
