import { Chip } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { AiOutlinePlus } from 'react-icons/ai';
import DropdownBtn, { Item } from '../../../../shared/dropdown-btn';
import SeparatorInputComponent from '../../../../shared/separator-input';
import Switch from '../../../../shared/switch';
import { attributes, operators } from './utils';
import {
  MerchRuleConfig,
  MerchRuleConfigAttribute,
  MerchRuleConfigOperation,
} from '../../../../resources/merch-rules/merch-rules-types';
import merchRulesService from '../../../../resources/merch-rules/merch-rules.service';
import { LoadingComponent } from '@amway/react-components';
import useToasts from '../../../../resources/toasts/toasts-hook';
import adminService from '../../../../service/admin.service';
import { Brand } from '../../../../interface/brand';
import { Category } from '../../../../interface/category';

interface Props {
  setEditingMerchRules: React.Dispatch<React.SetStateAction<MerchRuleConfig[]>>;
  marketId: number;
}

export default function MerchandisingRulesConfigurationForm({ setEditingMerchRules, marketId }: Props) {
  const [newRule, setNewRule] = useState<{
    attribute?: string;
    value?: string;
    operation?: string;
    exception?: string;
  }>();
  const [hasException, setHasException] = useState<boolean>(false);
  const [inputSkus, setInputSkus] = useState<string[]>([]);
  const [validSkus, setValidSkus] = useState<string[]>([]);
  const [invalidSkus, setInvalidSkus] = useState<string[]>([]);
  const [loadingSkuValidation, setLoadingSkuValidation] = useState<boolean>(false);
  const [categories, setCategories] = useState<Item[]>([]);
  const [brands, setBrands] = useState<Item[]>([]);
  const [selectedBrand, setSelectedBrand] = useState<Brand>();
  const [selectedCategory, setSelectedCategory] = useState<Category>();

  const { push: pushToast } = useToasts();

  const handleInputSkusChanges = useCallback((newSkus: string[]) => {
    setInputSkus(prev => {
      if (JSON.stringify(newSkus) === JSON.stringify(prev)) return prev;
      if (newSkus.length === 0) return [];

      if (newSkus.length < prev.length) {
        // if user deleted a SKU, remove it from valid and invalid lists
        setValidSkus([]);
        setInvalidSkus([]);
        return [];
      }

      const sku = newSkus[newSkus.length - 1];

      setLoadingSkuValidation(true);
      merchRulesService
        .verifySkuExistence(sku)
        .then(res => {
          if (res) {
            setValidSkus([sku]);
            setInvalidSkus([]);
          } else {
            setValidSkus([]);
            setInvalidSkus([sku]);
          }
        })
        .catch(err => {
          console.error(err);
          setValidSkus([]);
          setInvalidSkus([sku]);
        })
        .finally(() => {
          setLoadingSkuValidation(false);
        });

      return [sku];
    });
  }, []);

  const handleExceptionSkusChanges = useCallback(newSkus => {
    setInputSkus(prev => {
      if (newSkus === prev) return prev;
      if (newSkus.length === 0) return [];

      if (newSkus.length < prev.length) {
        // if user deleted a SKU, remove it from valid and invalid lists
        const deletedSku = prev.find(sku => !newSkus.some((newSku: any) => newSku === sku));
        if (deletedSku) {
          setValidSkus(validSkusState => validSkusState.filter(validSku => validSku !== deletedSku));
          setInvalidSkus(invalidSkusState => invalidSkusState.filter(invalidSku => invalidSku !== deletedSku));
        }
      }

      if (newSkus.length !== 0) {
        let sku = newSkus[newSkus.length - 1];
        // only fetch SKU if not fetched yet
        if (!prev.some(existingSku => existingSku === sku)) {
          setLoadingSkuValidation(true);
          merchRulesService
            .verifySkuExistence(sku)
            .then(res => {
              if (res) {
                setValidSkus(validSkusState => [...validSkusState, sku]);
              } else {
                setInvalidSkus(invalidSkusState => [...invalidSkusState, sku]);
              }
            })
            .catch(err => {
              console.error(err);
              setInvalidSkus(invalidSkusState => [...invalidSkusState, sku]);
            })
            .finally(() => setLoadingSkuValidation(false));
        }
      }

      return newSkus;
    });
  }, []);

  const loadBrands = useCallback(
    (marketId: number) => {
      adminService
        .getBrands(marketId)
        .then(response => {
          response.forEach(page => {
            (page as unknown as Item).label = page.name;
          });
          setBrands(response as unknown as Item[]);
        })
        .catch(err => {
          console.error(err);
          pushToast('Could not fetch brands', 'error');
        });
    },
    [pushToast],
  );

  const loadCategories = useCallback(
    (marketId: number) => {
      adminService
        .getCategories(marketId)
        .then(response => {
          response.forEach(page => {
            (page as unknown as Item).label = page.categoryName;
          });
          setCategories(response as unknown as Item[]);
        })
        .catch(err => {
          console.error(err);
          pushToast('Could not fetch categories', 'error');
        });
    },
    [pushToast],
  );

  useEffect(() => {
    setNewRule(prev => ({ ...prev, exception: undefined }));
    setInputSkus([]);
    setValidSkus([]);
    setInvalidSkus([]);
  }, [hasException]);

  const selectAttribute = useCallback(
    (attribute: Item) => {
      if (attribute) {
        setNewRule(prev => ({
          ...prev,
          attribute: attribute.id as string,
        }));

        setSelectedBrand(undefined);
        setSelectedCategory(undefined);
        setInputSkus([]);
        setValidSkus([]);
        setInvalidSkus([]);
        if (attribute.id === 'sku') {
          setHasException(false);
          setNewRule(prev => ({ ...prev, exception: undefined }));
        }
        if (attribute.id === 'brand' && brands.length === 0) {
          loadBrands(marketId);
        } else if (attribute.id === 'category' && categories.length === 0) {
          loadCategories(marketId);
        }
      }
    },
    [brands.length, categories.length, loadBrands, loadCategories, marketId],
  );

  const selectOperation = useCallback((operation: Item) => {
    if (operation) {
      setNewRule(prev => ({
        ...prev,
        operation: operation.id as string,
      }));
    }
  }, []);

  useEffect(() => {
    if (selectedBrand) {
      setNewRule(prev => ({
        ...prev,
        value: selectedBrand.name,
      }));
    } else if (selectedCategory) {
      setNewRule(prev => ({
        ...prev,
        value: selectedCategory.categoryName,
      }));
    } else if (newRule?.attribute === 'sku') {
      setNewRule(prev => ({
        ...prev,
        value: inputSkus.join(' '),
      }));
    }
  }, [inputSkus, newRule?.attribute, selectedBrand, selectedCategory]);

  const validateFields = useCallback(() => {
    return newRule?.attribute && newRule?.value && newRule?.operation && invalidSkus.length === 0;
  }, [invalidSkus.length, newRule?.attribute, newRule?.operation, newRule?.value]);

  const handleAddRule = useCallback(() => {
    if (validateFields()) {
      setEditingMerchRules(prev => {
        const newRuleConcat = `if ${newRule?.attribute} is ${newRule?.value} then ${newRule?.operation}${
          hasException ? `, except ${inputSkus.join(', ')}` : ''
        }`;
        if (prev.findIndex(rule => rule.rule === newRuleConcat) !== -1) {
          pushToast('Rule already exists', 'warning');
          return prev;
        }
        return [
          ...prev,
          {
            priority: prev.length + 1,
            attribute: newRule?.attribute! as MerchRuleConfigAttribute,
            value: newRule?.value!,
            operation: newRule?.operation! as MerchRuleConfigOperation,
            exceptions: hasException ? inputSkus : undefined,
            status: 'ACTIVE',
            rule: newRuleConcat,
          },
        ];
      });
    }
  }, [
    hasException,
    inputSkus,
    newRule?.attribute,
    newRule?.operation,
    newRule?.value,
    pushToast,
    setEditingMerchRules,
    validateFields,
  ]);

  const adjustFormAligment = useMemo(() => {
    return invalidSkus.length > 0 || loadingSkuValidation ? 'mb-4' : '';
  }, [invalidSkus.length, loadingSkuValidation]);

  return (
    <Row style={{ justifyContent: 'space-between' }}>
      <Col style={{ width: 'max-content' }}>
        <Row style={{ alignItems: 'end' }}>
          <Col xl={3} xxl={2} className="mb-1">
            <Chip label="if" />
          </Col>
          <Col xl={9} xxl={10}>
            <DropdownBtn
              id="dropdown-merchandising-rules-configuration-attribute"
              required={true}
              label="Attribute"
              placeholder="Select Attribute"
              items={attributes}
              onClick={selectAttribute as unknown as (item?: Item | null) => void}
            />
          </Col>
        </Row>
      </Col>
      <Col style={{ width: 'max-content' }}>
        <Row style={{ alignItems: 'end' }}>
          <Col xl={3} xxl={2} className={`mb-1 ${newRule?.attribute === 'sku' ? adjustFormAligment : ''}`}>
            <Chip label="is" />
          </Col>
          <Col xl={9} xxl={10}>
            <Form.Group id="value-text">
              <Form.Label htmlFor="value-text-ctrl">
                Value <sup style={{ color: 'var(--warning-error)' }}>*</sup>
              </Form.Label>

              {newRule?.attribute === 'brand' && (
                <DropdownBtn
                  id="dropdown-brands"
                  disabled={brands.length === 0}
                  none={false}
                  placeholder="Select Brands"
                  items={brands}
                  onClick={setSelectedBrand as unknown as (item?: Item | null) => void}
                />
              )}
              {(newRule?.attribute === 'category' || newRule?.attribute === undefined) && (
                <DropdownBtn
                  id="dropdown-categories"
                  disabled={categories.length === 0 || newRule?.attribute === undefined}
                  none={false}
                  placeholder="Select Categories"
                  items={categories}
                  onClick={setSelectedCategory as unknown as (item?: Item | null) => void}
                />
              )}

              {newRule?.attribute === 'sku' && (
                <>
                  <SeparatorInputComponent
                    id="skus"
                    badgeTheme="gray"
                    separator=" "
                    value={inputSkus}
                    validValues={validSkus}
                    invalidValues={invalidSkus}
                    onChange={handleInputSkusChanges}
                  />
                  {loadingSkuValidation && (
                    <div className="loading-label">
                      Validating SKU <LoadingComponent />
                    </div>
                  )}
                  {invalidSkus.length > 0 && <div className="error-label">Added non-existent SKU</div>}
                </>
              )}
            </Form.Group>
          </Col>
        </Row>
      </Col>
      <Col style={{ width: 'max-content' }}>
        <Row style={{ alignItems: 'end' }}>
          <Col xl={4} xxl={!hasException ? 2 : 3} className="mb-1">
            <Chip label="then" />
          </Col>
          <Col xl={8} xxl={!hasException ? 10 : 9}>
            <DropdownBtn
              id="dropdown-merchandising-rules-configuration-operation"
              required={true}
              label="Operation"
              placeholder="Select Operation"
              items={operators}
              onClick={selectOperation as unknown as (item?: Item | null) => void}
            />
          </Col>
        </Row>
      </Col>
      {newRule?.attribute !== 'sku' && (
        <>
          {!hasException ? (
            <Col xl={2} xxl={2} className="mt-4" style={{ width: 'max-content' }}>
              <Switch checked={hasException} onChange={e => setHasException(e.target.checked)} /> Exceptions
            </Col>
          ) : (
            <Col xl={9} xxl={10} style={{ width: 'max-content', marginTop: '-6px' }}>
              <Switch checked={hasException} onChange={e => setHasException(e.target.checked)} /> Exceptions
              <SeparatorInputComponent
                id="skus"
                badgeTheme="gray"
                separator=" "
                value={inputSkus}
                validValues={validSkus}
                invalidValues={invalidSkus}
                onChange={handleExceptionSkusChanges}
              />
              {loadingSkuValidation && (
                <div className="loading-label">
                  Validating SKU <LoadingComponent />
                </div>
              )}
              {invalidSkus.length > 0 && <div className="error-label">Added non-existent SKU</div>}
            </Col>
          )}
        </>
      )}
      <Col xs={1} className={adjustFormAligment} style={{ width: 'max-content', alignItems: 'end', display: 'flex' }}>
        {/* This div is necessary to create the icon background */}
        <div
          onClick={validateFields() ? handleAddRule : undefined}
          style={{
            cursor: validateFields() ? 'pointer' : 'auto',
            background: validateFields() ? 'var(--primary)' : 'var(--warning-disable)',
            width: 'max-content',
            padding: '8px',
            borderRadius: '10px',
          }}>
          <AiOutlinePlus color="white" size={24} />
        </div>
      </Col>
    </Row>
  );
}
