import { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { FeatureMarkets } from '../../../config/feature-markets';
import { Features } from '../../../config/features';
import { WithScopedFeaturesProxy } from '../../../helpers/with-features-proxy';
import { Channel } from '../../../interface/channel';
import { Container } from '../../../interface/container';
import { Market } from '../../../interface/market';
import { Page } from '../../../interface/page';
import { Scenario } from '../../../interface/scenario';
import adminService from '../../../service/admin.service';
import scenarioService from '../../../service/scenario.service';
import { Item } from '../../../shared/dropdown-btn';
import MultiSelectDropdownBtn from '../../../shared/multi-select-dropdown-btn';
import './index.css';

export interface DashboardFormFields {
  markets: Market[];
  channels: Channel[];
  pages: Page[];
  containers: Container[];
  scenario?: Scenario;
}

interface ExposedProps {
  onSubmit: (request: DashboardFormFields) => void;
}

interface Props extends ExposedProps {
  availableMarkets: FeatureMarkets[];
}

const DashboardFormComponent: React.FC<Props> = props => {
  const { onSubmit, availableMarkets } = props;
  const [markets, setMarkets] = useState<Item[]>([]);
  const [channels, setChannels] = useState<Item[]>([]);
  const [pages, setPages] = useState<Item[]>([]);
  const [containers, setContainers] = useState<Item[]>([]);
  const [selectedMarkets, setSelectedMarkets] = useState<Market[]>([]);
  const [selectedChannels, setSelectedChannels] = useState<Channel[]>([]);
  const [selectedScenario] = useState<Scenario>();
  const [selectedPages, setSelectedPages] = useState<Page[]>([]);
  const [selectedContainers, setSelectedContainers] = useState<Container[]>([]);
  const [allPreSelected, setAllPreSelected] = useState<boolean>(true);

  const loadMarkets = useCallback(() => {
    const { promise } = adminService.getMarkets();
    promise.then(response => {
      setMarkets(
        response
          .filter(market => availableMarkets.includes(market.code as any))
          .map(market => ({ ...market, label: market.name })),
      );
    });
  }, [availableMarkets]);

  const loadChannels = useCallback(() => {
    const { promise } = adminService.getChannels();

    promise.then(response => {
      response.forEach(channel => {
        (channel as unknown as Item).label = channel.name;
      });

      setChannels(response as unknown as Item[]);
    });
  }, []);

  const handleSubmit = useCallback(() => {
    onSubmit({
      markets: selectedMarkets,
      channels: selectedChannels,
      pages: selectedPages,
      containers: selectedContainers,
      scenario: selectedScenario,
    });
  }, [onSubmit, selectedMarkets, selectedChannels, selectedPages, selectedContainers, selectedScenario]);

  const isButtonDisabled = useMemo<boolean>(() => {
    return !selectedMarkets.length || !selectedChannels.length || !selectedPages.length || !selectedContainers.length;
  }, [selectedMarkets, selectedChannels, selectedPages, selectedContainers]);

  useEffect(() => {
    loadMarkets();
    loadChannels();
  }, [loadMarkets, loadChannels]);

  useEffect(() => {
    if (selectedMarkets.length && selectedChannels.length) {
      const { promise } = adminService.getPages(
        selectedMarkets.map(market => market.id),
        selectedChannels.map(channel => channel.id),
      );

      promise.then(response => {
        response.forEach(page => {
          (page as unknown as Item).label = page.name;
        });

        setPages(response as unknown as Item[]);
      });
    } else {
      setPages([]);
    }
  }, [selectedMarkets, selectedChannels]);

  useEffect(() => {
    if (selectedPages.length) {
      adminService.getContainers(selectedPages.map(page => page.id)).then(response => {
        response.forEach(container => {
          (container as unknown as Item).label = container.name;
        });

        setContainers(response as unknown as Item[]);
      });

      scenarioService.getScenarios(selectedPages).then(response => {
        response.forEach(scenario => {
          (scenario as unknown as Item).label = scenario.name;
        });
      });
    } else {
      setContainers([]);
    }
  }, [selectedPages]);

  useEffect(() => {
    if (allPreSelected && !isButtonDisabled) {
      handleSubmit();
      setAllPreSelected(false);
    }
  }, [allPreSelected, isButtonDisabled, handleSubmit]);

  return (
    <Form className="dashboard-form">
      <Row className="mb-3">
        <Col>
          <MultiSelectDropdownBtn
            id="dropdown-market"
            required={true}
            disabled={markets.length === 0}
            none={true}
            all={true}
            allPreSelected={allPreSelected}
            label="Market"
            placeholder="Select Market"
            items={markets}
            onClick={setSelectedMarkets as unknown as (item?: Item[]) => void}
          />
        </Col>
        <Col>
          <MultiSelectDropdownBtn
            id="dropdown-channel"
            required={true}
            disabled={channels.length === 0}
            none={true}
            all={true}
            allPreSelected={allPreSelected}
            channel={true}
            label="Channel"
            placeholder="Select Channel"
            items={channels}
            onClick={setSelectedChannels as unknown as (item?: Item[]) => void}
          />
        </Col>
        <Col>
          <MultiSelectDropdownBtn
            id="dropdown-page"
            required={true}
            disabled={pages.length === 0}
            none={true}
            all={true}
            allPreSelected={allPreSelected}
            label="Page"
            placeholder="Select Page"
            items={pages}
            onClick={setSelectedPages as unknown as (item?: Item[]) => void}
          />
        </Col>
        <Col>
          <MultiSelectDropdownBtn
            id="dropdown-container"
            required={true}
            disabled={containers.length === 0}
            none={true}
            all={true}
            allPreSelected={allPreSelected}
            label="Container"
            placeholder="Select Container"
            items={containers}
            onClick={setSelectedContainers as unknown as (item?: Item[]) => void}
          />
        </Col>
      </Row>
      <Row className="d-flex justify-content-center mt-4">
        {/* <Col md={6}>
          <DropdownBtn
            id="dropdown-scenario"
            disabled={scenarios.length === 0}
            none={true}
            label="Scenario"
            placeholder="Select Scenario"
            items={scenarios}
            onClick={setSelectedScenario as unknown as (item?: Item | null) => void}
          />
        </Col> */}
        <Col md={3} className="submit">
          <Button disabled={isButtonDisabled} onClick={handleSubmit}>
            UPDATE
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default WithScopedFeaturesProxy<ExposedProps>(Features.DashboardScreen)((props, _, markets) => {
  return <DashboardFormComponent {...props} availableMarkets={markets} />;
});
