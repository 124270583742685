import axios from 'axios';
import { apiWrapper } from '../../config/api-wrapper';
import { initialTableListRulesMapper } from './merch-rules-mapper';
import {
  AffectedByMerchRules,
  InitialTableListRules,
  MerchRuleConfig,
  MerchRuleVersionType,
  SubmitMerchRuleVersionRequest,
} from './merch-rules-types';
import { WithAbortFn } from '../../@types/external-api';

class MerchRulesService {
  public getContainerRules(pageIds: string[]): WithAbortFn<Promise<InitialTableListRules[]>> {
    const source = axios.CancelToken.source();

    return {
      promise: apiWrapper.adminApi
        .get('/merch-rule/list/container', { params: { pageIds: pageIds.join() }, cancelToken: source.token })
        .then(res => initialTableListRulesMapper(res.data, 'container' as MerchRuleVersionType)),
      abort: source.cancel,
    };
  }

  public getSkuRules(marketId: string): WithAbortFn<Promise<InitialTableListRules[]>> {
    const source = axios.CancelToken.source();

    return {
      promise: apiWrapper.adminApi
        .get('/merch-rule/list/sku', { params: { marketIds: marketId }, cancelToken: source.token })
        .then(res => initialTableListRulesMapper(res.data, 'sku' as MerchRuleVersionType)),
      abort: source.cancel,
    };
  }

  public getRulesByVersionIds(versionIds: string[]): WithAbortFn<Promise<InitialTableListRules[]>> {
    const source = axios.CancelToken.source();

    return {
      promise: apiWrapper.adminApi
        .get('/merch-rule/list/versions', { params: { merchRuleIds: versionIds.join() }, cancelToken: source.token })
        .then(res => initialTableListRulesMapper(res.data, res.data[0].type)),
      abort: source.cancel,
    };
  }

  public getLiveRulesByItemIds(
    itemIds: string[],
    type: MerchRuleVersionType,
    requestType: string,
  ): WithAbortFn<Promise<InitialTableListRules[]>> {
    const source = axios.CancelToken.source();

    return {
      promise: apiWrapper.adminApi
        .get(`/merch-rule/list/live-rules/${type.toLowerCase()}`, {
          params: { itemIds: itemIds.join(), type: requestType },
          cancelToken: source.token,
        })
        .then(res => initialTableListRulesMapper(res.data, type)),
      abort: source.cancel,
    };
  }

  public getSkuRulesbyProductId(productIds: string[]): WithAbortFn<Promise<InitialTableListRules[]>> {
    const source = axios.CancelToken.source();

    return {
      promise: apiWrapper.adminApi
        .get(`/merch-rule/list/sku/key`, { params: { productIds: productIds.join() }, cancelToken: source.token })
        .then(res => initialTableListRulesMapper(res.data, 'sku' as MerchRuleVersionType)),
      abort: source.cancel,
    };
  }

  public getContainerRulesbyContainerId(containerIds: string[]): WithAbortFn<Promise<InitialTableListRules[]>> {
    const source = axios.CancelToken.source();

    return {
      promise: apiWrapper.adminApi
        .get(`/merch-rule/list/container/key`, {
          params: { containerIds: containerIds.join() },
          cancelToken: source.token,
        })
        .then(res => initialTableListRulesMapper(res.data, 'container' as MerchRuleVersionType)),
      abort: source.cancel,
    };
  }

  public async saveRulesAsDraft(
    itemIds: Number[],
    type: 'SKU' | 'CONTAINER',
    rules: { merchRuleConfig: MerchRuleConfig[]; deletedMerchRuleIds: String[]; useLiveVersionRules: boolean },
  ): Promise<number[]> {
    const res = await apiWrapper.adminApi.post(
      '/merch-rule/save-draft',
      {
        deletedMerchRuleIds: rules.deletedMerchRuleIds,
        merchRuleConfigs: rules.merchRuleConfig.map(rule => ({ ...rule, rule: undefined })),
        useLiveVersionRules: rules.useLiveVersionRules,
      },
      { params: { type, itemIds: itemIds.join() } },
    );
    return res.data;
  }

  public verifySkuExistence(sku: string): Promise<boolean> {
    return apiWrapper.adminApi.get(`/merch-rule/verify-sku`, { params: { sku } }).then(res => res.data);
  }

  public submitMerchRuleVersion(submitMerchRuleVersionRequest: SubmitMerchRuleVersionRequest) {
    return apiWrapper.adminApi.post(`/merch-rule/approval-request/version`, submitMerchRuleVersionRequest);
  }

  public getAffectedByMerchRules(
    containerIds?: string[],
    skuIds?: string[],
    versionIds?: string[],
  ): WithAbortFn<Promise<AffectedByMerchRules>> {
    const source = axios.CancelToken.source();

    return {
      promise: apiWrapper.adminApi
        .get(`/merch-rule/list/affected-by-merch-rules`, {
          params: { containerIds: containerIds?.join(), skuIds: skuIds?.join(), versionIds: versionIds?.join() },
          cancelToken: source.token,
        })
        .then(res => res.data),
      abort: source.cancel,
    };
  }

  public deleteUserDraft(itemId: string, type: MerchRuleVersionType): Promise<void> {
    return apiWrapper.adminApi.delete(`/merch-rule/delete/user-draft`, { params: { itemId, type } });
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new MerchRulesService();
