import { useEffect, useState } from 'react';
import ExternalDataRenderer from '../../../../../../components/hocs/external-data-renderer';
import useScenarios from '../../../../../../resources/scenarios/scenarios-hook';
import { FilterExecutor, ScenarioParentStep } from '../../../../../../resources/scenarios/scenarios-versions-models';
import DialogLayout from '../../../../../../shared/dialog-layout';
import SearchInput from '../../../../../../shared/search-input';
import FilterChildStepListViewer from './child-step-list-viewer';
import './index.scss';

const filterBySearchText = (
  text: string,
  steps: FilterExecutor[],
  parentStep: ScenarioParentStep,
): FilterExecutor[] => {
  return steps.filter(
    step =>
      !parentStep.subSteps?.some(excludedStep => excludedStep.name?.toLowerCase() === step.name.toLowerCase()) &&
      (step.name.toLowerCase().includes(text.toLowerCase()) ||
        step.description.toLowerCase().includes(text.toLowerCase())),
  );
};

interface Props {
  isOpen: boolean;
  parentStep: ScenarioParentStep;
  onCancel: VoidFunction;
  onAdd: (parentStep: ScenarioParentStep, step: FilterExecutor) => void;
}

function AddChildStepModal(props: Props) {
  const { isOpen, parentStep, onAdd, onCancel } = props;
  const { filterSteps, fetchFilterSteps } = useScenarios();
  const [searchText, setSearchText] = useState('');
  const [selectedStep, setSelectedStep] = useState<FilterExecutor>();

  useEffect(() => {
    void fetchFilterSteps();
  }, [fetchFilterSteps]);

  const handleSelectedStepChanges = (newSelectedStep: FilterExecutor) => {
    setSelectedStep(newSelectedStep);
  };

  const handleConfirmAction = () => {
    onAdd(parentStep, selectedStep!);
    setSelectedStep(undefined);
    setSearchText('');
  };

  const handleScapeAction = () => {
    setSelectedStep(undefined);
    onCancel();
    setSearchText('');
  };

  if (!isOpen) return null;

  return (
    <DialogLayout
      open={isOpen}
      variant="outlined"
      scapeActionText="CANCEL"
      scapeActionHandler={handleScapeAction}
      confirmActionText="ADD NEW STEP"
      confirmActionHandler={selectedStep ? handleConfirmAction : undefined}
      title="Add new Child-Step"
      subtitle="Select the child-step model below:">
      <section className="add-child-step-modal-container">
        <SearchInput
          id="step-filter"
          value={searchText}
          onChange={value => setSearchText(value)}
          placeholder="Search for a child-step"
          className="mb-3"
        />
        <div className="add-child-step-modal-content">
          <ExternalDataRenderer
            externalData={filterSteps}
            makeDataElement={steps => (
              <FilterChildStepListViewer
                steps={filterBySearchText(searchText, steps, parentStep)}
                selectedStep={selectedStep}
                handleSelectedStepChanges={handleSelectedStepChanges}
              />
            )}
          />
        </div>
      </section>
    </DialogLayout>
  );
}

export default AddChildStepModal;
