import useToasts from '../resources/toasts/toasts-hook';
import axios from 'axios';
import { useCallback, useMemo } from 'react';
import useAuth from '../resources/auth/auth-hook';

interface ErrorsHandlers {
  [statusCode: number]: VoidFunction;
  unexpected: VoidFunction;
  cancel: VoidFunction;
}

type CustomErrorsHandlers = Partial<ErrorsHandlers>;

type ErrorHandler = (error: any, customErrorsHandlers?: CustomErrorsHandlers) => void;

const mergeErrorHandlers = (
  defaultErrorsHandlers: ErrorsHandlers,
  customErrorsHandlers: CustomErrorsHandlers,
): ErrorsHandlers => {
  return Object.entries(defaultErrorsHandlers).reduce<any>((acc, next) => {
    const [key] = next;

    if (key === 'unexpected' || key === 'cancel') {
      acc[key] = customErrorsHandlers[key] ?? defaultErrorsHandlers[key];
    } else {
      const keyAsInt = parseInt(key);
      acc[keyAsInt] = customErrorsHandlers[keyAsInt] ?? defaultErrorsHandlers[keyAsInt];
    }

    return acc;
  }, {}) as ErrorsHandlers;
};

const useExternalApiErrorHandler = (): ErrorHandler => {
  const { push } = useToasts();
  const { signOut } = useAuth();
  const defaultErrorsHandlers = useMemo<ErrorsHandlers>(
    () => ({
      400: () => {
        push('Bad request', 'error');
      },
      401: () => {
        push('Your session has expired. Please sign in again.', 'error');
        signOut();
      },
      403: () => {
        push('Unauthorized request', 'error');
      },
      404: () => {
        push('Not found error', 'error');
      },
      500: () => {
        push('Internal server error', 'error');
      },
      cancel: () => {},
      unexpected: () => {
        push('Unexpected error', 'error');
      },
    }),
    [push, signOut],
  );

  const errorHandler: ErrorHandler = useCallback(
    (err, customErrorsHandlers) => {
      const errorsHandlers = mergeErrorHandlers(defaultErrorsHandlers, customErrorsHandlers ?? {});

      console.error(err);
      if (axios.isCancel(err)) {
        errorsHandlers.cancel();
      } else if (axios.isAxiosError(err)) {
        if (err.response && err.response?.status in errorsHandlers) {
          errorsHandlers[err.response.status]();
        } else {
          errorsHandlers.unexpected();
        }
      } else {
        errorsHandlers.unexpected();
      }
    },
    [defaultErrorsHandlers],
  );

  return errorHandler;
};

export default useExternalApiErrorHandler;
