import React from 'react';
import { ExternalData } from '../../../../../@types/external-api';
import ExternalDataRenderer from '../../../../../components/hocs/external-data-renderer';
import { ScenarioVersion, ScenarioVersionStatus } from '../../../../../resources/scenarios/scenarios-versions-models';
import Accordion from '../../../../../shared/accordion';
import DrawerLayout from '../../../../../shared/drawer-layout';
import Subtitle from '../../../../../shared/subtitle';
import Typography from '../../../../../shared/typography';
import Title from '../../../../../shared/ui/title';
import { getDateString, getRelativeTime } from '../../../../../utils/date.utils';
import './index.scss';
import ReadonlyStepsTable from '../readonly-steps-table';

interface Props {
  isHistoryDrawerOpen: boolean;
  handleCloseHistoryDrawer: VoidFunction;
  scenarioVersionHistory: ExternalData<ScenarioVersion[]>;
}

const VersionItemHeader: React.FC<{ version: ScenarioVersion }> = ({ version }) => {
  if (version.status === ScenarioVersionStatus.LIVE) {
    return (
      <header className="scenario-version-header">
        <Title sizeVariant="sm">Current version</Title>
        <Typography sizeVariant="sm" colorVariant="primary">
          Requested By {version.createdBy} | Approved by {version.createdBy}
        </Typography>
        <Typography sizeVariant="sm">{getRelativeTime(version.createdAt)}</Typography>
      </header>
    );
  }

  return (
    <header className="scenario-version-header">
      <Title sizeVariant="sm">{getDateString(version.createdAt.toISOString())}</Title>
      <Typography sizeVariant="sm">
        Requested By {version.createdBy} | Approved by {version.createdBy}
      </Typography>
    </header>
  );
};

const VersionItemContainer: React.FC<{ version: ScenarioVersion }> = ({ version }) => {
  return (
    <article>
      <div className="mb-3">
        <Typography colorVariant="secondary">Request reason: </Typography>
        <Typography colorVariant="primary">{version.requestReason}</Typography>
      </div>
      <ReadonlyStepsTable
        scenarioSteps={version.steps}
        className="history"
        status={version.status}
        scenarioVersionId={version.id}
      />
    </article>
  );
};

const VersionsHistoryDrawer: React.FC<Props> = props => {
  const { isHistoryDrawerOpen, handleCloseHistoryDrawer, scenarioVersionHistory } = props;

  return (
    <DrawerLayout isOpen={isHistoryDrawerOpen} close={handleCloseHistoryDrawer} className="scenario-history-drawer">
      <header>
        <Title colorVariant="primary">Steps Version History</Title>
        <Subtitle sizeVariant="md">Personalized Customer Recommendations</Subtitle>
      </header>
      <section className="fancy-scrollbar">
        <ExternalDataRenderer
          externalData={scenarioVersionHistory}
          makeDataElement={versions => (
            <Accordion
              className="scenarios-versions-accordion"
              accordionItems={versions.map(version => ({
                key: version.id.toString(),
                data: {
                  header: <VersionItemHeader version={version} />,
                  content: <VersionItemContainer version={version} />,
                  className: `version-item ${version.status}`,
                },
              }))}
            />
          )}
        />
      </section>
    </DrawerLayout>
  );
};

export default VersionsHistoryDrawer;
