import { mdiClose } from '@mdi/js';
import Icon from '@mdi/react';

import './index.scss';

interface Chip {
  title: string;
  value: string;
}

export interface Props {
  chips: Chip[];
  className?: string;
  isReadOnly?: boolean;
  onChange?: (newChips: Chip[]) => void;
}

const Chips: React.FC<Props> = (props: Props) => {
  const { chips, className = '', onChange, isReadOnly = true } = props;

  function handleDelete(chipValue: string): void {
    onChange?.(chips.filter(chip => chip.value !== chipValue));
  }

  function handleBackgroundColors(title: string): string {
    if (title?.toLowerCase().includes('bury')) {
      return 'bury';
    } else if (title?.toLowerCase().includes('boost')) {
      return 'boost';
    } else {
      return '';
    }
  }

  return (
    <section className={`chips ${className}`}>
      {chips.map(({ title, value }) => (
        <span key={value} className={`item ${isReadOnly ? 'read-only' : ''} ${handleBackgroundColors(title)}`}>
          {title}
          {!isReadOnly && (
            <span onClick={() => handleDelete(value)} className="delete-icon-wrapper">
              <Icon path={mdiClose} size="1rem" />
            </span>
          )}
        </span>
      ))}
    </section>
  );
};

export default Chips;
