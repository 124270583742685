import { useCallback, useEffect, useState } from 'react';
import { Dropdown, DropdownButton, Form } from 'react-bootstrap';
import './index.scss';

export interface Item {
  id: string | number;
  label: string;
}

export interface Props {
  id: string;
  className?: string;
  variant?: 'primary' | 'secondary';
  value?: string;
  required?: boolean;
  disabled?: boolean;
  none?: boolean;
  label?: string;
  placeholder: string;
  items: Item[];
  onClick: (item?: Item | null) => void;
}

export default function DropdownBtn(props: Props) {
  const {
    id,
    className,
    required,
    value,
    variant = 'primary',
    disabled,
    none,
    label,
    placeholder,
    items,
    onClick,
  } = props;
  const [selected, setSelected] = useState<Item | undefined | null>();

  useEffect(() => {
    if (value) {
      const item = items.find(item => item.id.toString() === value.toString());
      if (item) {
        setSelected(item);
      }
    }
  }, [items, value]);

  useEffect(() => {
    onClick(selected);
  }, [selected, onClick]);

  const handleClick = useCallback((item?: Item | null) => {
    setSelected(item);
  }, []);

  useEffect(() => {
    if (items.length === 0) {
      setSelected(null);
    } else if (items.length === 1) {
      setSelected(items[0]);
    }
  }, [items]);

  useEffect(() => {
    if (items.length > 1 && selected) {
      const updatedSelected = items.find(({ id }) => id === selected.id);

      if (updatedSelected) {
        setSelected(updatedSelected);
      } else {
        setSelected(null);
      }
    }
  }, [items, selected]);

  return (
    <Form.Group id={id} className={`single-select-dropdown ${variant} ${className}`}>
      {label && (
        <Form.Label htmlFor={id} className={required ? 'required' : ''}>
          {label}
        </Form.Label>
      )}
      <DropdownButton
        id={`${id}-button`}
        className={selected ? 'selected' : ''}
        disabled={disabled}
        title={selected?.label ?? placeholder}>
        {none && !required && (
          <Dropdown.Item
            eventKey="none"
            id="dropdown-item-none"
            className="text-truncate"
            onClick={() => handleClick(null)}>
            None
          </Dropdown.Item>
        )}
        {items.map((item, i) => (
          <Dropdown.Item
            key={item.id}
            eventKey={item.id}
            id={`dropdown-item-${i}`}
            className={`text-truncate ${item.id === selected?.id ? 'active' : ''}`}
            onClick={() => handleClick(item)}>
            {item.label}
          </Dropdown.Item>
        ))}
      </DropdownButton>
    </Form.Group>
  );
}
