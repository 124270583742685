import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import ExternalDataRenderer from '../../../components/hocs/external-data-renderer';
import useBreadcrumbs from '../../../resources/breadcrumbs/breadcrumbs-hook';
import useScenarios from '../../../resources/scenarios/scenarios-hook';
import { ScenarioDetails } from '../../../resources/scenarios/scenarios-models';
import MetricsTileComponent from '../../../shared/metrics-tile';
import Breadcrumbs from '../../../shared/ui/breadcrumbs';
import ScenarioTreeViewComponent from '../scenario-tree-view';
import './index.scss';
import ReadonlyScenarioSteps from './readonly-scenario-steps';

interface Config {
  id: string;
  title: string;
  formattedData: string;
}

interface DetailsSize {
  id: string;
  color: string;
  text: string;
}

const toScenariosConfigs = (scenarioDetails: ScenarioDetails): Config[] => {
  return Object.entries(scenarioDetails.config).map(([key, value]) => ({
    id: key,
    title: key,
    formattedData: typeof value === 'number' ? value.toLocaleString() : (value as string),
  }));
};

const toScenarioDetailsSizes = (scenarioDetails: ScenarioDetails) => {
  return [
    {
      id: 'markets',
      color: 'var(--warning-blue)',
      text: `${scenarioDetails.marketsSize} Market${scenarioDetails.marketsSize !== 1 ? 's' : ''}`,
    },
    {
      id: 'channels',
      color: 'var(--warning-success)',
      text: `${scenarioDetails.channelsSize} Channel${scenarioDetails.channelsSize !== 1 ? 's' : ''}`,
    },
    {
      id: 'pages',
      color: 'var(--warning-orange)',
      text: `${scenarioDetails.pagesSize} Page${scenarioDetails.pagesSize !== 1 ? 's' : ''}`,
    },
    {
      id: 'containers',
      color: 'var(--primary)',
      text: `${scenarioDetails.containersSize} Container${scenarioDetails.containersSize !== 1 ? 's' : ''}`,
    },
  ];
};

export default function ScenarioDetailsScreen() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { breadcrumbs } = useBreadcrumbs();
  const {
    scenarioDetails: scenarioDetailsData,
    scenarioSteps,
    fetchScenarioDetails,
    fetchScenarioSteps,
  } = useScenarios();
  const scenarioDetails: ScenarioDetails | undefined = useMemo(
    () => scenarioDetailsData.data,
    [scenarioDetailsData.data],
  );
  const configs = useMemo<Config[]>(
    () => (scenarioDetails?.config ? toScenariosConfigs(scenarioDetails) : []),
    [scenarioDetails],
  );
  const detailsSizes = useMemo<DetailsSize[]>(
    () => (scenarioDetails ? toScenarioDetailsSizes(scenarioDetails) : []),
    [scenarioDetails],
  );
  const [selectedTab, setSelectedTab] = useState<string>('details');

  useEffect(() => {
    fetchScenarioDetails(parseInt(id as string));
    fetchScenarioSteps(parseInt(id as string));
  }, [fetchScenarioDetails, fetchScenarioSteps, id]);

  const handleTabChange = useCallback((_: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
  }, []);

  const handleEditClick = () => {
    navigate(`/scenario-list/${id}/edit`);
  };

  return (
    <Container className="scenario-details-container">
      <Card className="scenario-details">
        <Card.Body>
          <Row>
            <Breadcrumbs breadcrumbs={breadcrumbs} />
          </Row>
          <ExternalDataRenderer
            externalData={scenarioDetailsData}
            makeDataElement={scenarioDetailsData => (
              <>
                <Row className="title">
                  <Col className="d-flex">
                    <Card.Title>Scenario:</Card.Title>
                    <Card.Title>{scenarioDetailsData.name}</Card.Title>
                  </Col>
                </Row>
                <TabContext value={selectedTab}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleTabChange} centered>
                      <Tab label="Scenario Details" value="details" />
                      <Tab label="Step Viewer" value="steps" />
                      <Tab label="Config" value="config" />
                    </TabList>
                  </Box>
                  <TabPanel value="details">
                    <Row>
                      <Col>
                        <ScenarioTreeViewComponent scenarioDetails={scenarioDetailsData} />
                      </Col>
                      <Col md={2} className="mt-3">
                        {detailsSizes.map(({ id, color, text }) => (
                          <Row key={id} id={id} className="mb-3 h5" style={{ color }}>
                            {text}
                          </Row>
                        ))}
                      </Col>
                    </Row>
                  </TabPanel>
                  <TabPanel sx={{ paddingLeft: 0, paddingRight: 0 }} value="steps">
                    <ExternalDataRenderer
                      externalData={scenarioSteps}
                      makeDataElement={scenarioSteps => (
                        <ReadonlyScenarioSteps
                          scenarioId={parseInt(id as string)}
                          scenarioSteps={scenarioSteps}
                          onEditClick={handleEditClick}
                        />
                      )}
                    />
                  </TabPanel>
                  <TabPanel value="config">
                    <Row>
                      {configs.map(c => (
                        <Col key={c.id}>
                          <MetricsTileComponent {...c} />
                        </Col>
                      ))}
                    </Row>
                  </TabPanel>
                </TabContext>
              </>
            )}
          />
        </Card.Body>
      </Card>
    </Container>
  );
}
