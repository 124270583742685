import { useCallback } from 'react';
import { Cache, CacheInfo, CacheReloadRequest } from '../../../interface/cache';
import { Market } from '../../../interface/market';
import useToasts from '../../../resources/toasts/toasts-hook';
import cacheService from '../../../service/cache.service';
import CacheReloaderFormComponent from '../cache-reloader-form';
import CacheReloaderMetricsComponent from '../cache-reloader-metrics';

type Props = {
  cacheInfo?: CacheInfo | null;
  getCacheData: (market: Market, cache: Cache) => void;
};

export default function CacheReloaderFormContainerComponent({ cacheInfo, getCacheData }: Props) {
  const { push: pushToast } = useToasts();

  const reloadCache = useCallback(
    (market: Market, request: CacheReloadRequest) => {
      cacheService
        .reloadCache(market, request)
        .then(() => {
          pushToast('It might take a few minutes.', 'success', {
            customTitle: 'Cache reload requested',
            delay: 10000,
          });
        })
        .catch(error => {
          console.error(error);
          pushToast('Could not reload cache', 'error');
        });
    },
    [pushToast],
  );

  return (
    <CacheReloaderFormComponent cacheInfo={cacheInfo} getCacheData={getCacheData} onSubmit={reloadCache}>
      {cacheInfo && <CacheReloaderMetricsComponent cacheInfo={cacheInfo} />}
    </CacheReloaderFormComponent>
  );
}
