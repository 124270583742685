import { useCallback, useEffect, useMemo, useState } from 'react';
import ExternalDataRenderer from '../../../components/hocs/external-data-renderer';
import { FeatureMarkets } from '../../../config/feature-markets';
import { UnscopedPermissions, ScopedPermissions } from '../../../config/permissions';
import useFeatures from '../../../resources/features/features-hook';
import { ScopedPermission, UnscopedPermission } from '../../../resources/features/features-types';
import adminService from '../../../service/admin.service';
import { prettyFormatList } from '../../../utils/pretty-formatters-utils';
import DialogLayout from '../../dialog-layout';
import DropdownBtn, { Props as DropdownBtnProps } from '../../dropdown-btn';
import SearchInput from '../../search-input';
import './index.scss';
import PermissionFeaturesListItem, { Props as PermissionFeaturesProps } from './permission-features-list-item';
import {
  CustomClaimPermissionsName,
  ensureContains,
  filterBySearchText,
  getAppliedClaimName,
  makeClaimsPermissionItems,
  makePermissionsFeatures,
  parseToPermissionsNames,
  PermissionFeature,
} from './utils';

interface Props {
  isOpen: boolean;
  onClose: VoidFunction;
}

const PermissionsModal: React.FC<Props> = props => {
  const { isOpen, onClose } = props;
  const {
    permissions: appliedPermissions,
    featuresMap,
    claimsPermissions,
    updatePermissionsStatus,
    fetchFeaturesMap,
    updatePermissions,
    fetchClaimsPermissions,
  } = useFeatures();
  const [permissionNames, setPermissionNames] = useState<string[]>(parseToPermissionsNames(appliedPermissions));
  const [searchText, setSearchText] = useState('');
  const [markets, setMarkets] = useState<PermissionFeaturesProps['marketItems']>([]);
  const [permissionsFeatures, setPermissionsFeatures] = useState<PermissionFeature[] | undefined>();
  const [selectedClaimPermissionsName, setSelectedClaimPermissionName] = useState<string>(CustomClaimPermissionsName);
  const claimPermissionItems = useMemo(
    () => makeClaimsPermissionItems(claimsPermissions?.data ?? []),
    [claimsPermissions?.data],
  );

  useEffect(() => {
    if (featuresMap.data) {
      setPermissionsFeatures(makePermissionsFeatures(appliedPermissions, featuresMap.data));
    }
  }, [appliedPermissions, featuresMap.data]);

  useEffect(() => {
    const { promise } = adminService.getMarkets();

    promise.then(response => {
      setMarkets(response.map(market => ({ name: market.name, value: market.code })));
    });
  }, []);

  useEffect(() => {
    fetchFeaturesMap();
  }, [fetchFeaturesMap]);

  useEffect(() => {
    void fetchClaimsPermissions();
  }, [fetchClaimsPermissions]);

  useEffect(() => {
    setPermissionNames(parseToPermissionsNames(appliedPermissions));
  }, [appliedPermissions]);

  useEffect(() => {
    const claimPermission = (claimsPermissions.data ?? []).find(
      claimPermissions => claimPermissions.name === selectedClaimPermissionsName,
    );

    if (claimPermission) {
      setPermissionNames(
        ensureContains(
          [
            ...claimPermission.unscopedPermissions,
            ...claimPermission.scopedPermissions.map(scopedPermission => scopedPermission.name),
          ],
          UnscopedPermissions.ManageOwnPermissions,
        ),
      );
      setPermissionsFeatures(prev =>
        (prev ?? []).map(permissionFeatures => {
          const permission = claimPermission.scopedPermissions.find(
            scopedPermission => scopedPermission.name === permissionFeatures.permission,
          );

          if (!permission) return { ...permissionFeatures, active: false };

          return { ...permissionFeatures, active: true, markets: permission.markets };
        }),
      );
    }
  }, [claimsPermissions, selectedClaimPermissionsName]);

  useEffect(() => {
    setPermissionsFeatures(prev =>
      (prev ?? []).map(permissionFeatures => ({
        ...permissionFeatures,
        active: permissionNames.includes(permissionFeatures.permission),
      })),
    );
  }, [permissionNames]);

  useEffect(() => {
    if (!getAppliedClaimName(permissionsFeatures ?? [], claimsPermissions.data ?? [])) {
      setSelectedClaimPermissionName(CustomClaimPermissionsName);
    }
  }, [claimsPermissions, permissionsFeatures]);

  useEffect(() => {
    if (updatePermissionsStatus.success) {
      onClose();
    }
  }, [onClose, updatePermissionsStatus.success]);

  const handleClaimPermissionsChange: DropdownBtnProps['onClick'] = useCallback(item => {
    setSelectedClaimPermissionName((item?.id as string) ?? '');
  }, []);

  const onPermissionMarketsChange = useCallback((permission: string, markets: FeatureMarkets[]) => {
    setPermissionsFeatures(prev =>
      (prev ?? []).map(permissionFeatures =>
        permissionFeatures.permission === permission
          ? {
              ...permissionFeatures,
              markets: markets,
            }
          : permissionFeatures,
      ),
    );
  }, []);

  const handleToggleActive = (permission: string, active: boolean) => {
    if (active) setPermissionNames(prev => ensureContains(prev, permission));
    else setPermissionNames(prev => prev.filter(perm => perm !== permission));
  };

  const handleApply = () => {
    void updatePermissions(
      permissionsFeatures!
        .filter(permFeat => permFeat.active)
        .map(permFeat =>
          permFeat.markets
            ? new ScopedPermission(permFeat.permission as ScopedPermissions, permFeat.markets!)
            : new UnscopedPermission(permFeat.permission as UnscopedPermissions),
        ),
    );
  };

  return (
    <DialogLayout
      open={isOpen}
      variant="outlined"
      title="Permissions Settings"
      subtitle={`Set the permissions according to the features below. Use "Claims permissions" for specific setup.`}
      confirmActionText="APPLY"
      confirmActionHandler={handleApply}
      scapeActionText="CANCEL"
      scapeActionHandler={onClose}
      isLoading={updatePermissionsStatus.loading}>
      <section className="permissions-modal-container">
        <header className="permissions-modal-container-header">
          <DropdownBtn
            id="claims-selector"
            variant="secondary"
            value={selectedClaimPermissionsName}
            items={claimPermissionItems}
            label="Claims permissions"
            placeholder="Select a claim set of permissions"
            onClick={handleClaimPermissionsChange}
          />
          <SearchInput
            id="permissions-searcher"
            label="Search"
            placeholder="Search permissions..."
            value={searchText}
            onChange={txt => setSearchText(txt)}
          />
        </header>
        {
          <ExternalDataRenderer
            externalData={featuresMap}
            makeDataElement={() => (
              <section className="permissions-modal-container-content">
                <header>
                  <h5>Permission</h5>
                  <h5>Features</h5>
                  <h5>Markets</h5>
                </header>
                <section className="permissions-modal-container-content-list fancy-scrollbar">
                  {filterBySearchText(permissionsFeatures ?? [], searchText).map(permissionFeat => (
                    <PermissionFeaturesListItem
                      key={permissionFeat.label}
                      marketItems={markets}
                      permissionFeat={{
                        ...permissionFeat,
                        onToggleActive: active => handleToggleActive(permissionFeat.permission, active),
                        onChangeMarkets: permissionFeat.markets
                          ? markets => onPermissionMarketsChange(permissionFeat.permission, markets)
                          : undefined,
                      }}
                    />
                  ))}
                </section>
              </section>
            )}
          />
        }
        <footer className="permissions-modal-container-footer">
          <p>
            <span className="label">{permissionNames.length} permissions enabled: </span>
            <span className="permissions">{prettyFormatList(permissionNames)}</span>
          </p>
        </footer>
      </section>
    </DialogLayout>
  );
};

export default PermissionsModal;
