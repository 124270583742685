import env from '../config/env';
import {
  Cache,
  CacheDetails,
  CacheInfo,
  CacheRecent,
  CacheReloadRequest,
  CacheReloadResponse,
} from '../interface/cache';
import { Market } from '../interface/market';
import { AuthorizedService } from './authorized.service';

class CacheService extends AuthorizedService {
  public getCaches(market: Market): Promise<string[]> {
    return super.get<string[]>(`${market.code.toLowerCase()}/api/cache/list`).then(caches => caches.sort());
  }

  public getCacheReloadInfo(market: Market): Promise<CacheInfo> {
    return super.get<CacheInfo>(`${market.code.toLowerCase()}/api/cache/reload`);
  }

  public getRecentCacheReload(market: Market): Promise<CacheRecent[]> {
    return super.get<CacheRecent[]>(`${market.code.toLowerCase()}/api/cache-control/recent`);
  }

  public getCacheDetails(market: Market, cache: Cache): Promise<CacheDetails> {
    return super.get<CacheDetails>(`${market.code.toLowerCase()}/api/cache/${cache.name}/details`).then(details => {
      if (details.items.length > 0 && details.items[0] instanceof Array) {
        details.items = details.items.flat();
      }

      return details;
    });
  }

  public reloadCache(market: Market, request: CacheReloadRequest): Promise<CacheReloadResponse> {
    return super.post<CacheReloadResponse, CacheReloadRequest>(
      `${market.code.toLowerCase()}/api/cache-control`,
      request,
    );
  }
}

const cacheService = new CacheService(env.API_URL);
export default cacheService;
