import { useCallback } from 'react';
import { Feature } from '../../../../config/features';
import { FeatureMarkets } from '../../../../config/feature-markets';
import Chips from '../../../chips';
import MultiSelectDropdown, { Props as MultiSelectDropdownProps } from '../../../multi-select-dropdown';
import Switch from '../../../switch';
import './index.scss';

export interface Props {
  permissionFeat: {
    label: string;
    active: boolean;
    features: Feature[];
    onToggleActive: (active: boolean) => void;
    readonly: boolean;
    markets?: FeatureMarkets[];
    onChangeMarkets?: (markets: FeatureMarkets[]) => void;
  };
  marketItems: MultiSelectDropdownProps['items'];
}

const PermissionFeaturesListItem: React.FC<Props> = props => {
  const {
    permissionFeat: { label, active, readonly, features, markets, onChangeMarkets, onToggleActive },
    marketItems,
  } = props;

  const handleOnMarketsChange: MultiSelectDropdownProps['onChange'] = useCallback(
    newMarkets => {
      onChangeMarkets?.(newMarkets as FeatureMarkets[]);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <article data-disabled={!active} className={`permission-features-container`}>
      <section>
        <Switch checked={active} onChange={!readonly ? e => onToggleActive(e.target.checked) : () => {}} />
      </section>
      <section>
        <p>{label}</p>
      </section>
      <section>
        <Chips
          chips={features.map(feat => ({
            title: feat.label,
            value: feat.label,
          }))}
        />
      </section>
      <section>
        <MultiSelectDropdown
          id={`${label}-permission-selector`}
          placeholder="Select Markets"
          variant="secondary"
          items={marketItems}
          value={markets ? markets : (marketItems ?? []).map(item => item.value)}
          disabled={!markets}
          all
          label=""
          onChange={handleOnMarketsChange}
        />
      </section>
    </article>
  );
};

export default PermissionFeaturesListItem;
