import { useMemo } from 'react';
import { authenticatedRoutes } from '../../config/routes';
import { FeaturesCtxType } from '../../resources/features/features-context';
import useFeatures from '../../resources/features/features-hook';
import { SidebarNav } from '@amway/react-components';
import { MdOutlineSpeed, MdOutlineChecklistRtl } from 'react-icons/md';
import { RiPlayListAddFill, RiTestTubeFill } from 'react-icons/ri';
import { PiClockClockwiseFill } from 'react-icons/pi';
import { BsGear } from 'react-icons/bs';

type Props = {
  open: boolean;
  toggleMenu: VoidFunction;
  onLogout: () => void;
};

const links = [
  {
    to: '/dashboard',
    label: 'Dashboard',
    eventKey: 'dashboard',
    IconComponent: MdOutlineSpeed,
  },
  {
    to: '/scenario-list',
    label: 'Scenario List',
    eventKey: 'scenario-list',
    IconComponent: RiPlayListAddFill,
  },
  {
    to: '/cache-reloader',
    label: 'Cache Reloader',
    eventKey: 'cache-reloader',
    IconComponent: PiClockClockwiseFill,
  },
  {
    to: '/testing-tool',
    label: 'Testing Tool',
    eventKey: 'testing-tool',
    IconComponent: RiTestTubeFill,
  },
  {
    to: '/admin-configurations',
    label: 'Admin Configurations',
    eventKey: 'admin-configurations',
    IconComponent: BsGear,
  },
  {
    to: '/request-list',
    label: 'Request List',
    eventKey: 'request-list',
    IconComponent: MdOutlineChecklistRtl,
  },
];

const filterAllowedLinks = (hasFeature: FeaturesCtxType['hasFeature']) => {
  return links.filter(link => {
    const linkRoute = authenticatedRoutes.find(route => route.path === link.to)!;

    return hasFeature(linkRoute.feature);
  });
};

export default function SideNavComponent({ open, toggleMenu, onLogout }: Props) {
  const { hasFeature } = useFeatures();
  const allowedLinks = useMemo(() => filterAllowedLinks(hasFeature), [hasFeature]);

  return <SidebarNav open={open} toggleMenu={toggleMenu} onLogout={onLogout} allowedLinks={allowedLinks} />;
}
