import { Typography } from '@amway/react-components';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import useQueryParams, { QueryParams } from '../../hooks/use-query-params';
import useBreadcrumbs from '../../resources/breadcrumbs/breadcrumbs-hook';
import PaginatedTableComponent, { Column } from '../../shared/paginated-table';
import Breadcrumbs from '../../shared/ui/breadcrumbs';
import { valueToArray } from '../scenario-list';
import './index.scss';
import MerchandisingRulesForm, { MerchandisingRulesFormFields } from './merchandising-rules-form';
import useMerchRules from '../../resources/merch-rules/merch-rules-hook';
import ExternalDataRenderer from '../../components/hocs/external-data-renderer';
import { InitialTableListRules } from '../../resources/merch-rules/merch-rules-types';

export const formDataToQueryParams = (formData: MerchandisingRulesFormFields): QueryParams => {
  return {
    filterBy: formData.filterBy ?? '',
    market: formData.market ?? '',
    channels: formData.channels ?? [],
    pages: formData.pages ?? [],
  };
};

export const queryParamsToFormData = (queryParams: QueryParams): MerchandisingRulesFormFields => {
  return {
    filterBy: queryParams.filterBy ? String(queryParams.filterBy) : undefined,
    market: queryParams.market ? String(queryParams.market) : undefined,
    channels: valueToArray(queryParams.channels),
    pages: valueToArray(queryParams.pages),
  };
};

export default function MerchandisingRulesPage() {
  const { breadcrumbs } = useBreadcrumbs();
  const { fetchRules, rules, resetRules } = useMerchRules();
  const [params, setParams] = useQueryParams({});
  const navigate = useNavigate();

  const urlValues = useMemo(() => queryParamsToFormData(params), [params]);

  const [selectedFilterBy, setSelectedFilterBy] = useState<string | undefined>(urlValues.filterBy);
  const [selectedMarket, setSelectedMarket] = useState<string | undefined>(urlValues.market);
  const [selectedChannels, setSelectedChannels] = useState<string[] | undefined>(urlValues.channels);
  const [selectedPages, setSelectedPages] = useState<string[] | undefined>(urlValues.pages);

  const columns: Column[] = useMemo(
    () => [
      {
        id: 'name',
        label: urlValues.filterBy === 'container' ? 'Container' : 'SKU',
      } as any,
      {
        id: 'rules',
        label: 'Rules',
      },
      {
        id: 'lastUpdate',
        label: 'Last Update',
      },
    ],
    [urlValues.filterBy],
  );

  const handleEditSelectedItems = useCallback(
    (rows: string[]) => {
      navigate(
        `edit?filterBy=${selectedFilterBy}&market=${urlValues.market}${
          selectedChannels ? `&channels=${selectedChannels.join()}` : ''
        }${selectedPages ? `&pages=${selectedPages.join()}` : ''}&selected=${rows.join()}`,
      );
    },
    [navigate, selectedChannels, selectedFilterBy, selectedPages, urlValues.market],
  );

  const rows = useCallback(
    (data: InitialTableListRules[]) => {
      return data.map(row => ({
        ...row,
        rules: row.rules?.map(rule => rule.rule),
        onEditClick: {
          onClick: () => {
            handleEditSelectedItems([String(row.id)]);
          },
          isActionDisabled: row.userHasPending,
          isDraft: row.userHasDraft,
        },
      }));
    },
    [handleEditSelectedItems],
  );

  useEffect(() => {
    setSelectedFilterBy(urlValues.filterBy);
    setSelectedMarket(urlValues.market);
    setSelectedChannels(urlValues.channels);
    setSelectedPages(urlValues.pages);
  }, [urlValues.channels, urlValues.filterBy, urlValues.market, urlValues.pages]);

  const handleSubmit = useCallback(() => {
    setParams(
      formDataToQueryParams({
        filterBy: selectedFilterBy,
        market: selectedMarket,
        channels: selectedChannels,
        pages: selectedPages,
      }),
    );
  }, [selectedFilterBy, selectedChannels, selectedMarket, selectedPages, setParams]);

  useEffect(() => {
    resetRules();
  }, [resetRules]);

  useEffect(() => {
    let abortFetchRules: VoidFunction | undefined;
    switch (urlValues.filterBy) {
      case 'container':
        if (!!urlValues.market?.length && !!urlValues.channels?.length && !!urlValues.pages?.length) {
          abortFetchRules = fetchRules({ type: 'container', pageIds: urlValues.pages });
        }
        break;
      case 'sku':
        if (!!urlValues.market?.length) {
          abortFetchRules = fetchRules({ type: 'sku', marketId: urlValues.market });
        }
        break;
      default:
        return;
    }

    return () => {
      void abortFetchRules?.();
    };
  }, [
    fetchRules,
    urlValues.channels?.length,
    urlValues.filterBy,
    urlValues.market,
    urlValues.market?.length,
    urlValues.pages,
    urlValues.pages?.length,
  ]);

  return (
    <Container className="merchandising-rules">
      <Card>
        <Card.Body>
          <Row className="mb-3">
            <Breadcrumbs breadcrumbs={breadcrumbs} />
          </Row>
          <Row>
            <Col>
              <Typography variant="heading">Merchadising Rules</Typography>
              <Typography weight="bold" color="text-gray" className="mt-3">
                Optimize recommendations to your business goals by creating rules that boost or bury itens based on
                their brand, category, etc.
              </Typography>
            </Col>
            <Row className="mt-4">
              <MerchandisingRulesForm
                values={{
                  channels: selectedChannels,
                  pages: selectedPages,
                  market: selectedMarket,
                  filterBy: selectedFilterBy,
                }}
                onChange={{
                  handleChannelsChanges: setSelectedChannels,
                  handlePagesChanges: setSelectedPages,
                  handleMarketsChanges: setSelectedMarket,
                  handleFilterByChanges: setSelectedFilterBy,
                }}
                onSubmit={handleSubmit}
              />
            </Row>
          </Row>
        </Card.Body>
      </Card>
      {urlValues.filterBy !== undefined && (
        <Card>
          <Card.Body>
            <Row>
              <Col>
                <Typography variant="heading">
                  <span style={{ textTransform: 'capitalize' }}>{urlValues.filterBy}</span> Rules Results
                </Typography>
                <Typography weight="bold" color="text-gray" className="mt-3">
                  Previewing list based on market and custom parameters.
                </Typography>
              </Col>
            </Row>
            <Row className="mt-4">
              <ExternalDataRenderer
                externalData={rules}
                makeDataElement={data =>
                  data.length > 0 ? (
                    <PaginatedTableComponent
                      selectableRows
                      searchable
                      columns={columns}
                      rows={rows(data)}
                      rowIdPropName="id"
                      handleEditSelected={handleEditSelectedItems}
                    />
                  ) : (
                    <Typography variant="heading">No results found.</Typography>
                  )
                }
              />
            </Row>
          </Card.Body>
        </Card>
      )}
    </Container>
  );
}
