import { Button } from '@amway/react-components';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Market } from '../../../interface/market';
import { RecommendedProduct, RecommenderRequest } from '../../../interface/recommender';
import useBreadcrumbs from '../../../resources/breadcrumbs/breadcrumbs-hook';
import useModels from '../../../resources/model-output/model-output-hook';
import useRecommendation from '../../../resources/recommendation/recommendation-hook';
import useScenarios from '../../../resources/scenarios/scenarios-hook';
import Breadcrumbs from '../../../shared/ui/breadcrumbs';
import ApproveRequestModal from '../../request-list/modals/approve-request';
import RejectRequestModal from '../../request-list/modals/reject-request';
import SendToApprovalModal from '../../scenario-list/scenario-version-tester-screen/send-to-approval-modal';
import RecommendedProductsComponent from '../../scenario-tester/recommended-products';
import ScenarioTesterFormComponent, { ScenarioTesterFormFields } from '../../scenario-tester/scenario-tester-form';
import './index.css';

export default function ManualModelTestScreen() {
  const { id, modelVersionId } = useParams();
  const navigate = useNavigate();
  const { breadcrumbs } = useBreadcrumbs();
  const [market, setMarket] = useState<Market>();
  const [request, setRequest] = useState<RecommenderRequest>();
  const [inputSkus, setInputSkus] = useState<string[]>([]);
  const [recommendations, setRecommendations] = useState<RecommendedProduct[]>([]);
  const { recommendation, fetchRecommendationByModelVersionId, resetRecommendations } = useRecommendation();
  const { draftScenarioVersion } = useScenarios();
  const [scenarioVersionId, setScenarioVersionId] = useState<string | null>();
  const { draftModelVersion, fetchDraftModelVersion, sendModelVersionToApproval, checkDraftModelVersion } = useModels();
  const [isSendToApprovalModalOpen, setIsSendToApprovalModalOpen] = useState(false);
  const [isApproveRequestModalOpen, setIsApproveRequestModalOpen] = useState(false);
  const [isRejectRequestModalOpen, setIsRejectRequestModalOpen] = useState(false);
  const [isSendApprovalRequestLoading, setIsSendApprovalRequestLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const approvalRequest = useMemo(
    () => (searchParams.get('approval') ? Number(searchParams.get('approval')) : undefined),
    [searchParams],
  );

  useEffect(() => {
    return () => resetRecommendations();
  }, [resetRecommendations]);

  useEffect(() => {
    if (market) {
      fetchDraftModelVersion(market.id, modelVersionId ? parseInt(modelVersionId!) : undefined);
    }
  }, [fetchDraftModelVersion, market, modelVersionId]);

  useEffect(() => {
    if (modelVersionId && id) {
      checkDraftModelVersion(parseInt(id), parseInt(modelVersionId));
    }
  }, [modelVersionId, id, checkDraftModelVersion]);

  const handleFormChanges = useCallback(
    (formData: ScenarioTesterFormFields) => {
      setRequest({
        containerKey: formData.container?.containerKey,
        accountNum: formData.accountNum || undefined,
        skus: formData.skus.length ? formData.skus : undefined,
        recentlySearched: formData.recentlySearched.length ? formData.recentlySearched : undefined,
        recentlyViewedSkus: formData.recentlySearched.length ? formData.recentlySearched : undefined,
        productsInCart: formData.productsInCart.length ? formData.productsInCart : undefined,
        channel: formData.channel?.code,
        filter: formData.filter || undefined,
        searchText: formData.searchText || undefined,
        pageName: formData.page?.pageName,
        slots: formData.slots || undefined,
        variant: formData.variant || undefined,
        visitorUserProfile: formData.visitorUserProfile || undefined,
      });

      if (formData.market) {
        setMarket(formData.market);

        if (!_.isEqual(formData.skus, inputSkus)) {
          setInputSkus(formData.skus);
        }
      }

      if (formData.scenarioVersion) {
        setScenarioVersionId(formData.scenarioVersion.id.toString());
      }
    },
    [inputSkus],
  );

  const isButtonDisabled = useMemo<boolean>(() => {
    return !market || !request?.channel || !request?.containerKey || !scenarioVersionId;
  }, [market, request?.channel, request?.containerKey, scenarioVersionId]);

  const handleSubmit = useCallback(() => {
    if (market && request && draftModelVersion.data) {
      let modelVersionId = draftModelVersion.data?.id;

      fetchRecommendationByModelVersionId(market!.code, parseInt(id as string), {
        ...request!,
        scenarioVersionId: parseInt(scenarioVersionId || '') || draftScenarioVersion.data?.id,
        modelVersionId: modelVersionId,
      });
    }
  }, [
    market,
    request,
    draftModelVersion.data,
    fetchRecommendationByModelVersionId,
    id,
    scenarioVersionId,
    draftScenarioVersion.data?.id,
  ]);

  useEffect(() => {
    if (recommendation.data) {
      // setResponse(recommendation.data.recommendations);
      setRecommendations(
        recommendation.data.recommendations.map((recommendation, i) => ({
          // slot: i + 1,
          ...recommendation,
        })),
      );
    }
  }, [recommendation]);

  const recommendationsStyle = useMemo(
    () => ({
      maxWidth: recommendations.length * (240 + 117) + 'px',
    }),
    [recommendations],
  );

  const handleBackToConfigurations = () => {
    navigate(`/admin-configurations/manual-model/${id}/edit`);
  };

  const handleCancel = () => {
    setIsSendToApprovalModalOpen(false);
  };

  const handleOpenSendToApprovalModal = () => {
    setIsSendToApprovalModalOpen(true);
  };

  const handleSendToApproval = (title: string, description: string) => {
    setIsSendApprovalRequestLoading(true);
    sendModelVersionToApproval(market!.id, draftModelVersion.data!.id, title, description).then(() => {
      setIsSendApprovalRequestLoading(false);
      navigate(`/admin-configurations/manual-model/`);
    });
  };

  return (
    <Container className="model-tester">
      <Card>
        <Card.Body>
          <Breadcrumbs breadcrumbs={breadcrumbs} />
          <Row className="title">
            <Col>
              <Card.Title>Test Model Configurations</Card.Title>
            </Col>
          </Row>
          <ScenarioTesterFormComponent onFormChange={handleFormChanges} modelTesting={true} />
          <Row className="submit">
            <Col className="d-flex justify-content-center">
              <Button disabled={isButtonDisabled} onClick={handleSubmit}>
                TEST RECOMMENDATIONS
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      {market && recommendations.length !== 0 && (
        <Card>
          <Card.Body className="recommendation-results-container">
            <Row className="title">
              <Col>
                <Card.Title>Recommendation Results</Card.Title>
              </Col>
              <Col>
                <Card.Title className="execution-metrics">
                  {/* <h6 className="second-line">Matched Scenario: {scenario?.name}</h6> */}
                  {/* <h6 className="second-line">Execution time: {executionTime}ms</h6> */}
                </Card.Title>
              </Col>
            </Row>
            <Card.Text>Previewing container based on scenario and custom parameters.</Card.Text>
            <Row className="products" style={recommendationsStyle}>
              <RecommendedProductsComponent
                className="products-container"
                marketCode={market.code}
                recommendations={recommendations}
              />
            </Row>
            <footer className="actions-container">
              <Button variant="link" fontColor="primary" onClick={handleBackToConfigurations}>
                BACK TO CONFIGURATIONS
              </Button>
              {approvalRequest ? (
                <div style={{ display: 'flex', justifyContent: 'center', gap: '48px' }}>
                  <Button variant="link" fontColor="warning-error" onClick={() => setIsRejectRequestModalOpen(true)}>
                    REJECT CHANGES
                  </Button>
                  <Button backgroundColor="warning-success" onClick={() => setIsApproveRequestModalOpen(true)}>
                    APPROVE CHANGES
                  </Button>
                </div>
              ) : (
                <Button onClick={handleOpenSendToApprovalModal} className="confirm">
                  SEND REQUEST
                </Button>
              )}
            </footer>
          </Card.Body>
        </Card>
      )}
      <SendToApprovalModal
        isLoading={isSendApprovalRequestLoading}
        isOpen={isSendToApprovalModalOpen}
        market={market?.name}
        onSend={handleSendToApproval}
        onCancel={handleCancel}
      />
      <ApproveRequestModal
        isOpen={isApproveRequestModalOpen}
        onCancel={() => setIsApproveRequestModalOpen(false)}
        id={String(approvalRequest) ?? '0'}
      />
      <RejectRequestModal
        isOpen={isRejectRequestModalOpen}
        onCancel={() => setIsRejectRequestModalOpen(false)}
        id={String(approvalRequest) ?? '0'}
      />
    </Container>
  );
}
