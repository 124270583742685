import { mdiCheck, mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import './index.scss';

interface Props {
  onApply: VoidFunction;
  onCancel: VoidFunction;
}

const ParentStepEditionConfirmationCell: React.FC<Props> = props => {
  const { onApply, onCancel } = props;

  return (
    <section className="edition-confirmation-container">
      <button className="edition-button" onClick={onApply}>
        <Icon path={mdiCheck} size={0.6} color="var(--warning-success)" />
      </button>
      <button className="edition-button" onClick={onCancel}>
        <Icon path={mdiClose} size={0.6} color="var(--warning-error)" />
      </button>
    </section>
  );
};

export default ParentStepEditionConfirmationCell;
