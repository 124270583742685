import React, { useEffect, useState } from 'react';
import ExternalDataRenderer from '../../../../../../components/hocs/external-data-renderer';
import useScenarios from '../../../../../../resources/scenarios/scenarios-hook';
import DialogLayout from '../../../../../../shared/dialog-layout';
import SearchInput from '../../../../../../shared/search-input';
import ParentStepBlockListView from './parent-step-block-list-viewer';
import './index.scss';
import { makeParentStepLabel } from '../../scenario-steps-helpers';
import { NewParentStep } from '../../../../../../resources/scenarios/scenarios-versions-models';

const filterBySearchText = (text: string, steps: NewParentStep[]): NewParentStep[] => {
  return steps.filter(step => makeParentStepLabel(step).toLowerCase().includes(text.toLowerCase()));
};

interface Props {
  isOpen: boolean;
  onAdd: (data: NewParentStep) => void;
  onCancel: VoidFunction;
}

const AddParentStepModal: React.FC<Props> = props => {
  const { isOpen, onAdd, onCancel } = props;
  const { parentSteps, fetchParentSteps } = useScenarios();
  const [searchText, setSearchText] = useState('');
  const [selectedStep, setSelectedStep] = useState<NewParentStep>();

  const handleSelectedStepChanges = (newSelectedStep: NewParentStep) => {
    setSelectedStep(newSelectedStep);
  };

  useEffect(() => {
    void fetchParentSteps();
  }, [fetchParentSteps]);

  const handleConfirmAction = () => {
    onAdd(selectedStep!);
    setSelectedStep(undefined);
    setSearchText('');
  };

  const handleScapeAction = () => {
    setSelectedStep(undefined);
    onCancel();
    setSearchText('');
  };

  if (!isOpen) return null;

  return (
    <DialogLayout
      open={isOpen}
      title="Add New Parent-Step"
      subtitle="Select the parent-step block below:"
      confirmActionHandler={selectedStep ? handleConfirmAction : undefined}
      confirmActionText={'ADD NEW STEP'}
      scapeActionText={'CANCEL'}
      variant="outlined"
      scapeActionHandler={handleScapeAction}>
      <section className="add-parent-step-modal-container">
        <SearchInput
          id="parent-step-filter"
          value={searchText}
          onChange={value => setSearchText(value)}
          placeholder="Search for a parent-step"
          className="mb-3"
        />
        <div className="add-parent-step-modal-content">
          <ExternalDataRenderer
            externalData={parentSteps}
            makeDataElement={parentSteps => (
              <ParentStepBlockListView
                parentSteps={filterBySearchText(searchText, parentSteps)}
                selectedStep={selectedStep}
                handleSelectedStepChanges={handleSelectedStepChanges}
              />
            )}
          />
        </div>
      </section>
    </DialogLayout>
  );
};

export default AddParentStepModal;
