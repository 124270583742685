import { mdiDelete, mdiMagnify, mdiPencil, mdiPlus } from '@mdi/js';
import Icon from '@mdi/react';
import { DebouncedFunc } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Card, Col, Container, Form, Row } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ExternalDataRenderer from '../../../components/hocs/external-data-renderer';
import { Market } from '../../../interface/market';
import { ModelOutputRequest, OnModelFunction } from '../../../interface/model';
import useBreadcrumbs from '../../../resources/breadcrumbs/breadcrumbs-hook';
import useModels from '../../../resources/model-output/model-output-hook';
import { ModelOutput } from '../../../resources/model-output/model-output-models';
import modeloutputService from '../../../resources/model-output/model-output.service';
import useScenarios from '../../../resources/scenarios/scenarios-hook';
import useToasts from '../../../resources/toasts/toasts-hook';
import adminService from '../../../service/admin.service';
import Button from '../../../shared/button';
import { Item } from '../../../shared/dropdown-btn';
import MenuPopup from '../../../shared/menu-popup';
import PaginatedTableComponent, { Column } from '../../../shared/paginated-table';
import Title from '../../../shared/title';
import Typography from '../../../shared/typography';
import Breadcrumbs from '../../../shared/ui/breadcrumbs';
import CancelChangesModal from '../../scenario-list/scenario-details-screen/scenario-steps/modals/save-changes-modal';
import './index.scss';
import DeleteModelModal from './modals/delete-model';
import AddNewModelModal from './modals/new-model';

interface ModelOutputRow {
  id: number;
  keyType: string;
  key: string;
  productList: string;
}

const columns: Column[] = [
  {
    id: 'id',
    label: '#',
  } as any,
  {
    id: 'keyType',
    label: 'Key Type',
    format: (value: number) => `${value.toLocaleString()} markets`,
    sx: (value: number | string) => (typeof value === 'number' ? { color: '#4994EE' } : {}),
  },
  {
    id: 'key',
    label: 'Key',
    format: (value: number) => `${value.toLocaleString()} channels`,
    sx: (value: number | string) => (typeof value === 'number' ? { color: '#4DD895' } : {}),
  },
  {
    id: 'productList',
    label: 'Product List',
    format: (value: string) => `${value.toLocaleString()} pages`,
    sx: (value: number | string) => (typeof value === 'number' ? { color: '#F4CD67' } : {}),
  },
  {
    id: 'settings',
    label: '',
  },
];

interface StepMenuPopupItemProps {
  label: string;
  icon: any;
}

const StepMenuPopupItem: React.FC<StepMenuPopupItemProps> = props => {
  const { label, icon } = props;

  return (
    <span>
      <Icon path={icon} size={0.7} color="var(--text-gray)" className="me-2" />
      {label}
    </span>
  );
};

const onEdit: (
  id: number,
  setIsNewModelModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
  setSelectedModel: React.Dispatch<React.SetStateAction<number | undefined>>,
) => boolean = (id, setIsNewModelModalOpen, setSelectedModel) => {
  setIsNewModelModalOpen(true);
  setSelectedModel(id);
  return true;
};

const onDelete: (
  id: number,
  setIsDeleteModelModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
  setSelectedModel: React.Dispatch<React.SetStateAction<number | undefined>>,
) => boolean = (id, setIsDeleteModelModalOpen, setSelectedModel) => {
  setIsDeleteModelModalOpen(true);
  setSelectedModel(id);
  return true;
};

const toModelOutputRows = (
  models: ModelOutput[],
  setIsNewModelModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
  setIsDeleteModelModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
  setSelectedModel: React.Dispatch<React.SetStateAction<number | undefined>>,
): ModelOutputRow[] => {
  return models.map((model: any) => ({
    id: model.id,
    keyType: model.keyType,
    key: model.key,
    productList: model.productListStr,
    settings: (
      <MenuPopup
        title="Model Configuration"
        items={[
          {
            id: 'edit',
            onClick: () => onEdit(model.id, setIsNewModelModalOpen, setSelectedModel),
            element: <StepMenuPopupItem icon={mdiPencil} label="Edit Manual Model" />,
          },
          {
            id: 'delete',
            onClick: () => onDelete(model.id, setIsDeleteModelModalOpen, setSelectedModel),
            element: <StepMenuPopupItem icon={mdiDelete} label="Delete Manual Model" />,
          },
        ]}
      />
    ),
  }));
};

const ManualModelEditorScreen: React.FC = () => {
  const { id } = useParams();
  const { push: pushToast } = useToasts();
  const navigate = useNavigate();
  const { breadcrumbs } = useBreadcrumbs();
  const { scenarioDetails } = useScenarios();
  const {
    models: modelsData,
    draftModelVersion,
    fetchModels,
    saveManualModel,
    editManualModel,
    deleteModel,
    fetchDraftModelVersion,
    discardModelDraftVersion,
  } = useModels();
  const [selectedModel, setSelectedModel] = useState<number | undefined>(undefined);
  const [isNewModelModalOpen, setIsNewModelModalOpen] = useState(false);
  const [isDeleteModelModalOpen, setIsDeleteModelModalOpen] = useState<boolean>(false);
  const models: ModelOutputRow[] = useMemo(
    () => toModelOutputRows(modelsData.data ?? [], setIsNewModelModalOpen, setIsDeleteModelModalOpen, setSelectedModel),
    [modelsData.data],
  );
  const [searchText, setSearchText] = useState<string>('');
  const debouncedRef = useRef<DebouncedFunc<() => void> | null>(null);
  const [market, setMarket] = useState<Market | undefined | null>();
  const [keyTypes, setKeyTypes] = useState<Item[]>([]);
  const [filteredModels, setFilteredModels] = useState<ModelOutput[]>();
  const [isResetChangesModalOpen, setIsResetChangesModalOpen] = useState(false);

  const loadMarkets = useCallback(() => {
    const { promise } = adminService.getMarkets();

    promise
      .then(response => {
        const actualMarket = response.find(market => market.id === parseInt(id!));
        setMarket(actualMarket);
      })
      .catch(err => {
        console.error(err);
        pushToast('Could not fetch markets', 'error');
      });
  }, [id, pushToast]);

  useEffect(() => {
    fetchModels(parseInt(id!), 'DRAFT');
  }, [fetchModels, id]);

  useEffect(() => {
    modeloutputService
      .getKeyTypes()
      .then(response => {
        const mappedKeyTypes = response.map((keyType, id) => ({
          id: id,
          label: keyType,
        }));
        setKeyTypes(mappedKeyTypes);
      })
      .catch(err => {
        console.error(err);
        pushToast('Could not fetch key types', 'error');
      });
  }, [pushToast]);

  useEffect(() => {
    loadMarkets();
  }, [loadMarkets]);

  const handleReset = useCallback(() => {
    discardModelDraftVersion(parseInt(id!));
    navigate(`/admin-configurations/manual-model/`);
  }, [discardModelDraftVersion, id, navigate]);

  const handleAddNewModel = () => {
    setSelectedModel(undefined);
    setIsNewModelModalOpen(true);
  };

  const handleAddNewModelModalConfirm: OnModelFunction = (keyType: string, key: string, skus: string[]) => {
    const req = {
      marketId: market?.id,
      keyType: keyType,
      key: key,
      productList: skus,
    } as ModelOutputRequest;
    setIsNewModelModalOpen(false);
    saveManualModel(req).then(() => {
      fetchModels(market!.id, 'DRAFT');
    });
  };

  const handleEditModelModalConfirm: OnModelFunction = (keyType: string, key: string, skus: string[]) => {
    const req = {
      marketId: market?.id,
      keyType: keyType,
      key: key,
      productList: skus,
      modelId: selectedModel,
    } as ModelOutputRequest;
    setIsNewModelModalOpen(false);
    editManualModel(req).then(() => {
      fetchModels(market!.id, 'DRAFT');
    });
  };

  const handleDeleteModelModalConfirm: (id: number) => VoidFunction = (id: number) => {
    setIsDeleteModelModalOpen(false);
    deleteModel(id).then(() => {
      fetchModels(market!.id, 'DRAFT');
    });
    return () => {};
  };

  const handleAddNewModelModalCancel = () => {
    setIsNewModelModalOpen(false);
  };

  const handleDeleteModelModalCancel = () => {
    setIsDeleteModelModalOpen(false);
  };

  const handleSearchInput = (search: string) => {
    setSearchText(search);
    if (search) {
      const filteredModels = (
        modelsData.data!.map(model => ({
          ...model,
          productList: [model.productListStr.replaceAll(',', ', ')],
        })) as ModelOutput[]
      ).filter(model => model.productListStr.includes(search));
      setFilteredModels(filteredModels);
    } else {
      setFilteredModels(undefined);
    }
  };

  const handleTryToGoBack = () => {
    debouncedRef.current?.cancel();
    handleGoBack();
  };

  const handleGoBack = () => {
    navigate(`/admin-configurations/manual-model/`);
  };

  const handleTryToGoToTests = () => {
    debouncedRef.current?.cancel();
    handleGoToTests?.();
  };

  const handleGoToTests = () => {
    navigate(`/admin-configurations/manual-model/${id}/edit/test`);
  };

  const handleTryToResetChanges = () => {
    setIsResetChangesModalOpen(true);
  };

  const handleResetChangesModalConfirm = () => {
    handleReset();
    setIsResetChangesModalOpen(false);
  };

  const handleResetChangesModalCancel = () => {
    setIsResetChangesModalOpen(false);
  };

  return (
    <Container className="scenario-editor-container">
      <Card className="scenario-editor">
        <Card.Body>
          <Breadcrumbs breadcrumbs={breadcrumbs} />
          <ExternalDataRenderer
            externalData={modelsData}
            makeDataElement={() => (
              <>
                <Title>Manual Model Configurations</Title>
                <Typography weight="bold">
                  You are in configuration mode of{' '}
                  <Link to={`/scenario-list/${scenarioDetails.data?.id}`}>{scenarioDetails.data?.name}</Link> market.
                </Typography>
                <Card className="manual-model-table">
                  <Card.Body>
                    <ExternalDataRenderer
                      externalData={modelsData}
                      makeDataElement={() => (
                        <>
                          <header className="actions-container">
                            <Form.Group id="search-text" className="search-text">
                              <Icon className="search-icon" path={mdiMagnify} size={1} color="var(--text-gray)" />
                              <Form.Control
                                id="search-text-ctrl"
                                type="text"
                                value={searchText}
                                placeholder="Type to search"
                                className="search-text-ctrl"
                                onChange={e => handleSearchInput(e.target.value)}
                              />
                            </Form.Group>
                            <button className="action-button" onClick={handleAddNewModel}>
                              <Icon path={mdiPlus} size={1} color="var(--bg-white)" />
                            </button>
                          </header>
                          <Row className="mb-0">
                            <Col className="justify-content-center">
                              <PaginatedTableComponent
                                columns={columns}
                                rows={filteredModels || models}
                                rowIdPropName="id"
                                sx={{ maxWidth: 'calc(100vw - 307px - 2*24px - 32px - 38px - 19px)' }}
                              />
                            </Col>
                          </Row>
                          <footer className="actions-footer mt-3">
                            <Button onClick={handleTryToGoBack} variant="quaternary" className="back-btn">
                              BACK TO MODEL LIST
                            </Button>
                            <div>
                              <Button
                                onClick={handleTryToResetChanges}
                                variant="quaternary"
                                className="reset-changes-btn">
                                RESET CHANGES
                              </Button>
                              <Button disabled={handleGoToTests === undefined} onClick={handleTryToGoToTests}>
                                TEST CHANGES
                              </Button>
                            </div>
                          </footer>
                          <AddNewModelModal
                            market={market!}
                            keyTypes={keyTypes}
                            selectedModel={selectedModel}
                            isOpen={isNewModelModalOpen}
                            onAddModel={handleAddNewModelModalConfirm}
                            onEditModel={handleEditModelModalConfirm}
                            onCancel={handleAddNewModelModalCancel}
                          />
                          <DeleteModelModal
                            market={market!}
                            model={selectedModel ? selectedModel : -1}
                            isOpen={isDeleteModelModalOpen}
                            onDeleteModel={handleDeleteModelModalConfirm}
                            onCancel={handleDeleteModelModalCancel}
                          />
                          <CancelChangesModal
                            isOpen={isResetChangesModalOpen}
                            onSaveAndLeave={handleResetChangesModalConfirm}
                            onCancel={handleResetChangesModalCancel}
                          />
                        </>
                      )}
                    />
                  </Card.Body>
                </Card>
              </>
            )}
          />
        </Card.Body>
      </Card>
    </Container>
  );
};

export default ManualModelEditorScreen;
