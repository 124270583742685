import { useCallback, useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { FeatureMarkets } from '../../../config/feature-markets';
import { Features } from '../../../config/features';
import { WithScopedFeaturesProxy } from '../../../helpers/with-features-proxy';
import { Channel } from '../../../interface/channel';
import { Container } from '../../../interface/container';
import { Market } from '../../../interface/market';
import { Page } from '../../../interface/page';
import { RecommendationRequestUserType, RecommenderFilterRequest } from '../../../interface/recommender';
import { Scenario } from '../../../interface/scenario';
import useScenarios from '../../../resources/scenarios/scenarios-hook';
import { ScenarioVersionStatus } from '../../../resources/scenarios/scenarios-versions-models';
import useToasts from '../../../resources/toasts/toasts-hook';
import adminService from '../../../service/admin.service';
import scenarioService from '../../../service/scenario.service';
import DropdownBtn, { Item } from '../../../shared/dropdown-btn';
import ScenarioTesterParamsForm, { ScenarioTesterFilterFormFields } from '../scenario-tester-filters-form';
import './index.css';

export interface ScenarioTesterFormFields extends ScenarioTesterFilterFormFields {
  market?: Market | null;
  channel?: Channel | null;
  page?: Page | null;
  container?: Container | null;
  scenario?: Scenario | null;
  scenarioVersion?: Item | null;
}

interface ExposedProps {
  onFormChange: (request: ScenarioTesterFormFields) => void;
  modelTesting?: boolean;
}

interface Props extends ExposedProps {
  availableMarkets: FeatureMarkets[];
}

const ScenarioTesterFormComponent: React.FC<Props> = props => {
  const { onFormChange, availableMarkets, modelTesting } = props;
  const { push: pushToast } = useToasts();
  const [markets, setMarkets] = useState<Item[]>([]);
  const [channels, setChannels] = useState<Item[]>([]);
  const [pages, setPages] = useState<Item[]>([]);
  const [containers, setContainers] = useState<Item[]>([]);
  const [scenarios, setScenarios] = useState<Item[]>([]);
  const [scenarioVersions, setScenarioVersions] = useState<Item[]>([]);
  const [selectedMarket, setSelectedMarket] = useState<Market | undefined | null>();
  const [selectedChannel, setSelectedChannel] = useState<Channel | undefined | null>();
  const [selectedScenario, setSelectedScenario] = useState<Scenario | undefined | null>();
  const [selectedScenarioVersion, setSelectedScenarioVersion] = useState<Item | undefined | null>();
  const [selectedPage, setSelectedPage] = useState<Page | undefined | null>();
  const [selectedContainer, setSelectedContainer] = useState<Container | undefined | null>();
  const [skus, setSkus] = useState<string[]>([]);
  const [filter, setFilter] = useState<RecommenderFilterRequest | undefined>();
  const [searchText, setSearchText] = useState<string>('');
  const [accountNum, setAccountNum] = useState<string>('');
  const [slots, setSlots] = useState<number | undefined>();
  const [disabledSlots, setDisabledSlots] = useState<number | undefined>();
  const [variant, setVariant] = useState<string | undefined>();
  const [productsInCart, setProductsInCart] = useState<string[]>([]);
  const [recentlyViewedSkus, setRecentlyViewedSkus] = useState<string[]>([]);
  const [recentlySearched, setRecentlySearched] = useState<string[]>([]);
  const [visitorUserProfile, setVisitorUserProfile] = useState<RecommendationRequestUserType>();
  const { scenarioVersionHistory, fetchScenarioVersionHistory } = useScenarios();

  const loadMarkets = useCallback(() => {
    const { promise } = adminService.getMarkets();

    promise
      .then(response => {
        setMarkets(
          response
            .filter(market => availableMarkets.includes(market.code as any))
            .map(market => ({ ...market, label: market.name })),
        );
      })
      .catch(err => {
        console.error(err);
        pushToast('Could not fetch markets', 'error');
      });
  }, [availableMarkets, pushToast]);

  const loadChannels = useCallback(() => {
    const { promise } = adminService.getChannels();
    promise
      .then(response => {
        response.forEach(channel => {
          (channel as unknown as Item).label = channel.name;
        });

        setChannels(response as unknown as Item[]);
      })
      .catch(err => {
        console.error(err);
        pushToast('Could not fetch channels', 'error');
      });
  }, [pushToast]);

  const loadPages = useCallback(
    (market: Market, channel: Channel) => {
      const { promise } = adminService.getPages([market.id], [channel.id]);
      promise
        .then(response => {
          response.forEach(page => {
            (page as unknown as Item).label = page.name;
          });

          setPages(response as unknown as Item[]);
        })
        .catch(err => {
          console.error(err);
          pushToast('Could not fetch pages', 'error');
        });
    },
    [pushToast],
  );

  const loadContainers = useCallback(
    (page: Page) => {
      adminService
        .getContainers([page.id])
        .then(response => {
          response.forEach(container => {
            (container as unknown as Item).label = container.name;
          });

          setContainers(response as unknown as Item[]);
        })
        .catch(err => {
          console.error(err);
          pushToast('Could not fetch containers', 'error');
        });
    },
    [pushToast],
  );

  const loadScenarios = useCallback(
    (page: Page) => {
      scenarioService
        .getScenarios([page])
        .then(response => {
          response.forEach(scenario => {
            (scenario as unknown as Item).label = scenario.name;
          });

          setScenarios(response as unknown as Item[]);
        })
        .catch(err => {
          console.error(err);
          pushToast('Could not fetch scenarios', 'error');
        });
    },
    [pushToast],
  );

  const clearForm = useCallback(() => {
    setSelectedPage(null);
    setSelectedContainer(null);
    setSelectedScenario(null);
    setSelectedScenarioVersion(null);

    setPages([]);
    setContainers([]);
    setScenarios([]);
    setScenarioVersions([]);
  }, []);

  const selectMarket = useCallback(
    (market: Market) => {
      setSelectedMarket(market);
      clearForm();

      if (market) {
        if (selectedChannel) {
          loadPages(market, selectedChannel);
        }
      }
    },
    [selectedChannel, clearForm, loadPages],
  );

  const selectChannel = useCallback(
    (channel: Channel) => {
      setSelectedChannel(channel);
      clearForm();

      if (channel && selectedMarket) {
        loadPages(selectedMarket, channel);
      }
    },
    [selectedMarket, clearForm, loadPages],
  );

  const selectPage = useCallback(
    (page: Page) => {
      setSelectedPage(page);
      setSelectedContainer(null);
      setSelectedScenario(null);
      setSelectedScenarioVersion(null);
      setContainers([]);
      setScenarios([]);
      setScenarioVersions([]);

      if (page) {
        loadContainers(page);
        loadScenarios(page);
      }
    },
    [loadContainers, loadScenarios],
  );

  useEffect(() => {
    loadMarkets();
    loadChannels();
  }, [loadMarkets, loadChannels]);

  const handleFilterFormChanges = useCallback((changes: ScenarioTesterFilterFormFields) => {
    setSkus(changes.skus);
    setFilter(changes.filter);
    setSearchText(changes.searchText);
    setAccountNum(changes.accountNum);
    setSlots(changes.slots);
    setDisabledSlots(changes.disabledSlots);
    setVariant(changes.variant);
    setProductsInCart(changes.productsInCart);
    setRecentlyViewedSkus(changes.recentlyViewedSkus);
    setRecentlySearched(changes.recentlySearched);
    setVisitorUserProfile(changes.visitorUserProfile);
  }, []);

  useEffect(() => {
    if (selectedScenario?.id) {
      fetchScenarioVersionHistory(selectedScenario?.id);
    }
  }, [fetchScenarioVersionHistory, selectedScenario]);

  useEffect(() => {
    if (scenarioVersionHistory.data) {
      setScenarioVersions(
        scenarioVersionHistory.data.map(version =>
          version.status === ScenarioVersionStatus.LIVE
            ? {
                label: 'Current Version',
                id: version.id,
              }
            : {
                label: version.updatedAt.toISOString(),
                id: version.id,
              },
        ),
      );
    }
  }, [scenarioVersionHistory]);

  useEffect(() => {
    // TODO: add debounce time if field being edited is a text control
    onFormChange({
      market: selectedMarket,
      channel: selectedChannel,
      page: selectedPage,
      container: selectedContainer,
      scenario: selectedScenario,
      scenarioVersion: selectedScenarioVersion,
      skus,
      filter,
      searchText,
      accountNum,
      slots,
      disabledSlots,
      variant,
      productsInCart,
      recentlyViewedSkus,
      recentlySearched,
      visitorUserProfile,
    });
  }, [
    onFormChange,
    selectedMarket,
    selectedChannel,
    selectedPage,
    selectedContainer,
    selectedScenario,
    selectedScenarioVersion,
    skus,
    filter,
    searchText,
    accountNum,
    slots,
    variant,
    productsInCart,
    recentlyViewedSkus,
    recentlySearched,
    visitorUserProfile,
    disabledSlots,
  ]);

  return (
    <Form className="scenario-tester-form">
      <Row className="mb-3">
        <Col>
          <DropdownBtn
            id="dropdown-market"
            required={true}
            disabled={markets.length === 0}
            none={true}
            label="Market"
            placeholder="Select Market"
            items={markets}
            onClick={selectMarket as unknown as (item?: Item | null) => void}
          />
        </Col>
        <Col>
          <DropdownBtn
            id="dropdown-channel"
            required={true}
            disabled={channels.length === 0}
            none={true}
            label="Channel"
            placeholder="Select Channel"
            items={channels}
            onClick={selectChannel as unknown as (item?: Item | null) => void}
          />
        </Col>
        <Col>
          <DropdownBtn
            id="dropdown-page"
            required={true}
            disabled={pages.length === 0}
            none={true}
            label="Page"
            placeholder="Select Page"
            items={pages}
            onClick={selectPage as unknown as (item?: Item | null) => void}
          />
        </Col>
        <Col>
          <DropdownBtn
            id="dropdown-container"
            required={true}
            disabled={containers.length === 0}
            none={true}
            label="Container"
            placeholder="Select Container"
            items={containers}
            onClick={setSelectedContainer as unknown as (item?: Item | null) => void}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <DropdownBtn
            id="dropdown-scenario"
            disabled={scenarios.length === 0}
            none={true}
            label="Scenario"
            placeholder="Select Scenario"
            items={scenarios}
            onClick={setSelectedScenario as unknown as (item?: Item | null) => void}
          />
        </Col>
        {modelTesting && (
          <Col md={6}>
            <DropdownBtn
              id="dropdown-scenario-version"
              disabled={scenarioVersions.length === 0}
              none={false}
              label="Scenario Version"
              placeholder="Select Scenario Version"
              items={scenarioVersions}
              onClick={setSelectedScenarioVersion as unknown as (item?: Item | null) => void}
            />
          </Col>
        )}
      </Row>
      <ScenarioTesterParamsForm marketId={selectedMarket?.id} onFormChange={handleFilterFormChanges} />
    </Form>
  );
};

export default WithScopedFeaturesProxy<ExposedProps>(Features.TestingToolScreen)((props, _, markets) => {
  return <ScenarioTesterFormComponent {...props} availableMarkets={markets} />;
});
