import { Form } from 'react-bootstrap';

export interface Props {
  value: string;
  onChange: (newValue: string) => void;
  onFinishEdition: VoidFunction;
}

const ParentStepNameEditorCell: React.FC<Props> = props => {
  const { value, onChange, onFinishEdition } = props;

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = e => {
    e.preventDefault();
    onFinishEdition();
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group>
        <Form.Control autoFocus value={value} onChange={e => onChange(e.target.value)} />
      </Form.Group>
    </Form>
  );
};

export default ParentStepNameEditorCell;
