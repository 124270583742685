import { BaseSyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Form } from 'react-bootstrap';
import { removeDuplicates } from '../../utils/array.utils';
import ClosableBadgeComponent, { BadgeColorTheme, Verified } from '../closable-badge';
import './index.css';

type Separator = ' ' | ',' | ' AND,';

export interface VerifiedValue extends Verified {
  value: string;
}

type Props = {
  id: string;
  label?: string;
  badgeTheme: BadgeColorTheme;
  onChange: (event: string[]) => void;
  value?: string[];
  validValues?: string[];
  invalidValues?: string[];
  verifiedValues?: VerifiedValue[];
  maxLength?: number;
  // default: " "
  separator?: Separator;
};

const makeInputPlaceholder = (separator: Separator): string => {
  if (separator === ' ') {
    return 'Split by space';
  } else if (separator === ',') {
    return 'Split by comma';
  } else {
    return 'Split by space or comma';
  }
};

export default function SeparatorInputComponent(props: Props) {
  const {
    id,
    value = [],
    invalidValues,
    verifiedValues,
    onChange,
    label,
    badgeTheme,
    maxLength,
    separator = ' AND,',
  } = props;
  const [badges, setBadges] = useState<string[]>(value);
  const hasVerifierTheme = useMemo(() => badgeTheme === 'verifier' && verifiedValues, [badgeTheme, verifiedValues]);

  useEffect(() => {
    setBadges(value);
  }, [value]);

  useEffect(() => {
    onChange(badges);
  }, [badges, onChange]);

  const addBadgesFromValue = useCallback(
    (value: string): void => {
      let tokens: string[] = [];

      if (separator === ' ') {
        tokens = value.split(/[\s]+/); // split by space
      } else if (separator === ',') {
        tokens = value.split(','); // split by comma
      } else {
        tokens = value.split(/[\s,]+/); // split by space and comma
      }

      setBadges(previousBadges => removeDuplicates([...previousBadges, ...tokens.filter(token => token.length > 0)]));
    },
    [separator],
  );

  const handleChange = useCallback(
    (event: BaseSyntheticEvent<InputEvent, any, HTMLInputElement>) => {
      const { value } = event.target;

      // this is bad and need refactoring. But, a bad, though inside a small component, code is not that problematic
      if (value?.length) {
        if (
          (separator === ' ' && value.endsWith(' ')) ||
          (separator === ',' && value.endsWith(',')) ||
          (separator === ' AND,' && (value.endsWith(' ') || value.endsWith(',')))
        ) {
          addBadgesFromValue(value);
          event.target.value = '';
        }
      }
    },
    [addBadgesFromValue, separator],
  );

  const handleDelete = useCallback(
    (idx: number) => {
      setBadges([...badges.slice(0, idx), ...badges.slice(idx + 1)]);
    },
    [badges],
  );

  const handleOnBlur: React.FocusEventHandler<HTMLInputElement> = (e): void => {
    addBadgesFromValue(e.target.value);
    e.target.value = '';
  };

  const handleOnKeyDown: React.KeyboardEventHandler<HTMLInputElement> = e => {
    const value = (e.target as any).value;
    const key = e.key;

    if (key === 'Backspace' && value === '') {
      setBadges(badges => badges.slice(0, badges.length - 1));
    }
  };

  return (
    <Form.Group id={id} className="separator-input">
      {label && <Form.Label htmlFor={`${id}-ctrl`}>{label}</Form.Label>}
      <div className="form-input-container form-control fancy-scrollbar">
        {badges?.map((label, i) => (
          <ClosableBadgeComponent
            key={label}
            id={`closable-badge-${i}`}
            label={label}
            theme={invalidValues?.some(invalidValue => invalidValue === label) ? 'red' : badgeTheme}
            verified={hasVerifierTheme ? verifiedValues && verifiedValues.find(v => v.value === label) : undefined}
            onClose={() => handleDelete(i)}
          />
        ))}
        <Form.Control
          id={`${id}-ctrl`}
          type="text"
          maxLength={maxLength}
          placeholder={makeInputPlaceholder(separator)}
          onBlur={handleOnBlur}
          onChange={handleChange}
          onKeyDown={handleOnKeyDown}
        />
      </div>
    </Form.Group>
  );
}
