import { useCallback, useState } from 'react';
import { ExternalData } from '../../@types/external-api';
import {
  makeExternalCallErrorData,
  makeExternalDataInitialData,
  makeExternalDataSuccessData,
} from '../../helpers/external-api';
import useExternalApiErrorHandler from '../../hooks/use-external-api-error-handler';
import RecommendationContext, { RecommendationContextType } from './recommendation-context';
import { RecommendationResponse } from './recommendation-models';
import recommendationService from './recommendation.service';

const RecommendationsProvider: React.FC = ({ children }) => {
  const errorHandler = useExternalApiErrorHandler();
  const [recommendation, setRecommendation] = useState<ExternalData<RecommendationResponse>>({
    loading: false,
    unstarted: true,
  });

  const fetchRecommendationByScenarioVersionId: RecommendationContextType['fetchRecommendationByScenarioVersionId'] =
    useCallback(
      async (marketCode, scenarioId, params) => {
        try {
          setRecommendation(makeExternalDataInitialData());
          const data = await recommendationService.getRecommendationsWithTestParams(marketCode, scenarioId, params);
          setRecommendation(makeExternalDataSuccessData(data));
        } catch (err: any) {
          errorHandler(err);
          setRecommendation(makeExternalCallErrorData(err));
        }
      },
      [errorHandler],
    );

  const resetRecommendations: RecommendationContextType['resetRecommendations'] = useCallback(() => {
    setRecommendation({ unstarted: true });
  }, []);

  const fetchRecommendationByModelVersionId: RecommendationContextType['fetchRecommendationByModelVersionId'] =
    useCallback(
      async (marketCode, scenarioId, params) => {
        try {
          setRecommendation(makeExternalDataInitialData());
          const data = await recommendationService.getRecommendationsWithTestParams(marketCode, scenarioId, params);
          setRecommendation(makeExternalDataSuccessData(data));
        } catch (err: any) {
          errorHandler(err);
          setRecommendation(makeExternalCallErrorData(err));
        }
      },
      [errorHandler],
    );

  const fetchRecommendationsByMerchRuleIds: RecommendationContextType['fetchRecommendationsByMerchRuleIds'] =
    useCallback(
      async (marketCode, scenarioId, params) => {
        try {
          setRecommendation(makeExternalDataInitialData());
          const data = await recommendationService.getRecommendationsWithTestParams(marketCode, scenarioId, params);
          setRecommendation(makeExternalDataSuccessData(data));
        } catch (err: any) {
          errorHandler(err);
          setRecommendation(makeExternalCallErrorData(err));
        }
      },
      [errorHandler],
    );

  return (
    <RecommendationContext.Provider
      value={{
        recommendation,
        fetchRecommendationsByMerchRuleIds,
        fetchRecommendationByScenarioVersionId,
        fetchRecommendationByModelVersionId,
        resetRecommendations,
      }}>
      {children}
    </RecommendationContext.Provider>
  );
};

export default RecommendationsProvider;
