import { Col, Form, Row } from 'react-bootstrap';
import MultiSelectDropdown, { Item as MultiSelectItem } from '../../../shared/multi-select-dropdown';
import { useCallback, useEffect, useState } from 'react';
import adminService from '../../../service/admin.service';
import { WithScopedFeaturesProxy } from '../../../helpers/with-features-proxy';
import { FeatureMarkets } from '../../../config/feature-markets';
import { Features } from '../../../config/features';
import Radio, { RadioProps } from '../../../shared/radio';
import { Button } from '@amway/react-components';
import DropdownBtn, { Item } from '../../../shared/dropdown-btn';
import './index.scss';

type FilterBy = 'container' | 'sku' | undefined;

const filterByOptions: RadioProps[] = [
  {
    name: 'filter-by',
    value: 'container',
    title: 'Container',
  },
  {
    name: 'filter-by',
    value: 'sku',
    title: 'SKU',
  },
];

export interface MerchandisingRulesFormFields {
  filterBy?: string;
  market?: string;
  channels?: string[];
  pages?: string[];
  selected?: string[];
}

export interface MerchandisingRulesFormFieldsOnChangeHandlers {
  handleFilterByChanges: (value?: string) => void;
  handleMarketsChanges: (value?: string) => void;
  handleChannelsChanges: (value?: string[]) => void;
  handlePagesChanges: (value?: string[]) => void;
}

interface ExposedProps {
  values: MerchandisingRulesFormFields;
  onChange: MerchandisingRulesFormFieldsOnChangeHandlers;
  onSubmit: VoidFunction;
}

interface Props extends ExposedProps {
  availableMarkets: FeatureMarkets[];
}

function MerchandisingRulesForm(props: Props) {
  const [markets, setMarkets] = useState<Item[]>([]);
  const [channels, setChannels] = useState<MultiSelectItem[]>();
  const [pages, setPages] = useState<MultiSelectItem[]>();

  const {
    onSubmit,
    values: { market: selectedMarket, channels: selectedChannels, pages: selectedPages, filterBy: selectedFilterBy },
    availableMarkets,
    onChange: { handleChannelsChanges, handleFilterByChanges, handleMarketsChanges, handlePagesChanges },
  } = props;

  const loadMarkets = useCallback(() => {
    const { promise, abort } = adminService.getMarkets();

    promise.then(response => {
      setMarkets(
        response
          .filter(market => availableMarkets.includes(market.code as any))
          .map(item => ({ label: item.name, id: item.id.toString() })),
      );
    });

    return abort;
  }, [availableMarkets]);

  const loadChannels = useCallback(() => {
    const { promise, abort } = adminService.getChannels();

    promise.then(response => {
      setChannels(response.map(item => ({ name: item.name, value: item.id.toString() })));
    });

    return abort;
  }, []);

  useEffect(() => {
    const abortFetchMarkets = loadMarkets();
    const abortFetchChannels = loadChannels();

    return () => {
      abortFetchMarkets?.();
      abortFetchChannels?.();
    };
  }, [loadMarkets, loadChannels]);

  useEffect(() => {
    let abortFetchPages: VoidFunction | undefined;
    if (selectedMarket?.length && selectedChannels?.length) {
      const { promise, abort } = adminService.getPages([selectedMarket], selectedChannels);
      abortFetchPages = abort;
      promise.then(response => {
        setPages(response.map(item => ({ name: item.name, value: item.id.toString() })));
      });
    } else {
      setPages([]);
      handlePagesChanges([]);
    }

    return () => abortFetchPages?.();
  }, [selectedMarket, selectedChannels, handlePagesChanges]);

  const handleEnableSubmit = useCallback(() => {
    switch (selectedFilterBy) {
      case 'container':
        return !!(selectedMarket?.length && selectedChannels?.length && selectedPages?.length);
      case 'sku':
        return !(selectedMarket === undefined || selectedMarket === 'undefined');
      default:
        return false;
    }
  }, [selectedChannels?.length, selectedFilterBy, selectedMarket, selectedPages?.length]);

  return (
    <Row>
      <Col>
        <Row>
          <Form.Label className="mb-4">
            Filter by <sup style={{ color: 'var(--warning-error)' }}>*</sup>
          </Form.Label>
          {filterByOptions.map(option => (
            <Col key={option.value}>
              <Radio
                name={option.name}
                isChecked={option.value === selectedFilterBy}
                value={option.value}
                title={option.title}
                onChange={e => e.target.checked && handleFilterByChanges(option.value as FilterBy)}
              />
            </Col>
          ))}
        </Row>
      </Col>
      <Col style={{ maxWidth: '450px' }}>
        <DropdownBtn
          id="dropdown-market"
          required={true}
          disabled={!markets || selectedFilterBy === undefined}
          label="Market"
          placeholder="Select Market"
          items={markets}
          value={selectedMarket}
          onClick={item => handleMarketsChanges(String(item?.id))}
          className="dropdown-market-merch-rules"
        />
      </Col>
      <Col style={{ maxWidth: '450px' }}>
        <MultiSelectDropdown
          id="dropdown-channel"
          variant="tertiary"
          required
          none
          disabled={!channels || selectedFilterBy !== 'container'}
          all
          label="Channel"
          placeholder="Select Channel"
          items={channels}
          value={selectedChannels}
          onChange={handleChannelsChanges}
        />
      </Col>
      <Col style={{ maxWidth: '450px' }}>
        <MultiSelectDropdown
          id="dropdown-page"
          variant="tertiary"
          required
          none
          disabled={!pages || pages.length === 0 || selectedFilterBy !== 'container'}
          all
          label="Page"
          placeholder="Select Page"
          items={pages}
          value={selectedPages}
          onChange={handlePagesChanges}
        />
      </Col>
      <Row className="mt-5" style={{ justifyContent: 'center' }}>
        <Button style={{ width: 'max-content' }} onClick={onSubmit} disabled={!handleEnableSubmit()}>
          UPDATE
        </Button>
      </Row>
    </Row>
  );
}
// TODO: Change to the correct feature
export default WithScopedFeaturesProxy<ExposedProps>(Features.ScenariosListScreen)((props, _, markets) => {
  return <MerchandisingRulesForm {...props} availableMarkets={markets} />;
});
