import adminService from '../../service/admin.service';
import { ScenarioDetails, ScenarioDetailsListItem } from './scenarios-models';
import {
  ExecutorType,
  FilterExecutor,
  LoaderExecutor,
  ScenarioSteps,
  ScenarioVersion,
} from './scenarios-versions-models';

export const scenariosMapper = (apiData: any): ScenarioDetailsListItem[] => {
  return apiData;
};

export const scenarioDetailsMapper = async (apiData: any): Promise<ScenarioDetails> => {
  const { promise } = adminService.getMarkets();
  const existingMarkets = await promise;

  return {
    ...apiData,
    markets: apiData.markets.map((market: any) => ({
      ...market,
      code: existingMarkets.find(existingMarket => existingMarket.name === market.name)?.code,
    })),
    config: typeof apiData.config === 'string' ? JSON.parse(apiData.config) : apiData.config,
    updatedAt: new Date(apiData.updatedDt),
  };
};

export const scenarioStepsMapper = (apiData: any): ScenarioSteps => {
  return apiData.map((parentStep: any) => {
    let count = 0;

    return {
      id: parentStep.id,
      name: parentStep.description,
      subSteps: parentStep.subSteps.map((subStep: any) => {
        count++;

        return {
          id: subStep.id,
          executorId: subStep.executorId,
          name: subStep.executorName,
          executorType: subStep.modelName ? ExecutorType.LOADER : ExecutorType.FILTER,
          modelName: subStep.modelName,
          standard: count <= 3,
        };
      }),
    };
  });
};

export const optionalFiltersExecutorsMapper = (apiData: any): FilterExecutor[] => {
  return apiData
    .filter((executor: any) => executor.type === ExecutorType.FILTER && !executor.required)
    .map((data: any) => ({
      id: data.id,
      name: data.name,
      description: data.description,
      type: ExecutorType.FILTER,
    }));
};

export const requiredFiltersExecutorsMapper = (apiData: any): FilterExecutor[] => {
  return apiData
    .filter((executor: any) => executor.type === ExecutorType.FILTER && executor.required)
    .map((data: any) => ({
      id: data.id,
      name: data.name,
      description: data.description,
      type: ExecutorType.FILTER,
    }));
};

export const loaderExecutorsMapper = (apiData: any): LoaderExecutor[] => {
  return apiData
    .filter((executor: any) => executor.type === ExecutorType.LOADER)
    .map((data: any) => ({
      id: data.id,
      name: data.name,
      description: data.description,
      type: ExecutorType.LOADER,
      modelName: data.modelName,
    }));
};

export const scenarioVersionMapper = (apiData: any): ScenarioVersion => {
  return {
    id: apiData.id,
    approvalRequestId: apiData.approvalRequestId,
    scenarioId: apiData.scenarioId,
    steps: scenarioStepsMapper(apiData.steps),
    status: apiData.status,
    requestReason: apiData.requestReason,
    createdBy: apiData.createdBy,
    updatedBy: apiData.updatedBy,
    createdAt: new Date(apiData.createdDt),
    updatedAt: new Date(apiData.updatedDt),
  };
};
