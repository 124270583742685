import { Bullet, Label, p50, percent, Root, Scrollbar, Tooltip } from '@amcharts/amcharts5';
import { AxisRenderer } from '@amcharts/amcharts5/.internal/charts/xy/axes/AxisRenderer';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { AxisRendererX, AxisRendererY, CategoryAxis, ColumnSeries, ValueAxis, XYChart } from '@amcharts/amcharts5/xy';
import { CSSProperties, useCallback, useEffect, useState } from 'react';
import { DashboardRecommendationMetrics } from '../../../interface/dashboard';
import './index.css';

export interface StackedBarChartData {
  category: string;
  metrics: DashboardRecommendationMetrics[];
}

type Props = {
  id: string;
  style?: CSSProperties;
  data: StackedBarChartData[];
};

interface DataDict {
  category: string;
  [key: string]: number | any;
}

export default function DashboardStackedBarChartComponent({ id, style, data }: Props) {
  const [root, setRoot] = useState<Root>();
  const [chart, setChart] = useState<XYChart>();
  const [xAxis, setXAxis] = useState<CategoryAxis<AxisRenderer>>();
  const [yAxis, setYAxis] = useState<ValueAxis<AxisRenderer>>();

  const buildDataDict = useCallback((metrics: DashboardRecommendationMetrics[], category: string) => {
    return metrics.reduce(
      (previous, { name, total }) => {
        previous[name] = total;
        return previous;
      },
      {
        category,
      } as DataDict,
    ) as DataDict;
  }, []);

  const createColumnSeries = useCallback(
    (
      xAxis: CategoryAxis<AxisRenderer>,
      yAxis: ValueAxis<AxisRenderer>,
      name: string,
      fieldName: string,
      data: DataDict[],
    ) => {
      if (root && chart) {
        const series = chart.series.push(
          ColumnSeries.new(root, {
            name,
            stacked: true,
            xAxis,
            yAxis,
            valueYField: fieldName,
            valueYShow: 'valueYTotalPercent',
            categoryXField: 'category',
          }),
        );

        series.columns.template.setAll({
          tooltipText: "{name}: {valueYTotalPercent.formatNumber('#.##')}%",
          tooltipY: percent(10),
        });

        series.data.setAll(data);

        // Make stuff animate on load
        series.appear();

        series.bullets.push(() =>
          Bullet.new(root, {
            sprite: Label.new(root, {
              text: "{valueYTotalPercent.formatNumber('#.##')}%",
              fill: root.interfaceColors.get('alternativeText'),
              centerY: p50,
              centerX: p50,
              populateText: true,
            }),
          }),
        );

        return series;
      }
    },
    [root, chart],
  );

  const createYAxis = useCallback<() => ValueAxis<AxisRenderer>>(() => {
    if (root && chart) {
      return chart.yAxes.push(
        ValueAxis.new(root, {
          min: 0,
          max: 100,
          numberFormat: "#'%'",
          strictMinMax: true,
          calculateTotals: true,
          renderer: AxisRendererY.new(root, {}),
        }),
      );
    }

    return {} as ValueAxis<AxisRenderer>;
  }, [root, chart]);

  const createXAxis = useCallback<() => CategoryAxis<AxisRenderer>>(() => {
    if (root && chart) {
      return chart.xAxes.push(
        CategoryAxis.new(root, {
          categoryField: 'category',
          renderer: AxisRendererX.new(root, {}),
          tooltip: Tooltip.new(root, {}),
        }),
      );
    }

    return {} as CategoryAxis<AxisRenderer>;
  }, [root, chart]);

  useEffect(() => {
    if (!root) {
      setRoot(Root.new(id));
    }
  }, [id, root]);

  useEffect(() => {
    if (root && !chart) {
      // Set themes
      root.setThemes([am5themes_Animated.new(root)]);

      // Create chart
      const chart = root.container.children.push(
        XYChart.new(root, {
          panX: false,
          panY: false,
          wheelX: 'panY',
          wheelY: 'zoomY',
          layout: root.verticalLayout,
        }),
      );

      // Add scrollbar
      chart.set(
        'scrollbarY',
        Scrollbar.new(root, {
          orientation: 'vertical',
        }),
      );

      setChart(chart);
    }
  }, [root, chart]);

  useEffect(() => {
    if (chart && !xAxis && !yAxis) {
      const xAxis = createXAxis();
      const yAxis = createYAxis();

      setXAxis(xAxis);
      setYAxis(yAxis);
    }
  }, [chart, xAxis, yAxis, createXAxis, createYAxis]);

  useEffect(() => {
    if (chart && xAxis && yAxis && data.length) {
      chart.series.clear();

      const dataDicts = data.map(({ category, metrics }) => buildDataDict(metrics, category));

      dataDicts
        .map(dict => ({
          dict,
          keys: Object.keys(dict).filter(k => k !== 'category'),
        }))
        .forEach(({ keys }) => {
          keys.forEach((k: string) => {
            createColumnSeries(xAxis, yAxis, k, k, dataDicts);
          });
        });

      xAxis.data.setAll(data);

      chart.appear(1000, 100);
    }
  }, [chart, xAxis, yAxis, createColumnSeries, buildDataDict, data]);

  return <div id={id} className="dashboard-stacked-bar-chart" style={style} />;
}
