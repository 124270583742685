import { Button } from 'react-bootstrap';
import './index.css';

export default function NotFoundComponent() {
  return (
    <div className="not-found">
      <h1>404</h1>
      <h2>Page not found</h2>
      <h4>We couldn't find the page you were looking for.</h4>

      <Button href="/">BACK TO APR</Button>
    </div>
  );
}
