import React from 'react';
import DialogLayout from '../../../../../../shared/dialog-layout';
import './index.scss';
import Typography from '../../../../../../shared/typography';

interface Props {
  isOpen: boolean;
  onSaveAndLeave: VoidFunction;
  onCancel: VoidFunction;
}

function CancelChangesModal(props: Props) {
  const { isOpen, onCancel, onSaveAndLeave } = props;

  return (
    <DialogLayout
      open={isOpen}
      title={'Reset Changes'}
      className="reset-changes-dialog"
      scapeActionText={'CLOSE'}
      scapeActionHandler={onCancel}
      confirmActionText={'RESET CHANGES'}
      confirmActionHandler={onSaveAndLeave}>
      <Typography colorVariant="primary">
        Are you sure you want to cancel all changes without sending a request to apply? All configurations will be lost.
      </Typography>
    </DialogLayout>
  );
}

export default CancelChangesModal;
