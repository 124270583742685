import env from '../config/env';
import { Container } from '../interface/container';
import { Page } from '../interface/page';
import { Scenario, ScenarioDetails, ScenarioDetailsListItem, ScenarioStep } from '../interface/scenario';
import { AuthorizedService } from './authorized.service';

class ScenarioService extends AuthorizedService {
  public getScenarios(pages: Page[]): Promise<Scenario[]> {
    return super
      .get<Scenario[]>('scenario', {
        params: {
          pageIds: pages.map(({ id }: Page) => id).join(','),
        },
      })
      .then(scenarios => {
        scenarios.forEach(this.parseConfig);
        return scenarios;
      });
  }

  public getScenario(id: number): Promise<Scenario> {
    return super.get<Scenario>(`scenario/${id}`).then(this.parseConfig);
  }

  public getScenarioDetailsList(containers: Container[]): Promise<ScenarioDetailsListItem[]> {
    return super.get<ScenarioDetailsListItem[]>('scenario/list', {
      params: {
        containerIds: containers.map(({ id }: Container) => id).join(','),
      },
    });
  }

  public getScenarioDetails(id: number): Promise<ScenarioDetails> {
    return super.get<ScenarioDetails>(`scenario/${id}/details`).then(this.parseConfig);
  }

  public getScenarioSteps(id: number): Promise<ScenarioStep[]> {
    return super.get<ScenarioStep[]>(`scenario/${id}/steps`);
  }

  private parseConfig(scenario: { config: any }): any {
    if (typeof scenario.config === 'string') {
      scenario.config = JSON.parse(scenario.config);
    }

    return scenario;
  }
}

const scenarioService = new ScenarioService(env.ADMIN_API_URL);
export default scenarioService;
