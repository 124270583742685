import { createContext } from 'react';
import { ExternalData } from '../../@types/external-api';
import { ApprovalItem, ApprovalRequest, RequestType } from './approval-request-types';

export interface ApprovalRequestCtxType {
  approvalRequestList: ExternalData<ApprovalItem[]>;
  fetchApprovalRequestList: (req: ApprovalRequest, type?: RequestType, order?: string) => VoidFunction | undefined;
}

const ApprovalRequestContext = createContext<ApprovalRequestCtxType>(null as any);

export default ApprovalRequestContext;
