import React from 'react';
import { FilterExecutor } from '../../../../../../../resources/scenarios/scenarios-versions-models';
import Radio from '../../../../../../../shared/radio';
import Typography from '../../../../../../../shared/typography';
import './index.scss';

interface Props {
  steps: FilterExecutor[];
  selectedStep?: FilterExecutor;
  handleSelectedStepChanges: (newStep: FilterExecutor) => void;
}

interface FilterChildStepListItemProps {
  step: FilterExecutor;
  checked: boolean;
  onCheck: VoidFunction;
}

const FilterChildStepListItem: React.FC<FilterChildStepListItemProps> = props => {
  const { step, checked, onCheck } = props;

  return (
    <article className="child-step-item-container" onClick={onCheck}>
      <section className="child-step-item-actions">
        <Radio
          name={'child-step'}
          isChecked={checked}
          value={step.id.toString()}
          onChange={e => e.target.checked && onCheck()}
        />
      </section>
      <section className="child-step-item-content">
        <Typography colorVariant="primary">{step.name}</Typography>
        <Typography sizeVariant="sm" className="mt-2">
          {step.description}
        </Typography>
      </section>
    </article>
  );
};

const FilterChildStepListViewer: React.FC<Props> = props => {
  const { steps, selectedStep, handleSelectedStepChanges: handleSelectedChanges } = props;

  const handleStepChecked = (step: FilterExecutor) => {
    handleSelectedChanges(step);
  };

  return (
    <section className="child-steps-container fancy-scrollbar">
      {steps.map(step => (
        <FilterChildStepListItem
          key={step.id}
          step={step}
          checked={step.id === selectedStep?.id}
          onCheck={() => handleStepChecked(step)}
        />
      ))}
    </section>
  );
};

export default FilterChildStepListViewer;
