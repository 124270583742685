import { useCallback, useEffect, useRef, useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { DashboardMetricsResponse, DashboardRequest, DashboardUniqueSkusResponse } from '../../interface/dashboard';
import dashboardService from '../../service/dashboard.service';
import { diffInDays, diffInMinutes, toISODateString, toISOString } from '../../utils/date.utils';
import DashboardChartContainerComponent, { RecommendationMetrics } from './dashboard-chart-container';
import DashboardFormComponent, { DashboardFormFields } from './dashboard-form';
import DashboardMetricsComponent from './dashboard-metrics';
import DashboardPeriodFormComponent from './dashboard-period-form';
import DashboardPollingInfoComponent from './dashboard-polling-info';
import './index.css';

export default function DashboardComponent() {
  const [lastSubmitDate, setLastSubmitDate] = useState<Date>(new Date());
  const [maxDate] = useState<string>(toISOString(lastSubmitDate));
  const [startDate, setStartDate] = useState<string>(maxDate);
  const [endDate, setEndDate] = useState<string>(maxDate);
  const [formValues, setFormValues] = useState<DashboardFormFields>();
  const [request, setRequest] = useState<DashboardRequest>();
  const [metricsResponse, setMetricsResponse] = useState<DashboardMetricsResponse>();
  const [uniqueSkusResponse, setUniqueSkusResponse] = useState<DashboardUniqueSkusResponse>();
  const [recommendationMetrics, setRecommendationMetrics] = useState<RecommendationMetrics>();
  const [daysDiff, setDaysDiff] = useState<number>(0);
  const [pollingEnabled, setPollingEnabled] = useState<boolean>(true);
  const [pollingTimeExpired, setPollingTimeExpired] = useState<boolean>(false);
  const intervalRef = useRef<NodeJS.Timeout>();

  const createDashboardRequest = useCallback(
    (formData: DashboardFormFields) => ({
      marketCodes: formData.markets.map(({ code }) => code),
      channels: formData.channels.map(({ code }) => code),
      containerKeys: formData.containers.map(({ containerKey }) => containerKey),
      // TODO: set the timezone offset when the back-end starts to support grouping data based on the client timezone
      startDate: startDate + 'T00:00:00',
      endDate: endDate + 'T23:59:59',
    }),
    [startDate, endDate],
  );

  const getDashboardRequest = useCallback(
    (formData?: DashboardFormFields) => {
      if (formData) {
        setFormValues(formData);
      }

      return createDashboardRequest(formData ?? (formValues as DashboardFormFields));
    },
    [formValues, createDashboardRequest],
  );

  const getDashboardMetrics = useCallback(
    (req: DashboardRequest = request as DashboardRequest, callback?: VoidFunction) => {
      dashboardService
        .getRecommendationMetrics(req)
        .then(setMetricsResponse)
        .finally(() => {
          if (callback) {
            callback();
          }
        });
    },
    [request],
  );

  const getUniqueSkus = useCallback(
    (req: DashboardRequest = request as DashboardRequest) => {
      dashboardService.getUniqueSkusRecommended(req).then(setUniqueSkusResponse);
    },
    [request],
  );

  const getRecommendationMetrics = useCallback(
    (req: DashboardRequest = request as DashboardRequest) => {
      Promise.all([
        dashboardService.getRecommendationsByPageMetrics(req),
        dashboardService.getRecommendationsByScenarioMetrics(req),
        dashboardService.getRecommendationsByModelMetrics(req),
      ]).then(([metricsByPage, metricsByScenario, metricsByModel]) => {
        setRecommendationMetrics({
          metricsByModel,
          metricsByPage,
          metricsByScenario,
        });
      });
    },
    [request],
  );

  const handleSubmit = useCallback(
    (formData?: DashboardFormFields) => {
      const req = getDashboardRequest(formData) as DashboardRequest;
      const startISODate = toISODateString(req.startDate);
      const endISODate = toISODateString(req.endDate);
      const days = diffInDays(startISODate, endISODate);
      const isToday = days === 0 && maxDate === startISODate && maxDate === endISODate;

      getDashboardMetrics(req, () => {
        setRequest(req);
        setLastSubmitDate(new Date());
        setDaysDiff(days);
        setPollingEnabled(isToday);

        if (isToday) {
          setPollingTimeExpired(false);
        }
      });
      getUniqueSkus(req);
      getRecommendationMetrics(req);
    },
    [getDashboardRequest, getDashboardMetrics, getUniqueSkus, getRecommendationMetrics, maxDate],
  );

  const polling = useCallback(() => {
    const timeExpired = diffInMinutes(lastSubmitDate, new Date()) >= 10;
    setPollingTimeExpired(timeExpired);

    if (pollingEnabled && !timeExpired) {
      getDashboardMetrics();
      getUniqueSkus();
      getRecommendationMetrics();
    }
  }, [getDashboardMetrics, getUniqueSkus, getRecommendationMetrics, pollingEnabled, lastSubmitDate]);

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      polling();
    }, 30000);

    return () => {
      clearInterval(intervalRef.current!);
    };
  }, [polling]);

  return (
    <Container className="dashboard">
      <Card>
        <Card.Body>
          <Row className="title">
            <Col>
              <Card.Title>Scenarios</Card.Title>
            </Col>
          </Row>
          <Card.Text>
            Fill out the scenario information from the selectors below to change the scenario below.
          </Card.Text>
          <DashboardFormComponent onSubmit={handleSubmit} />
        </Card.Body>
      </Card>

      {metricsResponse && (
        <Card>
          <Card.Body>
            <Row className="title">
              <Col>
                <Card.Title>Data and Reports</Card.Title>
              </Col>
            </Row>
            <Card.Text>A few core metrics from the APR system:</Card.Text>

            <DashboardPeriodFormComponent
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              maxDate={maxDate}
              onSubmit={handleSubmit}
            />

            <DashboardPollingInfoComponent
              startDate={request?.startDate}
              endDate={request?.endDate}
              lastSubmitDate={lastSubmitDate}
              pollingEnabled={pollingEnabled}
              pollingTimeExpired={pollingTimeExpired}
            />

            <DashboardMetricsComponent metricsResponse={metricsResponse} uniqueSkusResponse={uniqueSkusResponse} />

            <DashboardChartContainerComponent
              metricsResponse={metricsResponse}
              daysDiff={daysDiff}
              recommendationMetrics={recommendationMetrics}
            />
          </Card.Body>
        </Card>
      )}
    </Container>
  );
}
