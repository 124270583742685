export interface FacetValue {
  name: string;
  value: string;
  selected?: boolean;
}

export enum FacetKind {
  Union,
  Intersection,
}

export interface Facet {
  kind?: FacetKind;
  name: string;
  values: FacetValue[];
}

export interface OrderBy {
  name: string;
  value: string;
  selected?: boolean;
}
