import { useCallback, useState } from 'react';
import { ExternalData } from '../../@types/external-api';
import {
  makeExternalCallErrorData,
  makeExternalDataInitialData,
  makeExternalDataSuccessData,
} from '../../helpers/external-api';
import useExternalApiErrorHandler from '../../hooks/use-external-api-error-handler';
import { ModelOutputRequest } from '../../interface/model';
import useToasts from '../toasts/toasts-hook';
import ModelOutputContext, { ModelOutputContextType } from './model-output-context';
import { ModelOutput, ModelVersion } from './model-output-models';
import modeloutputService from './model-output.service';

const ModelOutputProvider: React.FC = ({ children }) => {
  const { push } = useToasts();
  const errorHandler = useExternalApiErrorHandler();
  const [models, setModels] = useState<ExternalData<ModelOutput[]>>({ unstarted: true });
  const [modelVersionHistory, setModelVersionHistory] = useState<ExternalData<ModelVersion[]>>({ unstarted: true });
  const [draftModelVersion, setDraftModelVersion] = useState<ExternalData<ModelVersion>>({ unstarted: true });

  const fetchModels: ModelOutputContextType['fetchModels'] = useCallback(async (marketId: number, status: string) => {
    try {
      setModels(makeExternalDataInitialData());
      const data = await modeloutputService.getManualModelsByMarketIdAndStatus(marketId, status);
      setModels(makeExternalDataSuccessData(data));
    } catch (err: any) {
      setModels(makeExternalCallErrorData(err));
    }
  }, []);

  const getModelOutputVersionById = useCallback((modelId: number) => {
    try {
      setModels(makeExternalDataInitialData());
      const { promise, abort } = modeloutputService.getModelOutputVersionById(modelId);
      promise.then(data => setModels(makeExternalDataSuccessData(data)));
      return abort;
    } catch (err: any) {
      setModels(makeExternalCallErrorData(err));
    }
  }, []);

  const saveManualModel: ModelOutputContextType['saveManualModel'] = useCallback(
    async (req: ModelOutputRequest) => {
      try {
        await modeloutputService.saveManualModel(req);
      } catch (err: any) {
        errorHandler(err);
        throw err;
      }
    },
    [errorHandler],
  );

  const editManualModel: ModelOutputContextType['editManualModel'] = useCallback(
    async (req: ModelOutputRequest) => {
      try {
        await modeloutputService.editManualModel(req);
      } catch (err: any) {
        errorHandler(err);
        throw err;
      }
    },
    [errorHandler],
  );

  const deleteModel: ModelOutputContextType['deleteModel'] = useCallback(
    async (modelId: number) => {
      try {
        await modeloutputService.deleteModel(modelId);
      } catch (err: any) {
        errorHandler(err);
        throw err;
      }
    },
    [errorHandler],
  );

  const fetchKeyTypes: ModelOutputContextType['fetchKeyTypes'] = useCallback(async () => {
    try {
      await modeloutputService.getKeyTypes();
    } catch (err: any) {
      errorHandler(err);
      throw err;
    }
  }, [errorHandler]);

  const fetchModelVersionHistory: ModelOutputContextType['fetchModelVersionHistory'] = useCallback(
    async marketId => {
      try {
        setModelVersionHistory(makeExternalDataInitialData());
        const modelVersions = await modeloutputService.getVersionsHistory(marketId);
        setModelVersionHistory(makeExternalDataSuccessData(modelVersions));
      } catch (err: any) {
        errorHandler(err);
        setModelVersionHistory(makeExternalCallErrorData(err));
      }
    },
    [errorHandler],
  );

  const fetchDraftModelVersion: ModelOutputContextType['fetchDraftModelVersion'] = useCallback(
    async (marketId: number, modelVersionId?: number) => {
      try {
        setDraftModelVersion(makeExternalDataInitialData());
        const modelVersion = await modeloutputService.getDraftModelVersion(marketId, modelVersionId);
        setDraftModelVersion(makeExternalDataSuccessData(modelVersion));
      } catch (err: any) {
        errorHandler(err, {
          // 404 errors are expected here. The first time a user start to edit a model.
          // Therefore, we should not be doing anything at all
          404: () => {},
        });
        setDraftModelVersion(makeExternalCallErrorData(err));
      }
    },
    [errorHandler],
  );

  const discardModelDraftVersion: ModelOutputContextType['discardModelDraftVersion'] = useCallback(
    async marketId => {
      try {
        await modeloutputService.discardModelDraftVersion(marketId);
        fetchDraftModelVersion(marketId);
      } catch (err: any) {
        errorHandler(err);
      }
    },
    [errorHandler, fetchDraftModelVersion],
  );

  const sendModelVersionToApproval: ModelOutputContextType['sendModelVersionToApproval'] = useCallback(
    async (marketId, versionId, title, description) => {
      try {
        await modeloutputService.sendModelVersionToApproval(marketId, versionId, title, description);
        push('Configuration Request sent.');
      } catch (err: any) {
        errorHandler(err);
        throw err;
      }
    },
    [errorHandler, push],
  );

  const checkDraftModelVersion: ModelOutputContextType['checkDraftModelVersion'] = useCallback(
    async (marketId, versionId) => {
      try {
        await modeloutputService.checkDraftModelVersion(marketId, versionId);
      } catch (err: any) {
        errorHandler(err, {
          // 404 errors are expected here. The first time a user start to edit a model.
          // Therefore, we should not be doing anything at all
          404: () => {},
        });
      }
    },
    [errorHandler],
  );

  return (
    <ModelOutputContext.Provider
      value={{
        models,
        modelVersionHistory,
        draftModelVersion,
        fetchModels,
        saveManualModel,
        editManualModel,
        deleteModel,
        fetchKeyTypes,
        getModelOutputVersionById,
        fetchModelVersionHistory,
        fetchDraftModelVersion,
        discardModelDraftVersion,
        sendModelVersionToApproval,
        checkDraftModelVersion,
      }}>
      {children}
    </ModelOutputContext.Provider>
  );
};

export default ModelOutputProvider;
