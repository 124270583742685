import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { SxProps, Theme } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { useCallback, useState } from 'react';
import { Column, Row } from '../';
import TableCellOverlayComponent from '../table-cell-overlay';

type Props = {
  columns: Column[];
  row: Row;
  formatCell: (column: Column, row: Row) => string;
  collapsibleRowIdPropName: string;
  collapsibleRowsPropName: string;
  collapsibleBeforeChevronColumns: string[][];
  collapsibleRows: any[];
  sx?: SxProps<Theme>;
  onClick?: (column: Column, row: Row) => void;
  level?: number;
};

export default function CollapsibleTableRow({
  columns,
  row,
  formatCell,
  collapsibleRowIdPropName = 'id',
  collapsibleRowsPropName,
  collapsibleRows = [],
  collapsibleBeforeChevronColumns = [],
  sx,
  onClick,
  level = 0,
}: Props) {
  const [open, setOpen] = useState<boolean>(false);

  const toggle = useCallback(() => {
    setOpen(!open);
  }, [open]);

  return (
    <>
      <TableRow
        hover
        role="checkbox"
        className={row.__meta?.className ? row.__meta?.className : ''}
        onClick={() => row.__meta?.onClick?.(row)}
        tabIndex={-1}
        sx={{ ...sx }}>
        {columns
          .filter(col => collapsibleBeforeChevronColumns[level]?.includes(col.id))
          .map(column => (
            <TableCellOverlayComponent
              key={column.id}
              column={column}
              row={row}
              formatCell={formatCell}
              onClick={onClick}
            />
          ))}
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            sx={{ display: collapsibleRows.length ? 'block' : 'none' }}
            disabled={!collapsibleRows.length}
            onClick={toggle}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {columns
          .filter(col => !collapsibleBeforeChevronColumns[level]?.includes(col.id))
          .map(column => (
            <TableCellOverlayComponent
              key={column.id}
              column={column}
              row={row}
              formatCell={formatCell}
              onClick={onClick}
            />
          ))}
      </TableRow>
      {collapsibleRows.map(collapsibleRow => (
        <CollapsibleTableRow
          key={collapsibleRow[collapsibleRowIdPropName]}
          columns={columns}
          row={collapsibleRow}
          formatCell={formatCell}
          collapsibleRowIdPropName={collapsibleRowIdPropName}
          collapsibleRowsPropName={collapsibleRowsPropName}
          collapsibleRows={collapsibleRow[collapsibleRowsPropName]}
          collapsibleBeforeChevronColumns={collapsibleBeforeChevronColumns}
          level={level + 1}
          sx={{
            visibility: open ? undefined : 'collapse',
          }}
          onClick={onClick}
        />
      ))}
    </>
  );
}
