import { Col, Container, Image, Row } from 'react-bootstrap';
import './index.css';

export interface MetricsTile {
  id: string;
  title: string;
  formattedData?: string | null;
  color?: string;
  fontSize?: string;
  onClick?: VoidFunction;
}

type Props = MetricsTile;

export default function MetricsTileComponent({ id, title, formattedData, color = 'black', onClick, fontSize }: Props) {
  return (
    <Container id={id} className={`metrics-tile ${onClick ? 'clickable' : ''}`}>
      <Row className="title">
        <Col onClick={onClick}>{title}</Col>
        {onClick && (
          <Col sm={1} className="external-link" onClick={onClick}>
            <Image src="/assets/external-link.svg" width="18" height="18" />
          </Col>
        )}
      </Row>
      <Row className="formatted-data">
        <Col style={onClick ? undefined : { color, fontSize }} onClick={onClick}>
          {formattedData ?? '-'}
        </Col>
      </Row>
    </Container>
  );
}
