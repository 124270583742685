import { Typography } from '@amway/react-components';
import { Col, Row } from 'react-bootstrap';
import { AffectedByMerchRules } from '../../../../resources/merch-rules/merch-rules-types';
import Tooltip from '../../../../shared/tooltip';

interface Props {
  affectedByMerchRules: AffectedByMerchRules;
}

export default function MerchandisingRulesConfigurationMetadata({ affectedByMerchRules }: Props) {
  const { markets, channels, pages, containers, products } = affectedByMerchRules;

  const addPlural = (value: number) => (value > 1 ? 's' : '');

  return (
    <Row>
      {markets.length > 0 && (
        <Col>
          <Row>
            <Typography color="text-gray">Market:</Typography>
          </Row>
          <Row>
            <Tooltip
              title={markets.map(market => (
                <div key={market.name}>
                  {market.name}
                  <br />
                </div>
              ))}>
              <div style={{ width: 'max-content' }}>
                <Typography color="warning-blue">
                  {markets.length > 1 ? `${markets.length} markets` : markets[0].name}
                </Typography>
              </div>
            </Tooltip>
          </Row>
        </Col>
      )}
      {channels.length > 0 && (
        <Col>
          <Row>
            <Typography color="text-gray">Channel:</Typography>
          </Row>
          <Row>
            <Tooltip
              title={channels.map(channel => (
                <div key={channel.name}>
                  {channel.name}
                  <br />
                </div>
              ))}>
              <div style={{ width: 'max-content' }}>
                <Typography color="warning-success">
                  {channels.length} channel{addPlural(channels.length)}
                </Typography>
              </div>
            </Tooltip>
          </Row>
        </Col>
      )}
      {pages.length > 0 && (
        <Col>
          <Row>
            <Typography color="text-gray">Page:</Typography>
          </Row>
          <Tooltip
            title={pages.map(page => (
              <div key={page.name}>
                {page.name}
                <br />
              </div>
            ))}>
            <div style={{ width: 'max-content' }}>
              <Typography color="warning-orange">
                {pages.length} page{addPlural(pages.length)}
              </Typography>
            </div>
          </Tooltip>
        </Col>
      )}
      {containers.length > 0 && (
        <Col>
          <Row>
            <Typography color="text-gray">Container:</Typography>
          </Row>
          <Tooltip
            title={containers.map(container => (
              <div key={container.name}>
                {container.name}
                <br />
              </div>
            ))}>
            <div style={{ width: 'max-content' }}>
              <Typography color="primary">
                {containers.length} container{addPlural(containers.length)}
              </Typography>
            </div>
          </Tooltip>
        </Col>
      )}
      {products.length > 0 && (
        <Col>
          <Row>
            <Typography color="text-gray">Product:</Typography>
          </Row>
          <Tooltip
            title={products.map(product => (
              <div key={product.name}>
                {product.name}
                <br />
              </div>
            ))}>
            <div style={{ width: 'max-content' }}>
              <Typography color="primary">
                {products.length} product{addPlural(products.length)}
              </Typography>
            </div>
          </Tooltip>
        </Col>
      )}
      <hr className="mt-4 mb-4" />
    </Row>
  );
}
