import React from 'react';
import NavBarComponent from '../../navbar';
import './index.scss';

const GuestLayout: React.FC = props => {
  return (
    <>
      <NavBarComponent />
      <div className="guest-aside-main">
        <main>{props.children}</main>
      </div>
    </>
  );
};

export default GuestLayout;
