import { Feature, ScopedFeature } from '../../config/features';
import { UnscopedPermission, ScopedPermission } from './features-types';

export const displayPermissionsGracefully = (permissions: Array<ScopedPermission | UnscopedPermission>) => {
  const collator = new Intl.Collator('en');
  console.warn('Permissions: ');
  console.table(
    permissions
      .sort((a, b) => collator.compare(a.name, b.name))
      .map(permission => ({
        name: permission.name,
        markets: permission instanceof ScopedPermission ? permission.markets.join(', ').concat('.') : 'Unscoped',
      })),
  );
};

export const displayFeaturesGracefully = (features: Array<Feature | ScopedFeature>) => {
  const collator = new Intl.Collator('en');
  console.warn('Features: ');
  console.table(
    features
      .sort((a, b) => collator.compare(a.label, b.label))
      .map(feat => ({
        name: feat.label,
        markets: feat instanceof ScopedFeature ? feat.markets.join(', ').concat('.') : 'Unscoped',
      })),
  );
};
