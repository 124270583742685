import { WithAbortFn } from './../../@types/external-api';
import { apiWrapper } from '../../config/api-wrapper';
import { claimsPermissionsMapper, authorizationMapper, featuresMapMapper } from './features-mapper';
import { UnscopedPermission, ScopedPermission, ClaimPermissions, FeaturesMap, Authorization } from './features-types';
import axios from 'axios';

class PermissionService {
  public getAuthorization(): WithAbortFn<Promise<Authorization>> {
    const source = axios.CancelToken.source();

    return {
      promise: apiWrapper.adminApi
        .get('/user/info', { cancelToken: source.token })
        .then(res => authorizationMapper(res.data)),
      abort: source.cancel,
    };
  }

  public update(permissions: Array<UnscopedPermission | ScopedPermission>): Promise<void> {
    return apiWrapper.adminApi.post('/user/permissions', {
      permissions: permissions
        .filter(permission => permission instanceof UnscopedPermission)
        .map(permission => permission.name),
      scopedPermissions: permissions.filter(scopedPermission => scopedPermission instanceof ScopedPermission),
    });
  }

  public listClaimsPermissions(): Promise<ClaimPermissions[]> {
    return apiWrapper.adminApi.get('/user/permissions/claims').then(res => claimsPermissionsMapper(res.data));
  }

  public getFeaturesMap(): Promise<FeaturesMap> {
    return apiWrapper.adminApi.get('/user/permissions/features').then(res => featuresMapMapper(res.data));
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new PermissionService();
