import { Typography } from '@amway/react-components';
import ExternalDataRenderer from '../../../../components/hocs/external-data-renderer';
import DialogLayout from '../../../../shared/dialog-layout';
import PaginatedTableComponent, { Column } from '../../../../shared/paginated-table';
import { InitialTableListRules, MerchRuleVersionType } from '../../../../resources/merch-rules/merch-rules-types';
import { useCallback, useEffect, useMemo } from 'react';
import useMerchRules from '../../../../resources/merch-rules/merch-rules-hook';

interface Props {
  isOpen: boolean;
  onCancel: VoidFunction;
  type: string;
  itemIds: string[];
}

const MerchandisingRulesConfigurationMultipleRulesModal = ({ isOpen, onCancel, type, itemIds }: Props) => {
  const { liveRules, fetchLiveRulesByItemIds } = useMerchRules();

  useEffect(() => {
    if (isOpen) {
      fetchLiveRulesByItemIds(itemIds, type as MerchRuleVersionType, 'itemId');
    }
  }, [fetchLiveRulesByItemIds, isOpen, itemIds, type]);

  const rows = useCallback((data: InitialTableListRules[]) => {
    return data.map(row => ({
      ...row,
      rules: row.rules?.map(rule => rule.rule),
    }));
  }, []);

  const columns: Column[] = useMemo(
    () => [
      {
        id: 'name',
        label: type === 'container' ? 'Container' : 'SKU',
      } as any,
      {
        id: 'rules',
        label: 'Rules',
      },
    ],
    [type],
  );

  const returnCorrectName = type === 'container' ? 'Container' : 'SKU';

  return (
    <DialogLayout
      variant="outlined"
      maxWidth="lg"
      open={isOpen}
      title={`Multiple ${returnCorrectName} Rules`}
      subtitle={`View below Current Block of Multiple ${returnCorrectName} Rules. To edit this rules, you need to return to ${returnCorrectName} list and select the single ${returnCorrectName}.`}
      className="approve-request-dialog"
      scapeActionText={'CLOSE'}
      scapeActionHandler={onCancel}>
      <ExternalDataRenderer
        externalData={liveRules}
        makeDataElement={data =>
          rows(data).length > 0 ? (
            <PaginatedTableComponent columns={columns} rows={rows(data)} rowIdPropName="id" />
          ) : (
            <Typography variant="heading">No results found.</Typography>
          )
        }
      />
    </DialogLayout>
  );
};

export default MerchandisingRulesConfigurationMultipleRulesModal;
