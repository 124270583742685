import { createContext } from 'react';
import { ExternalData } from '../../@types/external-api';
import { AffectedByMerchRules, InitialTableListRules, MerchRuleVersionType } from './merch-rules-types';

export interface FetchRulesProps {
  type: 'sku' | 'container';
  marketId?: string;
  pageIds?: string[];
  productIds?: string[];
  containerIds?: string[];
}
export interface MerchRulesCtxType {
  fetchRules: (props: FetchRulesProps) => VoidFunction;
  fetchRulesByVersionIds: (versionIds: string[]) => VoidFunction | undefined;
  fetchLiveRulesByItemIds: (
    itemIds: string[],
    type: MerchRuleVersionType,
    requestType: string,
  ) => VoidFunction | undefined;
  fetchAffectedByMerchRules: ({
    containerIds,
    skuIds,
    versionIds,
  }: {
    containerIds?: string[] | undefined;
    skuIds?: string[] | undefined;
    versionIds?: string[] | undefined;
  }) => VoidFunction | undefined;
  affectedByMerchRules: ExternalData<AffectedByMerchRules>;
  rules: ExternalData<InitialTableListRules[]>;
  liveRules: ExternalData<InitialTableListRules[]>;
  resetRules: VoidFunction;
  resetToLiveRules: (itemId: string, type: MerchRuleVersionType) => void;
}

const MerchRulesContext = createContext<MerchRulesCtxType>(null as any);

export default MerchRulesContext;
