import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { DashboardMetricsResponse, DashboardRecommendationMetrics } from '../../../interface/dashboard';
import DashboardChartComponent from '../dashboard-chart';
import DashboardSortedBarChartComponent from '../dashboard-sorted-bar-chart';
import DashboardStackedBarChartComponent, { StackedBarChartData } from '../dashboard-stacked-bar-chart';

export interface RecommendationMetrics {
  metricsByModel: DashboardRecommendationMetrics[];
  metricsByPage: DashboardRecommendationMetrics[];
  metricsByScenario: DashboardRecommendationMetrics[];
}

type Props = {
  metricsResponse: DashboardMetricsResponse;
  daysDiff: number;
  recommendationMetrics?: RecommendationMetrics;
};

export default function DashboardChartContainerComponent({ metricsResponse, daysDiff, recommendationMetrics }: Props) {
  const [stackedBarChartData, setStackerBarChartData] = useState<StackedBarChartData[]>([]);
  const [selectedTab, setSelectedTab] = useState<string>('page');

  const handleChange = useCallback((event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
  }, []);

  useEffect(() => {
    if (recommendationMetrics) {
      setStackerBarChartData([
        {
          category: 'Requests by Page',
          metrics: recommendationMetrics.metricsByPage,
        },
        {
          category: 'Requests by Scenario',
          metrics: recommendationMetrics.metricsByScenario,
        },
        {
          category: 'Requests by Model',
          metrics: recommendationMetrics.metricsByModel,
        },
      ]);
    }
  }, [recommendationMetrics]);

  return (
    <>
      {(metricsResponse.chartPoints?.length ?? 0) > 0 && (
        <Row>
          <Col>
            {daysDiff >= 5 ? (
              <DashboardChartComponent
                id="dashboard-chart-by-day"
                style={{ display: daysDiff >= 5 ? 'block' : 'none' }}
                timeUnit="day"
                chartPoints={metricsResponse.chartPoints}
              />
            ) : daysDiff >= 1 ? (
              <DashboardChartComponent
                id="dashboard-chart-by-hour"
                style={{ display: daysDiff >= 1 && daysDiff < 5 ? 'block' : 'none' }}
                timeUnit="hour"
                chartPoints={metricsResponse.chartPoints}
              />
            ) : (
              <DashboardChartComponent
                id="dashboard-chart-by-minutes"
                style={{ display: daysDiff === 0 ? 'block' : 'none' }}
                timeUnit="minute"
                chartPoints={metricsResponse.chartPoints}
              />
            )}
          </Col>
        </Row>
      )}
      {stackedBarChartData.length > 0 && (
        <Row>
          <Col>
            <DashboardStackedBarChartComponent id="dashboard-stacked-bar-chart" data={stackedBarChartData} />
          </Col>
        </Row>
      )}
      {recommendationMetrics && (
        <Row className="dashboard-tabs-sorted-bar-chart">
          <Col>
            <TabContext value={selectedTab}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleChange} centered>
                  <Tab label="Requests by Page" value="page" />
                  <Tab label="Requests by Scenario" value="scenario" />
                  <Tab label="Requests by Model" value="model" />
                </TabList>
              </Box>
              <TabPanel value="page">
                {recommendationMetrics.metricsByPage.length > 0 && (
                  <DashboardSortedBarChartComponent
                    id="dashboard-sorted-bar-chart-page"
                    data={recommendationMetrics.metricsByPage}
                  />
                )}
              </TabPanel>
              <TabPanel value="scenario">
                {recommendationMetrics.metricsByScenario.length > 0 && (
                  <DashboardSortedBarChartComponent
                    id="dashboard-sorted-bar-chart-scenario"
                    data={recommendationMetrics.metricsByScenario}
                  />
                )}
              </TabPanel>
              <TabPanel value="model">
                {recommendationMetrics.metricsByModel.length > 0 && (
                  <DashboardSortedBarChartComponent
                    id="dashboard-sorted-bar-chart-model"
                    data={recommendationMetrics.metricsByModel}
                  />
                )}
              </TabPanel>
            </TabContext>
          </Col>
        </Row>
      )}
    </>
  );
}
