import { mdiCursorDefaultClickOutline, mdiHistory, mdiPencil, mdiMagnify } from '@mdi/js';
import Icon from '@mdi/react';
import Tooltip from '../../shared/tooltip';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import ExternalDataRenderer from '../../components/hocs/external-data-renderer';
import { Market } from '../../interface/market';
import ApprovalRequestService from '../../resources/approval-request/approval-request.service';
import useBreadcrumbs from '../../resources/breadcrumbs/breadcrumbs-hook';
import useModels from '../../resources/model-output/model-output-hook';
import { ModelOutput } from '../../resources/model-output/model-output-models';
import CancelRequest from '../../shared/cancel-request';
import DialogLayout from '../../shared/dialog-layout';
import PaginatedTableComponent, { Column } from '../../shared/paginated-table';
import Typography from '../../shared/typography';
import Breadcrumbs from '../../shared/ui/breadcrumbs';
import VersionsHistoryDrawer from '../../shared/version-history-drawer';
import './index.css';
import ManualModelFormComponent, { ManualModelFormFields } from './manual-model-form';

export interface ModelOutputRow {
  id: number;
  keyType: string;
  key: string;
  productList: string;
}

export const columns: Column[] = [
  {
    id: 'id',
    label: '#',
  } as any,
  {
    id: 'keyType',
    label: 'Key Type',
    format: (value: number) => `${value.toLocaleString()} key types`,
    sx: (value: number | string) => (typeof value === 'number' ? { color: '#4994EE' } : {}),
  },
  {
    id: 'key',
    label: 'Key',
    format: (value: number) => `${value.toLocaleString()} keys`,
    sx: (value: number | string) => (typeof value === 'number' ? { color: '#4DD895' } : {}),
  },
  {
    id: 'productListStr',
    label: 'Product List',
    format: (value: string) => `${value.toLocaleString()} products`,
    sx: (value: number | string) => (typeof value === 'number' ? { color: '#F4CD67' } : {}),
  },
];

export const toModelOutputRows = (models: ModelOutput[]): ModelOutputRow[] => {
  return models.map(model => ({
    id: model.id,
    keyType: model.keyType,
    key: model.key,
    productList: model.productListStr,
  }));
};

const ManualModelComponent: React.FC = () => {
  const navigate = useNavigate();
  const [market, setMarket] = useState<Market>();
  const [keyType, setKeyType] = useState<string>();
  const [key, setKey] = useState<string[]>();
  const {
    models: modelsData,
    modelVersionHistory,
    draftModelVersion,
    fetchModelVersionHistory,
    fetchDraftModelVersion,
  } = useModels();
  const models: ModelOutputRow[] = useMemo(() => toModelOutputRows(modelsData.data ?? []), [modelsData.data]);
  const [showTable, setShowTable] = useState<'visible' | 'hidden' | undefined>('hidden');
  const [filteredModels, setFilteredModels] = useState<ModelOutput[]>();
  const hasChanges = useMemo(() => !!draftModelVersion.data, [draftModelVersion.data]);
  const [isHistoryDrawerOpen, setIsHistoryDrawerOpen] = useState(false);
  const [searchText, setSearchText] = useState<string>('');
  const { breadcrumbs } = useBreadcrumbs();
  const [isCancelRequestOpen, setIsCancelRequestOpen] = useState(false);

  useEffect(() => {
    if (market && !modelsData.loading) {
      setShowTable('hidden');
      fetchModelVersionHistory(market.id).then(() => {
        fetchDraftModelVersion(market.id);
      });
    }
  }, [fetchDraftModelVersion, fetchModelVersionHistory, market, modelsData]);

  const handleFormChanges = useCallback((formData: ManualModelFormFields) => {
    if (formData.market) {
      setMarket(formData.market);
    }
    setKeyType(formData.keyType || undefined);
    if (formData.key) {
      setKey(formData.key);
    }
  }, []);

  const handleEditClick = () => {
    navigate(`/admin-configurations/manual-model/${market?.id}/edit`);
  };

  const handleOpenHistoryDrawer = () => {
    setIsHistoryDrawerOpen(true);
  };

  const handleCloseHistoryDrawer = () => {
    setIsHistoryDrawerOpen(false);
  };

  const handleSubmit = useCallback(() => {
    if (market) {
      setShowTable('visible');

      const filteredModels = keyType
        ? key?.length !== 0
          ? modelsData.data!.filter(model => model.keyType === keyType && key?.includes(model.key))
          : modelsData.data!.filter(model => model.keyType === keyType)
        : key?.length !== 0
        ? modelsData.data!.filter(model => key?.includes(model.key))
        : modelsData.data;

      setFilteredModels(filteredModels);
    }
  }, [market, keyType, key, modelsData.data]);

  const isButtonDisabled = useMemo<boolean>(() => {
    return !market;
  }, [market]);

  const handleSearchInput = (search: string) => {
    setSearchText(search);
    const filteredModels = modelsData.data!.filter(model => model.productListStr.includes(search));
    setFilteredModels(filteredModels);
  };

  const onCancel = () => {
    setIsCancelRequestOpen(true);
  };

  const onConfirmCancel = () => {
    ApprovalRequestService.modelCancelRequest(Number(draftModelVersion.data!.id!)).then(() => {
      fetchDraftModelVersion(market!.id);
      setIsCancelRequestOpen(false);
    });
  };

  return (
    <Container className="scenario-tester">
      <Card>
        <Card.Body>
          <Row>
            <Breadcrumbs breadcrumbs={breadcrumbs} />
          </Row>
          <Row className="title">
            <Col>
              <Card.Title>Manual Model</Card.Title>
            </Col>
          </Row>
          <Card.Text>Select your market, key type and key below.</Card.Text>
          <ManualModelFormComponent onFormChange={handleFormChanges} />
          <Row className="submit">
            <Col className="d-flex justify-content-center">
              <Button disabled={isButtonDisabled} onClick={handleSubmit}>
                VIEW MODEL
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      {showTable && (
        <>
          <Card style={{ visibility: showTable }}>
            <Card.Body>
              <ExternalDataRenderer
                externalData={modelsData}
                makeDataElement={() => (
                  <>
                    <Row className="title">
                      <Col>
                        <Card.Title>Model Results</Card.Title>
                      </Col>
                    </Row>
                    <Card.Text>Previewing list based on market and custom parameters.</Card.Text>
                    {draftModelVersion && draftModelVersion.data && draftModelVersion.data.approvalRequestId && (
                      <CancelRequest
                        text={'Manual Model Configuration in Pending Analysis'}
                        cancelActionHandler={onCancel}></CancelRequest>
                    )}
                    <header className="actions-container">
                      <Form.Group id="search-text" className="search-text">
                        <Icon className="search-icon" path={mdiMagnify} size={1} color="var(--text-gray)" />
                        <Form.Control
                          id="search-text-ctrl"
                          type="text"
                          value={searchText}
                          placeholder="Type to search"
                          className="search-text-ctrl"
                          onChange={e => handleSearchInput(e.target.value)}
                        />
                      </Form.Group>
                      <button className="action-button" onClick={handleOpenHistoryDrawer}>
                        <Icon path={mdiHistory} size={1} color="var(--bg-white)" />
                      </button>
                      <Tooltip
                        disabled={!hasChanges}
                        title={
                          <>
                            Continue Editing
                            <Icon
                              className="ms-1"
                              path={mdiCursorDefaultClickOutline}
                              size="16px"
                              color="var(--bg-white)"
                            />
                          </>
                        }>
                        <button
                          disabled={draftModelVersion.data?.approvalRequestId ? true : false}
                          onClick={handleEditClick}
                          className={`action-button ${hasChanges ? 'has-changes' : ''}`}>
                          <Icon size={1} color={'var(--bg-white)'} path={mdiPencil} />
                        </button>
                      </Tooltip>
                    </header>
                    <Row className="mb-0">
                      <Col className="justify-content-center">
                        <PaginatedTableComponent
                          columns={columns}
                          rows={filteredModels || models}
                          rowIdPropName="id"
                          sx={{ maxWidth: 'calc(100vw - 307px - 2*24px - 32px - 38px - 19px)' }}
                        />
                      </Col>
                    </Row>
                  </>
                )}
              />
            </Card.Body>
          </Card>
          <VersionsHistoryDrawer
            title="Model Version History"
            subtitle={`${market?.name} market.`}
            isHistoryDrawerOpen={isHistoryDrawerOpen}
            handleCloseHistoryDrawer={handleCloseHistoryDrawer}
            modelVersionHistory={modelVersionHistory}
          />
          <DialogLayout
            open={isCancelRequestOpen}
            title={'Cancel Request'}
            subtitle={'Manual Model Configuration in Pending Analysis'}
            className="cancel-request-dialog"
            scapeActionText={'CLOSE'}
            scapeActionHandler={() => setIsCancelRequestOpen(false)}
            confirmActionText={'CANCEL AND CONFIGURE'}
            confirmActionHandler={onConfirmCancel}
            maxWidth={'sm'}>
            <Typography colorVariant="primary">
              To start a new configuration, you need to cancel your request or wait for analysis to end. All
              configurations will be lost if cancel the current request.
            </Typography>
          </DialogLayout>
        </>
      )}
    </Container>
  );
};

export default ManualModelComponent;
