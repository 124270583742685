import Icon from '@mdi/react';
import { mdiAlertCircle } from '@mdi/js';

interface Props {
  text: string;
  cancelActionHandler: VoidFunction;
}

const CancelRequest: React.FC<Props> = props => {
  const { text, cancelActionHandler } = props;

  return (
    <div className="pending">
      <Icon path={mdiAlertCircle} color="var(--warning-blue)" size={0.7} />
      {text}. Check{' '}
      <a href="/request-list" rel="noreferrer">
        Request List
      </a>{' '}
      to view or{' '}
      <span
        className="cancel"
        onClick={() => {
          cancelActionHandler();
        }}>
        Cancel
      </span>{' '}
      your request.
    </div>
  );
};

export default CancelRequest;
