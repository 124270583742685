import { mdiCogOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { useCallback, useState } from 'react';
import environment, { Environment } from '../../../config/env';
import { Features } from '../../../config/features';
import { WithFeaturesProxy } from '../../../helpers/with-features-proxy';
import PermissionsModal from '../permissions-modal';
import './index.scss';

const resolveEnvLabel = (): Environment | null => {
  if (environment.ENV === Environment.PROD) {
    return null;
  }

  return environment.ENV;
};

interface ExposedProps {}

interface Props extends ExposedProps {
  onSettingsClick?: VoidFunction;
}

const QaNavBarContainer: React.FC<Props> = props => {
  const { onSettingsClick } = props;

  return (
    <section className="qa-navbar-container">
      <div className="qa-settings-container-slot">
        {onSettingsClick && (
          <button onClick={onSettingsClick} className="qa-settings-button">
            <Icon className="qa-settings" path={mdiCogOutline} size="22px" color="var(--primary)" />
          </button>
        )}
      </div>
      <h2 className="env-band">{resolveEnvLabel()}</h2>
    </section>
  );
};

export default WithFeaturesProxy<ExposedProps>(Features.ManageOwnPermissions)((props, hasFeature) => {
  const [isQaModalOpen, setIsQaModalOpen] = useState(false);

  const handleOpenQaModal = useCallback(() => {
    setIsQaModalOpen(true);
  }, []);

  const handleCloseQaModal = useCallback(() => {
    setIsQaModalOpen(false);
  }, []);

  if (hasFeature) {
    return (
      <>
        <QaNavBarContainer onSettingsClick={handleOpenQaModal} />
        <PermissionsModal isOpen={isQaModalOpen} onClose={handleCloseQaModal} />
      </>
    );
  }

  return <QaNavBarContainer {...props} />;
});
