import { Filter, RequestChangesAccordion, Typography } from '@amway/react-components';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { AccordionEventKey } from 'react-bootstrap/esm/AccordionContext';
import { useNavigate } from 'react-router-dom';
import ExternalDataRenderer from '../../components/hocs/external-data-renderer';
import { Features } from '../../config/features';
import { WithFeaturesProxy } from '../../helpers/with-features-proxy';
import { Facet, OrderBy } from '../../interface/filter-types';
import useApprovalRequest from '../../resources/approval-request/approval-request-hook';
import { mapEnumToRequestStatus } from '../../resources/approval-request/approval-request-mapper';
import { ApprovalRequest, RequestType } from '../../resources/approval-request/approval-request-types';
import AccordionContent from './accordion-content';
import { FilterNames, initialFilterValues, initialOrderByValues } from './filter-utils';
import './index.scss';
import RejectRequestModal from './modals/reject-request';

interface Props {
  canApproveRequests: boolean;
}

function RequestList({ canApproveRequests }: Props) {
  const [filter, setFilter] = useState<Facet[]>(initialFilterValues);
  const [orderBy, setOrderBy] = useState<OrderBy[]>(initialOrderByValues);
  const { fetchApprovalRequestList, approvalRequestList } = useApprovalRequest();
  const [selectedAccordion, setSelectedAccordion] = useState<{ id: number }>({
    id: 0,
  });
  const [rejectChangesModalOpen, setRejectChangesModalOpen] = useState<boolean>(false);
  const navigate = useNavigate();

  const filterTypes = useMemo(() => {
    return filter.find(f => f.name === FilterNames.Type)?.values.find(v => v.selected)?.value as RequestType;
  }, [filter]);

  const completeFilter = useMemo(() => {
    return {
      approvalStatus: filter.find(f => f.name === FilterNames.AnalysisStatus)?.values.find(v => v.selected)?.value,
      // market: filter.find(f => f.name === FilterNames.Market)?.values.find(v => v.selected)?.value,
    } as ApprovalRequest;
  }, [filter]);

  useEffect(() => {
    const orderByFilter = orderBy.find(o => o.selected)?.value;
    const abortFn = fetchApprovalRequestList(completeFilter, filterTypes, orderByFilter);

    return () => abortFn?.();
  }, [completeFilter, fetchApprovalRequestList, filterTypes, orderBy]);

  const defineHeaderLabel = useCallback((itemType?: RequestType) => {
    switch (itemType) {
      case RequestType.ManualModel:
        return 'Manual Model: ';
      case RequestType.StepViewer:
        return 'Step Viewer: ';
      case RequestType.MerchandisingRules:
        return 'Merchandising Rules: ';
      case RequestType.ContainerHeader:
        return 'Container Header: ';
      default:
        return '';
    }
  }, []);

  const accordionData = useMemo(() => {
    return (approvalRequestList.data ?? []).map(item => {
      return {
        id: String(item.id),
        requestTitle: item.title,
        requestDescription: item.requestReason,
        headerLinkLabel: defineHeaderLabel(item.itemType) + item.headerLabel,
        headerLinkHandler: () => console.log('clicked on link'),
        content: (
          <AccordionContent
            itemId={item.referenceId}
            selectedItemId={selectedAccordion.id}
            itemType={item.itemType ?? RequestType.ManualModel}
            approvalRequestId={Number(item.id)}
            merchRuleType={item.headerLabel.toLowerCase()}
            itemStatus={mapEnumToRequestStatus(item.status)}
          />
        ),
        runDt: item.createdDt,
        status: mapEnumToRequestStatus(item.status),
        requestedBy: item.createdBy,
        requestedDt: item.createdDt,
        reviewedBy: item.updatedBy,
        reviewedDt: item.updatedDt,
      };
    });
  }, [approvalRequestList.data, defineHeaderLabel, selectedAccordion.id]);

  const handleClickTestChanges = (id: string) => {
    const item = approvalRequestList.data?.find(item => item.id === Number(id));
    switch (item?.itemType) {
      case RequestType.StepViewer:
        return navigate(`/scenario-list/${item.referenceId[0]}/edit/test?approval=${item.id}`);
      case RequestType.ManualModel:
        return navigate(`/admin-configurations/manual-model/${item.marketId}/edit/test?approval=${item.id}`);
      case RequestType.MerchandisingRules:
        return navigate(
          `/admin-configurations/merchandising-rules/edit/test?selected=${item.referenceId.join()}&type=${item.headerLabel.toLowerCase()}&approval=${
            item.id
          }`,
        );
    }
  };

  const setSelectedAccordionReferenceId = (id: string) => {
    setSelectedAccordion({ id: Number(id) });
  };

  return (
    <Container className="request-list">
      <Card>
        <Card.Body>
          <Row>
            <Col>
              <Typography variant="heading">Request List</Typography>
              <Typography weight="bold" color="text-gray" className="mt-3">
                View below all requested configurations on Step Viewer that haven’t yet been implemented{' '}
              </Typography>
            </Col>
            <Row className="mt-4">
              <Col style={{ maxWidth: '20%' }}>
                <Filter orderBy={orderBy} facets={filter} onFacetsChange={setFilter} onOrderByChange={setOrderBy} />
              </Col>
              <Col>
                <ExternalDataRenderer
                  externalData={approvalRequestList}
                  makeDataElement={() =>
                    accordionData.length > 0 ? (
                      <RequestChangesAccordion
                        handleItemClick={(id: AccordionEventKey) => setSelectedAccordionReferenceId(String(id))}
                        data={accordionData}
                        buttonsText={{
                          approve: 'Test and Analyze',
                          reject: 'Reject Changes',
                        }}
                        handlers={
                          canApproveRequests
                            ? {
                                handleApprove: (changeId: string) => handleClickTestChanges(changeId),
                                handleReject: () => setRejectChangesModalOpen(true),
                              }
                            : undefined
                        }
                      />
                    ) : (
                      <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <Typography variant="heading">No data to be displayed.</Typography>
                      </div>
                    )
                  }
                />
              </Col>
            </Row>
          </Row>
        </Card.Body>
      </Card>
      <RejectRequestModal
        isOpen={rejectChangesModalOpen}
        onCancel={() => setRejectChangesModalOpen(false)}
        id={String(selectedAccordion.id)}
      />
    </Container>
  );
}

export default WithFeaturesProxy(Features.ApproveRequests)((props, hasPermission) => {
  return <RequestList {...props} canApproveRequests={hasPermission} />;
});
