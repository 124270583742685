import { FeaturesMap } from './../../../resources/features/features-types';
import _ from 'lodash';
import { ClaimPermissions, ScopedPermission, UnscopedPermission } from '../../../resources/features/features-types';
import { Props as DropdownBtnProps } from '../../../shared/dropdown-btn';
import { Props as PermissionFeaturesProps } from './permission-features-list-item';
import { UnscopedPermissions, ScopedPermissions, Permissions } from '../../../config/permissions';

export const CustomClaimPermissionsName = 'Custom';

export type PermissionFeature = Omit<
  PermissionFeaturesProps['permissionFeat'],
  'onToggleActive' | 'onChangeMarkets'
> & {
  permission: Permissions;
};

export const ensureContains = <T>(set: T[], item: T): T[] => {
  if (!set.includes(item)) {
    return [item, ...set];
  }

  return set;
};

export const makePermissionsFeatures = (
  activePermissions: Array<UnscopedPermission | ScopedPermission>,
  featuresMap: FeaturesMap,
): Array<PermissionFeature> => {
  return Object.entries({ ...featuresMap.unscoped, ...featuresMap.scoped }).map(([permission, features]) => {
    const activePermission = activePermissions.find(actPerm => actPerm.name === permission);

    return {
      permission: permission as Permissions,
      label: permission,
      active: !!activePermission,
      readonly: permission === UnscopedPermissions.ManageOwnPermissions,
      features: features.map(f => ({
        label: f,
      })),
      markets: permission in featuresMap.scoped ? (activePermission as ScopedPermission)?.markets ?? [] : undefined,
    };
  });
};

export const parseToPermissionsNames = (permissions: Array<UnscopedPermission | ScopedPermission>): string[] => {
  return permissions.map(permission => permission.name);
};

export const makeClaimsPermissionItems = (claimsPermissions: ClaimPermissions[]): DropdownBtnProps['items'] => {
  const collator = new Intl.Collator('en');

  return [
    { label: 'Custom Permissions', id: CustomClaimPermissionsName },
    ...claimsPermissions
      .map(claimPermission => ({
        label: claimPermission.name,
        id: claimPermission.name,
      }))
      .sort((a, b) => collator.compare(a.label, b.label)),
  ];
};

export function enumContains<T extends string, TEnumValue extends string>(
  enumVariable: { [key in T]: TEnumValue },
  value: any,
) {
  const enumValues = Object.values(enumVariable);
  return enumValues.includes(value);
}

export const getAppliedClaimName = (
  permissionsFeatures: PermissionFeature[],
  claimsPermissions: ClaimPermissions[],
): string | undefined => {
  const activeUnscopedPermissions = permissionsFeatures
    .filter(
      permissionFeatures =>
        enumContains(UnscopedPermissions, permissionFeatures.permission) && permissionFeatures.active,
    )
    .map(permissionFeatures => permissionFeatures.permission)
    .filter(unscopedPermission => unscopedPermission !== UnscopedPermissions.ManageOwnPermissions);
  const activeScopedPermissions = permissionsFeatures
    .filter(
      permissionFeatures => enumContains(ScopedPermissions, permissionFeatures.permission) && permissionFeatures.active,
    )
    .map(permissionFeature => ({ permission: permissionFeature.permission, markets: permissionFeature.markets }));

  return claimsPermissions.find(claimPermissions => {
    return (
      _.isEqual(
        claimPermissions.unscopedPermissions
          .filter(unscopedPermission => unscopedPermission !== UnscopedPermissions.ManageOwnPermissions)
          .sort(),
        activeUnscopedPermissions.sort(),
      ) &&
      claimPermissions.scopedPermissions.every(claimScopedPermission => {
        const activeScopedPermission = activeScopedPermissions.find(
          item => item.permission === claimScopedPermission.name,
        );

        return (
          activeScopedPermission &&
          _.isEqual(claimScopedPermission.markets.sort(), activeScopedPermission.markets?.sort())
        );
      })
    );
  })?.name;
};

export const filterBySearchText = (permissionFeatures: PermissionFeature[], searchText: string) => {
  return permissionFeatures.filter(permissionFeat => permissionFeat.permission.includes(searchText));
};
