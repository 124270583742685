import { Button, Typography } from '@amway/react-components';
import { AiFillExclamationCircle } from 'react-icons/ai';

interface EditorMultipleItemsCellProps {
  multipleRulesEnabled: boolean;
  onClick: () => void;
}

export const EditorMultipleItemsCell: React.FC<EditorMultipleItemsCellProps> = ({ multipleRulesEnabled, onClick }) => (
  <div
    onClick={onClick}
    style={{
      display: 'flex',
      flexDirection: 'column',
      background: multipleRulesEnabled ? 'var(--bg-blue)' : 'var(--detail-border)',
      padding: '16px',
      cursor: multipleRulesEnabled ? 'pointer' : 'not-allowed',
      gap: '8px',
    }}>
    <Typography>
      <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
        <AiFillExclamationCircle color="var(--warning-blue)" size="16px" /> Multiple Container Rules
      </div>
    </Typography>
    <Typography variant="captionMedium">
      Current Block of Multiple Container Rules. Click here to view selected container list with their rules.{' '}
    </Typography>
  </div>
);

interface EditorMultipleItemsClearCellProps {
  multipleRulesEnabled: boolean;
  onClick: () => void;
}

export const EditorMultipleItemsClearCell: React.FC<EditorMultipleItemsClearCellProps> = ({
  multipleRulesEnabled,
  onClick,
}) => (
  <Button style={{ marginLeft: 'auto' }} onClick={onClick} variant="link" fontColor="primary">
    {multipleRulesEnabled ? 'CLEAR RULES' : 'RETURN RULES'}
  </Button>
);
