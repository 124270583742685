import _ from 'lodash';

export const isEqual = (a: any, b: any) => {
  return _.isEqual(a, b);
};

export const deepCopy = <T>(data: T): T => {
  if (data === undefined) return undefined as T;
  return JSON.parse(JSON.stringify(data));
};
