import { mdiCellphoneLink, mdiEarth, mdiFileOutline, mdiTableRow } from '@mdi/js';
import Icon from '@mdi/react';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TreeItem, TreeItemProps, TreeView } from '@mui/lab';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ReactNode, useMemo } from 'react';
import { NamedEntity } from '../../../interface/base-entity';
import { ScenarioDetails } from '../../../interface/scenario';

type StyledTreeItemProps = TreeItemProps & {
  labelIcon: ReactNode;
  labelText: string;
};

function CustomTreeItem(props: StyledTreeItemProps) {
  const { labelIcon, labelText, ...other } = props;

  return (
    <TreeItem
      label={
        <Box sx={{ display: 'flex', alignItems: 'center', p: 0.5, pr: 0 }}>
          {labelIcon}
          <Typography variant="body1" sx={{ fontWeight: 700, pl: '12px', flexGrow: 1 }}>
            {labelText}
          </Typography>
        </Box>
      }
      {...other}
    />
  );
}

interface TreeNode extends NamedEntity {
  nodeId: string;
  nodes: TreeNode[];
}

type Props = {
  scenarioDetails: ScenarioDetails;
};

export default function ScenarioTreeViewComponent({ scenarioDetails }: Props) {
  const markets = useMemo<TreeNode[]>(
    () =>
      scenarioDetails.markets.map(market => ({
        id: market.id,
        name: market.name,
        nodeId: `market-${market.id}`,
        nodes: market.channels.map(channel => ({
          id: channel.id,
          name: channel.name,
          nodeId: `channel-${channel.id}`,
          nodes: channel.pages.map(page => ({
            id: page.id,
            name: page.name,
            nodeId: `page-${page.id}`,
            nodes: page.containers.map(container => ({
              id: container.id,
              name: container.name,
              nodeId: `container-${container.id}`,
              nodes: [],
            })),
          })),
        })),
      })),
    [scenarioDetails],
  );

  const expanded = useMemo<string[]>(
    () =>
      markets.flatMap(market => [
        market.nodeId,
        ...market.nodes?.flatMap(channel => [
          channel.nodeId,
          ...channel.nodes?.flatMap(page => [page.nodeId, ...page.nodes?.map(({ nodeId }) => nodeId)]),
        ]),
      ]),
    [markets],
  );

  return (
    <TreeView defaultCollapseIcon={<ExpandMoreIcon />} defaultExpandIcon={<ChevronRightIcon />} expanded={expanded}>
      {markets.map(market => (
        <CustomTreeItem
          collapseIcon={<ExpandMoreIcon htmlColor="#4994EE" />}
          expandIcon={<ChevronRightIcon htmlColor="#4994EE" />}
          labelIcon={<Icon path={mdiEarth} title="Market" size={1} horizontal vertical rotate={180} color="#4994EE" />}
          key={market.nodeId}
          nodeId={market.nodeId}
          labelText={market.name}>
          {market.nodes.map(channel => (
            <CustomTreeItem
              collapseIcon={<ExpandMoreIcon htmlColor="#4DD895" />}
              expandIcon={<ChevronRightIcon htmlColor="#4DD895" />}
              labelIcon={
                <Icon
                  path={mdiCellphoneLink}
                  title="Channel"
                  size={1}
                  horizontal
                  vertical
                  rotate={180}
                  color="#4DD895"
                />
              }
              key={channel.nodeId}
              nodeId={channel.nodeId}
              labelText={channel.name}>
              {channel.nodes.map(page => (
                <CustomTreeItem
                  collapseIcon={<ExpandMoreIcon htmlColor="#F4CD67" />}
                  expandIcon={<ChevronRightIcon htmlColor="#F4CD67" />}
                  labelIcon={
                    <Icon
                      path={mdiFileOutline}
                      title="Page"
                      size={1}
                      horizontal
                      vertical
                      rotate={180}
                      color="#F4CD67"
                    />
                  }
                  key={page.nodeId}
                  nodeId={page.nodeId}
                  labelText={page.name}>
                  {page.nodes.map(container => (
                    <CustomTreeItem
                      collapseIcon={<ExpandMoreIcon htmlColor="#715FCC" />}
                      expandIcon={<ChevronRightIcon htmlColor="#715FCC" />}
                      labelIcon={
                        <Icon
                          path={mdiTableRow}
                          title="Container"
                          size={1}
                          horizontal
                          vertical
                          rotate={180}
                          color="#715FCC"
                        />
                      }
                      key={container.nodeId}
                      nodeId={container.nodeId}
                      labelText={container.name}
                    />
                  ))}
                </CustomTreeItem>
              ))}
            </CustomTreeItem>
          ))}
        </CustomTreeItem>
      ))}
    </TreeView>
  );
}
