import { Market } from '../../../../../interface/market';
import DialogLayout from '../../../../../shared/dialog-layout';

interface Props {
  market: Market;
  model: number;
  isOpen: boolean;
  onDeleteModel: (id: number) => VoidFunction;
  onCancel: VoidFunction;
}

function DeleteModelModal(props: Props) {
  const { market, model, isOpen, onCancel, onDeleteModel } = props;

  const handleDeleteModel = () => {
    onDeleteModel(model);
  };

  return (
    <DialogLayout
      open={isOpen}
      title={'Delete Model'}
      subtitle={`Are you sure you want to delete a model in ${market?.name} market?`}
      className="delete-model-dialog"
      scapeActionText={'CLOSE'}
      scapeActionHandler={onCancel}
      confirmActionText={'DELETE MODEL'}
      confirmActionHandler={handleDeleteModel}
    >
    </DialogLayout>
  );
}

export default DeleteModelModal;