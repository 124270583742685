import { useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { User } from '../../resources/auth/user-model';
import './index.css';

type Props = {
  user: User;
};

export const prettyFormatNameInitials = (name: string): string => {
  const letters = name
    .split(' ')
    .filter(word => word.length)
    .map(word => word.charAt(0).toUpperCase())
    .join('');

  return letters.length > 2 ? letters.charAt(0) + letters.charAt(letters.length - 1) : letters;
};

export default function UserDetailsComponent({ user }: Props) {
  const initials = useMemo(() => prettyFormatNameInitials(user.name), [user.name]);

  return (
    <Row className="user-details">
      <Col>
        <h5 className="name">{user.name}</h5>
        <span className="email">{user.email}</span>
      </Col>
      <Col className="picture-container">
        <div className="picture">{initials}</div>
      </Col>
    </Row>
  );
}
