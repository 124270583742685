import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useScenarios from '../../../../resources/scenarios/scenarios-hook';
import { ScenarioVersion } from '../../../../resources/scenarios/scenarios-versions-models';
import DialogLayout from '../../../../shared/dialog-layout';
import Input from '../../../../shared/input';
import Typography from '../../../../shared/typography';
import { getDateString } from '../../../../utils/date.utils';
import './index.scss';

interface Props {
  isOpen: boolean;
  isLoading: boolean;
  scenarioTitle?: string;
  market?: string;
  onSend: (title: string, description: string) => void;
  onCancel: VoidFunction;
}

const SendToApprovalModal: React.FC<Props> = props => {
  const { isOpen, isLoading, scenarioTitle, market, onSend, onCancel } = props;
  const [title, setTitle] = useState('');
  const [dialogDescription, setDialogDescription] = useState('');
  const [description, setDescription] = useState('');
  const [dialogTexts, setDialogTexts] = useState({
    dialogTitle: '',
    cancelText: '',
  });
  const [scenarioVersion, setScenarioVersion] = useState<ScenarioVersion[]>();
  const { id, scenarioVersionId } = useParams();
  const { scenarioVersionHistory, fetchScenarioVersionHistory } = useScenarios();

  const handleConfirmAction = () => {
    onSend(title, description);
  };

  const handleScapeAction = () => {
    onCancel();
  };

  useEffect(() => {
    if (market) {
      setDialogDescription(`Manual Model of ${market} market`);
    } else {
      setDialogDescription('Step Viewer');
    }
  }, [market]);

  useEffect(() => {
    if (scenarioVersionId) {
      setDialogTexts({ dialogTitle: 'Request Version Restore', cancelText: 'BACK TO TEST' });
      const scenarioVersionIdInt = parseInt(scenarioVersionId, 10);
      setScenarioVersion(scenarioVersionHistory.data?.filter(version => version.id === scenarioVersionIdInt) || []);
    } else {
      setDialogTexts({ dialogTitle: 'Request Changes', cancelText: 'BACK TO TEST RESULTS' });
    }
  }, [scenarioVersionHistory, scenarioVersionId]);

  useEffect(() => {
    if (parseInt(id as string)) {
      fetchScenarioVersionHistory(parseInt(id as string));
    }
  }, [fetchScenarioVersionHistory, id, scenarioVersionId]);

  return (
    <DialogLayout
      open={isOpen}
      isLoading={isLoading}
      title={dialogTexts.dialogTitle}
      confirmActionText="SEND REQUEST"
      confirmActionHandler={title && description ? handleConfirmAction : undefined}
      scapeActionText={dialogTexts.cancelText}
      scapeActionHandler={handleScapeAction}
      maxWidth="sm"
      variant="outlined">
      <section className="send-to-approval-container">
        <Typography colorVariant="primary">
          You are sending a request for the configurations on the {dialogDescription}. Check ”Request List” to view or
          cancel your requests or wait for analysis.
        </Typography>
        {scenarioTitle && (
          <span className="mt-3">
            <Typography>Scenario: </Typography>
            <Typography colorVariant="tertiary">{scenarioTitle}</Typography>
          </span>
        )}
        {scenarioVersion && (
          <>
            <span className="mt-3">
              <Typography>Version: </Typography>
              <Typography colorVariant="tertiary">
                {getDateString(scenarioVersion[0]?.updatedAt.toISOString())}
              </Typography>
            </span>
            <span className="mt-3">
              <Typography style={{ fontSize: '12px' }}>
                Requested By {scenarioVersion[0]?.createdBy} | Approved by {scenarioVersion[0]?.createdBy}
              </Typography>
            </span>
          </>
        )}
        <Input
          title="Title"
          required
          placeholder="Type the title of your request"
          className="mt-4"
          value={title}
          onChange={e => setTitle(e.target.value)}
        />
        <Input
          title="Description"
          required
          placeholder="Type your reason for change request or any other information"
          className="mt-3"
          value={description}
          onChange={e => setDescription(e.target.value)}
        />
      </section>
    </DialogLayout>
  );
};

export default SendToApprovalModal;
