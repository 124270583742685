import { mdiDelete } from '@mdi/js';
import { ScenarioDetails } from '../../../../../../resources/scenarios/scenarios-models';
import { ScenarioStep } from '../../../../../../resources/scenarios/scenarios-versions-models';
import IconButton from '../../../../../../shared/ui/icon-btn';
import './index.scss';

interface Props {
  step: ScenarioStep;
  scenarioDetails: ScenarioDetails;
  onDelete: VoidFunction;
}

const StepActionButtonsCell: React.FC<Props> = props => {
  const { step, onDelete } = props;

  return (
    <section className="step-action-buttons">
      {!step.standard && <IconButton mdiIconPath={mdiDelete} size="16px" color="var(--text-gray)" onClick={onDelete} />}
    </section>
  );
};

export default StepActionButtonsCell;
