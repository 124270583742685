import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Card, Container } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ExternalDataRenderer from '../../../components/hocs/external-data-renderer';
import useBreadcrumbs from '../../../resources/breadcrumbs/breadcrumbs-hook';
import useScenarios from '../../../resources/scenarios/scenarios-hook';
import { ScenarioSteps } from '../../../resources/scenarios/scenarios-versions-models';
import Title from '../../../shared/title';
import Typography from '../../../shared/typography';
import Breadcrumbs from '../../../shared/ui/breadcrumbs';
import EditableScenarioSteps from '../scenario-details-screen/scenario-steps';
import { createStepVersionConfiguration } from '../scenario-details-screen/scenario-steps/scenario-steps-helpers';
import './index.scss';

export enum SavingStatus {
  OFF,
  AUTOMATIC,
  MANUAL,
}

const ScenarioEditorScreen: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { breadcrumbs } = useBreadcrumbs();
  const {
    scenarioSteps: scenarioStepsData,
    draftScenarioVersion: draftScenarioVersionData,
    scenarioDetails,
    fetchScenarioDetails,
    fetchScenarioSteps,
    fetchScenarioDraftVersion,
    saveScenarioStepsDraftVersion,
    discardScenarioDraftVersion,
  } = useScenarios();
  const [toEditScenarioSteps, setToEditScenarioSteps] = useState<ScenarioSteps | undefined>(undefined);
  const hasScenarioVersion = useMemo(() => !!draftScenarioVersionData.data, [draftScenarioVersionData.data]);
  const [savingStatus, setSavingStatus] = useState<SavingStatus>(SavingStatus.OFF);
  const [updatedAt, setUpdatedAt] = useState<Date | undefined>(undefined);

  useEffect(() => {
    if (draftScenarioVersionData.data) {
      setUpdatedAt(new Date(draftScenarioVersionData.data.updatedAt));
    }
  }, [draftScenarioVersionData.data]);

  useEffect(() => {
    fetchScenarioDetails(parseInt(id as string));
    fetchScenarioSteps(parseInt(id as string));
    fetchScenarioDraftVersion(parseInt(id as string));
  }, [fetchScenarioDetails, fetchScenarioDraftVersion, fetchScenarioSteps, id]);

  useEffect(() => {
    let toEditScenarioStepsNewValue;

    if (draftScenarioVersionData.error?.statusCode === 404) {
      toEditScenarioStepsNewValue = scenarioStepsData.data;
    } else {
      toEditScenarioStepsNewValue = draftScenarioVersionData.data?.steps;
    }

    setToEditScenarioSteps(toEditScenarioStepsNewValue);
  }, [draftScenarioVersionData.data, draftScenarioVersionData.error?.statusCode, scenarioStepsData.data]);

  const handleSave = useCallback(
    (editingScenarioSteps: ScenarioSteps) => {
      if (hasScenarioVersion || !_.isEqual(editingScenarioSteps, scenarioStepsData.data)) {
        setSavingStatus(SavingStatus.AUTOMATIC);
        saveScenarioStepsDraftVersion(
          parseInt(id as string),
          createStepVersionConfiguration(editingScenarioSteps),
        ).then(() => {
          setUpdatedAt(new Date(new Date().getTime() + 3 * 60 * 60 * 1000));
          setSavingStatus(SavingStatus.OFF);
        });
      }
    },
    [hasScenarioVersion, id, saveScenarioStepsDraftVersion, scenarioStepsData.data],
  );

  const handleReset = useCallback(() => {
    if (scenarioStepsData.data) {
      setToEditScenarioSteps(scenarioStepsData.data);
      discardScenarioDraftVersion(parseInt(id as string));
      setUpdatedAt(undefined);
    }
  }, [discardScenarioDraftVersion, id, scenarioStepsData.data]);

  const handleGoToTests = (latestEditingScenarioSteps: ScenarioSteps) => {
    setSavingStatus(SavingStatus.MANUAL);
    saveScenarioStepsDraftVersion(
      parseInt(id as string),
      createStepVersionConfiguration(latestEditingScenarioSteps),
    ).then(() => {
      navigate(`/scenario-list/${id}/edit/test`);
    });
  };

  const handleGoBack = (latestEditingScenarioSteps: ScenarioSteps) => {
    setSavingStatus(SavingStatus.MANUAL);
    saveScenarioStepsDraftVersion(
      parseInt(id as string),
      createStepVersionConfiguration(latestEditingScenarioSteps),
    ).then(() => {
      navigate(`/scenario-list/${id}`);
    });
  };

  return (
    <Container className="scenario-editor-container">
      <Card className="scenario-editor">
        <Card.Body>
          <Breadcrumbs breadcrumbs={breadcrumbs} />
          <ExternalDataRenderer
            externalData={scenarioStepsData}
            makeCustomIsLoading={() =>
              toEditScenarioSteps === undefined || !!scenarioDetails.loading || savingStatus === SavingStatus.MANUAL
            }
            makeDataElement={() => (
              <>
                <Title>Step Configurations</Title>
                <Typography weight="bold">
                  You are in configuration mode of{' '}
                  <Link to={`/scenario-list/${scenarioDetails.data?.id}`}>{scenarioDetails.data?.name}</Link> Scenario.
                </Typography>
                <EditableScenarioSteps
                  className="mt-3"
                  isSaving={[SavingStatus.MANUAL, SavingStatus.AUTOMATIC].includes(savingStatus)}
                  scenarioSteps={toEditScenarioSteps!}
                  scenarioDetails={scenarioDetails.data}
                  handleGoBack={handleGoBack}
                  handleGoToTests={handleGoToTests}
                  handleSave={handleSave}
                  handleReset={handleReset}
                  lastUpdateAt={updatedAt}
                />
              </>
            )}
          />
        </Card.Body>
      </Card>
    </Container>
  );
};

export default ScenarioEditorScreen;
