import { useCallback, useEffect, useMemo, useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import ExternalDataRenderer from '../../components/hocs/external-data-renderer';
import useQueryParams, { QueryParams, Value } from '../../hooks/use-query-params';
import useScenarios from '../../resources/scenarios/scenarios-hook';
import { ScenarioDetailsListItem } from '../../resources/scenarios/scenarios-models';
import PaginatedTableComponent, { Column } from '../../shared/paginated-table';
import './index.css';
import ScenarioListFormComponent, { ScenarioListFormFields } from './scenario-list-form';

interface ScenarioRow {
  id: number;
  name: string;
  description: string;
  market?: number | string;
  channel?: number | string;
  page?: number | string;
  container?: number | string;
}

const columns: Column[] = [
  {
    id: 'name',
    label: 'Scenario',
    onNavClick: (row: ScenarioRow) => `/scenario-list/${row.id}`,
  } as any,
  {
    id: 'market',
    label: 'Market',
    format: (value: number) => `${value.toLocaleString()} markets`,
    sx: (value: number | string) => (typeof value === 'number' ? { color: '#4994EE' } : {}),
  },
  {
    id: 'channel',
    label: 'Channel',
    format: (value: number) => `${value.toLocaleString()} channels`,
    sx: (value: number | string) => (typeof value === 'number' ? { color: '#4DD895' } : {}),
  },
  {
    id: 'page',
    label: 'Page',
    format: (value: number) => `${value.toLocaleString()} pages`,
    sx: (value: number | string) => (typeof value === 'number' ? { color: '#F4CD67' } : {}),
  },
  {
    id: 'container',
    label: 'Container',
    format: (value: number) => `${value.toLocaleString()} containers`,
    sx: (value: number | string) => (typeof value === 'number' ? { color: '#715FCC' } : {}),
  },
];

const toScenariosRows = (scenarios: ScenarioDetailsListItem[]): ScenarioRow[] => {
  return scenarios.map(scenario => ({
    id: scenario.id,
    name: scenario.name,
    description: scenario.description,
    market: scenario.marketsSize > 1 ? scenario.marketsSize : scenario.marketName,
    channel: scenario.channelsSize > 1 ? scenario.channelsSize : scenario.channelName,
    page: scenario.pagesSize > 1 ? scenario.pagesSize : scenario.pageName,
    container: scenario.containersSize > 1 ? scenario.containersSize : scenario.containerName,
  }));
};

export const valueToArray = (value?: Value): string[] | undefined => {
  if (!value) {
    return undefined;
  } else if (typeof value === 'string') {
    return [value];
  }

  return value;
};

export const formDataToQueryParams = (formData: ScenarioListFormFields): QueryParams => {
  return {
    markets: formData.markets ?? [],
    channels: formData.channels ?? [],
    pages: formData.pages ?? [],
    containers: formData.containers ?? [],
  };
};

export const queryParamsToFormData = (queryParams: QueryParams): ScenarioListFormFields => {
  return {
    markets: valueToArray(queryParams.markets),
    channels: valueToArray(queryParams.channels),
    pages: valueToArray(queryParams.pages),
    containers: valueToArray(queryParams.containers),
  };
};

export default function ScenarioListComponent() {
  const [params, setParams] = useQueryParams({});
  const { scenarios: scenariosData, resetScenarios, fetchScenarios } = useScenarios();
  const urlValues = useMemo(() => queryParamsToFormData(params), [params]);
  const scenarios: ScenarioRow[] = useMemo(() => toScenariosRows(scenariosData.data ?? []), [scenariosData.data]);
  const [selectedMarkets, setSelectedMarkets] = useState<string[] | undefined>(urlValues.markets);
  const [selectedChannels, setSelectedChannels] = useState<string[] | undefined>(urlValues.channels);
  const [selectedPages, setSelectedPages] = useState<string[] | undefined>(urlValues.pages);
  const [selectedContainers, setSelectedContainers] = useState<string[] | undefined>(urlValues.containers);

  useEffect(() => {
    setSelectedMarkets(urlValues.markets);
    setSelectedChannels(urlValues.channels);
    setSelectedPages(urlValues.pages);
    setSelectedContainers(urlValues.containers);
  }, [urlValues.channels, urlValues.containers, urlValues.markets, urlValues.pages]);

  useEffect(() => () => resetScenarios(), [resetScenarios]);

  useEffect(() => {
    if (urlValues.containers?.length) {
      fetchScenarios(urlValues.containers?.map(containerId => parseInt(containerId)));
    }
  }, [urlValues.containers, fetchScenarios]);

  const handleSubmit = useCallback(() => {
    setParams(
      formDataToQueryParams({
        markets: selectedMarkets,
        channels: selectedChannels,
        containers: selectedContainers,
        pages: selectedPages,
      }),
    );
  }, [selectedChannels, selectedContainers, selectedMarkets, selectedPages, setParams]);

  const handleMarketsChanges = useCallback(newMarkets => {
    setSelectedMarkets(newMarkets);
  }, []);

  const handleChannelsChanges = useCallback(newChannels => {
    setSelectedChannels(newChannels);
  }, []);

  const handlePagesChanges = useCallback(newPages => {
    setSelectedPages(newPages);
  }, []);

  const handleContainersChanges = useCallback(newContainers => {
    setSelectedContainers(newContainers);
  }, []);

  return (
    <Container className="scenario-list">
      <Card style={{ display: 'flex' }}>
        <Card.Body>
          <Row className="title">
            <Col>
              <Card.Title>Scenario</Card.Title>
            </Col>
          </Row>
          <Card.Text>
            Fill out the scenario information from the selectors below to change the scenario below.
          </Card.Text>
          <ScenarioListFormComponent
            values={{
              channels: selectedChannels,
              containers: selectedContainers,
              markets: selectedMarkets,
              pages: selectedPages,
            }}
            onChange={{ handleMarketsChanges, handleChannelsChanges, handleContainersChanges, handlePagesChanges }}
            onSubmit={handleSubmit}
          />
        </Card.Body>
      </Card>

      {!scenariosData.unstarted && (
        <Card>
          <Card.Body>
            <ExternalDataRenderer
              externalData={scenariosData}
              makeDataElement={() => (
                <>
                  <Row className="title">
                    <Col>
                      <Card.Title>Filtered Scenarios</Card.Title>
                    </Col>
                  </Row>
                  <Card.Text>Click on a scenario from the list below to see its details.</Card.Text>
                  <Row className="mb-0">
                    <Col className="justify-content-center">
                      <PaginatedTableComponent columns={columns} rows={scenarios} rowIdPropName="id" />
                    </Col>
                  </Row>
                </>
              )}
            />
          </Card.Body>
        </Card>
      )}
    </Container>
  );
}
