import { Button } from '@amway/react-components';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Market } from '../../../interface/market';
import { RecommenderRequest } from '../../../interface/recommender';
import useBreadcrumbs from '../../../resources/breadcrumbs/breadcrumbs-hook';
import useModels from '../../../resources/model-output/model-output-hook';
import useRecommendation from '../../../resources/recommendation/recommendation-hook';
import useScenarios from '../../../resources/scenarios/scenarios-hook';
import Breadcrumbs from '../../../shared/ui/breadcrumbs';
import ApproveRequestModal from '../../request-list/modals/approve-request';
import RejectRequestModal from '../../request-list/modals/reject-request';
import RecommendedProductsComponent from '../../scenario-tester/recommended-products';
import ScenarioTesterFormComponent, { ScenarioTesterFormFields } from '../../scenario-tester/scenario-tester-form';
import './index.scss';
import SendToApprovalMerchRulesModal from './send-to-approval-modal';
import merchRulesService from '../../../resources/merch-rules/merch-rules.service';
import ExternalDataRenderer from '../../../components/hocs/external-data-renderer';

interface Props {
  testingTool?: boolean;
}

export default function MerchandisingRulesTestScreen({ testingTool = false }: Props) {
  const { id, modelVersionId } = useParams();
  const navigate = useNavigate();
  const { breadcrumbs } = useBreadcrumbs();
  const [market, setMarket] = useState<Market>();
  const [request, setRequest] = useState<RecommenderRequest>();
  const [inputSkus, setInputSkus] = useState<string[]>([]);
  const { recommendation, fetchRecommendationsByMerchRuleIds, resetRecommendations } = useRecommendation();
  const { draftScenarioVersion } = useScenarios();
  const [scenarioVersionId, setScenarioVersionId] = useState<string | null>();
  const { checkDraftModelVersion } = useModels();
  const [isSendToApprovalModalOpen, setIsSendToApprovalModalOpen] = useState(false);
  const [isApproveRequestModalOpen, setIsApproveRequestModalOpen] = useState(false);
  const [isRejectRequestModalOpen, setIsRejectRequestModalOpen] = useState(false);
  const [isSendApprovalRequestLoading, setIsSendApprovalRequestLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const [requestStarted, setRequestStarted] = useState(false);
  const approvalRequest = useMemo(
    () => (searchParams.get('approval') ? Number(searchParams.get('approval')) : undefined),
    [searchParams],
  );
  const selecteds = useMemo(
    () => (searchParams.get('selected') ? String(searchParams.get('selected')).split(',') : undefined),
    [searchParams],
  );

  useEffect(() => {
    return () => resetRecommendations();
  }, [resetRecommendations]);

  useEffect(() => {
    if (modelVersionId && id) {
      checkDraftModelVersion(parseInt(id), parseInt(modelVersionId));
    }
  }, [modelVersionId, id, checkDraftModelVersion]);

  const handleFormChanges = useCallback(
    (formData: ScenarioTesterFormFields) => {
      setRequest({
        containerKey: formData.container?.containerKey,
        accountNum: formData.accountNum || undefined,
        skus: formData.skus.length ? formData.skus : undefined,
        recentlySearched: formData.recentlySearched.length ? formData.recentlySearched : undefined,
        recentlyViewedSkus: formData.recentlyViewedSkus.length ? formData.recentlyViewedSkus : undefined,
        productsInCart: formData.productsInCart.length ? formData.productsInCart : undefined,
        channel: formData.channel?.code,
        filter: formData.filter || undefined,
        searchText: formData.searchText || undefined,
        pageName: formData.page?.pageName,
        slots: formData.slots || undefined,
        disabledSlots: formData.disabledSlots || undefined,
        variant: formData.variant || undefined,
        visitorUserProfile: formData.visitorUserProfile || undefined,
      });

      if (formData.market) {
        setMarket(formData.market);

        if (!_.isEqual(formData.skus, inputSkus)) {
          setInputSkus(formData.skus);
        }
      }

      if (formData.scenarioVersion) {
        setScenarioVersionId(formData.scenarioVersion.id.toString());
      }
    },
    [inputSkus],
  );

  const isButtonDisabled = useMemo<boolean>(() => {
    return !market || !request?.channel || !request?.containerKey || !scenarioVersionId;
  }, [market, request?.channel, request?.containerKey, scenarioVersionId]);

  const handleSubmit = useCallback(() => {
    if (market && request) {
      setRequestStarted(true);

      fetchRecommendationsByMerchRuleIds(market!.code, parseInt(id as string), {
        ...request,
        scenarioVersionId: parseInt(scenarioVersionId || '') || draftScenarioVersion.data?.id,
        merchRuleVersionIds: (selecteds ?? []).map(Number),
      });
    }
  }, [
    market,
    request,
    fetchRecommendationsByMerchRuleIds,
    id,
    scenarioVersionId,
    draftScenarioVersion.data?.id,
    selecteds,
  ]);

  const handleBackToConfigurations = () => {
    if (approvalRequest) {
      navigate(`/request-list/`);
    } else {
      navigate(`/admin-configurations/merchandising-rules/`);
    }
  };

  const handleCancel = () => {
    setIsSendToApprovalModalOpen(false);
  };

  const handleOpenSendToApprovalModal = () => {
    setIsSendToApprovalModalOpen(true);
  };

  const handleSendToApproval = (title: string, description: string) => {
    setIsSendApprovalRequestLoading(true);
    merchRulesService
      .submitMerchRuleVersion({
        title,
        description,
        versionIds: selecteds ?? [],
      })
      .finally(() => {
        setIsSendApprovalRequestLoading(false);
        navigate('/request-list');
      });
  };

  return (
    <Container className="model-tester">
      <Card>
        <Card.Body>
          {!testingTool && !approvalRequest && <Breadcrumbs breadcrumbs={breadcrumbs} />}
          <Row className="title">
            <Col>
              <Card.Title>{testingTool ? 'Testing Tool' : 'Test Merchandising Rules'}</Card.Title>
            </Col>
          </Row>
          <ScenarioTesterFormComponent onFormChange={handleFormChanges} modelTesting={true} />
          <Row className="submit">
            <Col className="d-flex justify-content-center">
              <Button disabled={isButtonDisabled} onClick={handleSubmit} loading={recommendation.loading === true}>
                TEST RECOMMENDATIONS
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      {requestStarted && market && (
        <Card>
          <Card.Body className="recommendation-results-container">
            <Row className="title">
              <Col>
                <Card.Title>Recommendation Results</Card.Title>
              </Col>
            </Row>
            <Card.Text>Previewing container based on scenario and custom parameters.</Card.Text>
            <Row className="products">
              <ExternalDataRenderer
                externalData={recommendation}
                makeDataElement={data => (
                  <RecommendedProductsComponent
                    className="products-container"
                    marketCode={market.code}
                    recommendations={data.recommendations}
                    rawRecommendations={data.disabledRecommendations}
                  />
                )}
              />
            </Row>
            {!testingTool && (
              <footer className="actions-container">
                <Button variant="link" fontColor="primary" onClick={handleBackToConfigurations}>
                  {approvalRequest ? 'BACK TO REQUEST LIST' : 'BACK TO CONFIGURATIONS'}
                </Button>
                {approvalRequest ? (
                  <div style={{ display: 'flex', justifyContent: 'center', gap: '48px' }}>
                    <Button variant="link" fontColor="warning-error" onClick={() => setIsRejectRequestModalOpen(true)}>
                      REJECT CHANGES
                    </Button>
                    <Button backgroundColor="warning-success" onClick={() => setIsApproveRequestModalOpen(true)}>
                      APPROVE CHANGES
                    </Button>
                  </div>
                ) : (
                  <Button onClick={handleOpenSendToApprovalModal} className="confirm">
                    SEND REQUEST
                  </Button>
                )}
              </footer>
            )}
          </Card.Body>
        </Card>
      )}
      <SendToApprovalMerchRulesModal
        isLoading={isSendApprovalRequestLoading}
        isOpen={isSendToApprovalModalOpen}
        onSend={handleSendToApproval}
        onCancel={handleCancel}
      />
      <ApproveRequestModal
        isOpen={isApproveRequestModalOpen}
        onCancel={() => setIsApproveRequestModalOpen(false)}
        id={String(approvalRequest) ?? '0'}
      />
      <RejectRequestModal
        isOpen={isRejectRequestModalOpen}
        onCancel={() => setIsRejectRequestModalOpen(false)}
        id={String(approvalRequest) ?? '0'}
      />
    </Container>
  );
}
