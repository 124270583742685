import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { Dropdown, Form } from 'react-bootstrap';
import { prettyFormatList } from '../../utils/pretty-formatters-utils';
import Tooltip from '../tooltip';
import './index.scss';

export interface Item {
  name: string;
  value: string;
  immutable?: boolean;
}

export interface Props {
  id: string;
  required?: boolean;
  disabled?: boolean;
  variant?: 'primary' | 'secondary' | 'tertiary';
  all?: boolean;
  none?: boolean;
  allPreSelected?: boolean;
  badges?: boolean;
  label: string;
  placeholder?: string;
  items?: Item[];
  value?: string[];
  onChange: (item?: string[]) => void;
  /** when this property is TRUE, the onChange handler is triggered every time the user select or unselect an option.
   *  when this is FALSE, the onChange is triggered only after the user closes the selector dropdown
   *  default: TRUE
   */
  eager?: boolean;
}

const hydrateChanges = (value: string[], items: Item[]): Item[] => {
  return value.map(v => items.find(it => it.value === v)).filter(isDefined => !!isDefined) as Item[];
};

const isSelected = (item: Item, hydratedValues: Item[]): boolean => {
  return !!hydratedValues.find(hV => hV.value === item.value);
};

const formatHydratedValues = (hydratedValues: Item[]): string => {
  return prettyFormatList(hydratedValues.map(item => item.name));
};

const MultiSelectDropdown: React.FC<Props> = ({
  id,
  required,
  variant = 'primary',
  disabled,
  all,
  none,
  allPreSelected,
  badges,
  value,
  label,
  placeholder = '',
  items,
  onChange,
  eager = true,
}) => {
  const toggleElRef = useRef<HTMLDivElement>(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [hasLoadedItemsOnce, setHasLoadedItemsOnce] = useState<boolean>(false);
  const [changes, setChanges] = useState<string[] | undefined>(value);
  const hydratedChanges = useMemo(() => hydrateChanges(changes ?? [], items ?? []), [items, changes]);
  const isAllSelected = useMemo<boolean>(
    () => hydratedChanges.length === items?.length,
    [hydratedChanges.length, items?.length],
  );
  const [shouldShowTooltip, setShouldShowTooltip] = useState(
    !!(isAllSelected || (toggleElRef.current && toggleElRef.current?.clientWidth < toggleElRef.current?.scrollWidth)) &&
      !isDropdownOpen,
  );

  useEffect(() => {
    if (items?.length === 0) {
      setChanges(undefined);
    }
  }, [items, value]);

  useEffect(() => {
    if (items && items.length > 1) {
      const itemsValues = items?.map(item => item.value);
      setChanges(prev => prev?.filter(value => itemsValues.includes(value)));
    }
  }, [items]);

  useEffect(() => {
    if (eager) onChange(changes);
    else {
      if (!isDropdownOpen) {
        onChange(changes);
      }
    }
  }, [changes, eager, isDropdownOpen, onChange]);

  useLayoutEffect(() => {
    setShouldShowTooltip(
      !!(
        isAllSelected ||
        (toggleElRef.current && toggleElRef.current?.clientWidth < toggleElRef.current?.scrollWidth)
      ) && !isDropdownOpen,
    );
  }, [toggleElRef, isAllSelected, isDropdownOpen]);

  const handleDelete = useCallback(
    (index: number) => {
      if (required && changes?.length === 1) return;

      setChanges([...(changes ?? []).slice(0, index), ...(changes ?? []).slice(index + 1)]);
    },
    [required, changes],
  );

  const handleClick = useCallback(
    (item?: Item) => {
      if (item) {
        if (item.immutable) return;
        if ((changes ?? []).includes(item.value)) {
          handleDelete((changes ?? []).indexOf(item.value));
        } else {
          setChanges([...(changes ?? []), item.value]);
        }
      }
    },
    [handleDelete, setChanges, changes],
  );

  const selectedLabel = useMemo<string>(() => {
    let lbl: string = '';

    if (all && isAllSelected && items && items.length > 1) {
      lbl = 'All';
    } else {
      lbl = formatHydratedValues(hydratedChanges);
    }

    return lbl;
  }, [all, isAllSelected, items, hydratedChanges]);

  const handleClickAll = useCallback(() => {
    if (!isAllSelected) {
      setChanges((items ?? []).map(it => it.value));
    }
  }, [isAllSelected, setChanges, items]);

  const handleClickNone = useCallback(() => {
    setChanges([]);
  }, [setChanges]);

  useEffect(() => {
    if (all && !isAllSelected && !hasLoadedItemsOnce && allPreSelected && items && items.length) {
      handleClickAll();
      setHasLoadedItemsOnce(true);
    }
  }, [items, all, allPreSelected, isAllSelected, hasLoadedItemsOnce, handleClickAll]);

  return (
    <Form.Group id={id} className={`multi-select-dropdown ${variant}`}>
      {label && (
        <Form.Label htmlFor={`${id}-toggle`}>
          {label} {required && <sup style={{ color: 'var(--warning-error)' }}>*</sup>}
        </Form.Label>
      )}
      <Dropdown
        id={`${id}-button`}
        show={isDropdownOpen}
        onToggle={setIsDropdownOpen}
        autoClose="outside"
        className={`${disabled ? 'disabled' : ''} ${hydratedChanges.length && !badges ? 'selected' : ''}`}>
        <Tooltip disabled={!shouldShowTooltip} title={formatHydratedValues(hydratedChanges)} placement="bottom-start">
          <Dropdown.Toggle
            ref={toggleElRef}
            id={`${id}-toggle`}
            as="div"
            data-empty={hydratedChanges.length === 0}
            className={`select-button ${disabled ? 'disabled' : ''}`}
            disabled={disabled}>
            {hydratedChanges.length ? selectedLabel : placeholder}
          </Dropdown.Toggle>
        </Tooltip>
        <Dropdown.Menu className="fancy-scrollbar">
          {all && !isAllSelected && (
            <Dropdown.Item
              eventKey="all"
              id="dropdown-item-all"
              className={`text-truncate ${isAllSelected ? 'active' : ''}`}
              onClick={() => handleClickAll()}>
              All
            </Dropdown.Item>
          )}
          {none && value && value.length > 0 && (
            <Dropdown.Item
              eventKey="none"
              id="dropdown-item-none"
              className="text-truncate"
              onClick={() => handleClickNone()}>
              None
            </Dropdown.Item>
          )}
          {(items ?? []).map(item => (
            <Dropdown.Item
              key={item.value}
              eventKey={item.value}
              id={`dropdown-item-${item.value}`}
              className={`text-truncate ${isSelected(item, hydratedChanges) ? 'active' : ''}`}
              onClick={() => handleClick(item)}>
              {item.name}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </Form.Group>
  );
};

export default MultiSelectDropdown;
