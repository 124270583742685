import { useCallback, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Features } from '../../../config/features';
import { WithFeaturesProxy } from '../../../helpers/with-features-proxy';
import { DashboardMetricsResponse, DashboardUniqueSkusResponse } from '../../../interface/dashboard';
import MetricsTileComponent from '../../../shared/metrics-tile';
import DashboardUniqueSkusModalComponent from '../dashboard-unique-skus-modal';

interface Props {
  metricsResponse?: DashboardMetricsResponse;
  uniqueSkusResponse?: DashboardUniqueSkusResponse;
  displayErrors: boolean;
}

type ExposedProps = Omit<Props, 'displayErrors'>;

const DashboardMetricsComponent: React.FC<Props> = ({ metricsResponse, uniqueSkusResponse, displayErrors }) => {
  const [uniqueSkusModalOpen, setUniqueSkusModalOpen] = useState<boolean>(false);
  const uniqueSkusRecommended = useMemo(() => uniqueSkusResponse?.uniqueSkusRecommended, [uniqueSkusResponse]);

  const toggleUniqueSkusModal = useCallback(() => {
    setUniqueSkusModalOpen(!uniqueSkusModalOpen);
  }, [uniqueSkusModalOpen]);

  return (
    <>
      <Row>
        <Col>
          <MetricsTileComponent
            id="avg-response-time"
            title="Average Response Time"
            formattedData={
              (metricsResponse?.averageExecutionTime ?? -1) >= 0
                ? metricsResponse?.averageExecutionTime + 'ms'
                : undefined
            }
          />
        </Col>
        <Col>
          <MetricsTileComponent
            id="number-apr-requests"
            title="Number of APR Requests"
            formattedData={metricsResponse?.numberRequests?.toLocaleString()}
          />
        </Col>
        {displayErrors && (
          <Col>
            <MetricsTileComponent
              id="warning-requests"
              title="Warnings"
              formattedData={metricsResponse?.numberWarnings?.toLocaleString()}
              color="#FA9F47"
            />
          </Col>
        )}
        {displayErrors && (
          <Col>
            <MetricsTileComponent
              id="error-requests"
              title="Errors"
              formattedData={metricsResponse?.numberErrors?.toLocaleString()}
              color="#FF4949"
            />
          </Col>
        )}
        <Col>
          <MetricsTileComponent
            id="unique-skus-recommended"
            title="Unique SKUs Recommended"
            formattedData={uniqueSkusResponse?.uniqueSkusRecommended?.length?.toLocaleString()}
            onClick={toggleUniqueSkusModal}
          />
        </Col>
      </Row>
      <DashboardUniqueSkusModalComponent
        show={uniqueSkusModalOpen}
        onHide={toggleUniqueSkusModal}
        rows={uniqueSkusRecommended}
      />
    </>
  );
};

export default WithFeaturesProxy<ExposedProps>(Features.DisplayErrorMetrics)((props, hasPermission) => {
  return <DashboardMetricsComponent {...props} displayErrors={hasPermission} />;
});
