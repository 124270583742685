import { useEffect, useMemo, useState } from 'react';
import { Badge, Col, Container, Image, Placeholder, Row } from 'react-bootstrap';
import { Brand } from '../../../interface/brand';
import { Category } from '../../../interface/category';
import { Product } from '../../../interface/product';
import { RecommendedProduct } from '../../../interface/recommender';
import useToasts from '../../../resources/toasts/toasts-hook';
import adminService from '../../../service/admin.service';
import recommenderService from '../../../service/recommender.service';
import './index.scss';
import { FaArrowTrendUp, FaArrowTrendDown } from 'react-icons/fa6';
import Tooltip from '../../../shared/tooltip';

type Props = {
  id: string;
  marketCode: string;
  sku: string;
  product?: Product;
  recommendation?: RecommendedProduct;
  rawRecommendation?: boolean;
};

export default function ProductDetailsComponent(props: Props) {
  const { id, product: givenProduct, marketCode, sku, recommendation, rawRecommendation } = props;
  const { push: pushToast } = useToasts();
  const [product, setProduct] = useState<Product>();
  const [loadingBrand, setLoadingBrand] = useState(true);
  const [loadingCategory, setLoadingCategory] = useState(true);
  const [loadingImage, setLoadingImage] = useState(true);
  const [brands, setBrands] = useState<Brand[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);

  const modelName = useMemo<string>(() => {
    const name = recommendation?.modelName;

    return name?.replaceAll('_', ' ').toLowerCase() ?? '';
  }, [recommendation]);

  const name = useMemo<string>(() => {
    return (
      product?.name
        .split(/[\s]+/)
        .map(word => (word.length > 3 ? word.toLowerCase() : word))
        .join(' ') ?? ''
    );
  }, [product]);

  const imageUrl = useMemo<string>(() => {
    return product ? product.images?.replace('qa.amway', 'amway') || '/assets/product-placeholder.svg' : '';
  }, [product]);

  const imageLoaded = () => {
    setLoadingImage(false);
  };

  useEffect(() => {
    if (givenProduct) {
      setProduct(givenProduct);
    }
  }, [givenProduct]);

  useEffect(() => {
    if (!givenProduct && marketCode && sku && sku !== product?.sku) {
      recommenderService
        .getProduct(marketCode, sku)
        .then(setProduct)
        .catch(err => {
          console.error(err);
          pushToast(`Could not fetch product with "${sku}" sku.`, 'error');
        });
    }
  }, [marketCode, sku, product?.sku, pushToast, givenProduct]);

  useEffect(() => {
    if (product?.brandId) {
      setLoadingBrand(true);

      adminService
        .getProductBrands(product.id)
        .then(setBrands)
        .catch(err => {
          console.error(err);
          pushToast(`Could not fetch brands for product with "${sku}" sku.`, 'error');
        })
        .finally(() => setLoadingBrand(false));
    }
  }, [product, pushToast, sku]);

  useEffect(() => {
    if (product?.categoryId) {
      setLoadingCategory(true);

      adminService
        .getProductCategories(product.id)
        .then(setCategories)
        .catch(err => {
          console.error(err);
          pushToast(`Could not fetch categories for product with "${sku}" sku.`, 'error');
        })
        .finally(() => setLoadingCategory(false));
    }
  }, [product, pushToast, sku]);

  const containerBackgroundColor = useMemo(() => {
    if (!recommendation?.appliedRules) return undefined;

    return recommendation?.appliedRules[0].includes('boost') ? 'var(--bg-green)' : 'var(--bg-red)';
  }, [recommendation?.appliedRules]);

  return (
    <Col
      id={id}
      style={{ background: containerBackgroundColor }}
      className={`product-details ${rawRecommendation ? 'raw-recommendation' : ''}`}>
      <Container>
        {recommendation?.modelName && (
          <Row className="model-name">
            <Col>
              {modelName ? (
                <span className="model">{modelName}</span>
              ) : (
                <Placeholder animation="glow" className="w-75">
                  <Placeholder className="w-100 h-100" />
                </Placeholder>
              )}
            </Col>
            {(recommendation.appliedRules ?? [])?.length > 0 && (
              <Tooltip
                title={recommendation.appliedRules!.map((rule, index) => (
                  <div key={rule}>
                    {index + 1}. {rule}
                    <br />
                  </div>
                ))}>
                <div className="ms-auto" style={{ width: 'max-content' }}>
                  {recommendation.appliedRules![0].includes('boost') ? (
                    <FaArrowTrendUp color="var(--warning-success)" />
                  ) : (
                    <FaArrowTrendDown color="var(--warning-error)" />
                  )}
                </div>
              </Tooltip>
            )}
          </Row>
        )}
        <Row className="display">
          <Col className="slot">{recommendation?.slot && product && <Badge>SLOT {recommendation?.slot}</Badge>}</Col>
          <Col className="image">
            {imageUrl && <Image src={imageUrl} onLoad={imageLoaded} />}
            {loadingImage && (
              <Placeholder animation="glow" className="w-75">
                <Placeholder className="w-100 h-100" />
              </Placeholder>
            )}
          </Col>
          <Col className="external-link">
            {product && (
              <a href={product.url} target="_blank" rel="noreferrer">
                <Image src="/assets/external-link.svg" width="18" height="18" />
              </a>
            )}
          </Col>
        </Row>
        <Row className="name">
          <Col>
            {name ? (
              <h5>{name}</h5>
            ) : (
              <Placeholder animation="glow" className="w-100">
                <Placeholder className="w-100 h-75" />
              </Placeholder>
            )}
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="brand-category">
          {brands.length === 0 && loadingBrand ? (
            <LoadingBadgeComponent id="loading-brand" loading={loadingBrand} className="brand" />
          ) : (
            brands.map((brand, i) => (
              <LoadingBadgeComponent key={brand.brandId} id={`brand-${i}`} className="brand" label={brand?.name} />
            ))
          )}
          {categories.length === 0 && loadingCategory ? (
            <LoadingBadgeComponent id="loading-category" loading={loadingCategory} className="category" />
          ) : (
            categories.map((category, i) => (
              <LoadingBadgeComponent
                key={category.categoryId}
                id={`category-${i}`}
                className="category"
                label={category.categoryName}
              />
            ))
          )}
        </Row>
        <Row className="sku">
          <Col>
            <span>SKU: {sku}</span>
          </Col>
        </Row>
      </Container>
    </Col>
  );
}

type LoadingBadgeProps = {
  id: string;
  className: string;
  loading?: boolean;
  label?: string;
};

function LoadingBadgeComponent({ id, loading, className, label }: LoadingBadgeProps) {
  return (
    <>
      {loading ? (
        <Placeholder animation="glow" className="w-50">
          <Placeholder className="w-100 h-75" />
        </Placeholder>
      ) : (
        label && (
          <Badge id={id} className={className}>
            {label}
          </Badge>
        )
      )}
    </>
  );
}
