import { createContext } from 'react';
import { ExternalData } from '../../@types/external-api';
import { RecommendationResponse } from './recommendation-models';
import { RecommendationParamsRequest } from './recommendation-types';
import { RecommenderRequest } from '../../interface/recommender';

export interface RecommendationContextType {
  recommendation: ExternalData<RecommendationResponse>;
  fetchRecommendationByScenarioVersionId(
    marketCode: string,
    scenarioId: number,
    params: RecommendationParamsRequest,
  ): void;
  fetchRecommendationByModelVersionId(marketCode: string, scenarioId: number, params: RecommenderRequest): void;
  fetchRecommendationsByMerchRuleIds(marketCode: string, scenarioId: number, params: RecommenderRequest): void;
  resetRecommendations: VoidFunction;
}

const RecommendationsContext = createContext<RecommendationContextType>(null as any);

export default RecommendationsContext;
