export enum Environment {
  DEV = 'DEV',
  QA = 'QA',
  PROD = 'PROD',
}

const env = {
  ADMIN_API_URL: process.env.REACT_APP_ADMIN_API_URL ?? '',
  API_URL: process.env.REACT_APP_API_URL ?? '',
  ENV: process.env.REACT_APP_ENV as Environment,
  OKTA: Object.seal({
    ISSUER: process.env.REACT_APP_OKTA_ISSUER!,
    CLIENT_ID: process.env.REACT_APP_OKTA_CLIENT_ID!,
    SCOPES: process.env.REACT_APP_OKTA_SCOPES!.split(',')!,
  }),
};

export default env;
