import { createContext } from 'react';
import { ExternalAction, ExternalData } from '../../@types/external-api';
import { ScenarioDetails, ScenarioDetailsListItem } from './scenarios-models';
import { ScenarioStepsVersionConfiguration } from './scenarios-types';
import { FilterExecutor, NewParentStep, ScenarioSteps, ScenarioVersion } from './scenarios-versions-models';

export interface ScenariosContextType {
  scenarios: ExternalData<ScenarioDetailsListItem[]>;
  filterSteps: ExternalData<FilterExecutor[]>;
  parentSteps: ExternalData<NewParentStep[]>;
  scenarioDetails: ExternalData<ScenarioDetails>;
  scenarioVersionHistory: ExternalData<ScenarioVersion[]>;
  scenarioSteps: ExternalData<ScenarioSteps>;
  discardScenarioDraftVersionStatus: ExternalAction;
  draftScenarioVersion: ExternalData<ScenarioVersion>;
  fetchScenarios: (containersIds: number[]) => void;
  resetScenarios: VoidFunction;
  fetchScenarioDetails: (id: number) => void;
  fetchScenarioSteps: (id: number) => void;
  fetchFilterSteps: () => void;
  fetchParentSteps: () => void;
  fetchApprovalScenarioVersion: (scenarioId: number, approvalRequestId: number) => VoidFunction | undefined;
  fetchScenarioVersionByVersionId: (scenarioId: number, versionId: number) => void;
  fetchScenarioDraftVersion: (scenarioId: number) => void;
  saveScenarioStepsDraftVersion: (scenarioId: number, editSteps: ScenarioStepsVersionConfiguration) => Promise<void>;
  discardScenarioDraftVersion: (scenarioId: number) => void;
  sendScenarioVersionToApproval: (
    scenarioId: number,
    scenarioVersionId: number,
    title: string,
    description: string,
  ) => Promise<void>;
  fetchScenarioVersionHistory: (scenarioId: number) => void;
}

const ScenariosContext = createContext<ScenariosContextType>(null as any);

export default ScenariosContext;
