import React, { useCallback, useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { Brand } from '../../../interface/brand';
import { Category } from '../../../interface/category';
import useToasts from '../../../resources/toasts/toasts-hook';
import adminService from '../../../service/admin.service';
import AccordionV2, { AccordionV2Header } from '../../../shared/accordionv2';
import DropdownBtn, { Item } from '../../../shared/dropdown-btn';
import MultiSelectDropdownBtn from '../../../shared/multi-select-dropdown-btn';
import SeparatorInputComponent from '../../../shared/separator-input';
import './index.scss';
import { RecommendationRequestUserType } from '../../../interface/recommender';
import { RecommenderFilterRequest } from '../../../interface/recommender';

const userTypes: { id: RecommendationRequestUserType; label: string }[] = [
  { id: 'abo', label: 'ABO' },
  { id: 'customer', label: 'Customer' },
  { id: 'guest', label: 'Visitor' },
];

export interface ScenarioTesterFilterFormFields {
  skus: string[];
  filter: RecommenderFilterRequest | undefined;
  searchText: string;
  accountNum: string;
  slots?: number;
  disabledSlots?: number;
  variant?: string;
  productsInCart: string[];
  recentlyViewedSkus: string[];
  recentlySearched: string[];
  visitorUserProfile?: RecommendationRequestUserType;
}

interface Props {
  marketId?: number | null;
  className?: string;
  onFormChange: (request: ScenarioTesterFilterFormFields) => void;
}

const ScenarioTesterParamsForm: React.FC<Props> = props => {
  const { marketId, className = '', onFormChange } = props;
  const { push: pushToast } = useToasts();
  const [categories, setCategories] = useState<Item[]>([]);
  const [brands, setBrands] = useState<Item[]>([]);
  const [selectedBrands, setSelectedBrands] = useState<Brand[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<Category[]>([]);
  const [skus, setSkus] = useState<string[]>([]);
  const [excludedSkus, setExcludedSkus] = useState<string[]>([]);
  const [searchText, setSearchText] = useState<string>('');
  const [accountNum, setAccountNum] = useState<string>('');
  const [slots, setSlots] = useState<number>(4);
  const [disabledSlots, setDisabledSlots] = useState<number>(4);
  const [variant, setVariant] = useState<string | undefined>();
  const [productsInCart, setProductsInCart] = useState<string[]>([]);
  const [recentlyViewedSkus, setRecentlyViewedSkus] = useState<string[]>([]);
  const [recentlySearched, setRecentlySearched] = useState<string[]>([]);
  const [visitorUserProfile, setVisitorUserProfile] = useState<RecommendationRequestUserType>();

  const loadBrands = useCallback(
    (marketId: number) => {
      adminService
        .getBrands(marketId)
        .then(response => {
          response.forEach(page => {
            (page as unknown as Item).label = page.name;
          });

          setSelectedBrands([]);
          setBrands(response as unknown as Item[]);
        })
        .catch(err => {
          console.error(err);
          pushToast('Could not fetch brands', 'error');
        });
    },
    [pushToast],
  );

  const loadCategories = useCallback(
    (marketId: number) => {
      adminService
        .getCategories(marketId)
        .then(response => {
          response.forEach(page => {
            (page as unknown as Item).label = page.categoryName;
          });

          setSelectedCategories([]);
          setCategories(response as unknown as Item[]);
        })
        .catch(err => {
          console.error(err);
          pushToast('Could not fetch categories', 'error');
        });
    },
    [pushToast],
  );

  useEffect(() => {
    if (marketId) {
      loadBrands(marketId);
      loadCategories(marketId);
    }
  }, [loadBrands, loadCategories, marketId]);

  useEffect(() => {
    onFormChange({
      skus,
      filter: {
        excludedSkus: excludedSkus,
        categories: selectedCategories.length ? selectedCategories.map(c => c.categoryId) : undefined,
        brands: selectedBrands.length ? selectedBrands.map(b => b.brandId) : undefined,
      },
      searchText,
      accountNum,
      disabledSlots,
      slots,
      variant,
      productsInCart,
      recentlyViewedSkus,
      recentlySearched,
      visitorUserProfile,
    });
  }, [
    accountNum,
    productsInCart,
    excludedSkus,
    skus,
    onFormChange,
    recentlySearched,
    recentlyViewedSkus,
    searchText,
    selectedBrands,
    selectedCategories,
    slots,
    variant,
    visitorUserProfile,
    disabledSlots,
  ]);

  const handleInputSkusChanges = useCallback(newSkus => {
    setSkus(newSkus);
  }, []);

  const handleExcludedSkusChanges = useCallback(newSkus => {
    setExcludedSkus(newSkus);
  }, []);

  const handleRecentlyViewedSkusChanges = useCallback(newSkus => {
    setRecentlyViewedSkus(newSkus);
  }, []);

  const handleProductsInCartSkusChanges = useCallback(newSkus => {
    setProductsInCart(newSkus);
  }, []);

  const handleRecentlySearchedChanges = useCallback(recentSearches => {
    setRecentlySearched(recentSearches);
  }, []);

  const handleUserTypeChanges = useCallback((visitorUserProfile?: RecommendationRequestUserType) => {
    setVisitorUserProfile(visitorUserProfile);
  }, []);

  return (
    <AccordionV2
      className={`mb-5 scenario-tester-params-form ${className}`}
      accordionItems={[
        {
          key: 'additional-fields',
          data: {
            header: <AccordionV2Header title="Custom parameters to change your testing results" />,
            content: (
              <>
                <Row>
                  <Col>
                    <SeparatorInputComponent
                      id="input-skus"
                      label="Input SKUs"
                      badgeTheme="gray"
                      value={skus}
                      onChange={handleInputSkusChanges}
                    />
                  </Col>
                  <Col>
                    <SeparatorInputComponent
                      id="excluded-skus"
                      label="Excluded SKUs"
                      badgeTheme="red"
                      value={excludedSkus}
                      onChange={handleExcludedSkusChanges}
                    />
                  </Col>
                  <Col>
                    <Form.Group id="account-number">
                      <Form.Label htmlFor="account-number-ctrl">Account Number</Form.Label>
                      <Form.Control
                        id="account-number-ctrl"
                        type="text"
                        value={accountNum}
                        onChange={e => setAccountNum(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group id="search-text">
                      <Form.Label htmlFor="search-text-ctrl">Search Text</Form.Label>
                      <Form.Control
                        id="search-text-ctrl"
                        type="text"
                        value={searchText}
                        onChange={e => setSearchText(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Row>
                      <Col>
                        <Form.Group id="slot-qty">
                          <Form.Label htmlFor="slot-qty-ctrl">Slot Quantity</Form.Label>
                          <Form.Control
                            id="slot-qty-ctrl"
                            type="number"
                            min={1}
                            value={slots}
                            onChange={e => setSlots(+e.target.value)}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Label htmlFor="slot-qty-ctrl">Disabled Slots Quantity</Form.Label>
                        <Form.Control
                          id="disabled-slot-qty-ctrl"
                          type="number"
                          min={1}
                          value={disabledSlots}
                          onChange={e => setDisabledSlots(+e.target.value)}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    <Form.Group id="variant">
                      <Form.Label htmlFor="variant-ctrl">Variant</Form.Label>
                      <Form.Control
                        id="variant-ctrl"
                        type="text"
                        value={variant}
                        onChange={e => setVariant(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <MultiSelectDropdownBtn
                      id="dropdown-brands"
                      disabled={brands.length === 0}
                      none={true}
                      badges={true}
                      label="Brands"
                      placeholder="Select Brands"
                      badgeTheme="green"
                      items={brands}
                      onClick={setSelectedBrands as unknown as (item?: Item[]) => void}
                    />
                  </Col>
                  <Col>
                    <MultiSelectDropdownBtn
                      id="dropdown-categories"
                      disabled={categories.length === 0}
                      none={true}
                      badges={true}
                      label="Categories"
                      placeholder="Select Categories"
                      badgeTheme="orange"
                      items={categories}
                      onClick={setSelectedCategories as unknown as (item?: Item[]) => void}
                    />
                  </Col>
                  <Col>
                    <SeparatorInputComponent
                      id="recently-viewed-skus"
                      label="Recently viewed SKUs"
                      badgeTheme="gray"
                      value={recentlyViewedSkus}
                      onChange={handleRecentlyViewedSkusChanges}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <SeparatorInputComponent
                      id="cart-items-skus"
                      label="Cart Items SKUs"
                      badgeTheme="gray"
                      value={productsInCart}
                      onChange={handleProductsInCartSkusChanges}
                    />
                  </Col>
                  <Col>
                    <SeparatorInputComponent
                      id="recently-searched"
                      label="Recently searched"
                      badgeTheme="gray"
                      separator=","
                      value={recentlySearched}
                      onChange={handleRecentlySearchedChanges}
                    />
                  </Col>
                  <Col>
                    <DropdownBtn
                      id="dropdown-user-type"
                      disabled={userTypes.length === 0}
                      none={true}
                      label="User Type"
                      placeholder="Select user type"
                      items={userTypes}
                      onClick={item => handleUserTypeChanges((item?.id as RecommendationRequestUserType) ?? undefined)}
                    />
                  </Col>
                </Row>
              </>
            ),
          },
        },
      ]}
    />
  );
};

export default ScenarioTesterParamsForm;
