import React, { useCallback, useState } from 'react';
import useAuth from '../../../resources/auth/auth-hook';
import NavBarComponent from '../../navbar';
import SideNavComponent from '../../side-nav';
import './index.scss';

const AuthenticatedLayout: React.FC = props => {
  const auth = useAuth();
  const [open, setOpen] = useState<boolean>(false);

  const toggleMenu = useCallback(() => {
    setOpen(!open);
  }, [setOpen, open]);

  return (
    <>
      <NavBarComponent />
      <div className="authenticated-aside-main">
        <SideNavComponent open={open} toggleMenu={toggleMenu} onLogout={auth.signOut} />
        <main id="main" onClick={open ? toggleMenu : undefined}>
          {props.children}
        </main>
      </div>
    </>
  );
};

export default AuthenticatedLayout;
