import { LoginCallback } from '@okta/okta-react';
import React from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import LoginComponent from '../routes/login';
import GuestLayout from '../shared/core/guest-layout';

const GuestRouter: React.FC = () => {
  const location = useLocation();

  return (
    <Routes>
      <Route
        path="login"
        element={
          <GuestLayout>
            <LoginComponent />
          </GuestLayout>
        }
      />
      <Route path="login/callback" element={<LoginCallback />} />
      <Route path="*" element={<Navigate to="/login" state={{ from: location }} replace />} />
    </Routes>
  );
};

export default GuestRouter;
