import { apiWrapper } from '../../config/api-wrapper';
import { approvalRequestMapper } from './approval-request-mapper';
import {
  ApprovalItem,
  ApprovalRequest,
  ModelTransitionRequest,
  RequestType,
  ScenarioTransitionRequest,
  ScenarioCancelRequest,
  MerchandisingRulesTransitionRequest,
} from './approval-request-types';
import cacheControlService, { CacheNamesEnum } from '../cache-controll/cache-control.service';
import axios from 'axios';
import { WithAbortFn } from '../../@types/external-api';

class ApprovalRequestService {
  public listScenarioApprovalRequests(req: ApprovalRequest): WithAbortFn<Promise<ApprovalItem[]>> {
    const source = axios.CancelToken.source();

    return {
      promise: apiWrapper.adminApi
        .get('/scenario/version/approvals', { params: req })
        .then(res => approvalRequestMapper(res.data, RequestType.StepViewer)),
      abort: source.cancel,
    };
  }

  public listModelApprovalRequests(req: ApprovalRequest): WithAbortFn<Promise<ApprovalItem[]>> {
    const source = axios.CancelToken.source();

    return {
      promise: apiWrapper.adminApi
        .get('/model-output/version/approvals', { params: req, cancelToken: source.token })
        .then(res => approvalRequestMapper(res.data, RequestType.ManualModel)),
      abort: source.cancel,
    };
  }

  public listMerchRulesApprovalRequests(req: ApprovalRequest): WithAbortFn<Promise<ApprovalItem[]>> {
    const source = axios.CancelToken.source();

    return {
      promise: apiWrapper.adminApi
        .get('/merch-rule/version/approvals', { params: req, cancelToken: source.token })
        .then(res => approvalRequestMapper(res.data, RequestType.MerchandisingRules)),
      abort: source.cancel,
    };
  }

  public scenarioTransitionRequest(req: ScenarioTransitionRequest, scenarioMarketCodes?: string[]): Promise<void> {
    return apiWrapper.adminApi
      .post(`/scenario/${req.scenarioId}/version/${req.id}/transition`, {
        status: req.status,
        decisionReason: req.decisionReason,
      })
      .then(res => {
        scenarioMarketCodes?.flatMap(mktCode => {
          return [
            cacheControlService.resetCache(
              mktCode,
              CacheNamesEnum.SCENARIO_STEP,
              'apr-admin',
              `APPLY SCENARIO VERSION | scenarioId: ${req.scenarioId} | versionId: ${req.id}`,
            ),
            cacheControlService.resetCache(
              mktCode,
              CacheNamesEnum.SCENARIO_SUB_STEP,
              'apr-admin',
              `APPLY SCENARIO VERSION | scenarioId: ${req.scenarioId} | versionId: ${req.id}`,
            ),
          ];
        });
      });
  }

  public modelTransitionRequest(req: ModelTransitionRequest): Promise<void> {
    return apiWrapper.adminApi.post(`model-output/version/${req.modelId}/transition`, {
      status: req.status,
      decisionReason: req.decisionReason,
    });
  }

  public merchandisingRulesTransitionRequest(req: MerchandisingRulesTransitionRequest): Promise<void> {
    return apiWrapper.adminApi.post(`merch-rule/version/${req.approvalId}/transition`, {
      status: req.status,
      decisionReason: req.decisionReason,
    });
  }

  public modelCancelRequest(modelId: number): Promise<void> {
    return apiWrapper.adminApi.post(`model-output/version/${modelId}/cancel-request`);
  }

  public scenarioCancelRequest(req: ScenarioCancelRequest): Promise<void> {
    return apiWrapper.adminApi.post(`/scenario/${req.scenarioId}/version/${req.id}/cancel-request`);
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new ApprovalRequestService();
