import React from 'react';
import { ScenarioParentStep, ScenarioStep } from '../../../../../../resources/scenarios/scenarios-versions-models';
import { isScenarioChildStep } from '../../scenario-steps-helpers';
import DeleteChildStepModal from './delete-child-step-modal';
import DeleteParentStepModal from './delete-parent-step-modal';
import './index.scss';

interface Props {
  isOpen: boolean;
  step: ScenarioParentStep | ScenarioStep;
  onCancel: VoidFunction;
  onDelete: VoidFunction;
}

const DeleteStepModal: React.FC<Props> = props => {
  const { step } = props;

  if (!step) return null;
  else if (isScenarioChildStep(step)) {
    return <DeleteChildStepModal {...props} step={step as ScenarioStep} />;
  } else {
    return <DeleteParentStepModal {...props} step={step as ScenarioParentStep} />;
  }
};

export default DeleteStepModal;
