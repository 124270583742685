import { ScenarioStepsVersionConfiguration } from '../../../../resources/scenarios/scenarios-types';
import {
  NewParentStep,
  NewStep,
  ScenarioParentStep,
  ScenarioStep,
  ScenarioSteps,
} from '../../../../resources/scenarios/scenarios-versions-models';
import { Column, Row } from '../../../../shared/paginated-table';
import { randomFloatId } from '../../../../utils/id.utils';

export const columns: Column[] = [
  {
    id: 'order',
    label: '',
  },
  {
    id: 'executionSeq',
    label: 'Step',
    format: (value: number) => value.toLocaleString(),
  },
  {
    id: 'name',
    label: 'Name',
  },
  {
    id: 'modelName',
    label: 'Model Name',
  },
  {
    id: 'settings',
    label: '',
  },
];

export const beforeChevronColumns = [['order']];

export const isScenarioChildStep = (step: ScenarioParentStep | ScenarioStep | NewStep): boolean => {
  return 'modelName' in step;
};

export const makeParentStepLabel = (step: ScenarioParentStep | NewParentStep) => {
  return (step.subSteps as any[])
    .filter(subStep => !isStepAdderRow(subStep))
    .map(subStep => subStep.name)
    .join(' -> ');
};

export enum StepAdderOrientation {
  Before,
  After,
}

export const isStepAdderRow = (
  item: ScenarioParentStep | Row<{ step: ScenarioParentStep; orientation: StepAdderOrientation }>,
) => {
  return '__meta' in item;
};

const spreadSubsStepRowsWithStepAdderRows = (
  scenarioSteps: ScenarioStep[],
  onStepAdderClick: (step: ScenarioParentStep | ScenarioStep, orientation: StepAdderOrientation) => void,
): Array<ScenarioParentStep | Row<{ step: ScenarioStep; orientation: StepAdderOrientation }>> => {
  const list: Array<ScenarioParentStep | Row> = scenarioSteps.slice(0, 2);

  scenarioSteps.slice(2).forEach(step => {
    list.push(step);
    list.push({
      id: randomFloatId(),
      __meta: {
        className: 'step-adder child',
        onClick: () => onStepAdderClick(step, StepAdderOrientation.After),
      },
    });
  });

  return list;
};

export const spreadStepRowsWithStepAdderRows = (
  scenarioParentSteps: ScenarioSteps,
  onStepAdderClick: (step: ScenarioParentStep | ScenarioStep, orientation: StepAdderOrientation) => void,
): Array<ScenarioParentStep | Row<{ step: ScenarioParentStep; orientation: StepAdderOrientation }>> => {
  const list: Array<ScenarioParentStep | Row> = [];

  const firstStep = scenarioParentSteps[0];

  if (!firstStep) return [];

  list.push({
    id: randomFloatId(),
    __meta: {
      className: 'step-adder parent',
      onClick: () => onStepAdderClick(firstStep, StepAdderOrientation.Before),
    },
  });
  list.push({
    ...firstStep,
    subSteps: spreadSubsStepRowsWithStepAdderRows(firstStep.subSteps, onStepAdderClick),
  });
  list.push({
    id: randomFloatId(),
    __meta: {
      className: 'step-adder parent',
      onClick: () => onStepAdderClick(firstStep, StepAdderOrientation.After),
    },
  });

  scenarioParentSteps.slice(1).forEach(parentStep => {
    list.push({
      ...parentStep,
      subSteps: spreadSubsStepRowsWithStepAdderRows(parentStep.subSteps, onStepAdderClick),
    });
    list.push({
      id: randomFloatId(),
      __meta: {
        className: 'step-adder parent',
        onClick: () => onStepAdderClick(parentStep, StepAdderOrientation.After),
      },
    });
  });

  return list;
};

export const discoverStep = (
  stepId: number,
  source: ScenarioSteps,
): {
  step: ScenarioParentStep | ScenarioStep;
  list: Array<ScenarioParentStep | ScenarioStep>;
} => {
  let step = source.find(parentStep => parentStep.id === stepId);

  if (step) {
    return { step, list: source };
  }

  step = source.find(parentStep => parentStep.subSteps.find(subStep => subStep.id === stepId));

  return {
    step: step!.subSteps.find(subStep => subStep.id === stepId)!,
    list: step!.subSteps,
  };
};

export const createStepVersionConfiguration = (steps: ScenarioSteps): ScenarioStepsVersionConfiguration => {
  return {
    steps: steps.map(
      parentStep =>
        ({
          description: parentStep.name,
          subSteps: parentStep.subSteps.map(subStep => ({
            executorId: subStep.executorId,
          })),
        } as any),
    ),
  };
};
