import axios from 'axios';
import { apiWrapper } from '../../config/api-wrapper';
import env from '../../config/env';
import { ModelOutputRequest } from '../../interface/model';
import { AuthorizedService } from '../../service/authorized.service';
import { ModelOutput, ModelVersion } from './model-output-models';
import { WithAbortFn } from '../../@types/external-api';

class ModelOutputSerice extends AuthorizedService {
  public getManualModelsByMarketIdAndStatus(marketId: number, status: string): Promise<ModelOutput[]> {
    return super.get<ModelOutput[]>('model-output', { params: { marketId: marketId, status: status } });
  }

  public saveManualModel(request: ModelOutputRequest): Promise<ModelOutput[]> {
    return super.post<ModelOutput[], ModelOutputRequest>('model-output', request);
  }

  public editManualModel(request: ModelOutputRequest): Promise<ModelOutput[]> {
    return super.post<ModelOutput[], ModelOutputRequest>('model-output/edit', request);
  }

  public deleteModel(modelId: number): Promise<ModelOutput[]> {
    return apiWrapper.adminApi.delete(`model-output`, { params: { modelId: modelId } });
  }

  public getKeyTypes(): Promise<string[]> {
    return super.get<string[]>('model-output/key-types');
  }

  public getVersionsHistory(marketId: number): Promise<ModelVersion[]> {
    return super.get<ModelVersion[]>('model-output/history', { params: { marketId: marketId } });
  }

  public getDraftModelVersion(marketId: number, modelVersionId?: number): Promise<ModelVersion> {
    return super.get<ModelVersion>('model-output/draft', {
      params: { marketId: marketId, modelVersionId: modelVersionId },
    });
  }

  public discardModelDraftVersion(marketId: number) {
    return apiWrapper.adminApi.delete(`model-output/${marketId}/version`, { params: { marketId: marketId } });
  }

  public sendModelVersionToApproval(
    marketId: number,
    versionId: number,
    title: string,
    description: string,
  ): Promise<string[]> {
    return apiWrapper.adminApi.post(`model-output/${marketId}/approval-request/version/${versionId}`, {
      title: title,
      description: description,
    });
  }

  public checkDraftModelVersion(marketId: number, versionId: number): Promise<string[]> {
    return apiWrapper.adminApi.post(`model-output/${marketId}/${versionId}/draft`, {
      marketId: marketId,
      versionId: versionId,
    });
  }

  public getModelOutputVersionById(modelId: number): WithAbortFn<Promise<ModelOutput[]>> {
    const source = axios.CancelToken.source();

    return {
      promise: apiWrapper.adminApi.get(`model-output/${modelId}`, { cancelToken: source.token }).then(res => res.data),
      abort: source.cancel,
    };
  }
}

const modelOutputSerice = new ModelOutputSerice(env.ADMIN_API_URL);
export default modelOutputSerice;
