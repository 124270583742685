import { Feature, ScopedFeature } from '../../config/features';
import { FeatureMarkets } from '../../config/feature-markets';
import { Authorization, ClaimPermissions, FeaturesMap, ScopedPermission, UnscopedPermission } from './features-types';

// Up until now, these are the same, though it would be here
// that we would be parsing to our recognized markets, if necessary
const marketsMapper = (apiMarkets: any): FeatureMarkets[] => {
  return apiMarkets;
};

export const authorizationMapper = (apiData: any): Authorization => {
  return {
    permissions: permissionsMapper(apiData),
    features: featuresMapper(apiData),
  };
};

export const permissionsMapper = (apiData: any): Array<UnscopedPermission | ScopedPermission> => {
  return [
    ...apiData.scopedPermissions.map(
      (scopedPermission: any) => new ScopedPermission(scopedPermission.name, marketsMapper(scopedPermission.markets)),
    ),
    ...apiData.permissions.map((permissionName: any) => new UnscopedPermission(permissionName)),
  ];
};

export const featuresMapper = (apiData: any): Array<Feature | ScopedFeature> => {
  return [
    ...apiData.scopedFeatures.map((scopedFeature: any) => {
      return new ScopedFeature(scopedFeature.name, scopedFeature.markets);
    }),
    ...apiData.features.map((scopedFeature: any) => {
      return new Feature(scopedFeature.name);
    }),
  ];
};

export const claimsPermissionsMapper = (apiData: any): ClaimPermissions[] => {
  return apiData.claims.map((claim: any) => ({
    name: claim.claimName,
    unscopedPermissions: claim.permissions,
    scopedPermissions: claim.scopedPermissions.map((scopedPermission: any) => ({
      name: scopedPermission.name,
      markets: marketsMapper(scopedPermission.markets),
    })),
  }));
};

export const featuresMapMapper = (apiData: any): FeaturesMap => {
  return apiData;
};
