import React from 'react';
import { Breadcrumbs as MuiBreadCrumbs, Link } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Link as RouterLink } from 'react-router-dom';
import './index.scss';

interface Breadcrumb {
  label: string;
  path: string;
}

interface Props {
  breadcrumbs: Breadcrumb[];
}

const Breadcrumbs: React.FC<Props> = props => {
  const { breadcrumbs } = props;

  return (
    <MuiBreadCrumbs
      className="breadcrumbs"
      separator={<NavigateNextIcon fontSize="small" style={{ color: 'var(--detail-gray)' }} />}>
      {breadcrumbs.map(breadcrumb => (
        <Link color="inherit" component={RouterLink} key={breadcrumb.path} to={breadcrumb.path}>
          {breadcrumb.label}
        </Link>
      ))}
    </MuiBreadCrumbs>
  );
};

export default Breadcrumbs;
