import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { DashboardUniqueSkusRecommended } from '../../../interface/dashboard';
import PaginatedTableComponent, { Column } from '../../../shared/paginated-table';
import './index.css';

type Props = {
  show: boolean;
  onHide: VoidFunction;
  rows?: DashboardUniqueSkusRecommended[];
};

export default function DashboardUniqueSkusModalComponent({ show, onHide, rows = [] }: Props) {
  const [columns] = useState<Column[]>([
    {
      id: 'sku',
      label: 'SKU',
      href: 'url',
    },
    {
      id: 'total',
      label: 'Times Recommended',
      align: 'right',
      format: (value: number) => value.toLocaleString(),
    },
  ]);

  return (
    <Modal className="dashboard-unique-skus-modal" show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Unique SKUs Recommended</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <PaginatedTableComponent
          columns={columns}
          rows={rows}
          rowIdPropName="sku"
          searchable={true}
          sx={{ width: '100%', overflow: 'hidden', borderRadius: 0, maxHeight: 'calc(80vh - 103px)' }}
        />
      </Modal.Body>
    </Modal>
  );
}
