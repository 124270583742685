import axios, { AxiosRequestConfig } from 'axios';

export abstract class BaseService {
  private readonly baseUrl: string;

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  protected get<T>(endpoint: string, config?: AxiosRequestConfig): Promise<T> {
    return axios.get<T>(`${this.baseUrl}/${endpoint}`, config).then(({ data }) => data);
  }

  protected post<T, R>(endpoint: string, request: R, config?: AxiosRequestConfig): Promise<T> {
    return axios.post<T>(`${this.baseUrl}/${endpoint}`, request, config).then(({ data }) => data);
  }
}
