import { useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { CacheInfo } from '../../../interface/cache';
import MetricsTileComponent from '../../../shared/metrics-tile';
import { getDateString } from '../../../utils/date.utils';

type Props = {
  cacheInfo: CacheInfo;
};

export default function CacheReloaderMetricsComponent({ cacheInfo }: Props) {
  const lastLoadAttempt = useMemo(() => getDateString(cacheInfo.lastLoadAttempt), [cacheInfo]);
  return (
    <Row className="text-center">
      <Col>
        <MetricsTileComponent
          id="num-load-success-cache-reloader"
          title="Loads Succeeded"
          formattedData={cacheInfo.numLoadSuccess.toLocaleString()}
        />
      </Col>
      <Col>
        <MetricsTileComponent
          id="last-load-cache-reloader"
          title="Last Load"
          color="#999999"
          fontSize="medium"
          formattedData={lastLoadAttempt}
        />
      </Col>
      <Col>
        <MetricsTileComponent
          id="num-errors-cache-reloader"
          title="Errors"
          formattedData={cacheInfo?.numLoadError.toLocaleString()}
          color="#FF4949"
        />
      </Col>
      <Col>
        <MetricsTileComponent
          id="last-load-error-cache-reloader"
          title="Last Error"
          color="#999999"
          fontSize="medium"
          formattedData={cacheInfo?.lastLoadError}
        />
      </Col>
    </Row>
  );
}
