import { FeatureMarkets } from './feature-markets';

export class Feature {
  constructor(readonly label: string) {}
}

export class ScopedFeature extends Feature {
  constructor(readonly label: string, readonly markets: FeatureMarkets[]) {
    super(label);
  }
}

export enum Features {
  ManageOwnPermissions = 'ManageOwnPermissions',
  DisplayErrorMetrics = 'DisplayErrorMetrics',
  DashboardScreen = 'DashboardScreen',
  ScenariosListScreen = 'ScenariosListScreen',
  ScenarioDetailsScreen = 'ScenarioDetailsScreen',
  CacheReloaderScreen = 'CacheReloaderScreen',
  TestingToolScreen = 'TestingToolScreen',
  ManualModelScreen = 'ManualModelScreen',
  RequestListScreen = 'RequestListScreen',
  ApproveRequests = 'ApproveRequests',
  MerchandisingRulesScreen = 'MerchandisingRulesScreen',
}
