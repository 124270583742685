import { RecommendedProduct } from '../../../interface/recommender';
import InlineNotification from '../../../shared/inline-notification';
import ProductDetailsComponent from '../product-details';
import './index.scss';

type Props = {
  recommendations: RecommendedProduct[];
  rawRecommendations?: RecommendedProduct[];
  marketCode: string;
  className?: string;
};

export default function RecommendedProductsComponent(props: Props) {
  const { rawRecommendations, recommendations, marketCode, className = '' } = props;

  return (
    <div>
      <section className={`products-container ${className}`}>
        {recommendations.map(recommendation => (
          <ProductDetailsComponent
            key={recommendation.sku}
            id={`recommended-product-details-${recommendation.sku}`}
            marketCode={marketCode}
            sku={recommendation.sku}
            recommendation={recommendation}
          />
        ))}
      </section>

      {rawRecommendations?.length && (
        <div className="raw-recommendations">
          <InlineNotification title="Itens below are not showing as a recommendation to users: " description="" />
          <section className={`products-container ${className}`}>
            {rawRecommendations.map(recommendation => (
              <ProductDetailsComponent
                key={recommendation.sku}
                id={`raw-recommended-product-details-${recommendation.sku}`}
                marketCode={marketCode}
                sku={recommendation.sku}
                recommendation={recommendation}
                rawRecommendation
              />
            ))}
          </section>
        </div>
      )}
    </div>
  );
}
