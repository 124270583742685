import React, { useMemo } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { authenticatedRoutes, RouteData } from '../config/routes';
import { FeaturesCtxType } from '../resources/features/features-context';
import useFeatures from '../resources/features/features-hook';
import LoadingScreen from '../routes/loading';
import NotFoundComponent from '../routes/not-found';
import AuthenticatedLayout from '../shared/core/authenticated-layout';
import { resolveLocationFromState } from '../utils/location.utils';
import BreadcrumbsProvider from '../resources/breadcrumbs/breadcrumbs-provider';

const filterAllowedRoutes = (hasFeature: FeaturesCtxType['hasFeature']): RouteData[] => {
  return authenticatedRoutes.filter(route => hasFeature(route.feature));
};

const AuthenticatedRouter: React.FC = () => {
  const location = useLocation();
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { loading: permissionsAreLoading, hasFeature } = useFeatures();
  const allowedRoutes = useMemo(() => filterAllowedRoutes(hasFeature), [hasFeature]);

  if (permissionsAreLoading) {
    return <LoadingScreen />;
  }

  return (
    <BreadcrumbsProvider routes={allowedRoutes}>
      <Routes>
        <Route path="" element={<Navigate to={'/dashboard'} replace />} />
        <Route path="/logout/callback" element={<Navigate to="/dashboard" replace />} />
        {allowedRoutes.map(({ path, element, Layout }) => (
          <Route key={path} path={path} element={<Layout>{element}</Layout>} />
        ))}
        <Route path="login" element={<Navigate to={resolveLocationFromState(location)} replace={true} />} />
        <Route path="login/callback" element={<Navigate to={resolveLocationFromState(location)} replace={true} />} />
        <Route
          path="*"
          element={
            <AuthenticatedLayout>
              <NotFoundComponent />
            </AuthenticatedLayout>
          }
        />
      </Routes>
    </BreadcrumbsProvider>
  );
};

export default AuthenticatedRouter;
