import { mdiBarcode, mdiCodeBrackets, mdiSwapVertical } from '@mdi/js';

export const links = [
  {
    title: 'Manual Model',
    description: 'Change which products are recommended by the Manual Model recommendation step.',
    mdiIconPath: mdiBarcode,
    to: 'manual-model',
  },
  {
    title: 'Container Header',
    description:
      'Map APR containers to dynamic titles that will change based on the user type visiting the e-commerce site.',
    mdiIconPath: mdiCodeBrackets,
    to: 'container-header',
    active: false,
  },
  {
    title: 'Merchandising Rules',
    description:
      'Optimize recommendations to your business goals by creating rules that boost or bury itens based on their brand, category, etc.',
    mdiIconPath: mdiSwapVertical,
    to: 'merchandising-rules',
  },
];
