import React from 'react';
import { Form } from 'react-bootstrap';
import './index.scss';

export interface RadioProps {
  title?: string;
  name: string;
  value: string;
  isChecked?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
}

const Radio: React.FC<RadioProps> = (props: RadioProps) => {
  const { title, name, value, isChecked, onChange } = props;

  return (
    <Form.Check type="radio">
      <Form.Check.Input name={name} id={value} value={value} type="radio" checked={!!isChecked} onChange={onChange} />
      {title && <Form.Check.Label htmlFor={value}>{title}</Form.Check.Label>}
    </Form.Check>
  );
};

export default Radio;
