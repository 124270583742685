import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import env from './env';

class ApiWrapper {
  private readonly adminAxiosInstance: AxiosInstance;
  private readonly axiosInstance: AxiosInstance;

  get api(): AxiosInstance {
    return this.axiosInstance;
  }

  get adminApi(): AxiosInstance {
    return this.adminAxiosInstance;
  }

  // Provisory, we should be using this apiWrapper to make api calls instead
  get requestConfig(): AxiosRequestConfig {
    return {
      headers: {
        Authorization: this.axiosInstance.defaults.headers.common.Authorization,
      },
    };
  }

  constructor(adminApiConfig: AxiosRequestConfig, apiConfig: AxiosRequestConfig) {
    this.adminAxiosInstance = axios.create(adminApiConfig);
    this.axiosInstance = axios.create(apiConfig);
  }

  setAuthToken(token: string): void {
    this.adminAxiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
    this.axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
  }

  clearAuthToken(): void {
    delete this.axiosInstance.defaults.headers.common.Authorization;
    delete this.adminAxiosInstance.defaults.headers.common.Authorization;
  }
}

export const apiWrapper = new ApiWrapper({ baseURL: env.ADMIN_API_URL }, { baseURL: env.API_URL });
