import { useCallback, useEffect, useState } from 'react';
import { ExternalAction, ExternalData } from '../../@types/external-api';
import { Feature, ScopedFeature } from '../../config/features';
import {
  makeExternalCallErrorData,
  makeExternalCallSuccessData,
  makeExternalDataInitialData,
  makeExternalDataSuccessData,
} from '../../helpers/external-data';
import useAuth from '../auth/auth-hook';
import PermissionsContext, { FeaturesCtxType } from './features-context';
import { UnscopedPermission, ScopedPermission, ClaimPermissions, FeaturesMap } from './features-types';
import { displayFeaturesGracefully, displayPermissionsGracefully } from './features-utils';
import permissionsService from './permissions.service';

const FeaturesProvider: React.FC = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const [updatePermissionsStatus, setUpdatePermissionsStatus] = useState<ExternalAction>({});
  const [permissions, setPermissions] = useState<Array<UnscopedPermission | ScopedPermission>>([]);
  const [featuresMap, setFeaturesMap] = useState<ExternalData<FeaturesMap>>(makeExternalDataInitialData());
  const [claimsPermissions, setClaimsPermissions] = useState<ExternalData<ClaimPermissions[]>>(
    makeExternalDataInitialData(),
  );
  const [features, setFeatures] = useState<Array<Feature | ScopedFeature>>([]);
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const [loading, setLoading] = useState<boolean>(true);

  const fetchPermissions = useCallback(() => {
    if (isAuthenticated) {
      setLoading(true);
      const { promise, abort } = permissionsService.getAuthorization();
      void promise
        .then(authorization => {
          const { features, permissions } = authorization;
          setFeatures(features);
          setPermissions(permissions);

          console.warn('---------------------- PERMISSIONS CONFIGURATION ----------------------');
          displayFeaturesGracefully(features);
          displayPermissionsGracefully(permissions);
          console.warn('-----------------------------------------------------------------------');
        })
        .catch(err => {
          console.error(err);
          setPermissions([]);
          setFeatures([]);
        })
        .finally(() => {
          setLoading(false);
        });
      return abort;
    } else {
      setPermissions([]);
      setFeatures([]);
      setLoading(false);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    const abortFn = fetchPermissions();

    return () => {
      abortFn?.();
    };
  }, [fetchPermissions]);

  const hasFeature: FeaturesCtxType['hasFeature'] = useCallback(
    feature => {
      const userFeature = features.find(userFeature => userFeature.label === feature);

      return !!userFeature;
    },
    [features],
  );

  const hasScopedFeature: FeaturesCtxType['hasScopedFeature'] = useCallback(
    feature => {
      const userFeature = features
        .filter(userFeature => userFeature instanceof ScopedFeature)
        .find(userFeature => userFeature.label === feature) as ScopedFeature;

      return [!!userFeature, userFeature?.markets ?? []];
    },
    [features],
  );

  const updatePermissions: FeaturesCtxType['updatePermissions'] = useCallback(
    async permissions => {
      try {
        setUpdatePermissionsStatus(makeExternalDataInitialData);
        await permissionsService.update(permissions);
        fetchPermissions();
        setUpdatePermissionsStatus(makeExternalCallSuccessData);
      } catch (err: any) {
        setUpdatePermissionsStatus(makeExternalCallErrorData(err));
        // errorHandler(err);
      }
    },
    [fetchPermissions],
  );

  const fetchClaimsPermissions: FeaturesCtxType['fetchClaimsPermissions'] = useCallback(async () => {
    try {
      setClaimsPermissions(makeExternalDataInitialData);
      const claimsPermissions = await permissionsService.listClaimsPermissions();
      setClaimsPermissions(makeExternalDataSuccessData(claimsPermissions));
    } catch (err: any) {
      setClaimsPermissions(makeExternalCallErrorData(err));
      // errorHandler(err);
    }
  }, []);

  const fetchFeaturesMap: FeaturesCtxType['fetchFeaturesMap'] = useCallback(async () => {
    try {
      setFeaturesMap(makeExternalDataInitialData);
      const featuresMap = await permissionsService.getFeaturesMap();
      setFeaturesMap(makeExternalDataSuccessData(featuresMap));
    } catch (err: any) {
      setFeaturesMap(makeExternalCallErrorData(err));
      // errorHandler(err);
    }
  }, []);

  return (
    <PermissionsContext.Provider
      value={{
        loading,
        updatePermissionsStatus,
        permissions,
        featuresMap,
        claimsPermissions,
        hasFeature,
        hasScopedFeature,
        updatePermissions,
        fetchClaimsPermissions,
        fetchFeaturesMap,
      }}>
      {children}
    </PermissionsContext.Provider>
  );
};

export default FeaturesProvider;
