import { PropsWithChildren, ReactElement, ValidationMap, WeakValidationMap } from 'react';
import { FeatureMarkets } from '../config/feature-markets';
import { Features } from '../config/features';
import useFeatures from '../resources/features/features-hook';

interface ContextualizedFunctionComponent<P = {}> {
  (props: PropsWithChildren<P>, hasPermission: boolean): ReactElement<any, any> | null;
  propTypes?: WeakValidationMap<P> | undefined;
  contextTypes?: ValidationMap<any> | undefined;
  defaultProps?: Partial<P> | undefined;
  displayName?: string | undefined;
}

interface ScopedContextualizedFunctionComponent<P = {}> {
  (props: PropsWithChildren<P>, hasPermission: boolean, markets: FeatureMarkets[]): ReactElement<any, any> | null;
  propTypes?: WeakValidationMap<P> | undefined;
  contextTypes?: ValidationMap<any> | undefined;
  defaultProps?: Partial<P> | undefined;
  displayName?: string | undefined;
}

export const WithFeaturesProxy =
  <P extends unknown>(resourceFeature: Features) =>
  (applyContext: ContextualizedFunctionComponent<P>): React.FC<P> => {
    return props => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const { hasFeature } = useFeatures();

      return applyContext(props, hasFeature(resourceFeature));
    };
  };

export const WithScopedFeaturesProxy =
  <P extends unknown>(resourceScopedFeature: Features) =>
  (applyContext: ScopedContextualizedFunctionComponent<P>): React.FC<P> => {
    return props => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const { hasScopedFeature } = useFeatures();
      const [hasFeature, markets] = hasScopedFeature(resourceScopedFeature);

      return applyContext(props, hasFeature, markets);
    };
  };
