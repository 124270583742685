export enum UnscopedPermissions {
  ManageOwnPermissions = 'ManageOwnPermissions',
  DisplayErrorMetrics = 'DisplayErrorMetrics',
  ApproveRequests = 'ApproveRequests',
}

export enum ScopedPermissions {
  Dashboard = 'Dashboard',
  ScenariosList = 'ScenariosList',
  CacheReloader = 'CacheReloader',
  TestingTool = 'TestingTool',
}

export type Permissions = UnscopedPermissions | ScopedPermissions;
