import OktaAuth from '@okta/okta-auth-js';
import env from './env';

function createOktaAuthInstance(): OktaAuth {
  return new OktaAuth({
    clientId: env.OKTA.CLIENT_ID,
    issuer: env.OKTA.ISSUER,
    scopes: env.OKTA.SCOPES,
    state: window.location.search,
    pkce: true,
    redirectUri: window.location.origin + '/login/callback',
    postLogoutRedirectUri: window.location.origin + '/logout/callback',
    tokenManager: {
      storage: 'localStorage',
    },
  });
}

export const oktaAuth = createOktaAuthInstance();
