import { useCallback, useState } from 'react';
import { ExternalAction, ExternalData } from '../../@types/external-api';
import {
  makeExternalCallErrorData,
  makeExternalDataInitialData,
  makeExternalDataSuccessData,
} from '../../helpers/external-api';
import { makeExternalCallSuccessData } from '../../helpers/external-data';
import useExternalApiErrorHandler from '../../hooks/use-external-api-error-handler';
import { randomFloatId } from '../../utils/id.utils';
import useToasts from '../toasts/toasts-hook';
import ScenariosContext, { ScenariosContextType } from './scenarios-context';
import { ScenarioDetails, ScenarioDetailsListItem } from './scenarios-models';
import {
  ExecutorType,
  FilterExecutor,
  LoaderExecutor,
  NewParentStep,
  ScenarioSteps,
  ScenarioVersion,
} from './scenarios-versions-models';
import scenariosService from './scenarios.service';

const makeParentSteps = (
  loaderExecutors: LoaderExecutor[],
  defaultFilterExecutors: FilterExecutor[],
): NewParentStep[] => {
  return loaderExecutors.map(loaderExecutor => {
    const subSteps: NewParentStep['subSteps'] = [
      {
        executorId: loaderExecutor.id,
        name: loaderExecutor.name,
        executorDescription: loaderExecutor.description,
        modelName: loaderExecutor.modelName,
        executorType: ExecutorType.LOADER,
        standard: true,
      },
      ...defaultFilterExecutors.map(filterExecutor => ({
        executorId: filterExecutor.id,
        name: filterExecutor.name,
        executorDescription: filterExecutor.description,
        modelName: filterExecutor.modelName,
        executorType: ExecutorType.FILTER,
        standard: true as true,
      })),
    ];

    return {
      id: randomFloatId(),
      subSteps: subSteps,
    };
  });
};

const ScenariosProvider: React.FC = ({ children }) => {
  const { push } = useToasts();
  const errorHandler = useExternalApiErrorHandler();
  const [scenarios, setScenarios] = useState<ExternalData<ScenarioDetailsListItem[]>>({ unstarted: true });
  const [scenarioDetails, setScenarioDetails] = useState<ExternalData<ScenarioDetails>>(makeExternalDataInitialData());
  const [scenarioSteps, setScenarioSteps] = useState<ExternalData<ScenarioSteps>>(makeExternalDataInitialData());
  const [filterSteps, setFilterSteps] = useState<ExternalData<FilterExecutor[]>>(makeExternalDataInitialData());
  const [parentSteps, setParentSteps] = useState<ExternalData<NewParentStep[]>>(makeExternalDataInitialData());
  const [discardScenarioDraftVersionStatus, setDiscardScenarioDraftVersionStatus] = useState<ExternalAction>({});
  const [draftScenarioVersion, setDraftScenarioVersion] = useState<ExternalData<ScenarioVersion>>(
    makeExternalDataInitialData(),
  );
  const [scenarioVersionHistory, setScenarioVersionHistory] = useState<ExternalData<ScenarioVersion[]>>(
    makeExternalDataInitialData(),
  );

  const fetchScenarios: ScenariosContextType['fetchScenarios'] = useCallback(async containersIds => {
    try {
      setScenarios(makeExternalDataInitialData());
      const data = await scenariosService.getScenarioDetailsList(containersIds);
      setScenarios(makeExternalDataSuccessData(data));
    } catch (err: any) {
      setScenarios(makeExternalCallErrorData(err));
    }
  }, []);

  const resetScenarios = useCallback(() => {
    setScenarios({ unstarted: true });
  }, []);

  const fetchScenarioDetails: ScenariosContextType['fetchScenarioDetails'] = useCallback(async scenarioId => {
    try {
      setScenarioDetails(makeExternalDataInitialData());
      const data = await scenariosService.getScenarioDetails(scenarioId);
      setScenarioDetails(makeExternalDataSuccessData(data));
    } catch (err: any) {
      setScenarioDetails(makeExternalCallErrorData(err));
    }
  }, []);

  const fetchScenarioSteps: ScenariosContextType['fetchScenarioSteps'] = useCallback(async scenarioId => {
    try {
      setScenarioSteps(makeExternalDataInitialData());
      const data = await scenariosService.getScenarioSteps(scenarioId);
      setScenarioSteps(makeExternalDataSuccessData(data));
    } catch (err: any) {
      setScenarioSteps(makeExternalCallErrorData(err));
    }
  }, []);

  const fetchFilterSteps: ScenariosContextType['fetchFilterSteps'] = useCallback(async () => {
    try {
      setFilterSteps(makeExternalDataInitialData());
      const data = await scenariosService.getFilterSteps();
      setFilterSteps(makeExternalDataSuccessData(data));
    } catch (err: any) {
      setFilterSteps(makeExternalCallErrorData(err));
    }
  }, []);

  const fetchParentSteps: ScenariosContextType['fetchParentSteps'] = useCallback(async () => {
    try {
      setParentSteps(makeExternalDataInitialData());
      const [loaderSteps, defaultFilterSteps] = await Promise.all([
        scenariosService.getLoaderSteps(),
        scenariosService.getRequiredFilterSteps(),
      ]);
      setParentSteps(makeExternalDataSuccessData(makeParentSteps(loaderSteps, defaultFilterSteps)));
    } catch (err: any) {
      setParentSteps(makeExternalCallErrorData(err));
    }
  }, []);

  const fetchApprovalScenarioVersion: ScenariosContextType['fetchApprovalScenarioVersion'] = useCallback(
    (scenarioId, approvalRequestId) => {
      try {
        setDraftScenarioVersion(makeExternalDataInitialData());
        const { promise, abort } = scenariosService.getApprovalScenarioVersion(scenarioId, approvalRequestId);
        promise.then(data => setDraftScenarioVersion(makeExternalDataSuccessData(data)));
        return abort;
      } catch (err: any) {
        errorHandler(err);
        setDraftScenarioVersion(makeExternalCallErrorData(err));
        throw err;
      }
    },
    [errorHandler],
  );

  const fetchScenarioVersionByVersionId: ScenariosContextType['fetchScenarioVersionByVersionId'] = useCallback(
    async (scenarioId, versionId) => {
      try {
        setDraftScenarioVersion(makeExternalDataInitialData());
        const data = await scenariosService.getScenarioVersionByVersionId(scenarioId, versionId);
        setDraftScenarioVersion(makeExternalDataSuccessData(data));
      } catch (err: any) {
        errorHandler(err);
        throw err;
      }
    },
    [errorHandler],
  );

  const fetchScenarioDraftVersion: ScenariosContextType['fetchScenarioDraftVersion'] = useCallback(
    async scenarioId => {
      try {
        setDraftScenarioVersion(makeExternalDataInitialData());
        const data = await scenariosService.getScenarioDraftVersion(scenarioId);
        setDraftScenarioVersion(makeExternalDataSuccessData(data));
      } catch (err: any) {
        errorHandler(err, {
          // 404 errors are expected here. The first time a user start to edit a scenario.
          // Therefore, we should not be doing anything at all
          404: () => {},
        });
        setDraftScenarioVersion(makeExternalCallErrorData(err));
      }
    },
    [errorHandler],
  );

  const saveScenarioStepsDraftVersion: ScenariosContextType['saveScenarioStepsDraftVersion'] = useCallback(
    async (scenarioId, editedStepList) => {
      try {
        await scenariosService.saveScenarioStepsDraftVersion(scenarioId, editedStepList);
        // push('Scenario saved successfully!');
      } catch (err: any) {
        errorHandler(err);
        throw err;
      }
    },
    [errorHandler],
  );

  const discardScenarioDraftVersion: ScenariosContextType['discardScenarioDraftVersion'] = useCallback(
    async scenarioId => {
      try {
        setDiscardScenarioDraftVersionStatus(makeExternalDataInitialData());
        await scenariosService.discardScenarioDraftVersion(scenarioId);
        fetchScenarioDraftVersion(scenarioId);
        setDiscardScenarioDraftVersionStatus(makeExternalCallSuccessData);
      } catch (err: any) {
        errorHandler(err);
        setDiscardScenarioDraftVersionStatus(makeExternalCallErrorData(err));
      }
    },
    [errorHandler, fetchScenarioDraftVersion],
  );

  const sendScenarioVersionToApproval: ScenariosContextType['sendScenarioVersionToApproval'] = useCallback(
    async (scenarioId, scenarioVersionId, title, description) => {
      try {
        await scenariosService.sendScenarioVersionToApproval(scenarioId, scenarioVersionId, title, description);
        push('Configuration Request sent.');
      } catch (err: any) {
        errorHandler(err);
        throw err;
      }
    },
    [errorHandler, push],
  );

  const fetchScenarioVersionHistory: ScenariosContextType['fetchScenarioVersionHistory'] = useCallback(
    async scenarioId => {
      try {
        setScenarioVersionHistory(makeExternalDataInitialData());
        const scenarioVersions = await scenariosService.getVersionsHistory(scenarioId);
        setScenarioVersionHistory(makeExternalDataSuccessData(scenarioVersions));
      } catch (err: any) {
        errorHandler(err);
        setScenarioVersionHistory(makeExternalCallErrorData(err));
      }
    },
    [errorHandler],
  );

  return (
    <ScenariosContext.Provider
      value={{
        draftScenarioVersion,
        discardScenarioDraftVersionStatus,
        scenarios,
        scenarioDetails,
        scenarioVersionHistory,
        scenarioSteps,
        filterSteps,
        parentSteps,
        fetchScenarios,
        resetScenarios,
        fetchScenarioDetails,
        fetchScenarioSteps,
        fetchFilterSteps,
        fetchParentSteps,
        saveScenarioStepsDraftVersion,
        fetchApprovalScenarioVersion,
        fetchScenarioVersionByVersionId,
        fetchScenarioDraftVersion,
        discardScenarioDraftVersion,
        sendScenarioVersionToApproval,
        fetchScenarioVersionHistory,
      }}>
      {children}
    </ScenariosContext.Provider>
  );
};

export default ScenariosProvider;
