import { apiWrapper } from '../../config/api-wrapper';

export enum CacheNamesEnum {
  SCENARIO_STEP = 'SCENARIO_STEP',
  SCENARIO_SUB_STEP = 'SCENARIO_SUB_STEP',
  SCENARIO = 'SCENARIO',
}

class CacheControlService {
  public resetCache(
    marketCode: string,
    cacheName: CacheNamesEnum,
    changeSource: string,
    description: string,
  ): Promise<void> {
    return apiWrapper.api.post(`${marketCode.toLowerCase()}/api/cache-control`, {
      cacheName,
      description,
      changeSource,
    });
  }
}

const cacheControlService = new CacheControlService();
export default cacheControlService;
