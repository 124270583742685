import useAuth from '../resources/auth/auth-hook';
import AuthenticatedRouter from './authenticated-router';
import GuestRouter from './guest-router';

const Router: React.FC = () => {
  const { isAuthenticated } = useAuth();

  return isAuthenticated ? <AuthenticatedRouter /> : <GuestRouter />;
};

export default Router;
