import { AxiosError, AxiosRequestConfig } from 'axios';
import { apiWrapper } from '../config/api-wrapper';
import { BaseService } from './base.service';

export abstract class AuthorizedService extends BaseService {
  protected get<T>(endpoint: string, config?: AxiosRequestConfig): Promise<T> {
    return super.get<T>(endpoint, { ...apiWrapper.requestConfig, ...config }).catch(error => {
      this.handleUnauthorized<T>(error);

      throw error;
    });
  }

  protected post<T, R>(endpoint: string, request: R, config?: AxiosRequestConfig): Promise<T> {
    return super.post<T, R>(endpoint, request, { ...apiWrapper.requestConfig, ...config }).catch(error => {
      this.handleUnauthorized<T>(error);

      throw error;
    });
  }

  private handleUnauthorized<T>(error: AxiosError<T>): void {
    if (error.isAxiosError && error.response?.status === 401) {
      // authService.signOut(true);
    }
  }
}
