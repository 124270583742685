export const removeDuplicates = <T>(arr: T[]): T[] => {
  return Array.from(new Set(arr));
};

export const swap = <T>(array: T[], i: number, j: number) => {
  let tmp = array[i];
  array[i] = array[j];
  array[j] = tmp;
};

export const moveIfCan = <T>(list: T[], idx: number, dir: -1 | 1): T[] => {
  const nextPosIdx = idx + dir;

  if (nextPosIdx >= 0 && nextPosIdx < list.length) {
    swap(list, idx, nextPosIdx);
  }

  return list;
};
