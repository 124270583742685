import env from '../config/env';
import { Market } from '../interface/market';
import { Product } from '../interface/product';
import { RecommenderRequest, RecommenderResponse } from '../interface/recommender';
import { AuthorizedService } from './authorized.service';

class RecommenderService extends AuthorizedService {
  public getProduct(marketCode: string, sku: string): Promise<Product> {
    return super.get<Product>(`${marketCode.toLowerCase()}/api/product/`, { params: { sku } });
  }

  public getRecommendations(market: Market, request: RecommenderRequest): Promise<RecommenderResponse> {
    return super.post<RecommenderResponse, RecommenderRequest>(`${market.code.toLowerCase()}/api/recommender`, request);
  }
}

const recommenderService = new RecommenderService(env.API_URL);
export default recommenderService;
