import axios from 'axios';
import { WithAbortFn } from '../../@types/external-api';
import { apiWrapper } from './../../config/api-wrapper';
import {
  loaderExecutorsMapper,
  optionalFiltersExecutorsMapper,
  requiredFiltersExecutorsMapper,
  scenarioDetailsMapper,
  scenarioStepsMapper,
  scenarioVersionMapper,
  scenariosMapper,
} from './scenarios-mappers';
import { ScenarioDetails, ScenarioDetailsListItem } from './scenarios-models';
import { ScenarioStepsVersionConfiguration } from './scenarios-types';
import { FilterExecutor, LoaderExecutor, ScenarioSteps, ScenarioVersion } from './scenarios-versions-models';

class ScenariosService {
  public getScenarioDetailsList(containersId: number[]): Promise<ScenarioDetailsListItem[]> {
    return apiWrapper.adminApi
      .get('/scenario/list', {
        params: {
          containerIds: containersId.join(','),
        },
      })
      .then(res => scenariosMapper(res.data));
  }

  public getScenarioDetails(scenarioId: number): Promise<ScenarioDetails> {
    return apiWrapper.adminApi.get(`/scenario/${scenarioId}/details`).then(res => scenarioDetailsMapper(res.data));
  }

  public getScenarioSteps(id: number): Promise<ScenarioSteps> {
    return apiWrapper.adminApi.get(`scenario/${id}/steps`).then(res => scenarioStepsMapper(res.data));
  }

  public getScenarioVersionByVersionId(scenarioId: number, versionId: number): Promise<ScenarioVersion> {
    return apiWrapper.adminApi
      .get(`scenario/${scenarioId}/version/${versionId}`)
      .then(res => scenarioVersionMapper(res.data));
  }

  public getApprovalScenarioVersion(
    scenarioId: number,
    approvalRequestId: number,
  ): WithAbortFn<Promise<ScenarioVersion>> {
    const source = axios.CancelToken.source();

    return {
      promise: apiWrapper.adminApi
        .get(`scenario/${scenarioId}/version/${approvalRequestId}/approval`, { cancelToken: source.token })
        .then(res => scenarioVersionMapper(res.data)),
      abort: source.cancel,
    };
  }

  public getScenarioDraftVersion(scenarioId: number): Promise<ScenarioVersion> {
    return apiWrapper.adminApi.get(`scenario/${scenarioId}/version`).then(res => scenarioVersionMapper(res.data));
  }

  public saveScenarioStepsDraftVersion(scenarioId: number, version: ScenarioStepsVersionConfiguration): Promise<void> {
    return apiWrapper.adminApi.post(`scenario/${scenarioId}/version`, version);
  }

  public discardScenarioDraftVersion(scenarioId: number) {
    return apiWrapper.adminApi.delete(`scenario/${scenarioId}/version`);
  }

  public sendScenarioVersionToApproval(
    scenarioId: number,
    scenarioVersionId: number,
    title: string,
    description: string,
  ) {
    return apiWrapper.adminApi.post(`scenario/${scenarioId}/version/${scenarioVersionId}`, {
      title: title,
      description: description,
    });
  }

  public getVersionsHistory(scenarioId: number): Promise<ScenarioVersion[]> {
    return apiWrapper.adminApi
      .get(`scenario/${scenarioId}/version/history`)
      .then(res => res.data)
      .then(data => data.map((it: any) => scenarioVersionMapper(it)));
  }

  public getFilterSteps(): Promise<FilterExecutor[]> {
    return apiWrapper.adminApi
      .get('/executor')
      .then(response => response.data)
      .then(data => optionalFiltersExecutorsMapper(data));
  }

  public getLoaderSteps(): Promise<LoaderExecutor[]> {
    return apiWrapper.adminApi
      .get('/executor')
      .then(response => response.data)
      .then(data => loaderExecutorsMapper(data));
  }

  public getRequiredFilterSteps(): Promise<FilterExecutor[]> {
    return apiWrapper.adminApi
      .get('/executor')
      .then(response => response.data)
      .then(data => requiredFiltersExecutorsMapper(data));
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new ScenariosService();
