import { useCallback, useState } from 'react';
import ApprovalRequestService from './approval-request.service';
import ApprovalRequestContext from './approval-request-context';
import { ExternalData } from '../../@types/external-api';
import { ApprovalItem, ApprovalRequest, RequestType } from './approval-request-types';
import {
  makeExternalCallErrorData,
  makeExternalDataInitialData,
  makeExternalDataSuccessData,
} from '../../helpers/external-data';

const ApprovalRequestProvider: React.FC = ({ children }) => {
  const [approvalRequestList, setApprovalRequestList] = useState<ExternalData<ApprovalItem[]>>(
    makeExternalDataInitialData(),
  );

  const fetchApprovalRequestList = useCallback((req: ApprovalRequest, type?: RequestType, order?: string) => {
    let abortFn: VoidFunction | undefined;
    try {
      setApprovalRequestList(makeExternalDataInitialData);
      let modelPromise: Promise<ApprovalItem[]> | undefined;
      let scenarioPromise: Promise<ApprovalItem[]> | undefined;
      let merchRulesPromise: Promise<ApprovalItem[]> | undefined;
      if (type === RequestType.ManualModel || type === undefined) {
        const { promise, abort } = ApprovalRequestService.listModelApprovalRequests(req);
        modelPromise = promise;
        abortFn = abort;
      }
      if (type === RequestType.StepViewer || type === undefined) {
        const { promise, abort } = ApprovalRequestService.listScenarioApprovalRequests(req);
        scenarioPromise = promise;
        abortFn = abort;
      }
      if (type === RequestType.MerchandisingRules || type === undefined) {
        const { promise, abort } = ApprovalRequestService.listMerchRulesApprovalRequests(req);
        merchRulesPromise = promise;
        abortFn = abort;
      }

      Promise.all([modelPromise, scenarioPromise, merchRulesPromise]).then(
        ([manualModelList, scenariosList, merchandisingRules]) => {
          let completeList = [...(manualModelList ?? []), ...(scenariosList ?? []), ...(merchandisingRules ?? [])].sort(
            (a, b) => b.createdDt.getTime() - a.createdDt.getTime(),
          );
          if (order === 'least') {
            completeList = completeList.reverse();
          }
          setApprovalRequestList(makeExternalDataSuccessData(completeList));
        },
      );
    } catch (err: any) {
      setApprovalRequestList(makeExternalCallErrorData(err));
      // errorHandler(err);
    }

    return abortFn;
  }, []);

  return (
    <ApprovalRequestContext.Provider
      value={{
        approvalRequestList,
        fetchApprovalRequestList,
      }}>
      {children}
    </ApprovalRequestContext.Provider>
  );
};

export default ApprovalRequestProvider;
