export enum ExecutorType {
  LOADER = 'LOADER',
  FILTER = 'FILTER',
  STEPS_EXECUTER = 'STEPS_EXECUTOR',
}

export interface BaseExecutor<T = ExecutorType> {
  id: number;
  name: string;
  type: T;
  modelName: T extends ExecutorType.LOADER ? string : undefined;
  description: string;
}

export interface LoaderExecutor extends BaseExecutor<ExecutorType.LOADER> {}

export interface FilterExecutor extends BaseExecutor<ExecutorType.FILTER> {}

export type Executor = LoaderExecutor | FilterExecutor;

export interface ScenarioStep {
  id: number;
  executorId: number;
  name: string;
  executorType: ExecutorType;
  modelName?: string;
  standard: boolean;
}

export interface ScenarioParentStep {
  id: number;
  name?: string;
  subSteps: ScenarioStep[];
}

export type ScenarioSteps = ScenarioParentStep[];

export enum ScenarioVersionStatus {
  LIVE = 'LIVE',
  LEGACY = 'LEGACY',
  DRAFT = 'DRAFT',
  DISCARDED = 'DISCARDED',
}

export interface ScenarioVersion {
  id: number;
  approvalRequestId: number;
  scenarioId: number;
  steps: ScenarioSteps;
  status: ScenarioVersionStatus;
  requestReason: string;
  createdAt: Date;
  updatedAt: Date;
  createdBy: string;
  updatedBy: string;
}

export interface NewStep {
  executorId: number;
  name: string;
  executorDescription: string;
  executorType: ExecutorType;
  modelName?: string;
  standard: true;
}

export interface NewParentStep {
  id: number;
  subSteps: Array<NewStep>;
}
