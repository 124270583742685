import React from 'react';
import { mdiDotsVertical, mdiClose } from '@mdi/js';
import { Menu as MuiMenu, MenuItem as MuiMenuItem } from '@mui/material';
import IconButton from '../icon-btn';
import './index.scss';

// if the return is true, the menu should close
export type OnItemClickHandler = () => boolean;

export interface Props {
  title: string;
  items: Array<{
    id: string;
    element: JSX.Element;
    onClick: OnItemClickHandler;
  }>;
  close?: VoidFunction;
}

const MenuPopup: React.FC<Props> = props => {
  const { title, items } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onItemClickHandlerWrapper = (onItemClickHandler: OnItemClickHandler) => {
    return () => {
      if (onItemClickHandler()) setAnchorEl(null);
    };
  };

  return (
    <>
      <IconButton mdiIconPath={mdiDotsVertical} onClick={handleClick} size="24px" color="var(--primary)" />
      <MuiMenu
        className="popup-menu-container"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}>
        <header>
          <h6>{title}</h6>
          <IconButton mdiIconPath={mdiClose} onClick={handleClose} size="12px" color="var(--text-black)" />
        </header>
        {items.map(item => (
          <MuiMenuItem key={item.id} onClick={onItemClickHandlerWrapper(item.onClick)}>
            {item.element}
          </MuiMenuItem>
        ))}
      </MuiMenu>
    </>
  );
};

export default MenuPopup;
