import React, { useEffect, useMemo, useState } from 'react';
import DialogLayout from '../../../../shared/dialog-layout';
import Input from '../../../../shared/input';
import Typography from '../../../../shared/typography';
import './index.scss';
import MerchandisingRulesConfigurationMetadata from '../../merchandising-rules-configuration/merchandising-rules-configuration-metadata';
import { queryParamsToFormData } from '../../merchandising-rules-configuration';
import useQueryParams from '../../../../hooks/use-query-params';
import ExternalDataRenderer from '../../../../components/hocs/external-data-renderer';
import useMerchRules from '../../../../resources/merch-rules/merch-rules-hook';

interface Props {
  isOpen: boolean;
  isLoading: boolean;
  onSend: (title: string, description: string) => void;
  onCancel: VoidFunction;
}

const SendToApprovalMerchRulesModal: React.FC<Props> = props => {
  const { isOpen, isLoading, onSend, onCancel } = props;
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [params] = useQueryParams({});
  const urlValues = useMemo(() => queryParamsToFormData(params), [params]);
  const { affectedByMerchRules, fetchAffectedByMerchRules } = useMerchRules();

  const handleConfirmAction = () => {
    onSend(title, description);
  };

  const handleScapeAction = () => {
    onCancel();
  };

  useEffect(() => {
    if (!affectedByMerchRules.data && isOpen) {
      fetchAffectedByMerchRules({
        versionIds: urlValues.selected,
      });
    }
  }, [affectedByMerchRules.data, fetchAffectedByMerchRules, isOpen, urlValues.filterBy, urlValues.selected]);

  return (
    <DialogLayout
      open={isOpen}
      isLoading={isLoading}
      title="Request Changes"
      confirmActionText="SEND REQUEST"
      confirmActionHandler={title && description ? handleConfirmAction : undefined}
      scapeActionText="BACK TO TEST RESULTS"
      scapeActionHandler={handleScapeAction}
      maxWidth="sm"
      variant="outlined">
      <section className="send-to-approval-container">
        <Typography colorVariant="primary">
          You are sending a request for the Merchandising Rules configuration. Check ”Request List” to view or cancel
          your requests or wait for analysis.
        </Typography>
        <div className="mt-4">
          <ExternalDataRenderer
            externalData={affectedByMerchRules}
            makeDataElement={data => <MerchandisingRulesConfigurationMetadata affectedByMerchRules={data} />}
          />
        </div>
        <Input
          title="Title"
          required
          placeholder="Type the title of your request"
          className="mt-4"
          value={title}
          onChange={e => setTitle(e.target.value)}
        />
        <Input
          title="Description"
          required
          placeholder="Type your reason for change request or any other information"
          className="mt-3"
          value={description}
          onChange={e => setDescription(e.target.value)}
        />
      </section>
    </DialogLayout>
  );
};

export default SendToApprovalMerchRulesModal;
