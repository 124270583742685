import { mdiDelete, mdiPencil, mdiPlus } from '@mdi/js';
import Icon from '@mdi/react';
import React from 'react';
import MenuPopup, { OnItemClickHandler } from '../../../../../../shared/menu-popup';

interface Props {
  onEdit: OnItemClickHandler;
  onDelete: OnItemClickHandler;
  onAddChildStep: OnItemClickHandler;
}

interface StepMenuPopupItemProps {
  label: string;
  icon: any;
}

const StepMenuPopupItem: React.FC<StepMenuPopupItemProps> = props => {
  const { label, icon } = props;

  return (
    <span>
      <Icon path={icon} size={0.7} color="var(--text-gray)" className="me-2" />
      {label}
    </span>
  );
};

const ParentStepMenuPopupCell: React.FC<Props> = props => {
  const { onEdit, onDelete, onAddChildStep } = props;

  return (
    <MenuPopup
      title="Step configurations"
      items={[
        {
          id: 'edit',
          onClick: onEdit,
          element: <StepMenuPopupItem icon={mdiPencil} label="Edit" />,
        },
        {
          id: 'delete',
          onClick: onDelete,
          element: <StepMenuPopupItem icon={mdiDelete} label="Delete" />,
        },
        {
          id: 'add-child-step',
          onClick: onAddChildStep,
          element: <StepMenuPopupItem icon={mdiPlus} label="Add New Child-Step" />,
        },
      ]}
    />
  );
};

export default ParentStepMenuPopupCell;
