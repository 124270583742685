import { ExternalData } from './../../@types/external-api';
import { createContext } from 'react';
import { ExternalAction } from '../../@types/external-api';
import { FeatureMarkets } from '../../config/feature-markets';
import { Features } from './../../config/features';
import { UnscopedPermission, ScopedPermission, ClaimPermissions, FeaturesMap } from './features-types';

export interface FeaturesCtxType {
  loading: boolean;
  updatePermissionsStatus: ExternalAction;
  featuresMap: ExternalData<FeaturesMap>;
  permissions: Array<UnscopedPermission | ScopedPermission>;
  claimsPermissions: ExternalData<ClaimPermissions[]>;
  hasFeature: (feature: Features) => boolean;
  hasScopedFeature: (feature: Features) => [hasFeatureAccess: boolean, markets: FeatureMarkets[]];
  // These features should be enabled only on QA/DEV environments
  updatePermissions: (permissions: Array<UnscopedPermission | ScopedPermission>) => void;
  fetchClaimsPermissions: () => void;
  fetchFeaturesMap: () => void;
}

const PermissionsContext = createContext<FeaturesCtxType>(null as any);

export default PermissionsContext;
