import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { Market } from '../../../../../interface/market';
import { OnModelFunction } from '../../../../../interface/model';
import { RecommendedProduct } from '../../../../../interface/recommender';
import useModels from '../../../../../resources/model-output/model-output-hook';
import recommenderService from '../../../../../service/recommender.service';
import DialogLayout from '../../../../../shared/dialog-layout';
import DropdownBtn, { Item } from '../../../../../shared/dropdown-btn';
import IconButton from '../../../../../shared/icon-btn';
import SeparatorInputComponent from '../../../../../shared/separator-input';
import ProductsComponent from '../../../../scenario-tester/products';
import './index.scss';
import adminService from '../../../../../service/admin.service';
import useToasts from '../../../../../resources/toasts/toasts-hook';
import { Brand } from '../../../../../interface/brand';
import { Category } from '../../../../../interface/category';

interface Props {
  market: Market;
  keyTypes: Item[];
  selectedModel?: number;
  isOpen: boolean;
  onAddModel: OnModelFunction;
  onEditModel?: OnModelFunction;
  onCancel: VoidFunction;
}

function AddNewModelModal(props: Props) {
  const { market, keyTypes, selectedModel, isOpen, onAddModel, onEditModel, onCancel } = props;
  const [subtitle, setSubtitle] = useState<string>('add a new');
  const [inputSkus, setInputSkus] = useState<string[]>([]);
  const [validSkus, setValidSkus] = useState<string[]>([]);
  const [invalidSkus, setInvalidSkus] = useState<string[]>([]);
  const [isValid, setIsValid] = useState<boolean>(false);
  const [keys, setKeys] = useState<string>('');
  const [selectedKeyType, setSelectedKeyType] = useState<Item>();
  const [recommendations] = useState<RecommendedProduct[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const { models: modelsData } = useModels();
  const [brands, setBrands] = useState<Item[]>([]);
  const [selectedBrand, setSelectedBrand] = useState<Brand | undefined>(undefined);
  const [categories, setCategories] = useState<Item[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<Category>();

  const { push: pushToast } = useToasts();

  useEffect(() => {
    if (market?.id) {
      loadBrands(market.id!);
      loadCategories(market.id!);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [market?.id]);

  const handleInputSkusChanges = useCallback(
    newSkus => {
      if (newSkus === inputSkus) return;

      setInputSkus(newSkus);

      if (newSkus.length !== 0) {
        let sku = newSkus[newSkus.length - 1];
        // only fetch SKU if not fetched yet
        if (!validSkus.some(validSku => validSku === sku) && !invalidSkus.some(invalidSku => invalidSku === sku)) {
          recommenderService
            .getProduct(market.code!, sku)
            .then(res => {
              if (res) {
                setValidSkus(prev => [...prev, sku]);
              }
            })
            .catch(err => {
              console.error(err);
              setInvalidSkus(prev => [...prev, sku]);
            });
        } else {
          setIsValid(false);
        }
      }
    },
    [inputSkus, invalidSkus, market?.code, validSkus],
  );

  const clearForm = useCallback(() => {
    setInputSkus([]);
    setKeys('');
    setSelectedKeyType(undefined);
  }, []);

  useEffect(() => {
    if (inputSkus.length !== 0 && !inputSkus.some(sku => invalidSkus.includes(sku))) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [inputSkus, invalidSkus, validSkus]);

  /**
   * If selectedModel is present, then it's a Edit Model dialog
   */
  useEffect(() => {
    if (selectedModel && isOpen) {
      setSubtitle('edit');
      const modelData = modelsData.data?.find(model => model.id === selectedModel);
      if (modelData) {
        setInputSkus(modelData.productList || []);
        setKeys(modelData.key);
        const keyType = keyTypes.find(keyType => keyType.label === modelData.keyType);
        if (keyType) {
          setSelectedKeyType(keyType);
        }
      }
    } else {
      setSubtitle('add a new');
      setInputSkus([]);
    }
  }, [isOpen, keyTypes, modelsData.data, selectedModel]);

  const selectKeyType = useCallback((keyType: Item) => {
    if (keyType) {
      setSelectedKeyType(keyType);
    } else {
      setSelectedKeyType(undefined);
    }
  }, []);

  const handleKeyChanges = useCallback(newKeys => {
    setKeys(newKeys);
  }, []);

  useEffect(() => {
    if (selectedBrand) {
      handleKeyChanges(selectedBrand.brandId);
    } else if (selectedCategory) {
      handleKeyChanges(selectedCategory.categoryId);
    }
  }, [handleKeyChanges, selectedBrand, selectedCategory]);

  const recommendationsStyle = useMemo(
    () => ({
      maxWidth: recommendations.length * (240 + 117) + 'px',
    }),
    [recommendations],
  );

  const toggle = () => {
    setOpen(!open);
  };

  const handleAddNewModel = () => {
    onAddModel(selectedKeyType?.label || '', keys, inputSkus);
    clearForm();
  };

  const handleEditModel = () => {
    onEditModel!(selectedKeyType?.label || '', keys, inputSkus);
    clearForm();
  };

  const handleCancel = () => {
    setOpen(false);
    clearForm();
    onCancel();
  };

  const loadBrands = useCallback(
    (marketId: number) => {
      adminService
        .getBrands(marketId)
        .then(response => {
          response.forEach(page => {
            (page as unknown as Item).id = page.brandId;
            (page as unknown as Item).label = page.name;
          });
          setBrands(response as unknown as Item[]);
        })
        .catch(err => {
          console.error(err);
          pushToast('Could not fetch brands', 'error');
        });
    },
    [pushToast],
  );

  const loadCategories = useCallback(
    (marketId: number) => {
      adminService
        .getCategories(marketId)
        .then(response => {
          response.forEach(page => {
            (page as unknown as Item).label = page.categoryName;
          });
          setCategories(response as unknown as Item[]);
        })
        .catch(err => {
          console.error(err);
          pushToast('Could not fetch categories', 'error');
        });
    },
    [pushToast],
  );

  return (
    <DialogLayout
      open={isOpen}
      title={selectedModel ? 'Edit Model' : 'New Model'}
      subtitle={`Fill the inputs below to ${subtitle} model in ${market?.name} market.`}
      className="new-model-dialog"
      scapeActionText={'CANCEL'}
      scapeActionHandler={handleCancel}
      confirmActionText={selectedModel ? 'EDIT MODEL' : 'ADD MODEL'}
      confirmActionHandler={isValid ? (selectedModel ? handleEditModel : handleAddNewModel) : undefined}>
      <section>
        <Row className="mb-2">
          <Col>
            <DropdownBtn
              id="dropdown-key-type"
              disabled={selectedModel ? true : keyTypes.length === 0}
              none={true}
              label="Key Type"
              placeholder="Select key type"
              items={keyTypes}
              value={selectedKeyType?.id.toString() || undefined}
              onClick={selectKeyType as unknown as (item?: Item | null) => void}
            />
          </Col>
          <Col>
            <Form.Group id="key" className="key">
              <Form.Label htmlFor="key-ctrl">Key</Form.Label>
              {selectedKeyType?.label.toLowerCase() !== 'brand' && selectedKeyType?.label.toLowerCase() !== 'cat' && (
                <Form.Control
                  id="key-ctrl"
                  type="text"
                  disabled={selectedModel ? true : false}
                  value={keys}
                  placeholder="Key"
                  className="key-ctrl"
                  onChange={e => handleKeyChanges(e.target.value)}
                />
              )}
              {selectedKeyType?.label.toLowerCase() === 'brand' && (
                <DropdownBtn
                  id="dropdown-brands"
                  disabled={brands.length === 0}
                  none={false}
                  placeholder="Select Brands"
                  items={brands}
                  onClick={setSelectedBrand as unknown as (item?: Item | null) => void}
                />
              )}
              {selectedKeyType?.label.toLowerCase() === 'cat' && (
                <DropdownBtn
                  id="dropdown-categories"
                  disabled={categories.length === 0}
                  none={false}
                  placeholder="Select Categories"
                  items={categories}
                  onClick={setSelectedCategory as unknown as (item?: Item | null) => void}
                />
              )}
            </Form.Group>
          </Col>
        </Row>
        <SeparatorInputComponent
          id="skus"
          label="SKUs"
          badgeTheme="gray"
          separator=" "
          value={inputSkus}
          validValues={validSkus}
          invalidValues={invalidSkus}
          onChange={handleInputSkusChanges}
        />
        {!isValid && inputSkus.length !== 0 && <div className="error-label">Added non-existent SKU</div>}
        <Row className="products" style={recommendationsStyle}>
          <div className="preview">
            <IconButton aria-label="expand row" size="small" disabled={!inputSkus.length} onClick={toggle}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
            <p>Preview of the products selected as input</p>
          </div>
          {open && <ProductsComponent market={market} skus={inputSkus} className="new-model-products" />}
        </Row>
      </section>
    </DialogLayout>
  );
}

export default AddNewModelModal;
