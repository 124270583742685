import { useCallback, useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { FeatureMarkets } from '../../../config/feature-markets';
import { Features } from '../../../config/features';
import { WithScopedFeaturesProxy } from '../../../helpers/with-features-proxy';
import { Market } from '../../../interface/market';
import useModels from '../../../resources/model-output/model-output-hook';
import useToasts from '../../../resources/toasts/toasts-hook';
import adminService from '../../../service/admin.service';
import DropdownBtn, { Item } from '../../../shared/dropdown-btn';
import SeparatorInputComponent from '../../../shared/separator-input';
import './index.css';

export interface ManualModelFormFields {
  marketId?: number | null;
  market: Market | null;
  marketCode: string | null;
  keyType?: string | null;
  key?: string[] | null;
}

interface ExposedProps {
  onFormChange: (request: ManualModelFormFields) => void;
}

interface Props extends ExposedProps {
  availableMarkets: FeatureMarkets[];
}

const ManualModelFormComponent: React.FC<Props> = props => {
  const { onFormChange, availableMarkets } = props;
  const { push: pushToast } = useToasts();
  const [markets, setMarkets] = useState<Item[]>([]);
  const [keyTypes, setKeyTypes] = useState<Item[]>([]);
  const [keys, setKeys] = useState<string[]>([]);
  const [selectedMarketId, setSelectedMarketId] = useState<number | undefined | null>();
  const [selectedMarket, setSelectedMarket] = useState<Market | undefined | null>();
  const [selectedMarketCode, setSelectedMarketCode] = useState<string | undefined | null>();
  const [selectedKeyType, setSelectedKeyType] = useState<string | undefined | null>();
  const { models: modelsData, fetchModels } = useModels();

  const loadMarkets = useCallback(() => {
    const { promise } = adminService.getMarkets();
    promise
      .then(response => {
        setMarkets(
          response
            .filter(market => availableMarkets.includes(market.code as any))
            .map(market => ({ ...market, label: market.name })),
        );
      })
      .catch(err => {
        console.error(err);
        pushToast('Could not fetch markets', 'error');
      });
  }, [availableMarkets, pushToast]);

  useEffect(() => {
    loadMarkets();
  }, [loadMarkets]);

  const clearForm = useCallback(() => {
    setKeyTypes([]);
    setKeys([]);
  }, []);

  const selectMarket = useCallback(
    (market: Market) => {
      setSelectedMarket(market);
      setSelectedMarketId(market?.id);
      setSelectedMarketCode(market?.code);
      clearForm();

      if (market) {
        fetchModels(market.id, 'LIVE');
      }
    },
    [clearForm, fetchModels],
  );

  const selectKeyType = useCallback((keyType: Item) => {
    if (keyType) {
      setSelectedKeyType(keyType.label);
    } else {
      setSelectedKeyType(undefined);
    }
  }, []);

  const handleKeyChanges = useCallback(newKeys => {
    setKeys(newKeys);
  }, []);

  useEffect(() => {
    if (modelsData.data) {
      setKeyTypes([]);
      const keyTypeArray = Array.from(new Set(modelsData.data.map(modelOutput => modelOutput.keyType)));
      if (
        keyTypeArray.length > 0 &&
        !(keyTypeArray.length === 1 && (keyTypeArray[0] === '' || keyTypeArray[0] === null))
      ) {
        const arrayOfObjects = keyTypeArray.map((keyType, index) => ({ id: index, label: keyType! }));
        setKeyTypes(arrayOfObjects);
      }
    }
  }, [modelsData.data]);

  useEffect(() => {
    // TODO: add debounce time if field being edited is a text control
    onFormChange({
      marketId: selectedMarketId || null,
      market: selectedMarket || null,
      marketCode: selectedMarketCode || null,
      keyType: selectedKeyType || null,
      key: keys || null,
    });
  }, [onFormChange, selectedMarket, selectedKeyType, keys, selectedMarketId, selectedMarketCode]);

  return (
    <Form className="manual-model-form">
      <Row className="mb-3">
        <Col>
          <DropdownBtn
            id="dropdown-market"
            required={true}
            disabled={markets.length === 0}
            none={true}
            label="Market"
            placeholder="Select Market"
            items={markets}
            onClick={selectMarket as unknown as (item?: Item | null) => void}
          />
        </Col>
        <Col>
          <DropdownBtn
            id="dropdown-key-type"
            disabled={keyTypes.length === 0}
            none={true}
            label="Key Type"
            placeholder="Select key type"
            items={keyTypes}
            onClick={selectKeyType as unknown as (item?: Item | null) => void}
          />
        </Col>
        <Col>
          <SeparatorInputComponent id="keys" label="Key" badgeTheme="gray" value={keys} onChange={handleKeyChanges} />
        </Col>
      </Row>
    </Form>
  );
};

export default WithScopedFeaturesProxy<ExposedProps>(Features.ManualModelScreen)((props, _, markets) => {
  return <ManualModelFormComponent {...props} availableMarkets={markets} />;
});
