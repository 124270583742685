import { Feature, ScopedFeature } from '../../config/features';
import { FeatureMarkets } from '../../config/feature-markets';

export class UnscopedPermission {
  constructor(readonly name: string) {}
}

export class ScopedPermission {
  constructor(readonly name: string, readonly markets: FeatureMarkets[]) {}
}

export interface ClaimPermissions {
  name: string;
  unscopedPermissions: string[];
  scopedPermissions: Array<{
    name: string;
    markets: FeatureMarkets[];
  }>;
}

export interface FeaturesMap {
  unscoped: Record<string, string[]>;
  scoped: Record<string, string[]>;
}

export interface Authorization {
  permissions: Array<UnscopedPermission | ScopedPermission>;
  features: Array<Feature | ScopedFeature>;
}
