import { useCallback, useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { Cache, CacheInfo } from '../../interface/cache';
import { Market } from '../../interface/market';
import cacheService from '../../service/cache.service';
import PaginatedTableComponent, { Column } from '../../shared/paginated-table';
import CacheReloaderFormContainerComponent from './cache-reloader-form-container';
import './index.css';

export default function CacheReloaderComponent() {
  const [columns, setColumns] = useState<Column[]>([]);
  const [cacheInfo, setCacheInfo] = useState<CacheInfo | undefined | null>();
  const [cacheContentItems, setCacheContentItems] = useState<any[]>([]);

  const getCacheData = useCallback((market: Market, cache: Cache) => {
    cacheService.getCacheDetails(market, cache).then(response => {
      const { info, items } = response;
      const [first] = items;

      if (first) {
        makeDinamicColumns(Object.keys(first));
      }

      if (items) {
        setCacheContentItems(items);
      }

      setCacheInfo(info);
    });
  }, []);

  const makeDinamicColumns = (columnsName: string[]) => {
    const columns: Column[] = columnsName.map((column: string) => ({
      id: column,
      label: column,
      maxWidth: 15,
    }));
    setColumns(columns);
  };

  return (
    <Container className="cache-reloader">
      <Card>
        <Card.Body>
          <Row>
            <Col>
              <Card.Title>Cache Reloader</Card.Title>
            </Col>
          </Row>
          <Card.Text>Update cache and APR changes.</Card.Text>
          <CacheReloaderFormContainerComponent cacheInfo={cacheInfo} getCacheData={getCacheData} />
        </Card.Body>
      </Card>
      {cacheContentItems.length > 0 && (
        <Card>
          <Card.Body>
            <Row>
              <Col>
                <Card.Title>Preview</Card.Title>
              </Col>
            </Row>
            <Row>
              <Col className="justify-content-center">
                <PaginatedTableComponent
                  columns={columns}
                  rows={cacheContentItems}
                  rowIdPropName="id"
                  sx={{ maxWidth: 'calc(100vw - 307px - 2*24px - 32px - 38px - 19px)' }}
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      )}
    </Container>
  );
}
