export const attributes = [
  {
    id: 'sku',
    label: 'SKU',
  },
  {
    id: 'category',
    label: 'Category',
  },
  {
    id: 'brand',
    label: 'Brand',
  },
];

export const operators = [
  {
    id: 'boost',
    label: 'Boost',
  },
  {
    id: 'bury',
    label: 'Bury',
  },
];
