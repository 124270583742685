import { Typography } from '@amway/react-components';
import { Card, Col, Container, Row as RowBs } from 'react-bootstrap';
import './index.scss';
import MerchandisingRulesConfigurationForm from './merchandising-rules-configuration-form';
import MerchandisingRulesConfigurationMetadata from './merchandising-rules-configuration-metadata';
import EditableRulesTable from './editable-rules-table';
import {
  InitialTableListRules,
  MerchRuleConfig,
  MerchRuleVersionType,
} from '../../../resources/merch-rules/merch-rules-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  EditorMultipleItemsCell,
  EditorMultipleItemsClearCell,
} from './editable-rules-table/editor-multiple-items-cell';
import useQueryParams, { QueryParams } from '../../../hooks/use-query-params';
import { MerchandisingRulesFormFields } from '../merchandising-rules-form';
import { valueToArray } from '../../scenario-list';
import MerchandisingRulesConfigurationMultipleRulesModal from './merchandising-rules-configuration-multiple-rules-modal';
import useMerchRules from '../../../resources/merch-rules/merch-rules-hook';
import ExternalDataRenderer from '../../../components/hocs/external-data-renderer';
import merchRulesService from '../../../resources/merch-rules/merch-rules.service';
import { useNavigate } from 'react-router-dom';
import useToasts from '../../../resources/toasts/toasts-hook';
import Breadcrumbs from '../../../shared/ui/breadcrumbs';
import useBreadcrumbs from '../../../resources/breadcrumbs/breadcrumbs-hook';

export enum SavingStatus {
  OFF,
  AUTOMATIC,
  MANUAL,
}

export const MULTIPLE_RULES_BLOCK_IDENTIFIER = -1 as const;

export const queryParamsToFormData = (queryParams: QueryParams): MerchandisingRulesFormFields => {
  return {
    filterBy: queryParams.filterBy ? String(queryParams.filterBy) : undefined,
    market: queryParams.market ? String(queryParams.market) : undefined,
    channels: valueToArray(queryParams.channels),
    pages: valueToArray(queryParams.pages),
    selected: valueToArray(queryParams.selected),
  };
};

export default function MerchandisingRulesConfiguration() {
  const [savingStatus, setSavingStatus] = useState<SavingStatus>(SavingStatus.OFF);
  const [editingMerchRules, setEditingMerchRules] = useState<MerchRuleConfig[]>([]);
  const [multipleRulesEnabled, setMultipleRulesEnabled] = useState<boolean>(true);
  const [params] = useQueryParams({});
  const urlValues = useMemo(() => queryParamsToFormData(params), [params]);
  const [isMultRulesModalOpen, setIsMultRulesModalOpen] = useState<boolean>(false);
  const [deletedMerchRules, setDeletedMerchRules] = useState<String[]>([]);
  const { fetchRules, rules, resetRules, affectedByMerchRules, fetchAffectedByMerchRules, resetToLiveRules } =
    useMerchRules();
  const navigate = useNavigate();
  const { push: pushToast } = useToasts();
  const { breadcrumbs } = useBreadcrumbs();

  const fetchSelectedRules = useCallback(() => {
    fetchRules({
      type: urlValues.filterBy?.toLocaleLowerCase() as any,
      containerIds: urlValues.filterBy?.toLocaleLowerCase() === 'container' ? urlValues.selected : undefined,
      productIds: urlValues.filterBy?.toLocaleLowerCase() === 'sku' ? urlValues.selected : undefined,
    });
  }, [fetchRules, urlValues.filterBy, urlValues.selected]);

  useEffect(() => {
    resetRules();
  }, [resetRules]);

  useEffect(() => {
    fetchSelectedRules();
    fetchAffectedByMerchRules({
      containerIds: urlValues.filterBy?.toLocaleLowerCase() === 'container' ? urlValues.selected : undefined,
      skuIds: urlValues.filterBy?.toLocaleLowerCase() === 'sku' ? urlValues.selected : undefined,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Add multiple rules row
  const editedMerchRules = useMemo(() => {
    return editingMerchRules.map(rule => {
      if (rule.id === MULTIPLE_RULES_BLOCK_IDENTIFIER) {
        return {
          ...rule,
          rule: (
            <EditorMultipleItemsCell
              onClick={() => setIsMultRulesModalOpen(true)}
              multipleRulesEnabled={multipleRulesEnabled}
            />
          ),
          settings: (
            <EditorMultipleItemsClearCell
              multipleRulesEnabled={multipleRulesEnabled}
              onClick={() => setMultipleRulesEnabled(prev => !prev)}
            />
          ),
        };
      }
      return {
        ...rule,
        rule: Array.isArray(rule.rule) ? rule.rule : [rule.rule],
      };
    });
  }, [editingMerchRules, multipleRulesEnabled]);

  const formatDataRules = useCallback((data: InitialTableListRules[]) => {
    const merchRulesRows: MerchRuleConfig[] = [];

    data.forEach(rule => {
      if (rule.rules) merchRulesRows.push(...rule.rules);
    });
    return merchRulesRows;
  }, []);

  // This row is used to show the multiple row cell in the table
  const multipleRulesRow: MerchRuleConfig = useMemo(
    () => ({
      id: MULTIPLE_RULES_BLOCK_IDENTIFIER,
      priority: 1,
      attribute: 'sku',
      value: '',
      operation: 'boost',
      status: 'ACTIVE',
    }),
    [],
  );

  useEffect(() => {
    const merchRulesRows: MerchRuleConfig[] = [];
    if (urlValues.selected && urlValues.selected?.length > 1) {
      merchRulesRows.unshift(multipleRulesRow);
    } else if (rules.data) {
      merchRulesRows.push(...formatDataRules(rules.data));
    }
    setEditingMerchRules(merchRulesRows);
  }, [formatDataRules, multipleRulesRow, rules.data, urlValues.selected]);

  const handleGoToTests = useCallback(
    (versionIds: number[]) => {
      navigate(
        `/admin-configurations/merchandising-rules/edit/test?selected=${versionIds.join()}&type=${urlValues.filterBy}`,
      );
    },
    [navigate, urlValues.filterBy],
  );

  const handleSave = useCallback(
    (editingMerchRules: MerchRuleConfig[]) => {
      setSavingStatus(SavingStatus.AUTOMATIC);
      merchRulesService
        .saveRulesAsDraft(
          (urlValues.selected ?? []).map(item => Number(item)),
          urlValues.filterBy?.toUpperCase() as MerchRuleVersionType,
          {
            // Its needed to drop the row with the multiple rules cell
            merchRuleConfig: editingMerchRules,
            deletedMerchRuleIds: deletedMerchRules,
            useLiveVersionRules: multipleRulesEnabled,
          },
        )
        .then(data => {
          pushToast('Draft of the rules saved successfully');
          setDeletedMerchRules([]);
          handleGoToTests(data);
        })
        .catch(() => {
          pushToast("Couldn't save draft of the rules", 'error');
          setSavingStatus(SavingStatus.OFF);
        })
        .finally(() => {
          setSavingStatus(SavingStatus.OFF);
        });
    },
    [deletedMerchRules, handleGoToTests, multipleRulesEnabled, pushToast, urlValues.filterBy, urlValues.selected],
  );

  const handleResetChanges = useCallback(() => {
    setDeletedMerchRules?.([]);
    if (urlValues.selected && urlValues.selected?.length > 1) {
      setEditingMerchRules?.([multipleRulesRow]);
      // TODO: Reset to live version and delete user draft
    } else if (urlValues.selected && rules.data) {
      resetToLiveRules(urlValues.selected[0], urlValues.filterBy?.toUpperCase() as MerchRuleVersionType);
      // setEditingMerchRules?.([...formatDataRules(rules.data)]);
    }
  }, [multipleRulesRow, resetToLiveRules, rules.data, urlValues.filterBy, urlValues.selected]);

  return (
    <Container className="merchandising-rules-configuration">
      <Card>
        <Card.Body>
          <RowBs className="mb-3">
            <Breadcrumbs breadcrumbs={breadcrumbs} />
          </RowBs>
          <RowBs>
            <Col>
              <Typography variant="heading">Merchandising Rules Configuration</Typography>
              <Typography weight="bold" color="text-gray" className="mt-3">
                Optimize recommendations to your business goals by creating rules that boost or bury itens based on
                their brand, category, etc.
              </Typography>
            </Col>
          </RowBs>
          <div className="mt-5">
            <ExternalDataRenderer
              externalData={affectedByMerchRules}
              makeDataElement={data => <MerchandisingRulesConfigurationMetadata affectedByMerchRules={data} />}
            />
          </div>
          <RowBs className="mb-4">
            <Typography weight="bold" color="primary">
              1. Add rules
            </Typography>
          </RowBs>
          <MerchandisingRulesConfigurationForm
            setEditingMerchRules={setEditingMerchRules}
            marketId={Number(urlValues.market)}
          />
          <RowBs className="mb-4 mt-4">
            <Typography weight="bold" color="primary">
              2. Rules list
            </Typography>
          </RowBs>
          <ExternalDataRenderer
            externalData={rules}
            makeDataElement={() => (
              <EditableRulesTable
                handleResetChanges={handleResetChanges}
                editingMerchRules={editedMerchRules}
                setEditingMerchRules={setEditingMerchRules}
                setDeletedMerchRules={setDeletedMerchRules}
                handleGoToTests={() => handleSave(editingMerchRules)}
                isSaving={[SavingStatus.MANUAL, SavingStatus.AUTOMATIC].includes(savingStatus)}
              />
            )}
          />
        </Card.Body>
      </Card>
      <MerchandisingRulesConfigurationMultipleRulesModal
        isOpen={isMultRulesModalOpen}
        onCancel={() => setIsMultRulesModalOpen(false)}
        type={urlValues.filterBy ?? ''}
        itemIds={urlValues.selected ?? []}
      />
    </Container>
  );
}
