import { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { Features } from '../../../config/features';
import { FeatureMarkets } from '../../../config/feature-markets';
import { WithScopedFeaturesProxy } from '../../../helpers/with-features-proxy';
import { Cache, CacheInfo, CacheReloadRequest, LastCacheSended } from '../../../interface/cache';
import { Market } from '../../../interface/market';
import useAuth from '../../../resources/auth/auth-hook';
import useToasts from '../../../resources/toasts/toasts-hook';
import adminService from '../../../service/admin.service';
import cacheService from '../../../service/cache.service';
import DropdownBtn, { Item } from '../../../shared/dropdown-btn';
import { compareDates } from '../../../utils/date.utils';
import './index.css';

interface ExposedProps {
  cacheInfo?: CacheInfo | null;
  getCacheData: (market: Market, cache: Cache) => void;
  onSubmit: (market: Market, request: CacheReloadRequest) => void;
}

interface Props extends ExposedProps {
  availableMarkets: FeatureMarkets[];
}

const CacheReloaderFormComponent: React.FC<Props> = props => {
  const { cacheInfo, getCacheData, onSubmit, availableMarkets, children } = props;
  const auth = useAuth();
  const { push: pushToast } = useToasts();
  const [markets, setMarkets] = useState<Item[]>([]);
  const [caches, setCaches] = useState<Item[]>([]);
  const [market, setMarket] = useState<Market | undefined | null>();
  const [cache, setCache] = useState<Cache | undefined | null>();
  const [lastCacheSended, setLastCacheSended] = useState<LastCacheSended | undefined | null>();
  const [source] = useState<string>(`${auth.user?.name} <${auth.user?.email}>`);
  const [description, setDescription] = useState<string>('');
  const isValid = useMemo(() => {
    if (market && cache && lastCacheSended && cacheInfo) {
      const { lastCacheInfo, lastMarket, lastCache } = lastCacheSended;
      const { lastLoadSuccess, lastLoadError, loading } = lastCacheInfo;
      if (lastMarket.id === market.id && lastCache.name === cacheInfo.name) {
        const lastLoadErrorHasPassed = lastLoadError ? compareDates(lastLoadError, cacheInfo.lastLoadError) : false;
        const loadTimeHasPassed = compareDates(lastLoadSuccess, cacheInfo.lastLoadSuccess);
        return !!((lastLoadErrorHasPassed || loadTimeHasPassed) && !loading && cache && description && market);
      } else {
        return !!(market && !loading && cache && description);
      }
    }

    return !!(market && cache && description);
  }, [cache, cacheInfo, description, lastCacheSended, market]);

  const updateCaches = useCallback(
    (market: Market) => {
      cacheService
        .getCaches(market)
        .then(response => {
          const responseItem: Item[] = response.map(cache => ({
            label: cache,
            id: cache,
          }));

          setCaches(responseItem);
        })
        .catch(err => {
          setCache(null);
          setCaches([]);

          console.error(err);
          pushToast(`Could not fetch caches for ${market.name} market`, 'error');
        });
    },
    [pushToast],
  );

  const selectMarket = useCallback(
    (market: Market) => {
      if (market) {
        updateCaches(market);
        setMarket(market);
        setDescription('');
      }
    },
    [updateCaches],
  );

  const selectCache = useCallback(
    (item: Item) => {
      if (item && market) {
        const cache: Cache = { name: item.label };
        getCacheData(market, cache);
        setCache(cache);
        setDescription('');
      }
    },
    [getCacheData, market],
  );

  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (market && cache && description && source) {
        const request: CacheReloadRequest = {
          cacheName: cache.name,
          description: description,
          changeSource: source,
        };
        setDescription('');
        onSubmit(market, request);

        if (cacheInfo) {
          const lastCacheSended: LastCacheSended = {
            lastCacheInfo: cacheInfo,
            lastMarket: market,
            lastCache: cache,
          };
          setLastCacheSended(lastCacheSended);
        }
      }
    },
    [market, cache, description, source, cacheInfo, onSubmit],
  );

  const formatwithSeconds = (secRemainder: number) => {
    if (secRemainder !== 0) {
      return ` ${secRemainder}s`;
    }
    return '';
  };

  const lastLoadTimeformatted = useMemo(() => {
    if (cacheInfo?.lastLoadTime) {
      const milisecondsToSeconds = (cacheInfo.lastLoadTime + 60000) / 1000;
      const minutes = Math.trunc(milisecondsToSeconds / 60);
      const secRemainder = Math.round(milisecondsToSeconds % 60);
      const timeFormatted = minutes ? `${minutes}min${formatwithSeconds(secRemainder)}` : `${secRemainder}s`;
      return timeFormatted;
    }
    return '';
  }, [cacheInfo]);

  useEffect(() => {
    const { promise } = adminService.getMarkets();
    promise.then(response => {
      setMarkets(
        response
          .filter(market => availableMarkets.includes(market.code as any))
          .map(market => ({ ...market, label: market.name })),
      );
    });
  }, [availableMarkets]);

  return (
    <Form className="cache-reloader-form" onSubmit={handleSubmit}>
      <Row>
        <Col>
          <Row className="mb-3">
            <DropdownBtn
              id="dropdown-cache-reloader-market"
              required={true}
              label="Market"
              placeholder="Select Market"
              items={markets}
              onClick={selectMarket as unknown as (item?: Item | null) => void}
            />
          </Row>
          <Row className="mb-3">
            <DropdownBtn
              id="dropdown-cache-reloader-cache"
              required={true}
              label="Name"
              placeholder="Select Cache"
              disabled={caches.length === 0}
              items={caches}
              onClick={selectCache as unknown as (item?: Item | null) => void}
            />
          </Row>
          {lastLoadTimeformatted && (
            <Row>
              <Card.Text className="last-load-message fst-italic">
                Cache loading can take a few minutes. (Last load time: {lastLoadTimeformatted})
              </Card.Text>
            </Row>
          )}
        </Col>
        <Col>
          <Row className="mb-3">
            <Form.Group>
              <Form.Label htmlFor="source-ctrl">Source</Form.Label>
              <Form.Control id="source-ctrl" type="text" value={source} readOnly />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group>
              <Form.Label htmlFor="description-ctrl" className="required">
                Description
              </Form.Label>
              <Form.Control
                as="textarea"
                id="description-ctrl"
                required
                value={description}
                rows={5}
                disabled={!cache}
                placeholder="Description"
                onChange={e => setDescription(e.target.value)}
              />
            </Form.Group>
          </Row>
        </Col>
      </Row>
      {children}
      <Row className="submit">
        <Col className="d-flex justify-content-center">
          <Button type="submit" disabled={!isValid}>
            RELOAD CACHE
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default WithScopedFeaturesProxy<ExposedProps>(Features.CacheReloaderScreen)((props, _, markets) => {
  return <CacheReloaderFormComponent {...props} availableMarkets={markets} />;
});
