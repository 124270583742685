import { useMemo } from 'react';
import { ScenarioParentStep } from '../../../../../../resources/scenarios/scenarios-versions-models';
import { makeParentStepLabel } from '../../scenario-steps-helpers';
import './index.scss';

interface Props {
  step: ScenarioParentStep;
}

const isNullOrEmpty = (str?: string) => {
  return !str || str === '';
};

const ParentStepNameCell: React.FC<Props> = props => {
  const { step } = props;
  const parentStepLabel = useMemo(() => makeParentStepLabel(step), [step]);

  return (
    <span
      className="parent-step-name"
      title={parentStepLabel}
      data-label={parentStepLabel}
      data-label-hidden={!step.name || step.name === parentStepLabel}>
      {isNullOrEmpty(step.name) ? parentStepLabel : step.name}
    </span>
  );
};

export default ParentStepNameCell;
