import React from 'react';
import { ExternalData } from '../../@types/external-api';
import ExternalDataRenderer from '../../components/hocs/external-data-renderer';
import { ModelVersion } from '../../resources/model-output/model-output-models';
import { ScenarioVersion, ScenarioVersionStatus } from '../../resources/scenarios/scenarios-versions-models';
import ReadonlyStepsTable from '../../routes/scenario-list/scenario-details-screen/readonly-scenario-steps/readonly-steps-table';
import { getDateString, getRelativeTime } from '../../utils/date.utils';
import Accordion from '../accordion';
import DrawerLayout from '../drawer-layout';
import Subtitle from '../subtitle';
import Typography from '../typography';
import Title from '../ui/title';
import './index.scss';

interface Props {
  title: string;
  subtitle: string;
  isHistoryDrawerOpen: boolean;
  handleCloseHistoryDrawer: VoidFunction;
  scenarioVersionHistory?: ExternalData<ScenarioVersion[]>;
  modelVersionHistory?: ExternalData<ModelVersion[]>;
}

const VersionItemHeader: React.FC<{ version: ScenarioVersion | ModelVersion }> = ({ version }) => {
  let relativeTime = '';
  let dateString = '';
  if ('models' in version) {
    relativeTime = getRelativeTime(new Date(version.createdDt));
    dateString = getDateString(new Date(version.createdDt).toISOString());
  } else {
    relativeTime = getRelativeTime(version.createdAt);
    dateString = getDateString(version.createdAt.toISOString());
  }

  if (version.status === ScenarioVersionStatus.LIVE) {
    return (
      <header className="scenario-version-header">
        <Title sizeVariant="sm">Current version</Title>
        <Typography sizeVariant="sm" colorVariant="primary">
          Requested By {version.createdBy} | Approved by {version.createdBy}
        </Typography>
        <Typography sizeVariant="sm">{relativeTime}</Typography>
      </header>
    );
  }

  return (
    <header className="scenario-version-header">
      <Title sizeVariant="sm">{dateString}</Title>
      <Typography sizeVariant="sm">
        Requested By {version.createdBy} | Approved by {version.createdBy}
      </Typography>
    </header>
  );
};

const VersionItemContainer: React.FC<{ version: ScenarioVersion | ModelVersion }> = ({ version }) => {
  return (
    <article>
      <div className="mb-3">
        <Typography colorVariant="secondary">Request reason: </Typography>
        <Typography colorVariant="primary">{version.requestReason}</Typography>
      </div>
      {'models' in version ? (
        <ReadonlyStepsTable
          models={version.models}
          marketCode={version.marketId?.toString()}
          className="history"
          status={version.status}
          scenarioVersionId={version.id}
        />
      ) : (
        <ReadonlyStepsTable
          scenarioSteps={version.steps}
          className="history"
          status={version.status}
          scenarioVersionId={version.id}
        />
      )}
    </article>
  );
};

const VersionsHistoryDrawer: React.FC<Props> = props => {
  const {
    title,
    subtitle,
    isHistoryDrawerOpen,
    handleCloseHistoryDrawer,
    scenarioVersionHistory,
    modelVersionHistory,
  } = props;

  const generateAccordion = (versions: any) => (
    <Accordion
      className="scenarios-versions-accordion"
      accordionItems={versions.map((version: any) => ({
        key: version.id.toString(),
        data: {
          header: <VersionItemHeader version={version} />,
          content: <VersionItemContainer version={version} />,
          className: `version-item ${version.status}`,
        },
      }))}
    />
  );

  return (
    <DrawerLayout isOpen={isHistoryDrawerOpen} close={handleCloseHistoryDrawer} className="scenario-history-drawer">
      <header>
        <Title colorVariant="primary">{title}</Title>
        <Subtitle sizeVariant="md">{subtitle}</Subtitle>
      </header>
      <section className="fancy-scrollbar">
        {modelVersionHistory && (
          <ExternalDataRenderer externalData={modelVersionHistory!} makeDataElement={generateAccordion} />
        )}
        {scenarioVersionHistory && (
          <ExternalDataRenderer externalData={scenarioVersionHistory!} makeDataElement={generateAccordion} />
        )}
      </section>
    </DrawerLayout>
  );
};

export default VersionsHistoryDrawer;
