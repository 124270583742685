import React from 'react';
import { NewParentStep } from '../../../../../../../resources/scenarios/scenarios-versions-models';
import Radio from '../../../../../../../shared/radio';
import Typography from '../../../../../../../shared/typography';
import { ChildStepViewer } from '../../../components/step-viewer';
import { makeParentStepLabel } from '../../../scenario-steps-helpers';
import './index.scss';

interface Props {
  parentSteps: NewParentStep[];
  selectedStep?: NewParentStep;
  handleSelectedStepChanges: (newStep: NewParentStep) => void;
}

interface ParentStepBlockListItemProps {
  parentStep: NewParentStep;
  checked: boolean;
  onCheck: VoidFunction;
}

const ParentStepBlockListItem: React.FC<ParentStepBlockListItemProps> = props => {
  const { parentStep, checked, onCheck } = props;

  return (
    <article className="parent-step-item-container" onClick={onCheck}>
      <section className="parent-step-item-actions">
        <Radio
          name={'parent-step'}
          isChecked={checked}
          value={parentStep.id.toString()}
          onChange={e => e.target.checked && onCheck()}
        />
      </section>
      <section className="parent-step-item-content">
        <Typography colorVariant="primary">{makeParentStepLabel(parentStep)}</Typography>
        <Typography sizeVariant="sm" className="mt-2">
          {parentStep.subSteps[0].executorDescription}
        </Typography>
        <section className="parent-step-item-substeps">
          {parentStep.subSteps.map(subStep => (
            <ChildStepViewer key={subStep.executorId} step={subStep} />
          ))}
        </section>
      </section>
    </article>
  );
};

function ParentStepBlockListView(props: Props) {
  const { parentSteps, selectedStep, handleSelectedStepChanges } = props;

  return (
    <section className="parent-steps-container fancy-scrollbar">
      {parentSteps.map(parentStep => (
        <ParentStepBlockListItem
          key={parentStep.id}
          parentStep={parentStep}
          checked={parentStep.id === selectedStep?.id}
          onCheck={() => handleSelectedStepChanges(parentStep)}
        />
      ))}
    </section>
  );
}

export default ParentStepBlockListView;
