import { createContext } from 'react';
import { ToastType } from '../../@types/toasts';

export interface PushOptions {
  delay?: number;
  customTitle?: string;
}

export interface ToastContextType {
  push: (text: string, type?: ToastType, options?: PushOptions) => void;
}

const toastContext = createContext<ToastContextType>(null as any);

export default toastContext;
