import { mdiArrowDown, mdiArrowUp } from '@mdi/js';
import IconButton from '../../../../../../shared/icon-btn';
import './index.scss';
import { useMemo } from 'react';
import { isScenarioChildStep, isStepAdderRow } from '../../scenario-steps-helpers';
import { ScenarioParentStep, ScenarioStep } from '../../../../../../resources/scenarios/scenarios-versions-models';

export enum Direction {
  UP,
  DOWN,
}

export interface Props<T = ScenarioParentStep | ScenarioStep> {
  step: T;
  stepList: Array<T>;
  onMove: (stepId: number, dir: Direction) => void;
}

const StepMoverCell: React.FC<Props> = props => {
  const { step, stepList, onMove } = props;
  const stepListWithoutStepAdders = stepList.filter(step => !isStepAdderRow(step));
  const stepIdx = useMemo(
    () => stepListWithoutStepAdders.findIndex(_step => _step.id === step.id),
    [step.id, stepListWithoutStepAdders],
  );
  const cannotMoveDown = useMemo(
    () => stepIdx === stepListWithoutStepAdders.length - 1,
    [stepIdx, stepListWithoutStepAdders.length],
  );
  const cannotMoveUp = useMemo(() => {
    if (stepIdx === 0) return true;

    return isScenarioChildStep(step) && (stepListWithoutStepAdders as ScenarioStep[])[stepIdx - 1].standard;
  }, [step, stepIdx, stepListWithoutStepAdders]);

  return (
    <span className="step-move-cell">
      <IconButton
        onClick={() => onMove(step.id, Direction.UP)}
        mdiIconPath={mdiArrowUp}
        size={'20px'}
        color={cannotMoveUp ? 'var(--warning-disable)' : 'var(--primary)'}
        disabled={cannotMoveUp}
      />
      <IconButton
        onClick={() => onMove(step.id, Direction.DOWN)}
        mdiIconPath={mdiArrowDown}
        size={'20px'}
        color={cannotMoveDown ? 'var(--warning-disable)' : 'var(--primary)'}
        disabled={cannotMoveDown}
      />
    </span>
  );
};

export default StepMoverCell;
