import { Button, Col, Row } from 'react-bootstrap';
import DatePickerComponent from '../../../shared/datepicker';

type Props = {
  startDate: string;
  setStartDate: (value: string) => void;
  endDate: string;
  setEndDate: (value: string) => void;
  minDate?: string;
  maxDate: string;
  onSubmit: () => void;
};

export default function DashboardPeriodFormComponent({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  minDate,
  maxDate,
  onSubmit,
}: Props) {
  return (
    <Row className="dates">
      <Col md={2}>
        <DatePickerComponent
          id="startDate"
          label="Start date"
          onChange={setStartDate}
          value={startDate}
          minDate={minDate}
          maxDate={endDate}
        />
      </Col>
      <Col md={2}>
        <DatePickerComponent
          id="endDate"
          label="End date"
          onChange={setEndDate}
          value={endDate}
          minDate={startDate}
          maxDate={maxDate}
        />
      </Col>
      <Col md={3} className="submit">
        <Button onClick={() => onSubmit()}>UPDATE CHARTS</Button>
      </Col>
    </Row>
  );
}
