import React from 'react';
import './index.scss';

interface Props {
  className?: string;
  colorVariant?: 'primary' | 'secondary' | 'tertiary' | 'quaternary';
  sizeVariant?: 'sm' | 'sm-md' | 'md' | 'lg';
  onClick?: VoidFunction;
}

const Title: React.FC<Props> = props => {
  const { colorVariant = 'tertiary', className = '', sizeVariant = 'md', onClick } = props;

  return (
    <h1 onClick={onClick} className={`title ${sizeVariant} ${colorVariant} ${className}`}>
      {props.children}
    </h1>
  );
};

export default Title;
