import { color, Legend, p100, p50, percent, Root, Scrollbar, Tooltip } from '@amcharts/amcharts5';
import { AxisRenderer } from '@amcharts/amcharts5/.internal/charts/xy/axes/AxisRenderer';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import {
  AxisRendererX,
  AxisRendererY,
  ColumnSeries,
  GaplessDateAxis,
  LineSeries,
  ValueAxis,
  XYChart,
  XYCursor,
  XYSeries,
} from '@amcharts/amcharts5/xy';
import { CSSProperties, useCallback, useEffect, useState } from 'react';
import { Features } from '../../../config/features';
import { WithFeaturesProxy } from '../../../helpers/with-features-proxy';
import { DashboardChartPoint } from '../../../interface/dashboard';
import './index.css';

interface Data {
  date: number;
  value: number;
  volume: number;
}

type Props = {
  id: string;
  style: CSSProperties;
  chartPoints: DashboardChartPoint[];
  timeUnit: 'day' | 'hour' | 'minute';
  displayErrors: boolean;
};

type ExposedProps = Omit<Props, 'displayErrors'>;

const DashboardChartComponent: React.FC<Props> = ({ id, style, chartPoints, timeUnit, displayErrors }) => {
  const [root, setRoot] = useState<Root>();
  const [chart, setChart] = useState<XYChart>();
  const [series, setSeries] = useState<LineSeries>();
  const [volumeAxis, setVolumeAxis] = useState<ValueAxis<AxisRenderer>>();
  const [volumeSeries, setVolumeSeries] = useState<ColumnSeries>();
  const [legend, setLegend] = useState<Legend>();
  const [dataSeries, setDataSeries] = useState<Data[]>([]);

  const styleYAxis = (yRenderer: AxisRendererY, series: XYSeries) => {
    yRenderer.grid.template.set('strokeOpacity', 0.05);
    yRenderer.labels.template.set('fill', series.get('fill'));
    yRenderer.setAll({
      stroke: series.get('fill'),
      strokeOpacity: 1,
      opacity: 1,
    });
  };

  const createLineSeries = useCallback(
    (xAxis: GaplessDateAxis<AxisRenderer>, name: string) => {
      return (yAxis: ValueAxis<AxisRenderer>) => {
        if (root && chart) {
          return chart.series.push(
            LineSeries.new(root, {
              name,
              xAxis,
              yAxis,
              valueYField: 'value',
              valueXField: 'date',
              tooltip: Tooltip.new(root, {
                labelText: 'Requests: {valueY}',
              }),
              fill: color('#715FCC'),
              stroke: color('#715FCC'),
              groupDataDisabled: true,
            }),
          );
        }
      };
    },
    [root, chart],
  );

  const createColumnSeries = useCallback(
    (xAxis: GaplessDateAxis<AxisRenderer>, name: string) => {
      return (yAxis: ValueAxis<AxisRenderer>) => {
        if (root && chart) {
          const series = chart.series.push(
            ColumnSeries.new(root, {
              name,
              xAxis: xAxis,
              yAxis,
              valueYField: 'volume',
              valueXField: 'date',
              tooltip: Tooltip.new(root, {
                labelText: 'Errors: {valueY}',
              }),
              fill: color('#FF4949'),
              stroke: color('#FF4949'),
              groupDataDisabled: true,
            }),
          );

          series.columns.template.setAll({
            fillOpacity: 0.9,
            strokeOpacity: 0,
            width: percent(40),
          });

          return series;
        }
      };
    },
    [root, chart],
  );

  const createYAxis = useCallback(
    (createSeries: (yAxis: ValueAxis<AxisRenderer>) => XYSeries | undefined, opposite?: boolean) => {
      if (root && chart) {
        const yAxisRenderer = AxisRendererY.new(root, { opposite });

        const yAxis = chart.yAxes.push(
          ValueAxis.new(root, {
            maxDeviation: 1,
            renderer: yAxisRenderer,
          }),
        );

        if (chart.yAxes.indexOf(yAxis) > 0) {
          yAxis.set('syncWithAxis', chart.yAxes.getIndex(0) as ValueAxis<AxisRenderer>);
        }

        const series = createSeries(yAxis);

        styleYAxis(yAxisRenderer, series as XYSeries);

        return { yAxisRenderer, yAxis, series };
      }

      return {};
    },
    [root, chart],
  );

  const createXAxis = useCallback(() => {
    if (root && chart) {
      const tooltipDateFormat = timeUnit === 'day' ? 'MMM dd' : timeUnit === 'hour' ? "MM/dd HH:'00'" : 'MM/dd HH:mm';

      return chart.xAxes.push(
        GaplessDateAxis.new(root, {
          baseInterval: {
            timeUnit: timeUnit,
            count: 1,
          },
          renderer: AxisRendererX.new(root, {}),
          tooltipDateFormat,
          tooltip: Tooltip.new(root, {}),
        }),
      );
    }

    return {} as GaplessDateAxis<AxisRenderer>;
  }, [root, chart, timeUnit]);

  const createDataSeries = useCallback(() => {
    const dataSeriesDict: { [date: string]: Data } = {};

    chartPoints.forEach(point => {
      const data = dataSeriesDict[point.date];

      if (data) {
        data.value += point.total;

        if (point.status === 'FAILED') {
          data.volume += point.total;
        }
      } else {
        dataSeriesDict[point.date] = {
          date: new Date(point.date).getTime(),
          value: point.total,
          volume: point.status === 'FAILED' ? point.total : 0,
        };
      }
    });

    return Object.values(dataSeriesDict);
  }, [chartPoints]);

  useEffect(() => {
    if (!root) {
      setRoot(Root.new(id));
    }
  }, [id, root]);

  useEffect(() => {
    setDataSeries(createDataSeries());
  }, [createDataSeries]);

  useEffect(() => {
    if (root && !chart && !legend) {
      // Set themes
      root.setThemes([am5themes_Animated.new(root)]);

      // Create chart
      const chart = root.container.children.push(
        XYChart.new(root, {
          panX: true,
          panY: true,
          wheelX: 'panX',
          wheelY: 'zoomX',
        }),
      );

      // Add cursor
      const cursor = chart.set(
        'cursor',
        XYCursor.new(root, {
          behavior: 'none',
        }),
      );
      cursor.lineY.set('visible', false);

      const legend = chart.children.push(
        Legend.new(root, {
          centerX: p50,
          centerY: p50,
          x: p50,
          y: p100,
        }),
      );

      // Add scrollbar
      chart.set(
        'scrollbarX',
        Scrollbar.new(root, {
          orientation: 'horizontal',
        }),
      );

      setChart(chart);
      setLegend(legend);
    }
  }, [root, chart, legend]);

  useEffect(() => {
    if (root && chart && legend && !series && !volumeSeries && !volumeAxis) {
      chart.xAxes.clear();

      const xAxis = createXAxis();

      chart.yAxes.clear();

      // Y axis and series for total APR requests
      const { series } = createYAxis(createLineSeries(xAxis, 'Total Requests'));

      legend.data.clear();
      legend.data.push(series);
      setSeries(series as LineSeries);

      if (displayErrors) {
        // Y axis and series for total APR errors
        const columnAxis = createYAxis(createColumnSeries(xAxis, 'Error Requests'), true);

        legend.data.push(columnAxis.series);

        setVolumeSeries(columnAxis.series as ColumnSeries);
        setVolumeAxis(columnAxis.yAxis);
      }
    }
  }, [
    root,
    chart,
    legend,
    series,
    volumeSeries,
    volumeAxis,
    displayErrors,
    createXAxis,
    createYAxis,
    createLineSeries,
    createColumnSeries,
  ]);

  useEffect(() => {
    if (dataSeries.length && chart && series && (displayErrors ? volumeSeries && volumeAxis : true)) {
      // Set data
      series.data.setAll(dataSeries);

      if (displayErrors && volumeSeries && volumeAxis) {
        volumeSeries.data.setAll(dataSeries);

        if (dataSeries.every(({ volume }) => volume === 0)) {
          volumeAxis.hide();
        } else {
          volumeAxis.show();
        }

        volumeSeries.appear(1000);
      }

      // Make stuff animate on load
      series.appear(1000);
      chart.appear(1000, 100);
    }
  }, [dataSeries, chart, series, volumeSeries, volumeAxis, displayErrors]);

  return <div id={id} className="dashboard-chart" style={style} />;
};

export default WithFeaturesProxy<ExposedProps>(Features.DisplayErrorMetrics)((props, hasPermission) => {
  return <DashboardChartComponent {...props} displayErrors={hasPermission} />;
});
