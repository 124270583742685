export function addDays(date: Date, days: number): Date {
  date.setDate(date.getDate() + days);

  return date;
}

export function subtractDays(date: Date, days: number): Date {
  addDays(date, -days);

  return date;
}

export function toISODateString(date: string): string {
  return date.split('T')[0];
}

export function toISOString(date: Date): string {
  return toISODateString(date.toISOString());
}

export function diffInMinutes(startDate: Date, endDate: Date): number {
  const minInMillis = 60000; // 1000*60
  const startDateTime = startDate.getTime();
  const endDateTime = endDate.getTime();
  return Math.abs(endDateTime - startDateTime) / minInMillis;
}

export function diffInDays(startDate: string, endDate: string): number {
  const dayInMillis = 86400000; // 1000*60*60*24
  const startDateTime = getDateTime(startDate);
  const endDateTime = getDateTime(endDate);
  return Math.abs(endDateTime - startDateTime) / dayInMillis;
}

export function getDateTime(date: string): number {
  return new Date(date).getTime();
}

export function compareDates(prevDate: string, currentDate: string): boolean {
  const prevDateTime = new Date(prevDate);
  const currentDateTime = new Date(currentDate);
  return prevDateTime > currentDateTime;
}

export function getDateString(dateString: string): string {
  const date = new Date(dateString);
  return date.toLocaleString('default', {
    month: 'long',
    year: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });
}

export function getRelativeTime(givenDate: Date): string {
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0); // Reset time components to zero
  givenDate.setHours(0, 0, 0, 0); // Reset time components to zero
  const timeDiff = currentDate.getTime() - givenDate.getTime();
  const daysDiff = Math.floor(timeDiff / (1000 * 3600 * 24));

  if (daysDiff === 0) {
    return 'Today';
  } else if (daysDiff === 1) {
    return 'Yesterday';
  } else {
    return `${daysDiff} days ago`;
  }
}
