import { Form } from 'react-bootstrap';
import { ApprovalItem, RequestType } from '../../../../resources/approval-request/approval-request-types';
import DialogLayout from '../../../../shared/dialog-layout';
import useApprovalRequest from '../../../../resources/approval-request/approval-request-hook';
import { useEffect, useState } from 'react';
import ApprovalRequestService from '../../../../resources/approval-request/approval-request.service';
import LoadingComponent from '../../../../shared/loading-component';
import useToasts from '../../../../resources/toasts/toasts-hook';
import { useNavigate } from 'react-router-dom';

interface Props {
  scenarioMarketCodes?: string[];
  id: string;
  isOpen: boolean;
  onCancel: VoidFunction;
}

export default function ApproveRequestModal({ scenarioMarketCodes, id, isOpen, onCancel }: Props) {
  const { approvalRequestList, fetchApprovalRequestList } = useApprovalRequest();
  const [approvalItem, setApprovalItem] = useState<ApprovalItem | undefined>(undefined);
  const [observations, setObservations] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const { push: pushToast } = useToasts();
  const navigate = useNavigate();

  useEffect(() => {
    if (isOpen && approvalRequestList.data === undefined) {
      fetchApprovalRequestList({});
    }
  }, [approvalRequestList.data, fetchApprovalRequestList, isOpen]);

  useEffect(() => {
    const currentItem = approvalRequestList.data?.find(item => item.id === Number(id));
    setApprovalItem(currentItem);
  }, [approvalRequestList.data, id]);

  const buildSubtitle = () => {
    const subtitle = 'You are approving configurations on ';
    switch (approvalItem?.itemType) {
      case RequestType.StepViewer:
        return subtitle + `the ${approvalItem?.headerLabel} scenario`;
      case RequestType.ManualModel:
        return subtitle + `Manual Model ${approvalItem?.headerLabel} market`;
      case RequestType.MerchandisingRules:
        return subtitle + `Merchandising Rules ${approvalItem?.headerLabel}`;
    }
  };

  const onApprove = async () => {
    setLoading(true);
    let promise;
    switch (approvalItem?.itemType) {
      case RequestType.StepViewer:
        promise = ApprovalRequestService.scenarioTransitionRequest(
          {
            status: 'APPROVED',
            decisionReason: observations,
            id: Number(approvalItem?.versionId ?? 0),
            scenarioId: Number(approvalItem?.referenceId ?? 0),
          },
          scenarioMarketCodes,
        );
        break;
      case RequestType.ManualModel:
        promise = ApprovalRequestService.modelTransitionRequest({
          status: 'APPROVED',
          decisionReason: observations,
          modelId: Number(approvalItem?.referenceId ?? 0),
        });
        break;
      case RequestType.MerchandisingRules:
        promise = ApprovalRequestService.merchandisingRulesTransitionRequest({
          status: 'APPROVED',
          decisionReason: observations,
          approvalId: String(approvalItem?.id ?? 0),
        });
        break;
      default:
        promise = Promise.reject();
    }
    promise
      .then(() => {
        pushToast('Request approved successfully');
        navigate('/request-list');
        onCancel();
      })
      .catch(() => {
        pushToast('Error approving request', 'error');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <DialogLayout
      variant="outlined"
      maxWidth="lg"
      open={isOpen}
      title={'Confirm Approval'}
      subtitle={buildSubtitle()}
      className="approve-request-dialog"
      scapeActionText={'CANCEL'}
      scapeActionHandler={onCancel}
      confirmActionText={!loading ? 'CONFIRM APPROVAL' : <LoadingComponent />}
      confirmActionHandler={observations.length > 0 && !loading ? onApprove : undefined}>
      <Form.Group id="observation">
        <Form.Label>Observation</Form.Label>
        <Form.Control
          id="observation-input"
          type="text"
          value={observations}
          placeholder="Type observation"
          onChange={e => setObservations(e.target.value)}
          required
        />
      </Form.Group>
    </DialogLayout>
  );
}
