import { BaseSyntheticEvent, useCallback, useEffect, useRef } from 'react';
import { Form } from 'react-bootstrap';

type Props = {
  id: string;
  label: string;
  value?: string;
  minDate?: string;
  maxDate?: string;
  onChange: (event: string) => void;
};

export default function DatePickerComponent({ id, label, value, minDate, maxDate, onChange }: Props) {
  const ctrlRef = useRef<HTMLInputElement>();
  const handleChange = useCallback(
    (event: BaseSyntheticEvent<InputEvent, any, HTMLInputElement>) => {
      const { value }: { value: string } = event.target;

      if (value?.length) {
        onChange(value);
      }
    },
    [onChange],
  );

  useEffect(() => {
    if (ctrlRef?.current && !ctrlRef.current.value && value?.length) {
      ctrlRef.current.value = value;
    }
  }, [ctrlRef, value]);

  return (
    <Form.Group className="datepicker">
      <Form.Label htmlFor={id}>{label}</Form.Label>
      <Form.Control ref={ctrlRef as any} id={id} type="date" onChange={handleChange} min={minDate} max={maxDate} />
    </Form.Group>
  );
}
