import env from '../config/env';
import {
  DashboardMetricsResponse,
  DashboardRecommendationMetrics,
  DashboardRequest,
  DashboardUniqueSkusResponse,
} from '../interface/dashboard';
import { AuthorizedService } from './authorized.service';

class DashboardService extends AuthorizedService {
  public getRecommendationMetrics(request: DashboardRequest): Promise<DashboardMetricsResponse> {
    return super.post<DashboardMetricsResponse, DashboardRequest>('dashboard', request);
  }

  public getUniqueSkusRecommended(request: DashboardRequest): Promise<DashboardUniqueSkusResponse> {
    return super.post<DashboardUniqueSkusResponse, DashboardRequest>('dashboard/unique-skus-recommended', request);
  }

  public getRecommendationsByPageMetrics(request: DashboardRequest): Promise<DashboardRecommendationMetrics[]> {
    return super.post<DashboardRecommendationMetrics[], DashboardRequest>(
      'dashboard/recommendation-metrics-by-page',
      request,
    );
  }

  public getRecommendationsByScenarioMetrics(request: DashboardRequest): Promise<DashboardRecommendationMetrics[]> {
    return super.post<DashboardRecommendationMetrics[], DashboardRequest>(
      'dashboard/recommendation-metrics-by-scenario',
      request,
    );
  }

  public getRecommendationsByModelMetrics(request: DashboardRequest): Promise<DashboardRecommendationMetrics[]> {
    return super.post<DashboardRecommendationMetrics[], DashboardRequest>(
      'dashboard/recommendation-metrics-by-model',
      request,
    );
  }
}

const dashboardService = new DashboardService(env.ADMIN_API_URL);
export default dashboardService;
