import AdminConfigurations from '../routes/admin-configurations';
import CacheReloaderComponent from '../routes/cache-reloader';
import DashboardComponent from '../routes/dashboard';
import ManualModelComponent from '../routes/manual-model';
import ManualModelEditorScreen from '../routes/manual-model/manual-model-editor-screen';
import ManualModelTestScreen from '../routes/manual-model/manual-model-test-screen';
import MerchandisingRulesPage from '../routes/merchandising-rules';
import MerchandisingRulesConfiguration from '../routes/merchandising-rules/merchandising-rules-configuration';
import MerchandisingRulesTestScreen from '../routes/merchandising-rules/merchandising-rules-test-screen';
import RequestList from '../routes/request-list';
import ScenarioListComponent from '../routes/scenario-list';
import ScenarioDetailsScreen from '../routes/scenario-list/scenario-details-screen';
import ScenarioEditorScreen from '../routes/scenario-list/scenario-editor-screen';
import ScenarioVersionTesterScreen from '../routes/scenario-list/scenario-version-tester-screen';
import AuthenticatedLayout from '../shared/core/authenticated-layout';
import { Features } from './features';

export interface RouteData {
  label: string;
  path: string;
  /** If this is undefined, we have an unrestricted route */
  feature: Features;
  element: JSX.Element;
  Layout: any;
}

export const authenticatedRoutes: RouteData[] = [
  {
    label: 'Dashboard',
    path: '/dashboard',
    element: <DashboardComponent />,
    feature: Features.DashboardScreen,
    Layout: AuthenticatedLayout,
  },
  {
    label: 'Scenario list',
    path: '/scenario-list',
    element: <ScenarioListComponent />,
    feature: Features.ScenariosListScreen,
    Layout: AuthenticatedLayout,
  },
  {
    label: 'Scenario Details',
    path: '/scenario-list/:id',
    element: <ScenarioDetailsScreen />,
    feature: Features.ScenarioDetailsScreen,
    Layout: AuthenticatedLayout,
  },
  {
    label: 'Steps Configuration',
    path: '/scenario-list/:id/edit',
    element: <ScenarioEditorScreen />,
    feature: Features.ScenarioDetailsScreen,
    Layout: AuthenticatedLayout,
  },
  {
    label: 'Test Results',
    path: '/scenario-list/:id/edit/test',
    element: <ScenarioVersionTesterScreen />,
    feature: Features.ScenarioDetailsScreen,
    Layout: AuthenticatedLayout,
  },
  {
    // Secondary test route from History versioning
    label: 'Test Results ',
    path: '/scenario-list/:id/test/:scenarioVersionId?',
    element: <ScenarioVersionTesterScreen />,
    feature: Features.ScenarioDetailsScreen,
    Layout: AuthenticatedLayout,
  },
  {
    label: 'Cache reloader',
    path: '/cache-reloader',
    element: <CacheReloaderComponent />,
    feature: Features.CacheReloaderScreen,
    Layout: AuthenticatedLayout,
  },
  {
    label: 'Testing tool',
    path: '/testing-tool',
    element: <MerchandisingRulesTestScreen testingTool />,
    feature: Features.TestingToolScreen,
    Layout: AuthenticatedLayout,
  },
  {
    label: 'Admin Configurations',
    path: '/admin-configurations',
    element: <AdminConfigurations />,
    feature: Features.ManualModelScreen, // FIXME: Update to the correct feature
    Layout: AuthenticatedLayout,
  },
  {
    label: 'Manual Model',
    path: '/admin-configurations/manual-model',
    element: <ManualModelComponent />,
    feature: Features.ManualModelScreen,
    Layout: AuthenticatedLayout,
  },
  {
    label: 'Manual Model Configuration',
    path: '/admin-configurations/manual-model/:id/edit',
    element: <ManualModelEditorScreen />,
    feature: Features.ManualModelScreen,
    Layout: AuthenticatedLayout,
  },
  {
    label: 'Test Model Configurations',
    path: '/admin-configurations/manual-model/:id/edit/test/:modelVersionId?',
    element: <ManualModelTestScreen />,
    feature: Features.ManualModelScreen,
    Layout: AuthenticatedLayout,
  },
  {
    label: 'Merchandising Rules',
    path: '/admin-configurations/merchandising-rules',
    element: <MerchandisingRulesPage />,
    feature: Features.MerchandisingRulesScreen,
    Layout: AuthenticatedLayout,
  },
  {
    label: 'Merchandising Rules Configuration',
    path: '/admin-configurations/merchandising-rules/edit',
    element: <MerchandisingRulesConfiguration />,
    feature: Features.MerchandisingRulesScreen,
    Layout: AuthenticatedLayout,
  },
  {
    label: 'Merchandising Rules Test Screen',
    path: '/admin-configurations/merchandising-rules/edit/test',
    element: <MerchandisingRulesTestScreen />,
    feature: Features.MerchandisingRulesScreen,
    Layout: AuthenticatedLayout,
  },
  {
    label: 'Request List',
    path: '/request-list',
    element: <RequestList />,
    feature: Features.RequestListScreen,
    Layout: AuthenticatedLayout,
  },
];
