import { mdiCursorDefaultClickOutline, mdiHistory, mdiPencil } from '@mdi/js';
import Icon from '@mdi/react';
import React, { useEffect, useMemo, useState } from 'react';
import useScenarios from '../../../../resources/scenarios/scenarios-hook';
import { ScenarioSteps } from '../../../../resources/scenarios/scenarios-versions-models';
import Tooltip from '../../../../shared/tooltip';
import './index.scss';
import ReadonlyStepsTable from './readonly-steps-table';
import VersionsHistoryDrawer from './version-history-drawer';
import CancelRequest from '../../../../shared/cancel-request';
import DialogLayout from '../../../../shared/dialog-layout';
import Typography from '../../../../shared/typography';
import ApprovalRequestService from '../../../../resources/approval-request/approval-request.service';

interface Props {
  scenarioId: number;
  scenarioSteps: ScenarioSteps;
  onEditClick: VoidFunction;
}

const ReadonlyScenarioSteps: React.FC<Props> = props => {
  const { scenarioId, scenarioSteps, onEditClick } = props;
  const { draftScenarioVersion, scenarioVersionHistory, fetchScenarioDraftVersion, fetchScenarioVersionHistory } =
    useScenarios();
  const [isHistoryDrawerOpen, setIsHistoryDrawerOpen] = useState(false);
  const hasChanges = useMemo(() => !!draftScenarioVersion.data, [draftScenarioVersion.data]);
  const [isCancelRequestOpen, setIsCancelRequestOpen] = useState(false);

  useEffect(() => {
    fetchScenarioDraftVersion(scenarioId);
    fetchScenarioVersionHistory(scenarioId);
  }, [fetchScenarioDraftVersion, fetchScenarioVersionHistory, scenarioId]);

  const handleOpenHistoryDrawer = () => {
    setIsHistoryDrawerOpen(true);
  };

  const handleCloseHistoryDrawer = () => {
    setIsHistoryDrawerOpen(false);
  };

  const onCancel = () => {
    setIsCancelRequestOpen(true);
  };

  const onConfirmCancel = () => {
    ApprovalRequestService.scenarioCancelRequest({
      scenarioId: scenarioId,
      id: Number(draftScenarioVersion.data!.id),
    }).then(() => {
      fetchScenarioDraftVersion(scenarioId);
      setIsCancelRequestOpen(false);
    });
  };

  return (
    <>
      <section>
        {draftScenarioVersion && draftScenarioVersion.data && draftScenarioVersion.data.approvalRequestId && (
          <CancelRequest
            text={'Steps Configuration in Pending Analysis'}
            cancelActionHandler={onCancel}></CancelRequest>
        )}
        <header className="actions-container">
          <button className="action-button" onClick={handleOpenHistoryDrawer}>
            <Icon path={mdiHistory} size={1} color="var(--bg-white)" />
          </button>
          <Tooltip
            disabled={!hasChanges}
            title={
              <>
                Continue Editing
                <Icon className="ms-1" path={mdiCursorDefaultClickOutline} size="16px" color="var(--bg-white)" />
              </>
            }>
            <button
              disabled={draftScenarioVersion.data?.approvalRequestId ? true : false}
              onClick={onEditClick}
              className={`action-button ${hasChanges ? 'has-changes' : ''}`}>
              <Icon size={1} color={'var(--bg-white)'} path={mdiPencil} />
            </button>
          </Tooltip>
        </header>
        <ReadonlyStepsTable scenarioSteps={scenarioSteps} />
      </section>
      <VersionsHistoryDrawer
        isHistoryDrawerOpen={isHistoryDrawerOpen}
        handleCloseHistoryDrawer={handleCloseHistoryDrawer}
        scenarioVersionHistory={scenarioVersionHistory}
      />
      <DialogLayout
        open={isCancelRequestOpen}
        title={'Cancel Request'}
        subtitle={'Steps Configuration in Pending Analysis'}
        className="cancel-request-dialog"
        scapeActionText={'CLOSE'}
        scapeActionHandler={() => setIsCancelRequestOpen(false)}
        confirmActionText={'CANCEL AND CONFIGURE'}
        confirmActionHandler={onConfirmCancel}
        maxWidth={'sm'}>
        <Typography colorVariant="primary">
          To start a new configuration, you need to cancel your request or wait for analysis to end. All configurations
          will be lost if cancel the current request.
        </Typography>
      </DialogLayout>
    </>
  );
};

export default ReadonlyScenarioSteps;
