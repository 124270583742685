import { createContext } from 'react';
import { ExternalData } from '../../@types/external-api';
import { ModelOutputRequest } from '../../interface/model';
import { ModelOutput, ModelVersion } from './model-output-models';

export interface ModelOutputContextType {
  models: ExternalData<ModelOutput[]>;
  modelVersionHistory: ExternalData<ModelVersion[]>;
  draftModelVersion: ExternalData<ModelVersion>;
  fetchModels: (marketId: number, status: string) => void;
  saveManualModel: (req: ModelOutputRequest) => Promise<void>;
  editManualModel: (req: ModelOutputRequest) => Promise<void>;
  deleteModel: (modelId: number) => Promise<void>;
  fetchKeyTypes: () => Promise<void>;
  getModelOutputVersionById: (modelId: number) => VoidFunction | undefined;
  fetchModelVersionHistory: (marketId: number) => Promise<void>;
  fetchDraftModelVersion: (marketId: number, modelVersionId?: number) => void;
  discardModelDraftVersion: (marketId: number) => void;
  sendModelVersionToApproval: (
    marketId: number,
    versionId: number,
    title: string,
    description: string,
  ) => Promise<void>;
  checkDraftModelVersion: (marketId: number, versionId: number) => Promise<void>;
}

const ModelOutputContext = createContext<ModelOutputContextType>(null as any);

export default ModelOutputContext;
