import axios from 'axios';
import env from '../config/env';
import { Brand } from '../interface/brand';
import { Category } from '../interface/category';
import { Channel } from '../interface/channel';
import { Container } from '../interface/container';
import { Market } from '../interface/market';
import { Page } from '../interface/page';
import { AuthorizedService } from './authorized.service';
import { WithAbortFn } from '../@types/external-api';

class AdminService extends AuthorizedService {
  public getMarkets(): WithAbortFn<Promise<Market[]>> {
    const source = axios.CancelToken.source();

    return { promise: super.get<Market[]>('markets', { cancelToken: source.token }), abort: source.cancel };
  }

  public getChannels(): WithAbortFn<Promise<Channel[]>> {
    const source = axios.CancelToken.source();

    return { promise: super.get<Channel[]>('channels', { cancelToken: source.token }), abort: source.cancel };
  }

  public getContainers(pageIds: Array<string | number>): Promise<Container[]> {
    return super.get<Container[]>('containers', {
      params: { pageIds: pageIds.join(',') },
    });
  }

  public getPages(marketIds: Array<string | number>, channelIds: Array<string | number>): WithAbortFn<Promise<Page[]>> {
    const source = axios.CancelToken.source();

    return {
      promise: super.get<Page[]>('pages', {
        params: { marketIds: marketIds.join(','), channelIds: channelIds.join(',') },
        cancelToken: source.token,
      }),
      abort: source.cancel,
    };
  }

  public getBrands(marketId: number): Promise<Brand[]> {
    return super.get<Brand[]>('brands', { params: { marketId } });
  }

  public getCategories(marketId: number): Promise<Category[]> {
    return super.get<Category[]>('categories', { params: { marketId } });
  }

  public getProductBrands(productId: number): Promise<Brand[]> {
    return super.get<Brand[]>(`product/${productId}/brands`);
  }

  public getProductCategories(productId: number): Promise<Category[]> {
    return super.get<Category[]>(`product/${productId}/categories`);
  }
}

const adminService = new AdminService(env.ADMIN_API_URL);
export default adminService;
