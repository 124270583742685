import { ApprovalItem, ApprovalStatus, RequestStatusEnum, RequestType } from './approval-request-types';

export const approvalRequestMapper = (apiData: any, requestType: RequestType): ApprovalItem[] => {
  return apiData.map((item: any) => ({
    id: item.id,
    createdDt: new Date(item.createdDt),
    updatedDt: new Date(item.updatedDt),
    createdBy: item.createdBy,
    updatedBy: item.updatedBy,
    status: item.status,
    title: item.title,
    requestReason: item.requestReason,
    decisionReason: item.decisionReason,
    referenceId: item.referenceId,
    headerLabel: item.headerLabel,
    itemType: requestType,
    versionId: item?.versionId,
    marketId: item?.marketId,
  }));
};

// Helper function to map the API enum to the Accordion enum
export const mapEnumToRequestStatus = (status: ApprovalStatus): RequestStatusEnum => {
  switch (status.toLocaleLowerCase()) {
    case ApprovalStatus.PendingApproval.toLowerCase().replace('_', ''):
      return RequestStatusEnum.Pending;
    case ApprovalStatus.Approved.toLowerCase():
      return RequestStatusEnum.Approved;
    case ApprovalStatus.Rejected.toLowerCase():
      return RequestStatusEnum.Rejected;
    case ApprovalStatus.Canceled.toLowerCase():
      return RequestStatusEnum.Canceled;
    default:
      return RequestStatusEnum.Pending;
  }
};
