import { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { Features } from '../../../config/features';
import { FeatureMarkets } from '../../../config/feature-markets';
import { WithScopedFeaturesProxy } from '../../../helpers/with-features-proxy';
import adminService from '../../../service/admin.service';
import MultiSelectDropdown, { Item } from '../../../shared/multi-select-dropdown';
import './index.css';

export interface ScenarioListFormFields {
  markets?: string[];
  channels?: string[];
  pages?: string[];
  containers?: string[];
}

export interface ScenarioListFormFieldsOnChangeHandlers {
  handleMarketsChanges: (value?: string[]) => void;
  handleChannelsChanges: (value?: string[]) => void;
  handlePagesChanges: (value?: string[]) => void;
  handleContainersChanges: (value?: string[]) => void;
}

interface ExposedProps {
  values: ScenarioListFormFields;
  onChange: ScenarioListFormFieldsOnChangeHandlers;
  onSubmit: VoidFunction;
}

interface Props extends ExposedProps {
  availableMarkets: FeatureMarkets[];
}

function ScenarioListFormComponent(props: Props) {
  const {
    onSubmit,
    values: {
      markets: selectedMarkets,
      channels: selectedChannels,
      pages: selectedPages,
      containers: selectedContainers,
    },
    availableMarkets,
    onChange: { handleChannelsChanges, handleContainersChanges, handleMarketsChanges, handlePagesChanges },
  } = props;
  const [markets, setMarkets] = useState<Item[]>();
  const [channels, setChannels] = useState<Item[]>();
  const [pages, setPages] = useState<Item[]>();
  const [containers, setContainers] = useState<Item[]>();

  useEffect(() => {
    if (pages && selectedPages) {
      // handlePagesChanges(pages.map(page => page.value).filter(page => selectedPages.includes(page)));
    }
  }, [handlePagesChanges, pages, selectedPages]);

  // useEffect(() => {
  //   handleContainersChanges([]);
  // }, [handleContainersChanges, containers]);

  const loadMarkets = useCallback(() => {
    const { promise } = adminService.getMarkets();

    promise.then(response => {
      setMarkets(
        response
          .filter(market => availableMarkets.includes(market.code as any))
          .map(item => ({ name: item.name, value: item.id.toString() })),
      );
    });
  }, [availableMarkets]);

  const loadChannels = useCallback(() => {
    const { promise } = adminService.getChannels();
    promise.then(response => {
      setChannels(response.map(item => ({ name: item.name, value: item.id.toString() })));
    });
  }, []);

  const isButtonDisabled = useMemo<boolean>(() => {
    return (
      !selectedMarkets?.length || !selectedChannels?.length || !selectedPages?.length || !selectedContainers?.length
    );
  }, [selectedMarkets, selectedChannels, selectedPages, selectedContainers]);

  useEffect(() => {
    loadMarkets();
    loadChannels();
  }, [loadMarkets, loadChannels]);

  useEffect(() => {
    if (selectedMarkets?.length && selectedChannels?.length) {
      const { promise } = adminService.getPages(selectedMarkets, selectedChannels);
      promise.then(response => {
        setPages(response.map(item => ({ name: item.name, value: item.id.toString() })));
      });
    } else {
      setPages([]);
      handlePagesChanges([]);
    }
  }, [selectedMarkets, selectedChannels, handlePagesChanges]);

  useEffect(() => {
    if (selectedPages?.length) {
      adminService.getContainers(selectedPages).then(response => {
        setContainers(response.map(item => ({ name: item.name, value: item.id.toString() })));
      });
    } else {
      setContainers([]);
      handleContainersChanges(undefined);
    }
  }, [handleContainersChanges, selectedPages]);

  return (
    <Form className="scenario-list-form">
      <Row className="mb-5">
        <Col>
          <MultiSelectDropdown
            id="dropdown-market"
            variant="tertiary"
            required={true}
            none
            disabled={!markets}
            all={true}
            label="Market"
            placeholder="Select Market"
            items={markets}
            value={selectedMarkets}
            onChange={handleMarketsChanges}
          />
        </Col>
        <Col>
          <MultiSelectDropdown
            id="dropdown-channel"
            variant="tertiary"
            required={true}
            none
            disabled={!channels}
            all={true}
            label="Channel"
            placeholder="Select Channel"
            items={channels}
            value={selectedChannels}
            onChange={handleChannelsChanges}
          />
        </Col>
        <Col>
          <MultiSelectDropdown
            id="dropdown-page"
            variant="tertiary"
            required={true}
            none
            disabled={!pages || pages.length === 0}
            all={true}
            label="Page"
            placeholder="Select Page"
            items={pages}
            value={selectedPages}
            onChange={handlePagesChanges}
          />
        </Col>
        <Col>
          <MultiSelectDropdown
            id="dropdown-container"
            variant="tertiary"
            required={true}
            none
            disabled={!containers || containers.length === 0}
            all={true}
            label="Container"
            placeholder="Select Container"
            items={containers}
            value={selectedContainers}
            onChange={handleContainersChanges}
          />
        </Col>
      </Row>
      <Row className="submit">
        <Col className="d-flex justify-content-center">
          <Button onClick={onSubmit} disabled={isButtonDisabled}>
            UPDATE
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

export default WithScopedFeaturesProxy<ExposedProps>(Features.ScenariosListScreen)((props, _, markets) => {
  return <ScenarioListFormComponent {...props} availableMarkets={markets} />;
});
