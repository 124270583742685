import { mdiArrowDown, mdiArrowUp } from '@mdi/js';
import { useMemo } from 'react';
import IconButton from '../../../../../shared/icon-btn';
import { MerchRuleConfig } from '../../../../../resources/merch-rules/merch-rules-types';

export enum Direction {
  UP,
  DOWN,
}

export interface Props<T = MerchRuleConfig> {
  rule: T;
  rulesList: Array<T>;
  onMove: (stepId: number, dir: Direction) => void;
}

const EditorMoverCell: React.FC<Props> = props => {
  const { rule, rulesList, onMove } = props;

  const stepIdx = useMemo(
    () => rulesList.findIndex(_step => _step.priority === rule.priority),
    [rule.priority, rulesList],
  );

  const cannotMoveDown = useMemo(() => stepIdx === rulesList.length - 1, [stepIdx, rulesList.length]);
  const cannotMoveUp = useMemo(() => {
    if (stepIdx === 0) return true;
  }, [stepIdx]);

  return (
    <span className="rule-move-cell">
      <IconButton
        onClick={() => onMove(rule.priority, Direction.UP)}
        mdiIconPath={mdiArrowUp}
        size={'20px'}
        color={cannotMoveUp ? 'var(--warning-disable)' : 'var(--primary)'}
        disabled={cannotMoveUp}
      />
      <IconButton
        onClick={() => onMove(rule.priority, Direction.DOWN)}
        mdiIconPath={mdiArrowDown}
        size={'20px'}
        color={cannotMoveDown ? 'var(--warning-disable)' : 'var(--primary)'}
        disabled={cannotMoveDown}
      />
    </span>
  );
};

export default EditorMoverCell;
