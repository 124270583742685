import { mdiPlus } from '@mdi/js';
import Icon from '@mdi/react';
import { Typography } from '@mui/material';
import _, { DebouncedFunc } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { makeScenarioParentStep, makeScenarioStep } from '../../../../resources/scenarios/scenarios-helpers';
import { ScenarioDetails } from '../../../../resources/scenarios/scenarios-models';
import {
  BaseExecutor,
  NewParentStep,
  ScenarioParentStep,
  ScenarioStep,
  ScenarioSteps,
} from '../../../../resources/scenarios/scenarios-versions-models';
import Button from '../../../../shared/button';
import InlineNotification from '../../../../shared/inline-notification';
import LoadingComponent from '../../../../shared/loading-component';
import PaginatedTableComponent from '../../../../shared/paginated-table';
import { moveIfCan } from '../../../../utils/array.utils';
import { deepCopy } from '../../../../utils/data.utils';
import { getDateString } from '../../../../utils/date.utils';
import { Direction } from './components/step-mover-cell';
import './index.scss';
import AddChildStepModal from './modals/add-child-step-modal';
import AddParentStepModal from './modals/add-parent-step-modal';
import DeleteStepModal from './modals/delete-step-modal';
import CancelChangesModal from './modals/save-changes-modal';
import {
  beforeChevronColumns,
  columns,
  discoverStep,
  isScenarioChildStep,
  StepAdderOrientation,
} from './scenario-steps-helpers';
import { useStepsRowsDataManager } from './use-steps-rows-data-manager';

const CHANGES_DEBOUNCE_TIME = 5000;

interface Props {
  scenarioDetails?: ScenarioDetails;
  scenarioSteps: ScenarioSteps;
  handleGoBack: (latestData: ScenarioSteps) => void;
  handleReset: () => void;
  handleGoToTests?: (latestData: ScenarioSteps) => void;
  handleSave: (data: ScenarioSteps) => void;
  isSaving: boolean;
  className?: string;
  lastUpdateAt?: Date;
}

function EditableScenarioSteps(props: Props) {
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    isSaving,
    scenarioDetails,
    scenarioSteps,
    className = '',
    handleSave,
    handleGoToTests,
    handleReset,
    lastUpdateAt,
  } = props;
  const [editingScenarioSteps, setEditingScenarioSteps] = useState<ScenarioSteps>(scenarioSteps);
  const [isAddParentStepModalOpen, setIsAddParentStepModalOpen] = useState(false);
  const [toDeleteStep, setToDeleteStep] = useState<ScenarioParentStep | ScenarioStep>();
  const [toAddChildParentStep, setToAddChildParentStep] = useState<ScenarioParentStep>();
  const [toAddStepStepRef, setToAddStepStepRef] = useState<{
    step: ScenarioParentStep | ScenarioStep;
    orientation: StepAdderOrientation;
  }>();
  const [isResetChangesModalOpen, setIsResetChangesModalOpen] = useState(false);
  const debouncedRef = useRef<DebouncedFunc<() => void> | null>(null);

  useEffect(() => {
    debouncedRef.current = _.debounce(() => {
      handleSave(editingScenarioSteps!);
    }, CHANGES_DEBOUNCE_TIME);

    debouncedRef.current();

    return () => {
      debouncedRef.current?.cancel();
    };
  }, [handleSave, editingScenarioSteps]);

  useEffect(() => {
    if (scenarioSteps) {
      setEditingScenarioSteps(deepCopy<ScenarioSteps>(scenarioSteps) ?? []);
    }
  }, [scenarioSteps]);

  useEffect(() => {
    if (toAddStepStepRef) {
      if (isScenarioChildStep(toAddStepStepRef.step)) {
        const parentStep = editingScenarioSteps!.find(
          item => !!item.subSteps.find(subStep => subStep.id === toAddStepStepRef.step.id),
        );
        setToAddChildParentStep(parentStep);
      } else {
        setIsAddParentStepModalOpen(true);
      }
    }
  }, [editingScenarioSteps, isAddParentStepModalOpen, toAddStepStepRef]);

  const handleStepOrderChange = useCallback(
    (stepId: number, dir: Direction) => {
      const { list, step } = discoverStep(stepId, editingScenarioSteps!);
      const stepIdx = list.indexOf(step);
      const convertedDir = dir === Direction.UP ? -1 : 1;

      setEditingScenarioSteps(parentSteps => {
        if (parentSteps === list) {
          return moveIfCan(parentSteps.slice(), stepIdx, convertedDir);
        }

        return parentSteps!.map(parentStep =>
          parentStep.subSteps === list
            ? {
                ...parentStep,
                subSteps: moveIfCan(parentStep.subSteps.slice(), stepIdx, convertedDir),
              }
            : parentStep,
        );
      });
    },
    [editingScenarioSteps],
  );

  const handleCancel = () => {
    handleReset();
  };

  const handleOnTryToDeleteStep = (step: ScenarioParentStep | ScenarioStep) => {
    setToDeleteStep(step);
  };

  const handleCancelDeleteStep = () => {
    setToDeleteStep(undefined);
  };

  const handleDeleteStep = () => {
    if (isScenarioChildStep(toDeleteStep!)) {
      setEditingScenarioSteps(steps =>
        steps?.map(parentStep =>
          !!parentStep.subSteps.find(subStep => subStep.id === toDeleteStep!.id)
            ? {
                ...parentStep,
                subSteps: parentStep.subSteps.filter(subsStep => subsStep.id !== toDeleteStep!.id),
              }
            : parentStep,
        ),
      );
    } else {
      setEditingScenarioSteps(steps => steps?.filter(parentStep => parentStep.id !== toDeleteStep?.id));
    }

    setToDeleteStep(undefined);
  };

  const handleEditParentStep = (parentStep: ScenarioParentStep, newName?: string) => {
    setEditingScenarioSteps(steps =>
      steps?.map(step => (step.id === parentStep.id ? { ...step, name: newName } : step)),
    );
  };

  const handleOnTryAddChildStep = (parentStep: ScenarioParentStep) => {
    setToAddChildParentStep(parentStep);
  };

  const handleAddChildStep = (parentStep: ScenarioParentStep, step: BaseExecutor) => {
    const scenarioStep = makeScenarioStep(step);
    setEditingScenarioSteps(parentSteps =>
      parentSteps!.map(item => {
        const isTheOne = item.id === parentStep.id;

        if (!isTheOne) return item;

        if (!toAddStepStepRef) {
          return {
            ...item,
            subSteps: [...item.subSteps, scenarioStep],
          };
        }

        const stepRefIdx = item.subSteps!.findIndex(_item => _item.id === toAddStepStepRef.step.id)!;
        const inc = toAddStepStepRef.orientation === StepAdderOrientation.After ? 1 : 0;

        return {
          ...item,
          subSteps: [
            ...item.subSteps.slice(0, stepRefIdx + inc),
            scenarioStep,
            ...item.subSteps.slice(stepRefIdx + inc),
          ],
        };
      }),
    );
    setToAddStepStepRef(undefined);
    setToAddChildParentStep(undefined);
  };

  const handleCancelAddChildStep = () => {
    setToAddChildParentStep(undefined);
  };

  const handleOnTryAddNewParentStep = () => {
    setIsAddParentStepModalOpen(true);
  };

  const handleAddParentStep = (parentStep: NewParentStep) => {
    const scenarioParentStep: ScenarioParentStep = makeScenarioParentStep(parentStep);

    if (toAddStepStepRef) {
      setEditingScenarioSteps(parentSteps => {
        const stepRefIdx = parentSteps!.findIndex(item => item.id === toAddStepStepRef.step.id)!;
        const inc = toAddStepStepRef.orientation === StepAdderOrientation.After ? 1 : 0;

        return [
          ...parentSteps!.slice(0, stepRefIdx + inc),
          scenarioParentStep,
          ...parentSteps!.slice(stepRefIdx + inc),
        ];
      });
      setToAddStepStepRef(undefined);
    } else {
      setEditingScenarioSteps(parentSteps => [scenarioParentStep, ...parentSteps!]);
    }
    setIsAddParentStepModalOpen(false);
  };

  const handleOnTryAddStep = (refStep: ScenarioParentStep | ScenarioStep, orientation: StepAdderOrientation) => {
    setToAddStepStepRef({
      step: refStep,
      orientation,
    });
  };

  const handleCancelAddParentStep = () => {
    if (toAddStepStepRef) {
      setToAddStepStepRef(undefined);
    }
    setIsAddParentStepModalOpen(false);
  };

  const handleTryToGoBack = () => {
    debouncedRef.current?.cancel();
    navigate(`/scenario-list/${id}`);
  };

  const handleTryToGoToTests = () => {
    debouncedRef.current?.cancel();
    handleGoToTests?.(editingScenarioSteps);
  };

  const handleTryToResetChanges = () => {
    setIsResetChangesModalOpen(true);
  };

  const handleResetChangesModalConfirm = () => {
    handleCancel();
    setIsResetChangesModalOpen(false);
  };

  const handleResetChangesModalCancel = () => {
    setIsResetChangesModalOpen(false);
  };

  const stepRows = useStepsRowsDataManager(
    editingScenarioSteps ?? [],
    {
      handleOrderChange: handleStepOrderChange,
      handleDeleteStep: handleOnTryToDeleteStep,
      handleEditParentStep: handleEditParentStep,
      handleAddChildStep: handleOnTryAddChildStep,
      handleAddStep: handleOnTryAddStep,
    },
    scenarioDetails!,
  );

  return (
    <>
      <InlineNotification
        title='Click on "Test Changes" after configuring to test before sending the request.'
        hidden={!lastUpdateAt}
        description={
          <div className="save-inline-notification-description">
            <Typography>
              Auto-saved edits, last update on {lastUpdateAt ? getDateString(lastUpdateAt.toISOString()) : ''}
            </Typography>
            {isSaving && <LoadingComponent className="ms-2 spinner" />}
          </div>
        }
        className="my-4"
      />
      <section className={`scenario-steps-container ${className}`}>
        <Button variant="quaternary" className="add-parent-step-btn" onClick={handleOnTryAddNewParentStep}>
          <Icon path={mdiPlus} size={0.8} />
          ADD STEP
        </Button>
        <PaginatedTableComponent
          columns={columns}
          rows={stepRows}
          rowIdPropName="id"
          collapsible={true}
          collapsibleRowsPropName="subSteps"
          collapsibleBeforeChevronColumns={beforeChevronColumns}
          withoutPagination
          className="steps-table-container"
        />
        <footer className="actions-footer mt-3">
          <Button onClick={handleTryToGoBack} variant="quaternary" className="back-btn">
            BACK TO SCENARIO DETAILS
          </Button>
          <div>
            <Button onClick={handleTryToResetChanges} variant="quaternary" className="reset-changes-btn">
              RESET CHANGES
            </Button>
            <Button
              disabled={handleGoToTests === undefined || editingScenarioSteps.length === 0}
              onClick={handleTryToGoToTests}>
              TEST CHANGES
            </Button>
          </div>
        </footer>
        <DeleteStepModal
          isOpen={!!toDeleteStep}
          step={toDeleteStep!}
          onCancel={handleCancelDeleteStep}
          onDelete={handleDeleteStep}
        />
        <AddChildStepModal
          isOpen={!!toAddChildParentStep}
          parentStep={toAddChildParentStep!}
          onAdd={handleAddChildStep}
          onCancel={handleCancelAddChildStep}
        />
        <AddParentStepModal
          isOpen={isAddParentStepModalOpen}
          onAdd={handleAddParentStep}
          onCancel={handleCancelAddParentStep}
        />
        <CancelChangesModal
          isOpen={isResetChangesModalOpen}
          onSaveAndLeave={handleResetChangesModalConfirm}
          onCancel={handleResetChangesModalCancel}
        />
      </section>
    </>
  );
}

export default EditableScenarioSteps;
