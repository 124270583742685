import { apiWrapper } from '../../config/api-wrapper';
import { RecommendationResponse } from './recommendation-models';
import { RecommendationParamsRequest } from './recommendation-types';

class RecommendationService {
  public getRecommendationsWithTestParams(
    marketCode: string,
    scenarioId: number,
    params: RecommendationParamsRequest,
  ): Promise<RecommendationResponse> {
    return apiWrapper.api
      .post(`${marketCode.toLowerCase()}/api/recommender/test`, params)
      .then(response => response.data)
      .then(data => {
        const { startDt = '0', endDt = '0' } = data ?? {};
        const executionTime = +endDt - +startDt;

        return {
          scenarioId,
          executionTime,
          recommendations: data.recommendations,
          disabledRecommendations: data?.disabledRecommendations,
        };
      });
  }
}

const recommendationService = new RecommendationService();
export default recommendationService;
