import { useCallback, useState } from 'react';
import MerchRulesContext, { FetchRulesProps } from './merch-rules-context';
import { ExternalData } from '../../@types/external-api';
import { AffectedByMerchRules, InitialTableListRules, MerchRuleVersionType } from './merch-rules-types';
import {
  makeExternalCallErrorData,
  makeExternalDataInitialData,
  makeExternalDataSuccessData,
} from '../../helpers/external-data';
import merchRulesService from './merch-rules.service';

const MerchRulesProvider: React.FC = ({ children }) => {
  const [rules, setRules] = useState<ExternalData<InitialTableListRules[]>>(makeExternalDataInitialData());
  const [liveRules, setLiveRules] = useState<ExternalData<InitialTableListRules[]>>(makeExternalDataInitialData());
  const [affectedByMerchRules, setAffectedByMerchRules] = useState<ExternalData<AffectedByMerchRules>>(
    makeExternalDataInitialData(),
  );

  const fetchRules = useCallback(({ type, marketId, pageIds, productIds, containerIds }: FetchRulesProps) => {
    setRules(makeExternalDataInitialData());

    let abortFn = () => {};

    try {
      if (type === 'container' && pageIds) {
        const { promise, abort } = merchRulesService.getContainerRules(pageIds);
        promise.then(data => setRules(makeExternalDataSuccessData(data)));
        abortFn = abort;
      } else if (type === 'container' && containerIds) {
        const { promise, abort } = merchRulesService.getContainerRulesbyContainerId(containerIds);
        promise.then(data => setRules(makeExternalDataSuccessData(data)));
        abortFn = abort;
      } else if (type === 'sku' && marketId) {
        const { promise, abort } = merchRulesService.getSkuRules(marketId);
        promise.then(data => setRules(makeExternalDataSuccessData(data)));
        abortFn = abort;
      } else if (type === 'sku' && productIds) {
        const { promise, abort } = merchRulesService.getSkuRulesbyProductId(productIds);
        promise.then(data => setRules(makeExternalDataSuccessData(data)));
        abortFn = abort;
      }
    } catch (err: any) {
      setRules(makeExternalCallErrorData(err));
    }

    return abortFn;
  }, []);

  const fetchRulesByVersionIds = useCallback((versionIds: string[]) => {
    setRules(makeExternalDataInitialData());
    try {
      const { promise, abort } = merchRulesService.getRulesByVersionIds(versionIds);
      promise.then(data => setRules(makeExternalDataSuccessData(data)));
      return abort;
    } catch (err: any) {
      setRules(makeExternalCallErrorData(err));
    }
  }, []);

  const fetchLiveRulesByItemIds = useCallback((itemIds: string[], type: MerchRuleVersionType, requestType: string) => {
    setLiveRules(makeExternalDataInitialData());
    try {
      const { promise, abort } = merchRulesService.getLiveRulesByItemIds(itemIds, type, requestType);
      promise.then(data => setLiveRules(makeExternalDataSuccessData(data)));
      return abort;
    } catch (err: any) {
      setLiveRules(makeExternalCallErrorData(err));
    }
  }, []);

  const fetchAffectedByMerchRules = useCallback(
    ({ containerIds, skuIds, versionIds }: { containerIds?: string[]; skuIds?: string[]; versionIds?: string[] }) => {
      setAffectedByMerchRules(makeExternalDataInitialData());
      try {
        const { promise, abort } = merchRulesService.getAffectedByMerchRules(containerIds, skuIds, versionIds);
        promise.then(data => setAffectedByMerchRules(makeExternalDataSuccessData(data)));
        return abort;
      } catch (err: any) {
        setAffectedByMerchRules(makeExternalCallErrorData(err));
      }
    },
    [],
  );

  const resetRules = useCallback(() => {
    setRules(makeExternalDataInitialData());
  }, []);

  const resetToLiveRules = useCallback((itemId: string, type: MerchRuleVersionType) => {
    setRules(makeExternalDataInitialData());

    merchRulesService.deleteUserDraft(itemId, type);

    const { promise } = merchRulesService.getLiveRulesByItemIds([itemId], type, 'itemId');
    promise.then(data => setRules(makeExternalDataSuccessData(data)));
  }, []);

  return (
    <MerchRulesContext.Provider
      value={{
        rules,
        liveRules,
        affectedByMerchRules,
        fetchAffectedByMerchRules,
        fetchRules,
        fetchLiveRulesByItemIds,
        fetchRulesByVersionIds,
        resetRules,
        resetToLiveRules,
      }}>
      {children}
    </MerchRulesContext.Provider>
  );
};

export default MerchRulesProvider;
