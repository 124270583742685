import { BrowserRouter } from 'react-router-dom';
import './App.css';
import AuthProvider from './resources/auth/auth-provider';
import FeaturesProvider from './resources/features/features-provider';
import ScenariosProvider from './resources/scenarios/scenarios-provider';
import ToastsProvider from './resources/toasts/toasts-provider';
import Router from './router';
import WithProviders from './shared/hocs/with-providers';
import RecommendationsProvider from './resources/recommendation/recommendation-provider';
import ModelOutputProvider from './resources/model-output/model-output-provider';
import ApprovalRequestProvider from './resources/approval-request/approval-request-provider';
import MerchRulesProvider from './resources/merch-rules/merch-rules-provider';

export default function App() {
  return (
    <WithProviders
      Providers={[
        BrowserRouter,
        ToastsProvider,
        AuthProvider,
        FeaturesProvider,
        ScenariosProvider,
        RecommendationsProvider,
        ModelOutputProvider,
        ApprovalRequestProvider,
        MerchRulesProvider,
      ]}>
      <Router />
    </WithProviders>
  );
}
