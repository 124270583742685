import { ScenarioParentStep } from '../../../../../../../resources/scenarios/scenarios-versions-models';
import DialogLayout from '../../../../../../../shared/dialog-layout';
import Typography from '../../../../../../../shared/typography';
import StepViewer from '../../../components/step-viewer';

interface Props {
  isOpen: boolean;
  step: ScenarioParentStep;
  onCancel: VoidFunction;
  onDelete: VoidFunction;
}

function DeleteParentStepModal(props: Props) {
  const { isOpen, onCancel, onDelete, step } = props;

  return (
    <DialogLayout
      open={isOpen}
      title="Delete Step"
      subtitle="You are deleting the parent-step block below:"
      variant="outlined"
      scapeActionText="CANCEL"
      scapeActionHandler={onCancel}
      confirmActionText="DELETE"
      confirmActionHandler={onDelete}>
      <section className="delete-step-modal-container">
        <StepViewer step={step} className="mb-4" />
        <Typography colorVariant="primary">
          This action will permanently delete this Step and you can add a new one on the Step Viewer.
        </Typography>
      </section>
    </DialogLayout>
  );
}

export default DeleteParentStepModal;
