import { randomFloatId } from '../../utils/id.utils';
import { BaseExecutor, NewParentStep, ScenarioParentStep, ScenarioStep } from './scenarios-versions-models';

export const makeScenarioStep = (executor: BaseExecutor): ScenarioStep => ({
  id: randomFloatId(),
  name: executor.name,
  executorId: executor.id,
  standard: false,
  executorType: executor.type,
  modelName: executor.modelName,
});

export const makeScenarioParentStep = (parentStep: NewParentStep): ScenarioParentStep => ({
  id: randomFloatId(),
  subSteps: parentStep.subSteps.map(subStep => ({
    id: randomFloatId(),
    executorId: subStep.executorId,
    name: subStep.name,
    executorType: subStep.executorType,
    modelName: subStep.modelName,
    standard: true,
    type: subStep.executorType,
    description: subStep.executorType,
  })),
});
