import React from 'react';
import LogoLoadingComponent from '../../shared/logo-loading-component';
import './index.scss';

const LoadingScreen: React.FC = () => {
  return (
    <main className="loading-screen">
      <LogoLoadingComponent />
    </main>
  );
};

export default LoadingScreen;
